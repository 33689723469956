import { Button, Card, Table, Tag, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { format } from "date-fns"
import qs from "qs"
import { ReactElement, useEffect, useState } from "react"
import { Bars } from "react-loader-spinner"
import recordHttpService from "../../../../services/https/apis/records.http.service"
import { RecordStatus } from "../../../../types/typings.d"

interface LatestRecordsProps {}

export default function LatestRecords(props: LatestRecordsProps): ReactElement {
  const [state, setState] = useState<{
    loading: boolean
    error: any
    data?: any[]
  }>({
    loading: false,
    error: null,
    data: [],
  })

  const fetchData = () => {
    setState({ ...state, loading: true, error: null })
    const query = qs.stringify(
      {
        sort: ["updatedAt:desc"],
        filters: {},
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    recordHttpService
      .fetchRecords(query, {
        params: {
          pagination: {
            limit: 7,
          },
        },
      })
      .then((res) => {
        setState({ ...state, loading: false, data: res.data })
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err })
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const renderTable = () => {
    if (state.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{state.error?.message || "Error occured"}</Typography>
          <Button type="primary" style={{ backgroundColor: "red" }} onClick={fetchData}>
            Try Again
          </Button>
        </Card>
      )
    }

    if (state.loading) {
      return (
        <Card>
          <div
            style={{
              minHeight: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      )
    }

    const dataSource = (state.data || []).map((f) => ({
      id: f?.id,
      recordType: f?.attributes?.recordType,
      dartaNo: f?.attributes?.dartaNo,
      status: f?.attributes?.status,
      updatedDate: f?.attributes?.updatedAt,
    }))

    const columns: ColumnsType<{}> = [
      {
        title: "रेकर्ड प्रकार",
        dataIndex: "recordType",
        key: "recordType",
        render: (recordType: string) => {
          return <Tag color="blue">{recordType}</Tag>
        },
      },
      {
        title: "दर्ता नं.",
        dataIndex: "dartaNo",
        key: "dartaNo",
      },

      {
        title: "अबस्था",
        dataIndex: "status",
        key: "status",
        render: (status: string) => {
          if (status === RecordStatus.INPROGRESS) {
            return <Tag color="warning">{status}</Tag>
          } else if (status === RecordStatus.CREATED) {
            return <Tag color="blue">{status}</Tag>
          } else if (status === RecordStatus.COMPLETED) {
            return <Tag color="success">{status}</Tag>
          }
        },
      },
      {
        title: "मिती (A.D)",
        dataIndex: "updatedDate",
        key: "updatedDate",
        render: (_: any, record: any) =>
          record?.updatedDate ? format(new Date(record?.updatedDate), "yyyy-MM-dd") : "--",
      },
    ]

    return (
      <Card title="Latest Records">
        <Table dataSource={dataSource} scroll={{ x: "auto" }} columns={columns} pagination={false} />
      </Card>
    )
  }

  return <div>{renderTable()}</div>
}
