import { Button, Card, Col, Form, message, Row, Space, Table, Tag, theme, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { endOfDay, format, startOfDay } from "date-fns"
import NepaliDate from "nepali-date-converter"
import qs from "qs"
import { ReactElement, useRef, useState } from "react"
import { Bars } from "react-loader-spinner"
import ExportTableMenu from "../../../../components/common/exportTableMenu"
import NPDatePicker from "../../../../components/common/npdatepicker"
import RecordStatusSelector from "../../../../components/selectors/recordStatusSelector"
import RecordTypeSelector from "../../../../components/selectors/recordTypeSelector"
import recordHttpService from "../../../../services/https/apis/records.http.service"
import { RecordStatus, RecordTypesEnum } from "../../../../types/typings.d"
import RecordStatistics, { RecordStatisticsProps } from "./components/recordStatistics"

interface RecordsReportProps {}

export default function RecordsReport(props: RecordsReportProps): ReactElement {
  const [state, setState] = useState<{
    loading: boolean
    error: any
    data?: any[]
    meta?: {
      pagination: {
        page: number
        pageCount: number
        pageSize: number
        total: number
      }
    }
    statisticsPropsData?: RecordStatisticsProps
    fetchCorrespondingStatisticsData?: boolean
  }>({
    loading: false,
    error: null,
    data: [],
    meta: undefined,
  })

  const { token } = theme.useToken()
  const [form] = Form.useForm()
  const pdfComponentRef = useRef<any>()

  const onFinish = (values: any) => {
    if (!values.fromDate || !values.toDate) {
      return message.error("Please select अबधि")
    }
    if (values.recordType === RecordTypesEnum.All) {
      delete values.recordType
    }
    if (values.status === "All") {
      delete values.status
    }
    if (values.fromDate) {
      //Convert Nepali date into english date
      const npDate = new NepaliDate(values.fromDate)
      const asJson = npDate.getAD()
      const adDate = new Date(asJson.year, asJson.month, asJson.date)
      values.fromDate = startOfDay(adDate)
    }
    if (values.toDate) {
      //Convert Nepali date into english date
      const npDate = new NepaliDate(values.toDate)
      const asJson = npDate.getAD()
      const adDate = new Date(asJson.year, asJson.month, asJson.date)
      values.toDate = endOfDay(adDate)
    }
    setState({ ...state, loading: true, error: null })

    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          "finalLetters",
          "finalLetters.pdfLetter",
          "uploadedTippanies",
          "usrEntries",
          "usrEntries.passportImage",
        ],

        pagination: {
          start: 0,
          limit: 2000,
        },

        filters: {
          $and: [
            {
              recordType: values.recordType,
            },
            {
              status: values.status,
            },
            {
              createdAt: {
                $gte: values.fromDate,
              },
            },
            {
              createdAt: {
                $lte: values.toDate,
              },
            },
          ],
        },
      },
      {
        encodeValuesOnly: true,
      }
    )

    recordHttpService
      .fetchRecords(query)
      .then((res) => {
        const fetchCorrespondingStatisticsData = [
          RecordTypesEnum.LabourConcurrence,
          RecordTypesEnum.Deportation,
        ].includes(values.recordType)
        setState({
          ...state,
          loading: false,
          data: res.data,
          meta: res.meta,
          fetchCorrespondingStatisticsData,
          statisticsPropsData: {
            recordType: values.recordType,
            fromDate: values.fromDate,
            toDate: values.toDate,
          },
        })
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err, fetchCorrespondingStatisticsData: false })
      })
  }

  const renderFilter = () => {
    return (
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          fromDate: "",
          toDate: "",
          recordType: RecordTypesEnum.All,
          status: "All",
        }}
      >
        <Row gutter={10}>
          <Col xs={24} sm={12}>
            <Form.Item label="अबधि (देखि)" name="fromDate" required rules={[{ message: "Required" }]}>
              <NPDatePicker />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item label="अबधि (सम्म)" name="toDate" required rules={[{ message: "Required" }]}>
              <NPDatePicker />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item label="दर्ता प्रकार" name="recordType">
              <RecordTypeSelector excludeFlightPermission={true} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item label="अबस्था" name="status">
              <RecordStatusSelector />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={state.loading}>
              Search
            </Button>
            <Button
              type="primary"
              htmlType="reset"
              style={{ backgroundColor: token.colorWarning }}
              disabled={state.loading}
            >
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    )
  }

  const renderTableHeader = (exportTablePayload: { header: string[]; body: any[] }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <ExportTableMenu
          exportTablePayload={{
            fileName: "Records_Report",
            sheet: "Records",
            tablePayload: exportTablePayload,
          }}
          disabled={(state?.data || [])?.length <= 0 ? true : false}
        />
      </div>
    )
  }

  const renderTable = () => {
    if (state.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{state.error?.message || "Error occured"}</Typography>
          <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => form.submit()}>
            Try Again
          </Button>
        </Card>
      )
    }

    if (state.loading) {
      return (
        <Card>
          {renderFilter()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      )
    }

    const dataSource = (state.data || []).map((f) => ({
      id: f?.id,
      dartaNo: f?.attributes?.dartaNo,
      recordType: f?.attributes?.recordType,
      invoiceNo: f?.attributes?.invoiceNo,
      invoiceDate: f?.attributes?.invoiceDate,
      chalaniNo: f?.attributes?.chalaniNo,
      status: f?.attributes?.status,
      totalEntryCount: f?.attributes?.usrEntries?.length || 0,
    }))

    // console.log(dataSource?.length, "length")

    // TODO: data source for export data
    // const dataSourceForExport = (allRecordState.data || []).map((f) => ({
    //   id: f?.id,
    //   dartaNo: f?.attributes?.dartaNo,
    //   recordType: f?.attributes?.recordType,
    //   invoiceNo: f?.attributes?.invoiceNo,
    //   invoiceDate: f?.attributes?.invoiceDate,
    //   chalaniNo: f?.attributes?.chalaniNo,
    //   status: f?.attributes?.status,
    //   totalEntryCount: f?.attributes?.usrEntries?.length || 0,
    // }))

    const columns: ColumnsType<{}> = [
      {
        title: "दर्ता नं.",
        dataIndex: "dartaNo",
        key: "dartaNo",
        fixed: "left",
      },
      {
        title: "रेकर्ड प्रकार",
        dataIndex: "recordType",
        key: "recordType",
        render: (recordType: string) => {
          return <Tag color="blue">{recordType}</Tag>
        },
      },
      {
        title: "पत्र संख्या",
        dataIndex: "invoiceNo",
        key: "invoiceNo",
      },
      {
        title: "पत्र मिती",
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        render: (_: any, record: any) =>
          record?.invoiceDate ? format(new Date(record?.invoiceDate), "yyyy-MM-dd") : "--",
      },
      {
        title: "चलानी नं.",
        dataIndex: "chalaniNo",
        key: "chalaniNo",
      },

      {
        title: "विदेशी संख्या",
        dataIndex: "totalEntryCount",
        key: "totalEntryCount",
      },

      {
        title: "अबस्था",
        dataIndex: "status",
        key: "status",
        render: (status: string) => {
          if (status === RecordStatus.INPROGRESS) {
            return <Tag color="warning">{status}</Tag>
          } else if (status === RecordStatus.CREATED) {
            return <Tag color="blue">{status}</Tag>
          } else if (status === RecordStatus.COMPLETED) {
            return <Tag color="success">{status}</Tag>
          }
        },
      },
    ]

    return (
      <Card>
        {renderFilter()}
        {renderTableHeader({
          header: columns.map((f) => f.title as string),
          body: dataSource.map(({ id, ...data }) => data),
        })}

        <div ref={pdfComponentRef}>
          <Table
            dataSource={dataSource}
            scroll={{ x: "auto" }}
            columns={columns}
            summary={() => {
              return (
                <Space style={{ marginTop: 10 }}>
                  {state?.meta && <Tag color="blue">जम्मा: {state?.meta?.pagination?.total}</Tag>}
                  {state.fetchCorrespondingStatisticsData && <RecordStatistics {...state.statisticsPropsData!} />}
                </Space>
              )
            }}
          />
        </div>
      </Card>
    )
  }

  return <div>{renderTable()}</div>
}
