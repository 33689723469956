import { PlusOutlined, UploadOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, Form, Input, Row, Space, Typography, Upload, theme } from "antd"
import { ReactElement, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import LoadingWrapper from "../../../../../../components/common/loadingwrapper"
import NPDatePicker from "../../../../../../components/common/npdatepicker"
import PassengerEntryFormRow from "../../../../../../components/passengerentryFormRow"
import CharterFlightTypeSelector from "../../../../../../components/selectors/charterFightTypeSelector/index"
import DistrictSelector from "../../../../../../components/selectors/districtSelector"
import EmployeeSelector from "../../../../../../components/selectors/employeeselector"
import FlightCompanySelector from "../../../../../../components/selectors/flightCompanySelector"
import FlightTypeSelector from "../../../../../../components/selectors/flightTypeSelector"
import ProvinceSelector from "../../../../../../components/selectors/provinceselector"
import { selectProfile } from "../../../../../../features/auth/authSlice"
import { createFlightPermission } from "../../../../../../features/flightPermissions/flightPermissionsThunk"
import { useAppDispatch, useAppSelector } from "../../../../../../hooks"
import notificationHttpService from "../../../../../../services/https/apis/notification.http.service"
import { FlightType } from "../../../../../../types/typings.d"

interface CreateFlightPermissionProps {
  entryByFlightCompany?: boolean
}

const passengerPayload = {
  fullName: "",
  country: "",
  passportNo: "",
}

export default function CreateFlightPermission({ entryByFlightCompany }: CreateFlightPermissionProps): ReactElement {
  const [_, setFormValues] = useState({})
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { token } = theme.useToken()
  const userProfile = useAppSelector(selectProfile)
  const [state, setState] = useState({
    loading: false,
  })
  const [passengers, setPassengers] = useState([passengerPayload])

  const onResetForm = () => {
    form.resetFields()
    setPassengers([])
  }

  const onFinish = (values: any) => {
    var formData = new FormData()
    if (values?.document?.file) {
      formData.append("files.document", values?.document?.file, values?.document?.file?.name)
    }
    if (entryByFlightCompany) {
      values.flightCompany = userProfile?.data?.id
    }
    delete values?.ownerFlightCompany
    delete values?.document

    formData.append(
      "data",
      JSON.stringify({
        ...values,
        enteredBy: userProfile?.data?.id,
        passengers: passengers,
      })
    )
    setState({ ...state, loading: true })
    dispatch(createFlightPermission(formData))
      .unwrap()
      .then((res) => {
        setState({ ...state, loading: false })
        onResetForm()
        if (entryByFlightCompany) {
          //Create notification
          notificationHttpService.createNotification(
            {
              title: `Flight Permission Record has been created`,
              description: `Flight Permission Record has been created with registratin id ${res?.data?.id}, Login to dashboard for more details`,
              notificationFor: "Flight Permission",
              linkedUser: userProfile?.data?.id,
              linkedFlightPermission: res?.data?.id,
            },
            true
          )
        }
        navigate(-1)
        toast.success(`Create`)
      })
      .catch((err) => {
        setState({ ...state, loading: false })
        console.log("Err", err)
        toast.error(err?.message || "Error creating")
      })
  }
  return (
    <div>
      <div className="site-card-border-less-wrapper" style={{ position: "relative" }}>
        {state.loading && <LoadingWrapper />}
        <Card title={<Typography.Title level={3} className="he-title">उडान दर्ता / Flight Permission</Typography.Title>} bordered={false}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              flightCompany: "",
              ownerFlightCompany: entryByFlightCompany
                ? userProfile?.data?.fullName || userProfile?.data?.username
                : "",
              flightDate: "",
              flightType: FlightType.RESCUE_FLIGHT,
              chartedFlightType: "",
              totalPassengerCount: "",
              province: "",
              district: "",
              localLevel: "",
              location: "",
              sector: "",
              totalShuttle: "",
              pharchyotPerson: null,
              ministryDecisionDate: "",
              dateOfCorrespondence: "",
              purposeOfFlight: "",
              itemDetails: "",
            }}
            onValuesChange={(values) => setFormValues(values)}
          >
            <Row gutter={10}>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="दर्ता नं." help="Auto generated by system">
                  <Input readOnly disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item
                  label="उडान कम्पनी"
                  name={entryByFlightCompany ? "ownerFlightCompany" : "flightCompany"}
                  rules={[{ required: true }]}
                >
                  {entryByFlightCompany ? (
                    <Input
                      readOnly={entryByFlightCompany ? true : false}
                      disabled={entryByFlightCompany ? true : false}
                    />
                  ) : (
                    <FlightCompanySelector />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="उडान मिति" name="flightDate">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="उडान प्रकार" name="flightType">
                  <FlightTypeSelector />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="चार्टड उडान" name="chartedFlightType">
                  <CharterFlightTypeSelector disabled={form.getFieldValue("flightType") !== "Chartered Flight"} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="जम्मा यात्रु संख्या" name="totalPassengerCount">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="प्रदेश" name="province">
                  <ProvinceSelector />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="जिल्ला" name="district">
                  <DistrictSelector selectedProvience={form.getFieldValue("province")} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="स्थानिय तह" name="localLevel">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="स्थान" name="location">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="उडानको प्रयोजन" name="purposeOfFlight">
                  <Input.TextArea />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="सेक्टर" name="sector">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="जम्मा शटल" name="totalShuttle">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="सामाग्री विवरण" name="itemDetails">
                  <Input.TextArea />
                </Form.Item>
              </Col>
              {!entryByFlightCompany && (
                <>
                  <Col xs={24} md={12} xl={8}>
                    <Form.Item label="फर्छ्योट गर्ने व्यक्ति" name={"pharchyotPerson"}>
                      <EmployeeSelector />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} md={12} xl={8}>
                    <Form.Item label="मन्त्रालयको निर्णय मिती" name="ministryDecisionDate">
                      <NPDatePicker />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} xl={8}>
                    <Form.Item label="पत्राचार हुने मिती" name="dateOfCorrespondence">
                      <NPDatePicker />
                    </Form.Item>
                  </Col> */}
                </>
              )}
            </Row>
            <Divider />
            <div>
              <div style={{ marginBottom: 20 }}>
                <div style={{ marginBottom: 20 }}>
                  <Typography.Title level={4} className="he-subtitle">यात्रु</Typography.Title>
                </div>
              </div>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {passengers?.map?.((psg, index) => {
                  return (
                    <PassengerEntryFormRow
                      key={index}
                      index={index}
                      values={psg}
                      onRemove={(index) => {
                        const newUsers = [...passengers]
                        newUsers.splice(index, 1)
                        setPassengers(newUsers)
                      }}
                      onReset={() => {
                        const newUsers = [...passengers]
                        newUsers[index] = passengerPayload
                        setPassengers(newUsers)
                      }}
                      onValuesChange={(values) => {
                        // console.log("values", values)
                        const newUsers = [...passengers]
                        newUsers[index] = values
                        setPassengers(newUsers)
                      }}
                    />
                  )
                })}
              </Row>
              <Space style={{ marginTop: 10, marginBottom: 20 }}>
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    const newUsers = [...passengers]
                    newUsers.push(passengerPayload)
                    setPassengers(newUsers)
                  }}
                  disabled={state.loading}
                >
                  थप्नुहोस्
                </Button>
              </Space>
            </div>
            {entryByFlightCompany && (
              <Form.Item label="कागजात" name="document" rules={[{ required: true, message: "Can not be empty" }]}>
                <Upload multiple={false} beforeUpload={() => false} accept="application/pdf">
                  <Button icon={<UploadOutlined />}>Click to Select कागजात</Button>
                </Upload>
              </Form.Item>
            )}
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" loading={state.loading}>
                  Submit
                </Button>
                <Button
                  type="primary"
                  htmlType="reset"
                  style={{ backgroundColor: token.colorWarning }}
                  disabled={state.loading}
                  onClick={onResetForm}
                >
                  Reset
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  )
}
