import { Select } from "antd"
import React from "react"
import countryList from "../../../constants/countryList"
export interface CountrySelectorProps extends React.ComponentProps<typeof Select> {}

export default function CountrySelector({ ...props }: CountrySelectorProps) {
  return (
    <Select
      {...props}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
      options={countryList.map((f) => ({
        value: f.country,
        label: `${f.country} (${f.iso})`,
      }))}
    />
  )
}
