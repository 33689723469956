import { Button, Card, Col, Form, Input, Row, Select, Space, Typography } from "antd"
import React, { useState } from "react"
import { toast } from "react-toastify"
import MultiImageUploadHandler from "../../../../../../../components/common/multiImageUploadHandler"
import withPasswordProtected from "../../../../../../../components/hoc/withPasswordProtected"
import DistrictSelector from "../../../../../../../components/selectors/districtSelector"
import MunicipalitySelector from "../../../../../../../components/selectors/municipalitySelector"
import ProvinceSelector from "../../../../../../../components/selectors/provinceselector"
import { selectUserId } from "../../../../../../../features/auth/authSlice"
import { createBorder, updateBorder } from "../../../../../../../features/internationalBorder/borderThunk"
import { useAppDispatch, useAppSelector } from "../../../../../../../hooks"

interface CreateORUpdateBorderFormProps {
  onSuccess?: () => void
  onClickCancel?: () => void
  showCancelButton?: boolean
  border?: Record<string, any>
  action?: "create" | "update"
  showTitle?: boolean
}

interface CreateORUpdateBorderFormState {
  creatingOrUpdating?: boolean
}

const initialState: CreateORUpdateBorderFormState = {
  //   border: undefined,
  //   action: "create",
  creatingOrUpdating: false,
}

const CreateORUpdateBorderForm = ({
  onSuccess,
  onClickCancel,
  showCancelButton,
  border,
  action = "create",
  showTitle,
}: CreateORUpdateBorderFormProps) => {
  const [, setFormValues] = React.useState({})
  const [form] = Form.useForm()

  const [state, setState] = useState<CreateORUpdateBorderFormState>(initialState)
  const userId = useAppSelector(selectUserId)
  const dispatch = useAppDispatch()

  const onFinishedCreateOrUpdate = (values: any) => {
    setState({ ...state, creatingOrUpdating: true })
    const { images = [], ...payload } = values || {}
    const formData = new FormData()
    for (let index = 0; index < images.length; index++) {
      const img = images[index]
      if (img.originFileObj) {
        //Newly selected Files
        formData.append("files.images", img.originFileObj, img.originFileObj?.name)
      }
    }
    if (action === "create") {
      formData.append(
        "data",
        JSON.stringify({
          ...payload,
          latitude: payload?.latitude || null,
          longitude: payload?.longitude || null,
          enteredBy: userId,
        })
      )
      dispatch(createBorder(formData))
        .unwrap()
        .then((res) => {
          setState({ ...state, creatingOrUpdating: false })
          toast.success("Created successfully")
          onSuccess?.()
        })
        .catch((err) => {
          setState({ ...state, creatingOrUpdating: false })
          toast.error(err?.message || "Error creating border")
        })
    } else {
      formData.append(
        "data",
        JSON.stringify({ ...payload, latitude: payload?.latitude || null, longitude: payload?.longitude || null })
      )
      dispatch(
        updateBorder({
          id: border?.id,
          payload: formData,
        })
      )
        .unwrap()
        .then((res) => {
          setState({ ...state, creatingOrUpdating: false })
          toast.success("Updated successfully")
          onSuccess?.()
        })
        .catch((err) => {
          setState({ ...state, creatingOrUpdating: false })
          toast.error(err?.message || "Error updating border")
        })
    }
  }

  const { creatingOrUpdating } = state
  return (
    <Card
      title={showTitle && <Typography.Title level={3} className="he-title">अन्तर्राष्ट्रिय सिमाना / International Border</Typography.Title>}
      bordered={false}
    >
      <Form
        initialValues={{
          latitude: border?.attributes?.latitude || null,
          longitude: border?.attributes?.longitude || null,
          municipality: border?.attributes?.municipality || "",
          district: border?.attributes?.district || "",
          province: border?.attributes?.province || "",
          wardNo: border?.attributes?.wardNo || "",
          location: border?.attributes?.location || "",
          condition: border?.attributes?.condition || "Normal",
          notes: border?.attributes?.notes,
          images: border?.attributes?.images?.data || [],
        }}
        form={form}
        onFinish={onFinishedCreateOrUpdate}
        layout="vertical"
        onValuesChange={(_, values) => setFormValues(values)}
      >
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name="latitude" label="अक्षांश (Latitude)" style={{ userSelect: "none" }}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="longitude" label="देशान्तर (Longitude)" style={{ userSelect: "none" }}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="province"
              label="प्रदेश"
              style={{ userSelect: "none" }}
              rules={[{ required: true, message: "Can not be empty" }]}
            >
              <ProvinceSelector />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="district"
              label="जिल्ला"
              style={{ userSelect: "none" }}
              rules={[{ required: true, message: "Can not be empty" }]}
            >
              <DistrictSelector selectedProvience={form.getFieldValue("province")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="municipality"
              label="पालिका"
              style={{ userSelect: "none" }}
              rules={[{ required: true, message: "Can not be empty" }]}
            >
              <MunicipalitySelector selectedDistrict={form.getFieldValue("district")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="wardNo"
              label="वार्ड नम्बर"
              style={{ userSelect: "none" }}
            // rules={[{ required: true, message: "Can not be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="location"
              label="स्थान"
              style={{ userSelect: "none" }}
              rules={[{ required: true, message: "Can not be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="condition" label="अवस्था" rules={[{ required: true, message: "Can not be empty" }]}>
              <Select
                options={[
                  { label: "Normal / सामान्य", value: "Normal" },
                  { label: "Repairable / मर्मत योग्य", value: "Repairable" },
                  { label: "Damaged / क्षतिग्रस्त", value: "Damaged" },
                  { label: "Missing / हराइरहेको", value: "Missing" },
                  { label: "Others / अन्य", value: "Others" },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="notes" label="नोटहरू">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Col span={24}>
          <Form.Item name="images" label="Photos">
            <MultiImageUploadHandler />
          </Form.Item>
        </Col>

        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            {showCancelButton && (
              <Button type="dashed" onClick={onClickCancel}>
                Cancel
              </Button>
            )}
            <Button type="primary" htmlType="submit" loading={creatingOrUpdating}>
              {action === "update" ? "Update" : "Create"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  )
}
export default withPasswordProtected(CreateORUpdateBorderForm)
