import { EditOutlined } from "@ant-design/icons"
import { Button, Card, Col, Form, Input, Row, Space, Table, Tag, theme } from "antd"
import { ReactElement, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { Records } from "../../../../constants/recordList"
import { useAppSelector } from "../../../../hooks"
import chalaniHttpService from "../../../../services/https/apis/chalani.http.service"
import { RecordStatus, RecordTypesEnum } from "../../../../types/typings.d"
import { getCurrentFiscalYear } from "../../../../utils/general.utils"

interface ChalaniProps {}

export default function Chalani(props: ChalaniProps): ReactElement {
  const [form] = Form.useForm()
  const { token } = theme.useToken()
  const [state, setState] = useState({
    loading: false,
    searchList: [],
  })
  const selectedFiscalYearConfig =
    useAppSelector((state) => state?.global?.appSettings?.selectedFiscallYear) || getCurrentFiscalYear()
  const onSearch = (values: any) => {
    const chalaniNo: string = values.chalaniNo
    setState({ ...state, loading: true })
    const query = {
      params: {
        filters: {
          chalaniNo: {
            $eq: chalaniNo,
          },
        },
      },
    }
    chalaniHttpService
      .fetchChalanis(selectedFiscalYearConfig.fiscalYear, query)
      .then((res) => {
        setState({ ...state, loading: false, searchList: res.data })
      })
      .catch((err) => {
        toast.error(err?.message || "Error searching")
        setState({ ...state, loading: false })
      })
  }

  const dataSource = state.searchList?.map((f: any) => {
    const isFlightpermission = Boolean(f?.attributes?.flightPermission?.data)
    return {
      recordType: isFlightpermission
        ? RecordTypesEnum.FlightPermission
        : f?.attributes?.record?.data?.attributes?.recordType,
      status: f?.attributes?.record?.data?.attributes?.status,
      id: isFlightpermission ? f?.attributes?.flightPermission?.data?.id : f?.attributes?.record?.data?.id,
      isFlightpermission: isFlightpermission,
    }
  })
  return (
    <div>
      <Card title="Search चलानी" bordered={false}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSearch}
          initialValues={{
            chalaniNo: "",
          }}
        >
          <Row gutter={10}>
            <Col xs={24} md={12} xl={8}>
              <Form.Item label="चलानी नं." name="chalaniNo" rules={[{ required: true, message: "Can not be empty" }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Search
              </Button>
              <Button
                type="primary"
                htmlType="reset"
                style={{ backgroundColor: token.colorWarning }}
                onClick={() => {
                  form.resetFields()
                }}
              >
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <div style={{ marginTop: 20 }}>
        <Table
          scroll={{ x: "auto" }}
          columns={[
            {
              title: "रेकर्ड प्रकार",
              dataIndex: "recordType",
              key: "recordType",
              render: (recordType: string) => {
                return <Tag color="blue">{recordType}</Tag>
              },
            },
            {
              title: "अबस्था",
              dataIndex: "status",
              key: "status",
              render: (status: string) => {
                if (status === RecordStatus.INPROGRESS) {
                  return <Tag color="warning">{status}</Tag>
                } else if (status === RecordStatus.CREATED) {
                  return <Tag color="blue">{status}</Tag>
                } else if (status === RecordStatus.COMPLETED) {
                  return <Tag color="success">{status}</Tag>
                }
              },
            },
            {
              title: "कार्यहरु",
              key: "action",
              render: (_: any, record: any) => (
                <Space size="middle">
                  {/* <EyeOutlined
                    style={{ cursor: "pointer", color: token.colorSuccess }}
                  /> */}
                  <Link to={Records?.find?.((f) => f.type === record?.recordType)?.updateRoute(record?.id) || "#"}>
                    <EditOutlined style={{ cursor: "pointer", color: token.colorWarning }} />
                  </Link>
                </Space>
              ),
            },
          ]}
          dataSource={dataSource}
          pagination={false}
        />
      </div>
    </div>
  )
}
