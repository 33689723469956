import { createAsyncThunk } from "@reduxjs/toolkit";
import flightPermissionHttpService from "../../services/https/apis/flightpermission.http.service";

export const fetchFlightPermissions = createAsyncThunk(
  "flightPermissions/fetchFlightPermissions",
  async (queryString?:string) => {
    return flightPermissionHttpService.fetchFlightPermissions(queryString);
  }
);

export const createFlightPermission = createAsyncThunk(
  "flightPermissions/createFlightPermission",
  async (payload: Record<string, any>, { dispatch, rejectWithValue }) => {
    return flightPermissionHttpService
      .createFlightPermission(payload)
      .then((res) => {
        dispatch(fetchFlightPermissions());
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const updateFlightPermission = createAsyncThunk(
  "flightPermissions/updateFlightPermission",
  async (
    updatePayload: { id: string; payload: Record<string, any> },
    { dispatch, rejectWithValue }
  ) => {
    return flightPermissionHttpService
      .updateFlightPermission(updatePayload.id, updatePayload.payload)
      .then((res) => {
        dispatch(fetchFlightPermissions());
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const deleteFlightPermission = createAsyncThunk(
  "flightPermissions/deleteFlightPermission",
  async (id: string, { dispatch, rejectWithValue }) => {
    return flightPermissionHttpService
      .deleteFlightPermission(id)
      .then((res) => {
        dispatch(fetchFlightPermissions());
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
