export const labourConcurranceUserEntryFormFieldNames = [
  "fullName",
  "country",
  "gender",
  "passportNo",
  "dob",
  "fromLabourConcurranceDate",
  "toLabourConcurranceDate",
  "passportImage",
]

export const freearrivalvisaUserEntryFormFieldNames = [
  "fullName",
  "country",
  "gender",
  "passportNo",
  "dob",
  "passportImage",
  // "fromVisaDuration",
  // "toVisaDuration",
  // "purposeOfVisit",
  // "ministryDecisionDate",
  // "dateOfCorrespondence",
  // "nepalEntryPoint",
]

export const freevisaextensionUserEntryFormFieldNames = [
  "fullName",
  "country",
  "gender",
  "passportNo",
  "dob",
  "passportImage",
  // "fromVisaDuration",
  // "toVisaDuration",
  // "purposeOfVisit",
  // "ministryDecisionDate",
  // "dateOfCorrespondence",
]

export const multiplevisaUserEntryFormFieldNames = [
  "fullName",
  "country",
  "gender",
  "passportNo",
  "dob",
  "passportImage",
  // "fromVisaDuration",
  // "toVisaDuration",
  // "ministryDecisionDate",
  // "dateOfCorrespondence",
]

export const freetrekkingUserEntryFormFieldNames = [
  "fullName",
  "country",
  "gender",
  "passportNo",
  "dob",
  // "designation",
  // "diplomaticBody",
  "passportImage",
  // "fromTrekkingDuration",
  // "toTrekkingDuration",
  // "trekkingLocation",
  // "trekkingDistrict",
  // "ministryDecisionDate",
  // "dateOfCorrespondence",
]

export const nrnvisaUserEntryFormFieldNames = [
  "fullName",
  "country",
  "gender",
  "passportNo",
  "dob",
  "passportImage",
  // "fromVisaDuration",
  // "toVisaDuration",
  // "ministryDecisionDate",
  // "dateOfCorrespondence",
]

export const arrivalUserEntryFormFieldNames = [
  "fullName",
  "country",
  "gender",
  "passportNo",
  "dob",
  "passportImage",
  // "fromVisaDuration",
  // "toVisaDuration",
  // "ministryDecisionDate",
  // "dateOfCorrespondence",
  // "dateOfCorrespondenceForConduct",
  // "dateOfReplay",
]

export const deportationUserEntryFormFieldNames = [
  "fullName",
  "country",
  "gender",
  "passportNo",
  // "passportImage",
  // "deportionPeriod",
  // "deportionReason",
  // "ministryDecisionDate",
  // "dateOfCorrespondence",
]

export const travelDocumentUserEntryFormFieldNames = [
  "childImage",
  "childFullNameNepali",
  "childFullNameEnglish",
  "childDobEnglish",
  "childDobNepali",
  "childMotherFullNameNepali",
  "childMotherFullNameEnglish",
  "childMotherPassportNo",
  "childMotherCitizenshipNo",
  "childMotherFatherFullName",
  "childMotherGFatherFullName",
  "childMotherPermaAddress",
  "childFatherFullNameNepali",
  "childFatherFullNameEnglish",
  "childFatherPassportNo",
  "childFatherCitizenshipNo",
  "childFatherFatherFullName",
  "childFatherGFatherFullName",
  "childFatherPermaAddress",
]
