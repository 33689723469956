import { UploadOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Row, Space, Switch } from "antd"
import Upload from "antd/es/upload/Upload"
import { useState } from "react"
import { toast } from "react-toastify"
import LastUsedChalaniViewer from "../../../../../components/common/lastUsedChalaniViewer"
import { SelfControlModalRefsHandler } from "../../../../../components/common/selfcontrolmodal/index"
import bibidhHttpService from "../../../../../services/https/apis/bibidh.http.service"

type UploadBibidhProps = {
  modalRef: React.RefObject<SelfControlModalRefsHandler>
  reloadData?: () => void
}

export default function UploadBibidh({ modalRef, reloadData }: UploadBibidhProps) {
  const [state, setState] = useState<{
    loading: boolean
    letterFile: any
  }>({
    loading: false,
    letterFile: null,
  })

  const [form] = Form.useForm()

  const onFinishForm = (values: any) => {
    if (!values?.letterFile) {
      return toast.info("Please select file to upload ")
    }
    const formData = new FormData()
    formData.append("files.pdfLetter", values?.letterFile?.file, values?.letterFile?.name?.file || "")
    values.linkedRegNo = values?.linkedRegNo || null
    formData.append(
      "data",
      JSON.stringify({
        linkedRegNo: values?.linkedRegNo,
        chalaniNo: values?.chalaniNo,
        record: values?.isFlightPermission ? null : values?.linkedRegNo,
        flightPermission: values?.isFlightPermission ? values?.linkedRegNo : null,
      })
    )
    setState({ ...state, loading: true })
    bibidhHttpService
      .uploadBibidhLetter(formData)
      .then((res) => {
        setState({ ...state, loading: false })
        toast.success("Uploaded")
        reloadData?.()
        modalRef?.current?.hide?.()
      })
      .catch((err) => {
        console.log("Error", err)
        setState({ ...state, loading: false })
        toast.error(err?.message || "Error uploading")
      })
  }

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          linkedRegNo: null,
          chalaniNo: "",
          letterFile: null,
          isFlightPermission: false,
        }}
        onFinish={onFinishForm}
      >
        <Row gutter={10}>
          <Col xs={24} sm={12}>
            <Space>
              <Form.Item label="रेकर्ड दर्ता नं." name="linkedRegNo">
                <Input />
              </Form.Item>
              <Form.Item label="उडान हो ?" name="isFlightPermission" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Space>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="चलानी नं."
              name="chalaniNo"
              rules={[{ required: true, message: "Can not be empty" }]}
              help={<LastUsedChalaniViewer />}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Final Letter" name="letterFile" rules={[{ required: true, message: "Can not be empty" }]}>
              <Upload
                multiple={false}
                beforeUpload={() => false}
                fileList={form.getFieldValue("letterFile")?.file ? [form.getFieldValue("letterFile")?.file] : []}
                onChange={({ file }) => {
                  setState({ ...state, letterFile: file })
                }}
                accept="application/pdf"
              >
                <Button icon={<UploadOutlined />}>Click to Select File</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" htmlType="submit" loading={state.loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}
