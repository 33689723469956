import { AxiosRequestConfig } from "axios"
import { AppWebRequest } from "../NetworkManager"
class BibidhHttpService {
  fetchBibidhs(config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {}
    const { sort = [], populate = [], ...otherParams } = params || {}
    return AppWebRequest({
      method: "get",
      url: "/bibidhs",
      ...axiosConfig,
      params: {
        sort: ["updatedAt:desc", ...sort],
        populate: ["pdfLetter", ...populate],
        ...otherParams,
      },
    })
  }
  async uploadBibidhLetter(formData: FormData) {
    return AppWebRequest({
      method: "post",
      url: `/bibidhs`,
      data: formData,
    }).then((res) => {
      return res
    })
  }

  deleteBibidh(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/bibidhs/${id}`,
    })
  }
}

const bibidhHttpService = new BibidhHttpService()
export default bibidhHttpService
