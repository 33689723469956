import { createSlice } from "@reduxjs/toolkit";
import {
  fetchApplicantBodies,
  createApplicantBody,
} from "./applicantBodiesThunk";

// Define a type for the slice state
export interface ApplicantBodyState {
  entities: any[];
  loading: boolean;
  error: any;
}

// Define the initial state using that type
const initialState: ApplicantBodyState = {
  entities: [],
  loading: false,
  error: null,
};

export const applicantBodiesSlice = createSlice({
  name: "applicantBodies",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Fetch
    builder.addCase(fetchApplicantBodies.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchApplicantBodies.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = action.payload.data as [];
    });
    builder.addCase(fetchApplicantBodies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    //Create
    builder.addCase(createApplicantBody.fulfilled, (state, action) => {
      state.entities.push(action.payload.data);
    });
  },
});

export const applicantBodiesReducer = applicantBodiesSlice.reducer;
