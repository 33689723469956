import { Button } from "antd";
import React from "react";
import { Helmet } from "react-helmet-async";
import {
  PrinterOutlined
  } from "@ant-design/icons";

interface EditorProps {
  html?: string;
  onChange?: (html: string) => void;
}

export default function Editor({ html = "", onChange }: EditorProps) {
  const [content, setContent] = React.useState("");
  React.useEffect(() => {
    setContent(html);
  }, [html]);
  const onContentBlur = React.useCallback(
    (evt: React.FocusEvent<HTMLDivElement, Element>) => {
      setContent(evt.currentTarget.innerHTML);
      onChange?.(evt.currentTarget.innerHTML);
    },
    []
  );

  const onPrint = () => {
    var iFrame: any = document?.getElementById?.("ifmcontentstoprint");
    var pri = iFrame?.contentWindow;
    pri.document.open();
    pri.document.write(content);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <>
      <Helmet>
        {/* inline style elements */}
        <style type="text/css">{`
        @print {
            @page :footer {
                display: none
            }

            @page :header {
                display: true
            }
        }

@media print {
    @page {
        margin-top: 0;
        margin-bottom: 0;
    }
    body {
        padding-top: 72px;
        padding-bottom: 72px ;
    }
}
    `}</style>
      </Helmet>
      <div
        contentEditable
        onBlur={onContentBlur}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <iframe
        id="ifmcontentstoprint"
        style={{ height: "0px", width: "0px", position: "absolute" }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end",marginTop:10 }}>
        <Button icon={<PrinterOutlined />} type="primary" onClick={onPrint}>Print</Button>
      </div>
    </>
  );
}
