import { InputNumber } from "antd";
import React, { ReactElement } from "react";
import {
  CountrySelectorProps,
} from "../../selectors/countryselector";

interface InputPhoneNumberProps
  extends React.ComponentProps<typeof InputNumber> {
  countryCodeSelectProps?: CountrySelectorProps;
  noCountryCodeSelection?: boolean;
}

export default function InputPhoneNumber({
  style,
  countryCodeSelectProps,
  noCountryCodeSelection,
  onChange,
  ...props
}: InputPhoneNumberProps): ReactElement {
  return (
    <InputNumber
      style={{ width: "100%", ...style }}
      minLength={10}
      maxLength={10}
      onChange={(value) => onChange?.(String(value))}
      {...props}
    />
  );
}
