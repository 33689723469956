import { Layout, Menu, MenuProps, theme } from "antd"
import { useNavigate } from "react-router-dom"
import CurrentUserPolicy from "../../../features/uipolicies/currentuserpolicy"

interface SiderMenuProps {}

export default function SiderMenu(props: SiderMenuProps) {
  const uiPolicy = CurrentUserPolicy.getInstance().uiPolicy
  // console.log(uiPolicy)
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  let navigate = useNavigate()
  const items: MenuProps["items"] = [
    ...(uiPolicy?.dashboard
      ? [
          {
            key: `/dashboard`,
            icon: <i className="fa fa-th-large"></i>,
            label: `गृहपृष्ठ`,
          },
        ]
      : []),
    ...(Object.keys(uiPolicy?.records || {}).length > 0
      ? [
          {
            key: `/dashboard/records#root`,
            icon: <i className="fa fa-tag"></i>,
            label: `रेकर्डहरु`,

            children: [
              ...(uiPolicy?.records?.recordsHome
                ? [
                    {
                      key: `/dashboard/records`,
                      label: `रेकर्डसूची`,
                    },
                  ]
                : []),
              ...(uiPolicy?.records?.registrations
                ? [
                    {
                      key: `/dashboard/records/registration`,
                      label: `दर्ता`,
                    },
                  ]
                : []),
              ...(uiPolicy?.records?.chalani
                ? [
                    {
                      key: `/dashboard/records/registration/record-by-chalani`,
                      label: `चलानी`,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(uiPolicy?.users?.view
      ? [
          {
            key: `/dashboard/users`,
            icon: <i className="fa fa-newspaper-o"></i>,
            label: `प्रयोगकर्ताहरु`,
          },
        ]
      : []),
    ...(uiPolicy?.thirdparties?.view
      ? [
          {
            key: `/dashboard/thirdparties`,
            icon: <i className="fa fa-newspaper-o"></i>,
            label: `तेस्रो पक्ष प्रयोगकर्ताहरु`,
          },
        ]
      : []),
    ...(Object.values(uiPolicy?.employees || {}).filter((f) => f.view).length > 0
      ? [
          {
            key: `/dashboard/employees#root`,
            icon: <i className="fa fa-money"></i>,
            label: `कर्मचारीहरु`,

            children: [
              ...(uiPolicy?.employees?.employeesHome?.view
                ? [
                    {
                      key: `/dashboard/employees`,
                      label: `कर्मचारी`,
                    },
                  ]
                : []),
              ...(uiPolicy?.employees?.departments?.view
                ? [
                    {
                      key: `/dashboard/employees/departments`,
                      label: `विभाग`,
                    },
                  ]
                : []),
              ...(uiPolicy?.employees?.designations?.view
                ? [
                    {
                      key: `/dashboard/employees/designations`,
                      label: `पदनाम`,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    // ...(uiPolicy?.roles?.view
    //   ? [
    //       {
    //         key: `/dashboard/roles`,
    //         icon: <i className="fa fa-th-large"></i>,
    //         label: `भूमिका`,
    //       },
    //     ]
    //   : []),
    ...(uiPolicy?.uipolicies?.view
      ? [
          {
            key: `/dashboard/uipolicies`,
            icon: <i className="fa fa-th-large"></i>,
            label: `प्रयोगकर्ता इन्टरफेस नीति`,
          },
        ]
      : []),
    ...(uiPolicy?.summarylogs
      ? [
          {
            key: `/dashboard/summarylogs`,
            icon: <i className="fa fa-database"></i>,
            label: `लगहरू`,
          },
        ]
      : []),
    ...(uiPolicy?.reports
      ? [
          {
            key: `/dashboard/reports`,
            icon: <i className="fa fa-file"></i>,
            label: `रिपोर्ट`,
          },
        ]
      : []),

    ...(uiPolicy?.bibidhs
      ? [
          {
            key: `/dashboard/bibidhs`,
            icon: <i className="fa fa-file"></i>,
            label: `बिबिध`,
          },
        ]
      : []),
  ]
  const onClickSideBarMenu = ({ key }: { key: string }) => {
    if (key !== "logout") {
      // window.open(key, "_blank")
      navigate(key)
    }
  }
  return (
    <Layout.Sider width={200} style={{ background: colorBgContainer }} collapsible>
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={["/dashboard"]}
        defaultOpenKeys={["/dashboard/records#root", "/dashboard/employees#root"]}
        style={{ height: "100%", borderRight: 0 }}
        items={items}
        onClick={onClickSideBarMenu}
      />
    </Layout.Sider>
  )
}
