import { startOfDay } from "date-fns"
import NepaliDate from "nepali-date-converter"
import apiUrl from "../services/https/apiUrl"
/**
 * This will check if the given object has any children
 * @param {object} item
 * @returns
 */
export function hasChildren(item: any) {
  const { children } = item

  if (children === undefined) {
    return false
  }

  if (children.constructor !== Array) {
    return false
  }

  if (children.length === 0) {
    return false
  }

  return true
}

// export function getSelectedFiscalYear() {
//   const selectedFiscallYear = store.getState?.().global?.appSettings?.selectedFiscallYear
//   return selectedFiscallYear || getCurrentFiscalYear()
// }

/**
 * Check if given date is expired from today's date
 * @param {Date|string} date
 * @returns
 */
export function isExpired(date: Date | string) {
  return new Date(date).getTime() < new Date().getTime()
}

// ##############################
// // // Function that converts from hex color to rgb color
// // // Example: input = #9c27b0 => output = 156, 39, 176
// // // Example: input = 9c27b0 => output = 156, 39, 176
// // // Example: input = #999 => output = 153, 153, 153
// // // Example: input = 999 => output = 153, 153, 153
// #############################
/**
 * Convert HEX color to RGB color
 * @param {string} input
 * @returns
 */
export const hexToRgb = (input: string, alpha = 1) => {
  input = input + ""
  input = input.replace("#", "")
  let hexRegex = /[0-9A-Fa-f]/g
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.")
  }
  if (input.length === 3) {
    let first = input[0]
    let second = input[1]
    let last = input[2]
    input = first + first + second + second + last + last
  }
  input = input.toUpperCase()
  let first = input[0] + input[1]
  let second = input[2] + input[3]
  let last = input[4] + input[5]
  return `rgb(${parseInt(first, 16)},${parseInt(second, 16)},${parseInt(last, 16)},${alpha})`
}

export function isValidHttpUrl(urlToCheck: string) {
  try {
    let url = new URL(urlToCheck)
    return url.protocol === "http:" || url.protocol === "https:"
  } catch (error) {
    return false
  }
}

export function isInThePast(date: Date) {
  const today = new Date()

  // 👇️ OPTIONAL!
  // This line sets the hour of the current date to midnight
  // so the comparison only returns `true` if the passed-in date
  // is at least yesterday
  today.setHours(0, 0, 0, 0)

  return date < today
}

export const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const getFilePath = (relativePath: string) => {
  if (!relativePath) {
    return ""
  }
  return `${apiUrl.BASE_URL}${relativePath}`
}

export const getFilename = function (str: string) {
  return str.substring(str.lastIndexOf("/") + 1)
}

export function getRandomColor() {
  var letters = "0123456789ABCDEF".split("")
  var color = "#"
  for (var i = 0; i < 6; i++) {
    color += letters[Math.round(Math.random() * 15)]
  }
  return color
}

export function convertNeplaiDateStrIntoEnglishDateStr(nepaliDateStr: string) {
  // Replace Nepali digits with corresponding Arabic numerals
  const nepaliDigits = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"]
  const arabicDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
  let convertedDateStr = nepaliDateStr
  for (let i = 0; i < nepaliDigits.length; i++) {
    convertedDateStr = convertedDateStr.replace(new RegExp(nepaliDigits[i], "g"), arabicDigits[i])
  }
  return convertedDateStr
}

export function getCurrentFiscalYear() {
  const currentYear = new NepaliDate().getYear()
  const currentFiscalYear = `${currentYear}-${currentYear + 1}`
  const currentFiscalYearDateRange = {
    fromDate: startOfDay(new NepaliDate(currentYear, 3, 1).toJsDate()), // Shawran 1
    toDate: startOfDay(new NepaliDate(currentYear + 1, 3, 1).toJsDate()), // Ashar end
  }
  return { fiscalYear: currentFiscalYear, ...currentFiscalYearDateRange }
}

/**
 * This will generate a fiscall list in neplai format with fromDate and toDate as AD format
 * @param numYearsBefore
 * @param numYearsAfter
 * @returns
 */
export function generateFiscalYears(numYearsBefore: number, numYearsAfter: number) {
  const currentYear = new NepaliDate().getYear()
  const list: Array<{ fiscalYear: string; fromDate: Date; toDate: Date }> = []
  const currentFiscalYear = `${currentYear}-${currentYear + 1}`
  const currentFiscalYearDateRange = {
    fromDate: startOfDay(new NepaliDate(currentYear, 3, 1).toJsDate()), // Shawran 1
    toDate: startOfDay(new NepaliDate(currentYear + 1, 3, 1).toJsDate()), // Ashar end
  }

  // Generate old fiscal years
  for (let i = currentYear - 1; i >= currentYear - numYearsBefore; i--) {
    const fiscalYear = `${i}-${i + 1}`
    const dateRange = {
      fromDate: startOfDay(new NepaliDate(i, 3, 1).toJsDate()), // Shawran 1
      toDate: startOfDay(new NepaliDate(i + 1, 3, 1).toJsDate()), // Ashar end
    }
    list.unshift({ fiscalYear, ...dateRange })
  }

  // Add current fiscal year
  list.push({ fiscalYear: currentFiscalYear, ...currentFiscalYearDateRange })

  // Generate new fiscal years
  for (let i = currentYear + 1; i <= currentYear + numYearsAfter; i++) {
    const fiscalYear = `${i}-${i + 1}`
    const dateRange = {
      fromDate: startOfDay(new NepaliDate(i, 3, 1).toJsDate()), // Shawran 1
      toDate: startOfDay(new NepaliDate(i + 1, 3, 1).toJsDate()), // Ashar end
    }
    list.push({ fiscalYear, ...dateRange })
  }

  return { list, currentFiscalYear: { fiscalYear: currentFiscalYear, ...currentFiscalYearDateRange } }
}
