import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Result,
  Row,
  Space,
  Typography,
  Upload,
} from "antd";
import { ReactElement, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Container } from "../../../../../../components/common/container";
import recordEntryHttpService from "../../../../../../services/https/apis/records.http.service";
import { isInThePast } from "../../../../../../utils/general.utils";

interface AddDocumentToLabourConcurranceProps { }

export default function AddDocumentToLabourConcurrance(
  props: AddDocumentToLabourConcurranceProps
): ReactElement {
  let [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [_, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onFinishForm = (values: any) => {
    if (!values?.documentFile || !values?.labourConcurranceDocument) {
      return toast.info("Please select document files to upload ");
    }
    const token = searchParams?.get?.("token") || "";
    const recordId = searchParams?.get?.("recordId") || "";
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "files.document",
      values?.documentFile?.file,
      values?.documentFile?.file?.name || ""
    );
    formData.append(
      "files.labourConcurranceDocument",
      values?.labourConcurranceDocument?.file,
      values?.labourConcurranceDocument?.file?.name || ""
    );
    recordEntryHttpService
      .addDocumentWithDocumentToken(recordId, formData, token)
      .then((res) => {
        setLoading(false);
        toast.success(`Submited successfully`);
        setSubmitted(true);
        setSearchParams((prev) => {
          prev.set('submitted', 'true')
          return prev
        })
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.message || "Error submitting");
      });
  };

  if (submitted || searchParams.get("submitted") === "true") {
    return (
      <Result
        status="success"
        title="Successfully Submitted you records"
        subTitle=""
      />
    );
  }

  if (
    !searchParams.get("token") ||
    !searchParams.get("expiredAt") ||
    !searchParams.get("recordId")
  ) {
    return (
      <Result
        status="warning"
        title="There are some problems with your application. query params are missing"
      />
    );
  }

  if (
    searchParams.get("expiredAt") &&
    isInThePast(new Date(searchParams.get("expiredAt") || new Date()))
  ) {
    return (
      <Result
        status="warning"
        title="There are some problems with your application. the token has already expired"
      />
    );
  }

  return (
    <Container>
      <Card
        title={<Typography.Title level={3} className="he-title">कागजात अपलोड</Typography.Title>}
        className="shadow-sm"
        style={{ marginTop: 20 }}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            documentFile: null,
            labourConcurranceDocument: null
          }}
          onFinish={onFinishForm}
          onValuesChange={(_, values) => {
            setFormValues(values);
          }}
        >
          <Row gutter={10}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Curriculum Vitae [CV] कागजात"
                name="documentFile"
                rules={[{ required: true, message: "Can not be empty" }]}
                help="फाइल नामको नमुना: रजिशष्ट्रेशन नम्बर_संस्थाको नाम"
              >
                <Upload
                  multiple={false}
                  beforeUpload={() => false}
                  fileList={
                    form.getFieldValue("documentFile")?.file
                      ? [form.getFieldValue("documentFile")?.file]
                      : []
                  }
                  accept="application/pdf"
                >
                  <Button icon={<UploadOutlined />}>
                    Click to Upload कागजात
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="कार्य सहमति कागजात [LC]"
                name="labourConcurranceDocument"
                rules={[{ required: true, message: "Can not be empty" }]}
                help="फाइल नामको नमुना: रजिशष्ट्रेशन नम्बर_संस्थाको नाम"
              >
                <Upload
                  multiple={false}
                  beforeUpload={() => false}
                  fileList={
                    form.getFieldValue("labourConcurranceDocument")?.file
                      ? [form.getFieldValue("labourConcurranceDocument")?.file]
                      : []
                  }
                  accept="application/pdf"
                >
                  <Button icon={<UploadOutlined />}>
                    Click to Upload कागजात
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Space style={{ marginTop: 20 }}>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              loading={loading}
              htmlType="submit"
            >
              Submit गर्नुहोस्
            </Button>
          </Space>
        </Form>
      </Card>
    </Container>
  );
}
