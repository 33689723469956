import { Select } from "antd";
import React from "react";
import { Records } from "../../../constants/recordList";
import { RecordTypesEnum } from "../../../types/typings.d";

interface RecordTypeSelectorProps extends React.ComponentProps<typeof Select> {
  excludeFlightPermission?: boolean;
}

export default function RecordTypeSelector({
  excludeFlightPermission,
  ...props
}: RecordTypeSelectorProps) {
  return (
    <Select
      {...props}
      options={(excludeFlightPermission
        ? Records.filter((f) => f.type !== RecordTypesEnum.FlightPermission)
        : Records
      ).map((r, i) => ({
        value: r.type,
        label: r.title,
      }))}
    />
  );
}
