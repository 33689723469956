import { createAsyncThunk } from "@reduxjs/toolkit";
import departmentHttpService from "../../services/https/apis/department.http.service";
export const fetchDepartments = createAsyncThunk(
  "departments/fetchDepartments",
  async () => {
    return departmentHttpService.fetchDepartments();
  }
);

export const createDepartment = createAsyncThunk(
  "departments/createDepartment",
  async (payload: Record<string, any>) => {
    return departmentHttpService.createDepartment(payload);
  }
);

export const updateDepartment = createAsyncThunk(
  "departments/updateDepartment",
  async (updatePayload:{id: string, payload: Record<string, any>}) => {
    return departmentHttpService.updateDepartment(updatePayload.id, updatePayload.payload);
  }
);

export const deleteDepartment = createAsyncThunk(
  "departments/deleteDepartment",
  async (id: string) => {
    return departmentHttpService.deleteDepartment(id);
  }
);
