import { Select } from "antd";
import React from "react";

interface CharterFlightTypeSelectorProps extends React.ComponentProps<typeof Select> {}

export default function CharterFlightTypeSelector({ ...props }: CharterFlightTypeSelectorProps) {
  return (
    <Select
      {...props}
      options={[
        { value: "Chartered", label: "Chartered" },
        { value: "Freight Chartered", label: "Freight Chartered" },
        { value: "Sling Flight", label: "Sling Flight" }
      ]}
    />
  );
}
