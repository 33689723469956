import { Button, Card, Table, theme, Typography } from "antd";
import { format } from "date-fns";
import { ReactElement, useState, useEffect } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { Bars } from "react-loader-spinner";
import auditLogsHttpService from "../../../services/https/apis/auditlog.http.service";
import { ColumnsType } from "antd/es/table";
import UnderlineTypography from "../../../components/common/underlineTypography";

interface ListAuditLogsProps {}

export default function ListAuditLogs(props: ListAuditLogsProps): ReactElement {
  const [state, setState] = useState<{
    loading: boolean;
    error: any;
    data: any[];
  }>({
    loading: true,
    error: null,
    data: [],
  });

  const fetchData = () => {
    setState({ ...state, loading: true, error: null });
    auditLogsHttpService
      .fetchAuditLogs({
        params: {
          populate: ["actionBy", "record", "flightPermission"],
          filters: {},
        },
      })
      .then((res) => {
        setState({ ...state, loading: false, data: res.data });
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Button
          type="dashed"
          icon={<ReloadOutlined />}
          onClick={() => fetchData()}
        >
          रिफ्रेस गर्नुहोस्
        </Button>
      </div>
    );
  };

  const renderTable = () => {
    if (state.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{state.error?.message || "Error occured"}</Typography>
          <Button
            type="primary"
            style={{ backgroundColor: "red" }}
            onClick={() => fetchData()}
          >
            Try Again
          </Button>
        </Card>
      );
    }

    if (state.loading) {
      return (
        <Card>
          {renderTableHeader()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      );
    }

    const dataSource = state.data.map((f) => ({
      id: f?.id,
      action: f?.attributes?.action,
      description: f?.attributes?.description,
      dateTime: f?.attributes?.createdAt,
      actionBy: f?.attributes?.actionBy?.data?.attributes?.username,
      recordId: f?.attributes?.record?.data?.id || "--",
      flightPermissionId: f?.attributes?.flightPermission?.data?.id || "--",
    }));

    const columns: ColumnsType<{}> = [
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        fixed: "left",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Action Date",
        dataIndex: "dateTime",
        key: "dateTime",
        render: (dateTime: string) =>
          dateTime ? format(new Date(dateTime), "yyyy-MM-dd") : "--",
      },
      {
        title: "Action By",
        dataIndex: "actionBy",
        key: "actionBy",
      },
      {
        title: "Record Id",
        dataIndex: "recordId",
        key: "recordId",
      },
      {
        title: "Flight Permission Id",
        dataIndex: "flightPermissionId",
        key: "flightPermissionId",
      },
    ];

    return (
      <Card>
        {renderTableHeader()}
        <Table
          scroll={{ x: "auto" }}
          dataSource={dataSource}
          columns={columns}
        />
      </Card>
    );
  };

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <UnderlineTypography>लगहरू</UnderlineTypography>
      </div>
      {renderTable()}
    </div>
  );
}
