import { Button, Card, Form, Input } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import Logo from "../../components/common/logo";
import { loginIntoSystem } from "../../features/auth/authSlice";
import { useAppDispatch } from "../../hooks";
import styles from "./style.module.css";
interface SignInPageProps { }
interface SignInPageState {
  loading: boolean
  error: any | null
  isCaptchaValid: boolean
}

function SignInPage(props: SignInPageProps) {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<SignInPageState>({
    loading: false,
    error: null,
    isCaptchaValid: false
  });
  const onFinish = (values: { username: string; password: string }) => {
    // if(!state.isCaptchaValid){
    //   return message.error("Please accept the captcha first!")
    // }
    setState({ ...state, loading: true, error: null });
    dispatch(
      loginIntoSystem({
        identifier: values.username,
        password: values.password,
      })
    )
      .unwrap()
      .then((res) => {
        // console.log("res",res)
        setState({ ...state, loading: false });
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err });
        toast.error(err?.message || "Error logging into system");
      });
  };

  return (
    <div className={[styles.parent].join(" ")}>
      <Card className={[styles.formCard].join(" ")} bordered={false}>
        <Logo withName={true} style={{ marginBottom: "20px" }} />
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout={"vertical"}
          className={[styles.form].join(" ")}
          style={{ width: '100%' }}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          {/* <ReCaptcha
             onChange={(token:string|null) =>
              setState({
                ...state,
                isCaptchaValid: Boolean(token),
              })
            }
          /> */}

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={state.loading}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default SignInPage;
