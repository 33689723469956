import { Tabs } from "antd"
import { ReactElement } from "react"
import CurrentUserPolicy from "../../../../features/uipolicies/currentuserpolicy"

interface RecordHomeProps {}

export default function RecordHome(props: RecordHomeProps): ReactElement {
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        items={CurrentUserPolicy.getInstance()
          .getRecords()
          .map((record, index) => {
            const id = String(index + 1)

            return {
              label: (
                <span style={{ verticalAlign: "middle" }}>
                  {record.icon({ style: { verticalAlign: "middle", marginRight: "10px" } })}
                  {record.title}
                </span>
              ),
              key: id,
              children: record.recordListComponent,
            }
          })}
      />
    </div>
  )
}
