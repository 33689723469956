import { Image, Button, Upload } from "antd";
import React, { ReactElement, useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import fileUploadHttpService from "../../../services/https/apis/fileupload.http.service";
import { toast } from "react-toastify";
import { getFilePath } from "../../../utils/general.utils";
import type { UploadFile } from "antd/es/upload/interface";
import { UploadedImage } from "../multiImageUploadHandler";

interface SingleImageUploadHandlerProps {
  imageProps?: React.ComponentProps<typeof Image>;
  value?: UploadFile & any;
  containerProps?: React.ComponentProps<"div">;
  height?: string | number;
  width?: string | number;
  onChange?: (value: any) => void;
  doHttpOperationOnAdd?: boolean;
}

export default function SingleImageUploadHandler({
  value,
  containerProps,
  imageProps,
  height = 100,
  width = 100,
  doHttpOperationOnAdd,
  onChange,
}: SingleImageUploadHandlerProps): ReactElement {
  const [file, setFile] = useState<UploadFile & any>(value);
  const [addloading, setAddLoading] = useState(false);

  useEffect(() => {
    setFile(value);
  }, [value]);

  const onRemove = (indexId: number) => {
    setFile(null);
    onChange?.(null);
  };

  const onReplace = (indexId: number, newFile: any) => {
    setFile(newFile);
    onChange?.(newFile);
  };

  const onAdd = (newFile: UploadFile<any>) => {
    if (doHttpOperationOnAdd && newFile) {
      setAddLoading(true);
      fileUploadHttpService
        .fileUpload(newFile.originFileObj)
        .then((res) => {
          setAddLoading(false);
          setFile(res);
          onChange?.(res);
        })
        .catch((err) => {
          toast.error("Error adding file");
          setAddLoading(false);
        });
    } else {
      setFile(newFile);
      onChange?.(newFile);
    }
  };

  const { style, ...imgProps } = imageProps || {};
  return (
    <div {...containerProps}>
      {file ? (
        <UploadedImage
          indexId={1}
          src={
            file?.attributes?.url || file?.url
              ? getFilePath(file?.attributes?.url || file?.url)
              : URL.createObjectURL(file?.originFileObj || {})
          }
          file={file}
          style={{
            width: width,
            height: height,
            objectFit: "contain",
            backgroundColor: "black",
            ...style,
          }}
          {...imgProps}
          onClickRemove={onRemove}
          onClickReplace={onReplace}
        />
      ) : (
        <Upload
          multiple={false}
          beforeUpload={() => false}
          accept="image/png,image/jpeg"
          onChange={(info) => {
            onAdd(info.fileList?.[0]);
          }}
          fileList={[]}
        >
          <div
            style={{
              height: height,
              width: width,
              border: "1px dashed black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              loading={addloading}
              shape="circle"
              icon={<PlusOutlined />}
              size="small"
            />
          </div>
        </Upload>
      )}
    </div>
  );
}
