import {
  Avatar,
  Button,
  Card,
  Image,
  Space,
  Table,
  Tag,
  theme,
  Typography,
} from "antd";
import React, { createRef } from "react";
import {
  deleteThirdparty,
  fetchThirdparties,
} from "../../../../features/thirdparties/thirdpartiesThunk";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import CreateORUpdateThirdPartyModal, {
  CreateORUpdateThirdPartyModalRefsHandler,
} from "./components/createORupdateModal/createORupdateModal";
import { Bars } from "react-loader-spinner";
import { getFilePath } from "../../../../utils/general.utils";
import { ColumnsType } from "antd/es/table";
import DeletePopConfirmed from "../../../../components/common/deletepopconfirmed";
import { toast } from "react-toastify";
import UnderlineTypography from "../../../../components/common/underlineTypography";

interface ThirdPartiesHomeProps {}

export default function ThirdPartiesHome({}: ThirdPartiesHomeProps) {
  const { token } = theme.useToken();
  const dispatch = useAppDispatch();
  const thirdparties = useAppSelector((state) => state.thirdparties);
  const createUpdateModalRef =
    createRef<CreateORUpdateThirdPartyModalRefsHandler>();

  const renderTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
          flexWrap: "wrap",
        }}
      >
        <Button
          type="dashed"
          icon={<ReloadOutlined />}
          onClick={() => {
            dispatch(fetchThirdparties());
          }}
          disabled={thirdparties.error || thirdparties.loading}
        >
          रिफ्रेस गर्नुहोस्
        </Button>
        <Space wrap>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => {
              createUpdateModalRef.current?.setState({ open: true });
            }}
          >
            थप्नुहोस्
          </Button>
          {/* <Button
            type="dashed"
            icon={<FilterOutlined />}
            disabled={thirdparties.error || thirdparties.loading}
          >
            फिल्टर गर्नुहोस्
          </Button> */}
          {/* <Button
            type="dashed"
            icon={<ExportOutlined />}
            disabled={thirdparties.error || thirdparties.loading}
          >
            निर्यात गर्नुहोस्
          </Button> */}
        </Space>
      </div>
    );
  };

  const renderTable = () => {
    if (thirdparties.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>
            {thirdparties.error?.message || "Error occured"}
          </Typography>
          <Button type="primary" style={{ backgroundColor: "red" }}>
            Try Again
          </Button>
        </Card>
      );
    }

    if (thirdparties.loading) {
      return (
        <Card>
          {renderTableHeader()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      );
    }

    const dataSource = thirdparties.entities.map((f) => ({
      id: f?.id,
      fullName: f?.fullName || "--",
      username: f?.username || "--",
      email: f?.email || "--",
      blocked: f?.blocked,
      phoneNumber: f?.phoneNumber || "--",
      avatarImagePath: getFilePath(f?.avatar?.url),
    }));

    const columns: ColumnsType<{}> = [
      {
        title: "Photo",
        dataIndex: "avatarImagePath",
        key: "avatarImagePath",
        render: (avatarImagePath: string) =>
          avatarImagePath ? (
            <Avatar
              src={<Image src={avatarImagePath} style={{ width: 32 }} />}
            />
          ) : (
            <Avatar icon={<UserOutlined />} />
          ),
        fixed: "left",
        width: 100,
      },
      {
        title: "नाम/थर",
        dataIndex: "fullName",
        key: "fullName",
        width: 250,
      },
      {
        title: "युजरनेम",
        dataIndex: "username",
        key: "username",
        width: 150,
      },
      {
        title: "इमेल",
        dataIndex: "email",
        key: "email",
        width: 150,
      },
      {
        title: "फोन",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        render: (phoneNumber: string) => phoneNumber || "--",
        width: 200,
      },
      {
        title: "अबस्था",
        dataIndex: "blocked",
        key: "blocked",
        render: (blocked: string) =>
          blocked ? (
            <Tag color="error">Blocked</Tag>
          ) : (
            <Tag color="success">Active</Tag>
          ),
        width: 200,
        fixed: "right",
      },
      {
        title: "कार्यहरु",
        key: "action",
        width: 200,
        fixed: "right",
        render: (_: any, record: any) => (
          <Space size="middle">
            <EditOutlined
              style={{ cursor: "pointer", color: token.colorWarning }}
              onClick={() => {
                const user = thirdparties?.entities?.find(
                  (f) => f.id === record.id
                );
                createUpdateModalRef.current?.setState({
                  open: true,
                  user: user,
                  action: "update",
                });
              }}
            />
            <DeletePopConfirmed
              title="Really want to delete?"
              actionPromiseFunction={async () => {
                return dispatch(deleteThirdparty(record?.id))
                  .unwrap()
                  .then((res) => {
                    toast.success("Deleted successfully");
                    return res;
                  })
                  .catch((err) => {
                    toast.error(err?.message || "Error deleting");
                    return Promise.reject(err);
                  });
              }}
            />
          </Space>
        ),
      },
    ];

    return (
      <Card>
        {renderTableHeader()}
        <Table
          scroll={{ x: "auto" }}
          dataSource={dataSource}
          columns={columns}
        />

        <CreateORUpdateThirdPartyModal ref={createUpdateModalRef} />
      </Card>
    );
  };

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <UnderlineTypography>तेस्रो पक्ष प्रयोगकर्ताहरु</UnderlineTypography>
      </div>
      {renderTable()}
    </div>
  );
}
