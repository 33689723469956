import { Modal } from "antd"
import React, { forwardRef, useImperativeHandle, useState } from "react"

interface SelfControlModalProps extends React.ComponentProps<typeof Modal> {
  anchorElement?: JSX.Element
}

interface SelfControlModalState {
  open?: boolean
}

export interface SelfControlModalRefsHandler {
  show: () => void
  hide: () => void
}

const initialState: SelfControlModalState = {
  open: false,
}

const SelfControlModal = forwardRef<SelfControlModalRefsHandler, SelfControlModalProps>(
  ({ children, anchorElement, ...props }: SelfControlModalProps, ref) => {
    const [state, setState] = useState<SelfControlModalState>(initialState)

    useImperativeHandle(ref, () => ({
      show: showModal,
      hide: hideModal,
    }))

    const showModal = () => {
      setState((prevState) => ({ ...prevState, open: true }))
    }

    const hideModal = () => {
      setState((prevState) => ({ ...prevState, ...initialState }))
    }

    const renderAnchorElementWithHandleShowModal = () => {
      if (!anchorElement) return null
      return React.cloneElement(anchorElement, {
        onClick: () => {
          anchorElement?.props?.onClick?.()
          showModal()
        },
        style: {
          cursor: "pointer",
          ...anchorElement?.props?.style,
        },
      })
    }

    return (
      <React.Fragment>
        {renderAnchorElementWithHandleShowModal()}
        <Modal
          centered
          open={state.open}
          footer={null}
          onCancel={hideModal}
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          {...props}
        >
          {state.open && children}
        </Modal>
      </React.Fragment>
    )
  }
)
export default SelfControlModal
