import React, { ReactElement, useState, useEffect } from "react";
import CountStats from "../../../../components/stats/count";
import usersHttpService from '../../../../services/https/apis/users.http.service';
interface UsersCountStatsProps {}

export default function UsersCountStats({}: UsersCountStatsProps): ReactElement {
  const [state, setState] = useState({
    loading: true,
    error: null,
    count: 0,
  });

  const fetchCount = () => {
    setState({ ...state, loading: true, error: null });
    usersHttpService
      .countUsers()
      .then((res) => {
        setState({ ...state, loading: false, count: res });
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err });
      });
  };

  useEffect(() => {
    fetchCount();
  }, []);

  return (
    <CountStats
      title="प्रयोगकर्ताहरु"
      count={state.count}
      error={state.error}
      loading={state.loading}
      hideNavigate
      onClickTryAgain={fetchCount}
    />
  );
}
