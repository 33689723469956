import { Select } from "antd";
import React from "react";
import { recordStatus } from "../../../constants/recordStatus";

interface RecordStatusSelectorProps
  extends React.ComponentProps<typeof Select> {}

export default function RecordStatusSelector({
  ...props
}: RecordStatusSelectorProps) {
  return (
    <Select
      {...props}
      options={recordStatus.map((f, i) => ({
        value: f.slug,
        label: f.name,
      }))}
    />
  );
}
