import { BellOutlined, SyncOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Drawer,
  List,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { formatDistance } from "date-fns";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAsyncRetry } from "react-use";
import { Records } from "../../../constants/recordList";
import notificationHttpService from "../../../services/https/apis/notification.http.service";
import DataFetchStateComponent from "../datafetchstatecomponent";

interface NotificationsControllerProps { }

export default function NotificationsController(
  props: NotificationsControllerProps
) {
  const [open, setOpen] = useState(false);
  const [draftNotifications, setDraftNotifications] = useState<any>();

  const notificationState = useAsyncRetry(async () => {
    const response = await notificationHttpService.fetchNotifications();
    setDraftNotifications(response);
    return response;
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // console.log({ notificationState });

  const getMessage = () => {
    if (notificationState.error) {
      return "Error";
    } else if (notificationState.loading) {
      return "Loading...";
    } else {
      return draftNotifications?.meta?.pagination?.total;
    }
  };

  const getActionLink = (item: any) => {
    var link = null;
    if (item?.attributes?.notificationFor === "Record") {
      if (item?.attributes?.linkedRecord) {
        link = Records?.find?.(
          (f) =>
            f.type ===
            item?.attributes?.linkedRecord?.data?.attributes?.recordType
        )?.viewRoute(item?.attributes?.linkedRecord?.data?.id);
      }
    } else if (item?.attributes?.notificationFor === "Flight Permission") {
      if (item?.attributes?.linkedFlightPermission) {
        link = `/dashboard/records/registration/view-flightpermission/${item?.attributes?.linkedFlightPermission?.data?.id}`;
      }
    }
    return link;
  };

  const onClickNotification = (item: any) => {
    if (item?.attributes?.isRead) {
      onClose();
    } else {
      notificationHttpService.markAsRead(item?.id).finally(() => {
        notificationState.retry();
        onClose();
      });
    }
  };

  return (
    <>
      <Badge size="small" count={getMessage()}>
        <Button type="link" icon={<BellOutlined />} onClick={showDrawer} />
      </Badge>
      <Drawer
        title={
          <Space>
            <Typography.Text>
              <Badge size="small" count={getMessage()}>
                <Button type="link" icon={<BellOutlined />} />
              </Badge>
              Notifications
            </Typography.Text>
            <Button
              type="link"
              icon={
                <Tooltip title="Refresh">
                  <SyncOutlined />
                </Tooltip>
              }
              onClick={notificationState.retry}
            />
          </Space>
        }
        placement="right"
        onClose={onClose}
        open={open}
      >
        <DataFetchStateComponent
          error={Boolean(notificationState.error)}
          loading={notificationState.loading}
          onClickTryAgain={notificationState.retry}
        >
          <div>
            <List
              itemLayout="horizontal"
              dataSource={draftNotifications?.data || []}
              renderItem={(item: any, index) => {
                const link = getActionLink(item);
                return (
                  <Card
                    key={item?.id || index}
                    bodyStyle={{
                      padding: 5,
                      ...(item?.attributes?.isRead
                        ? {}
                        : { backgroundColor: "#e6f2ff" }),
                      borderRadius: 10,
                    }}
                    className="shadow-sm"
                    style={{ marginBottom: 10 }}
                  >
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <BellOutlined
                            style={{
                              ...(item?.attributes?.isRead
                                ? {}
                                : { color: "red" }),
                            }}
                          />
                        }
                        title={
                          link ? (
                            <Link
                              to={link || ""}
                              onClick={() => onClickNotification(item)}
                            >
                              {item?.attributes?.title}
                            </Link>
                          ) : (
                            item?.attributes?.title
                          )
                        }
                        description={formatDistance(
                          new Date(item?.attributes?.createdAt),
                          new Date(),
                          { addSuffix: true }
                        )}
                      />
                    </List.Item>
                  </Card>
                );
              }}
            />
          </div>
        </DataFetchStateComponent>
      </Drawer>
    </>
  );
}
