import { configureStore } from "@reduxjs/toolkit"
import { applicantBodiesReducer } from "../features/applicantBodies/applicantBodiesSlice"
import authReducer from "../features/auth/authSlice"
import { departmentsReducer } from "../features/departments/departmentsSlice"
import { designationsReducer } from "../features/designations/designationsSlice"
import { employeesReducer } from "../features/employees/employeesSlice"
import { flightCompaniesReducer } from "../features/flightCompanies/employeesSlice"
import { flightPermissionsReducer } from "../features/flightPermissions/flightPermissionsSlice"
import { globalReducer } from "../features/global/globalSlice"
import { internationalBorderReducer } from "../features/internationalBorder/borderSlice"
import { recordsReducer } from "../features/records/recordsSlice"
import { rolesReducer } from "../features/roles/rolesSlice"
import { statisticsReducer } from "../features/statistics/statisticsSlice"
import { templatesReducer } from "../features/templates/templatesSlice"
import { thirdpatiesReducer } from "../features/thirdparties/thirdpartiesSlice"
import { uipoliciesReducer } from "../features/uipolicies/uipoliciesSlice"
import { usersReducer } from "../features/users/usersSlice"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    templates: templatesReducer,
    roles: rolesReducer,
    designations: designationsReducer,
    departments: departmentsReducer,
    records: recordsReducer,
    employees: employeesReducer,
    border: internationalBorderReducer,
    users: usersReducer,
    thirdparties: thirdpatiesReducer,
    uipolicies: uipoliciesReducer,
    flightCompanies: flightCompaniesReducer,
    flightPermissions: flightPermissionsReducer,
    applicantBodies: applicantBodiesReducer,
    global: globalReducer,
    statistics: statisticsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
