import { Popconfirm, theme } from "antd"
import React, { useState } from "react"

import { DeleteOutlined } from "@ant-design/icons"

interface DeletePopConfirmedProps {
  anchorElement?: JSX.Element
  actionPromiseFunction?: () => Promise<any>
  title: React.ReactNode
}

export default function DeletePopConfirmed({ anchorElement, title, actionPromiseFunction }: DeletePopConfirmedProps) {
  const { token } = theme.useToken()
  const [deleteState, setDeleteState] = useState({
    openPopConfirmed: false,
    deleting: false,
  })

  const renderAnchorElementWithHandleShowModal = () => {
    if (!anchorElement) return null
    return React.cloneElement(anchorElement, {
      onClick: () => {
        anchorElement?.props?.onClick?.()
        setDeleteState({ ...deleteState, openPopConfirmed: true })
      },
      style: {
        cursor: "pointer",
        ...anchorElement?.props?.style,
      },
    })
  }

  return (
    <Popconfirm
      title={title}
      onConfirm={() => {
        setDeleteState({ ...deleteState, deleting: true })
        actionPromiseFunction?.()
          .then((res) => {
            setDeleteState({
              ...deleteState,
              deleting: false,
              openPopConfirmed: false,
            })
          })
          .catch((err) => {
            setDeleteState({
              ...deleteState,
              deleting: false,
              openPopConfirmed: false,
            })
          })
      }}
      open={deleteState.openPopConfirmed}
      okButtonProps={{ loading: deleteState.deleting }}
      onCancel={() => {
        setDeleteState({ ...deleteState, openPopConfirmed: false })
      }}
    >
      {renderAnchorElementWithHandleShowModal() || (
        <DeleteOutlined
          style={{ cursor: "pointer", color: token.colorError }}
          onClick={() => {
            setDeleteState({ ...deleteState, openPopConfirmed: true })
          }}
        />
      )}
    </Popconfirm>
  )
}
