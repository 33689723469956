import { createAsyncThunk } from "@reduxjs/toolkit";
import applicantBodyHttpService from "../../services/https/apis/applicantbody.http.service";
export const fetchApplicantBodies = createAsyncThunk(
  "applicantBodies/fetchApplicantBodies",
  async () => {
    return applicantBodyHttpService.fetchApplicantBodies()
  }
);

export const createApplicantBody = createAsyncThunk(
  "applicantBodies/createApplicantBody",
  async (name:string) => {
    return applicantBodyHttpService.createApplicantBody(name)
  }
);
