import { Col, Layout, Menu, Row, Space, theme } from "antd"
import { useCallback, useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import FiscallDropdown from "../../../../components/common/fiscallDropdown"
import Logo from "../../../../components/common/logo"
import ProfileMenu from "../../../../components/common/profilemenu"
import { fetchDepartments } from "../../../../features/departments/departmentsThunk"
import { useAppDispatch } from "../../../../hooks"

const { Content, Header } = Layout

function DashboardLayout() {
  const dispatch = useAppDispatch()
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  let navigate = useNavigate()

  const fetchInitialDashboardData = useCallback(() => {
    //Do initial fetch here
    dispatch(fetchDepartments())
  }, [dispatch])
  useEffect(() => {
    fetchInitialDashboardData()
  }, [fetchInitialDashboardData])

  const onClickSideBarMenu = ({ key }: { key: string }) => {
    if (key !== "logout") {
      navigate(key)
    }
  }

  return (
    <Layout>
      <Header style={{ backgroundColor: "white" }}>
        <Row align="middle" justify="space-between">
          <Col>
            <Logo withName={true} />
          </Col>
          <Col>
            <Space size={25}>
              <FiscallDropdown />
              <ProfileMenu />
            </Space>
          </Col>
        </Row>
      </Header>
      <Layout className="site-layout">
        <Layout.Sider width={200} style={{ background: colorBgContainer }} collapsible>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["/dashboard/list-labourconcurrance"]}
            defaultOpenKeys={["/dashboard/labourconcurrance#root"]}
            style={{ height: "100%", borderRight: 0 }}
            items={[
              {
                key: `/dashboard/labourconcurrance#root`,
                icon: <i className="fa fa-tag"></i>,
                label: `रेकर्डहरु`,

                children: [
                  {
                    key: `/dashboard/list-labourconcurrance`,
                    label: `रेकर्डसूची`,
                  },
                ],
              },
            ]}
            onClick={onClickSideBarMenu}
          />
        </Layout.Sider>
        <Layout style={{ padding: "0 24px 24px", margin: "16px 0" }}>
          {/* <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
          </Breadcrumb> */}
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: "85vh",
              background: "white",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default DashboardLayout
