import { Card, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { ReactElement } from "react"
import { EngToNep } from "../../constants/EnglishtoNepali"

interface UserEntryDataViewPropsProps {
  onValuesChange?: (values: any, index: number) => void
  onRemove?: (index: number) => void
  // index: number;
  onReset?: (index: number) => void
  values?: Record<string, any>
  title?: string
  disabled?: boolean
  loading?: boolean
  fields?: Array<string>
}

export default function UserEntryDataView({ values, title, fields }: UserEntryDataViewPropsProps): ReactElement {
  const dataSource = values?.map((f: any, i: any) => {
    return { ...f, key: i + 1 }
  })

  const columns: ColumnsType<{}> = Array.from({ length: fields?.length || 0 }, (_, i) => ({
    title: fields?.[i] ? EngToNep(fields?.[i]) : "",
    dataIndex: fields?.[i] || "",
    key: fields?.[i] || "",
    width: 200,
    fixed: fields?.[i] === "fullName" || fields?.[i] === "childFullNameNepali" ? "left" : undefined,
  }))

  return (
    <Card title={title} className="shadow-sm" style={{ width: "100%" }}>
      <Table scroll={{ x: 1080 }} columns={columns} dataSource={dataSource} pagination={false} />
    </Card>
  )
}
