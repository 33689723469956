import apiUrl from "./apiUrl";
import axios, {AxiosRequestConfig } from "axios";
import qs from 'qs'

export const appAxiosInstance = axios.create({
  baseURL: `${apiUrl.BASE_URL}/api`,
});

/**
 * Any request to server with proper error handeling and request interceptor to add token to every request
 * @param {AxiosRequestConfig} config
 * @returns {Promise<any>}
 */
export async function AppWebRequest(config: AxiosRequestConfig<any>) {
  config.paramsSerializer = {
    serialize: (params) => qs.stringify(params)
  }
  // var responseError = {};
  return appAxiosInstance(config)
      .then((response) => {
        return Promise.resolve(response.data);
      })
}
