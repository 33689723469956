// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core"

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import { isValidHttpUrl } from "../../../utils/general.utils"
import SelfControlModal from "../selfcontrolmodal"
import PdfThumbnail, { PdfThumbnailProps } from "./thumbnail"

interface PdfViewerProps {
  fileUrl: string
  thumbnailProps?: Partial<Omit<PdfThumbnailProps, "fileUrl">>
  customThumbnail?: JSX.Element
}

export default function PdfViewer({ thumbnailProps, customThumbnail, fileUrl }: PdfViewerProps) {
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  return (
    <SelfControlModal
      anchorElement={
        customThumbnail || (
          <PdfThumbnail
            fileUrl={fileUrl}
            pageIndex={0}
            {...thumbnailProps}
            containerStyle={{
              cursor: "pointer",
              ...thumbnailProps?.containerStyle,
            }}
          />
        )
      }
      width={1000}
      bodyStyle={{
        padding: 20,
        height: "80vh",
        overflow: "scroll",
      }}
    >
      {isValidHttpUrl(fileUrl) ? (
        <Worker workerUrl="/pdf.worker.js">
          <Viewer
            fileUrl={fileUrl}
            plugins={[
              // Register plugins
              defaultLayoutPluginInstance,
            ]}
          />
        </Worker>
      ) : (
        <div
          style={{
            fontSize: 20,
            color: "red",
            textAlign: "center",
            marginTop: 30,
          }}
        >
          Invalid pdf file format, can not open!
        </div>
      )}
    </SelfControlModal>
  )
}
