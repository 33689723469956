import React, { ReactElement } from 'react'

interface RolesHomeProps {

}

export default function RolesHome({}: RolesHomeProps): ReactElement {
    return (
        <div>
RolesHome
        </div>
    )
}
