import { AxiosRequestConfig } from "axios";
import { AppWebRequest } from "../NetworkManager";
import qs from "qs";

class InternationalBorderHttpService {
  fetchBorders(queryString?: string, config?: AxiosRequestConfig<any>) {
    const parsedQs: Record<string, any> = queryString
      ? qs.parse(queryString)
      : {};
    const { params, ...axiosConfig } = config || {};
    const {
      sort = [],
      populate = [],
      filters = {},
      ...otherParams
    } = params || {};
    return AppWebRequest({
      method: "get",
      url: `/international-borders`,
      ...axiosConfig,
      params: {
        sort: parsedQs?.sort || ["updatedAt:desc", ...sort],
        populate: parsedQs?.populate || ["enteredBy", "images", ...populate],
        filters: parsedQs?.filters || filters,
        ...otherParams,
      },
    });
  }

  fetchBorderById(
    id: string,
    queryString?: string,
    config?: AxiosRequestConfig<any>
  ) {
    const parsedQs: Record<string, any> = queryString
      ? qs.parse(queryString)
      : {};
    const { params, ...axiosConfig } = config || {};
    const {
      sort = [],
      populate = [],
      filters = {},
      ...otherParams
    } = params || {};
    return AppWebRequest({
      method: "get",
      url: `/international-borders/${id}`,
      ...axiosConfig,
      params: {
        sort: parsedQs?.sort || sort,
        populate: parsedQs?.populate || ["enteredBy", "images", ...populate],
        filters: parsedQs?.filters || filters,
        ...otherParams,
      },
    });
  }

  createBorder(payload: Record<string, any>) {
    return AppWebRequest({
      method: "post",
      url: `/international-borders`,
      data: payload instanceof FormData ? payload : { data: payload },
    });
  }

  updateBorder(id: string, payload: Record<string, any>) {
    return AppWebRequest({
      method: "put",
      url: `/international-borders/${id}`,
      data: payload instanceof FormData ? payload : { data: payload },
    });
  }

  countBorders(querySting?: string) {
    return AppWebRequest({
      method: "get",
      url: querySting
        ? `/international-borders/count?${querySting}`
        : `/international-borders/count`,
    });
  }

  deleteBorder(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/international-borders/${id}`,
    });
  }
}

const internationalBorderHttpService = new InternationalBorderHttpService();
export default internationalBorderHttpService;
