import { Typography } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import DataFetchStateComponent from "../../../../../../../components/common/datafetchstatecomponent";
import { useAppSelector } from "../../../../../../../hooks";
import { useAppDispatch } from "../../../../../../../hooks/redux/index";
import { fetchRecordsStatisticsData } from "../../../../../../../features/statistics/statisticsThunk";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface RecordTypeChartProps {}

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Records",
    },
  },
};
export default function RecordTypeChart(props: RecordTypeChartProps) {
  const { recordTypeCountAndStatus } = useAppSelector(
    (state) => state.statistics
  );
  const dispatch = useAppDispatch();
  const labels = recordTypeCountAndStatus.entities?.recordsDataByStatus?.map?.(f=>f.recordType)
  const data = {
    labels,
    datasets: [
      {
        label: "CREATED",
        data: recordTypeCountAndStatus.entities?.recordsDataByStatus?.map?.(f=>f?.data?.find?.((d:any)=>d.status==="CREATED")?.count||0),
        backgroundColor: "#1677ff"
      },
      {
        label: "INPROGRESS",
        data: recordTypeCountAndStatus.entities?.recordsDataByStatus?.map?.(f=>f?.data?.find?.((d:any)=>d.status==="INPROGRESS")?.count||0),
        backgroundColor: "#faad14",
      },
      {
        label: "COMPLETED",
        data: recordTypeCountAndStatus.entities?.recordsDataByStatus?.map?.(f=>f?.data?.find?.((d:any)=>d.status==="COMPLETED")?.count||0),
        backgroundColor: "#52c41a",
      },
    ],
  };
  return (
    <div>
      <Typography.Title style={{ textAlign: "center" }} level={5}>
        Records
      </Typography.Title>
      <DataFetchStateComponent
        loading={recordTypeCountAndStatus.loading}
        error={Boolean(recordTypeCountAndStatus.error)}
        onClickTryAgain={() => {
          dispatch(fetchRecordsStatisticsData());
        }}
      >
        <Bar options={options} data={data} />
      </DataFetchStateComponent>
    </div>
  );
}
