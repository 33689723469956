import { ReloadOutlined } from "@ant-design/icons"
import { Select, Typography } from "antd"
import React, { ReactElement } from "react"
import { fetchRoles } from "../../../features/roles/rolesThunk"
import { useAppDispatch, useAppSelector } from "../../../hooks"

interface RoleSelectorProps extends React.ComponentProps<typeof Select> {}

export default function RoleSelector({ disabled, ...props }: RoleSelectorProps): ReactElement {
  const { entities, loading, error } = useAppSelector((state) => state.roles)
  const dispatch = useAppDispatch()

  const fetchData = () => {
    dispatch(fetchRoles())
  }

  return (
    <div>
      <Select
        {...props}
        loading={loading}
        disabled={disabled || loading || Boolean(error)}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        options={entities?.map?.((f) => ({
          value: f.id,
          label: `${f?.type === "authenticated" ? "User" : f?.name}`,
        }))}
        dropdownStyle={{ height: 200, overflow: "auto" }}
      />
      {error && (
        <Typography.Paragraph style={{ color: "red" }}>
          Error fetching,{" "}
          <span style={{ cursor: "pointer" }} title="Reload" onClick={fetchData}>
            Try again <ReloadOutlined style={{ fontSize: 13 }} />
          </span>
        </Typography.Paragraph>
      )}
    </div>
  )
}
