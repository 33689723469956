import { UploadOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Row, Space } from "antd"
import Upload from "antd/es/upload/Upload"
import { useState } from "react"
import { toast } from "react-toastify"
import LastUsedChalaniViewer from "../../../../../../components/common/lastUsedChalaniViewer"
import { SelfControlModalRefsHandler } from "../../../../../../components/common/selfcontrolmodal/index"
import recordHttpService from "../../../../../../services/https/apis/records.http.service"

type UploadFinalLetterProps = {
  record: any
  modalRef: React.RefObject<SelfControlModalRefsHandler>
  fetchByIdAndSetState?: () => void
}

export default function UploadFinalLetter({ record, modalRef, fetchByIdAndSetState }: UploadFinalLetterProps) {
  const [state, setState] = useState<{
    loading: boolean
    letterFile: any
  }>({
    loading: false,
    letterFile: null,
  })

  const [form] = Form.useForm()

  const onFinishForm = (values: any) => {
    if (!values?.letterFile) {
      return toast.info("Please select letter file to upload ")
    }
    const formData = new FormData()
    formData.append("files.pdfLetter", values?.letterFile?.file, values?.letterFile?.name?.file || "")
    formData.append("data", JSON.stringify({ chalaniNo: values?.chalaniNo, record: record.id }))
    setState({ ...state, loading: true })
    recordHttpService
      .uploadFinalLetter(record.id, formData)
      .then((res) => {
        setState({ ...state, loading: false })
        toast.success("Uploaded")
        fetchByIdAndSetState?.()
        modalRef?.current?.hide?.()
      })
      .catch((err) => {
        console.log("Error", err)
        setState({ ...state, loading: false })
        toast.error(err?.message || "Error uploading")
      })
  }

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          chalaniNo: "",
          letterFile: null,
        }}
        onFinish={onFinishForm}
      >
        <Row gutter={10}>
          <Col xs={24}>
            <Form.Item
              label="चलानी नं."
              name="chalaniNo"
              rules={[{ required: true, message: "Can not be empty" }]}
              help={<LastUsedChalaniViewer />}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Final Letter" name="letterFile" rules={[{ required: true, message: "Can not be empty" }]}>
              <Upload
                multiple={false}
                beforeUpload={() => false}
                fileList={form.getFieldValue("letterFile")?.file ? [form.getFieldValue("letterFile")?.file] : []}
                onChange={({ file }) => {
                  setState({ ...state, letterFile: file })
                }}
                accept="application/pdf"
              >
                <Button icon={<UploadOutlined />}>Click to Select File</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" htmlType="submit" loading={state.loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}
