import { Button, Card, Col, Divider, Form, Row, Switch, Tag, Typography } from "antd"

import qs from "qs"
import { ReactElement, useEffect, useState } from "react"
import { Bars } from "react-loader-spinner"
import { useParams } from "react-router-dom"
import recordHttpService from "../../../../../../services/https/apis/records.http.service"
import { RecordStatus, RecordTypesEnum } from "../../../../../../types/typings.d"

import PdfViewer from "../../../../../../components/common/pdfviewer"
import UserEntryDataView from "../../../../../../components/userentryDataView"
import { freevisaextensionUserEntryFormFieldNames } from "../../../../../../constants"
import { getFilePath } from "../../../../../../utils/general.utils"

interface ViewFreeVisaExtensionProps {}

export default function ViewFreeVisaExtension(props: ViewFreeVisaExtensionProps): ReactElement {
  const [form] = Form.useForm()
  const [users, setUsers] = useState<Array<any>>([])
  let { id } = useParams()

  const [state, setState] = useState<{
    loading: boolean
    fetchLoading: boolean
    fetchError: any
    fetchedData: any
    sendLinkToEntryForm: boolean
  }>({
    loading: false,
    fetchLoading: true,
    fetchError: null,
    fetchedData: null,
    sendLinkToEntryForm: true,
  })

  const fetchByIdAndSetState = () => {
    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          "pharchyotPerson",
          "document",
          "finalLetters",
          "finalLetters.pdfLetter",
          "uploadedTippanies",
          "usrEntries",
          "usrEntries.passportImage",
        ],
        filters: {
          recordType: {
            $eq: RecordTypesEnum.FreeArrivalVisa,
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    setState({ ...state, fetchLoading: true, fetchError: null })
    recordHttpService
      .fetchRecordById(id || "", query)
      .then((res) => {
        form.setFieldsValue({
          dartaNo: res?.data?.attributes?.dartaNo,
          invoiceNo: res?.data?.attributes?.invoiceNo,
          chalaniNo: res?.data?.attributes?.chalaniNo,
          invoiceDate: res?.data?.attributes?.invoiceDate,
          recordType: res?.data?.attributes?.recordType,
          recommendingBody: res?.data?.attributes?.recommendingBody,
          isDocumentVerified: res?.data?.attributes?.isDocumentVerified,
        })
        setUsers(res?.data?.attributes?.usrEntries || [])
        setState({ ...state, fetchLoading: false, fetchedData: res.data })
      })
      .catch((err) => {
        setState({ ...state, fetchLoading: false, fetchError: err })
      })
  }

  useEffect(() => {
    fetchByIdAndSetState()
  }, [])

  const onResetForm = () => {
    form.resetFields()
    setUsers([])
  }

  if (state.fetchLoading) {
    return (
      <Card>
        <div
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </Card>
    )
  }

  if (state.fetchError) {
    return (
      <Card
        style={{
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography>{state.fetchError?.message || "Error occured"}</Typography>
        <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => fetchByIdAndSetState()}>
          Try Again
        </Button>
      </Card>
    )
  }

  return (
    <div>
      <div className="site-card-border-less-wrapper">
        <Card
          title={
            <div>
              <Typography.Title level={3} className="he-title">
                नि: शुल्क भिसा / Free Visa Extension हेर्नुहोस्
              </Typography.Title>
              <Typography.Title style={{ marginTop: "-1rem" }}>
                {state?.fetchedData?.attributes?.status === RecordStatus.INPROGRESS ? (
                  <Tag color="warning">{state?.fetchedData?.attributes?.status}</Tag>
                ) : state?.fetchedData?.attributes?.status === RecordStatus.CREATED ? (
                  <Tag color="blue">{state?.fetchedData?.attributes?.status}</Tag>
                ) : (
                  <Tag color="success">{state?.fetchedData?.attributes?.status}</Tag>
                )}
              </Typography.Title>
            </div>
          }
          bordered={false}
        >
          <Row gutter={[32, 32]}>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>दर्ता नं.:</Col>
                <Col span={12}>{state.fetchedData?.id}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>पत्र संख्या:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.invoiceNo}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>चलानी नं.:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.chalaniNo}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>पत्र मिती:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.invoiceDate}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>पत्राचार गर्ने निकाय:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.correspondenceBody}</Col>
              </Row>
            </Col>

            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>फर्छ्योट गर्ने व्यक्ति:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.pharchyotPerson?.data?.attributes?.username}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>मन्त्रालयको निर्णय मिती:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.ministryDecisionDate}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>सिफारिस गर्ने निकाय:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.recommendingDiplomaticAgency}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>भिसा अबधि (देखि):</Col>
                <Col span={12}>{state.fetchedData?.attributes?.fromVisaDuration}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>भिसा अबधि (सम्म):</Col>
                <Col span={12}>{state.fetchedData?.attributes?.toVisaDuration}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Row>
                <Col span={6}>पत्राचार हुने मिती:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.dateOfCorrespondence}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Row>
                <Col span={6}>भ्रमण प्रयोजन:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.purposeOfVisit}</Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 20 }}>
                <Typography.Title level={4} className="he-subtitle">
                  विदेशी नागरिकको लागि
                </Typography.Title>
              </div>
            </div>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <UserEntryDataView fields={freevisaextensionUserEntryFormFieldNames} values={users} />
            </Row>
          </div>

          <Divider />
          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 20 }}>
              {state.fetchedData?.attributes?.document?.data && (
                <>
                  <Typography.Title level={5}>कागजात प्रमाणित छ</Typography.Title>
                  <Switch checked={state.fetchedData?.attributes?.isDocumentVerified} disabled={true} />
                </>
              )}
              <Divider />
              <Typography.Title level={4} className="he-subtitle" style={{ marginTop: 5 }}>
                कागजात
              </Typography.Title>
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {state.fetchedData?.attributes?.document?.data && (
                <PdfViewer fileUrl={getFilePath(state.fetchedData?.attributes?.document.data?.attributes?.url)} />
              )}
            </div>
          </div>
          <Divider />
          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 20 }}>
              <Typography.Title level={4} className="he-subtitle">
                अन्तिम पत्र
              </Typography.Title>
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {state.fetchedData?.attributes?.finalLetters?.data?.map?.((t: any, i: number) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    key={i}
                  >
                    <PdfViewer fileUrl={getFilePath(t?.attributes?.pdfLetter?.data?.attributes?.url)} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <span style={{ marginRight: 5 }}>चलानी नं.</span>
                      <span>{t?.attributes?.chalaniNo || "--"}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
