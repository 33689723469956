import { Affix, Button, Form, Input, Modal, Space, Typography } from "antd";
import {
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { toast } from "react-toastify";
import { uiPolicyDefault } from '../../../../../constants/uiPolicy';
import {
  createUIPolicy,
  updateUIPolicy,
} from "../../../../../features/uipolicies/uipoliciesThunk";
import { useAppDispatch } from "../../../../../hooks";
import PolicyEditor from "../policyeditor";
interface CreateORUpdateUIPolicyModalProps { }

interface CreateORUpdateUIPolicyModalState {
  open?: boolean;
  uipolicy?: Record<string, any>;
  action?: "create" | "update";
  creatingOrUpdating?: boolean;
}

export interface CreateORUpdateUIPolicyModalRefsHandler {
  setState: (comState: Partial<CreateORUpdateUIPolicyModalState>) => void;
}

const initialState: CreateORUpdateUIPolicyModalState = {
  open: false,
  uipolicy: undefined,
  action: "create",
  creatingOrUpdating: false,
};

const CreateORUpdateUIPolicyModal = forwardRef<
  CreateORUpdateUIPolicyModalRefsHandler,
  CreateORUpdateUIPolicyModalProps
>((props: CreateORUpdateUIPolicyModalProps, ref) => {
  const [form] = Form.useForm();
  const [comState, setComState] =
    useState<CreateORUpdateUIPolicyModalState>(initialState);

  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => ({
    setState: (comStateArg: Partial<CreateORUpdateUIPolicyModalState>) => {
      setComState({ ...comState, ...comStateArg });
      form.setFieldsValue(comStateArg?.uipolicy?.attributes)
    },
  }));

  const onFinishedCreateOrUpdate = (values: any) => {
    setComState({ ...comState, creatingOrUpdating: true });
    if (action === "create") {
      dispatch(createUIPolicy({ name: values?.name, policySettings: values.policySettings }))
        .unwrap()
        .then((res) => {
          setComState({ ...comState, creatingOrUpdating: false });
          toast.success("Created successfully");
          closeDialog()
        })
        .catch((err) => {
          setComState({ ...comState, creatingOrUpdating: false });
          toast.error(err?.message || "Error creating");
        });
    } else {
      dispatch(
        updateUIPolicy({
          id: comState.uipolicy?.id,
          payload: { name: values?.name, policySettings: values.policySettings },
        })
      )
        .unwrap()
        .then((res) => {
          setComState({ ...comState, creatingOrUpdating: false });
          toast.success("Updated successfully");
        })
        .catch((err) => {
          setComState({ ...comState, creatingOrUpdating: false });
          toast.error(err?.message || "Error updating uipolicy");
        });
    }
  };

  const closeDialog = () => {
    setComState({ ...comState, ...initialState });
    form.resetFields()
  };

  const { uipolicy, open, action, creatingOrUpdating } = comState;
  if (!open) {
    return null;
  }
  return (
    <Modal
      title={`प्रयोगकर्ता इन्टरफेस नीति ${action === "create" ? "थप्नुहोस्" : "सम्पादन गर्नुहोस्"}`}
      centered
      open={open}
      footer={null}
      onCancel={closeDialog}
      maskClosable={false}
      keyboard={false}
      width={900}
      destroyOnClose
    >
      {open && (
        <Form
          initialValues={{ name: uipolicy?.attributes?.name || "", policySettings: uipolicy?.attributes?.policySettings || uiPolicyDefault }}
          onFinish={onFinishedCreateOrUpdate}
          layout="vertical"
          form={form}
        >
          <Form.Item
            name="name"
            label="नाम"
            style={{ userSelect: "none" }}
            rules={[{ required: true, message: "Can not be empty" }]}
          >
            <Input />
          </Form.Item>

          <Typography.Title level={4} className="he-subtitle">नीति</Typography.Title>
          <Form.Item name="policySettings">
            <PolicyEditor />
          </Form.Item>

          <Form.Item>
            <Affix offsetBottom={0}>
              <Space style={{ display: "flex", justifyContent: "flex-end", backgroundColor: 'white', paddingBottom: 10 }}>
                <Button type="dashed" onClick={closeDialog}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={creatingOrUpdating}
                >
                  {action === "update" ? "Update" : "Create"}
                </Button>
              </Space>
            </Affix>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
});
export default CreateORUpdateUIPolicyModal;
