import { Button, Card, Col, Divider, Form, Row, Space, Switch, Tag, Typography } from "antd"
import qs from "qs"
import { ReactElement, useEffect, useState } from "react"
import { Bars } from "react-loader-spinner"
import { useParams } from "react-router-dom"
import recordHttpService from "../../../../../../services/https/apis/records.http.service"
import { RecordStatus, RecordTypesEnum } from "../../../../../../types/typings.d"

import PdfViewer from "../../../../../../components/common/pdfviewer"
import UserEntryDataView from "../../../../../../components/userentryDataView"
import { labourConcurranceUserEntryFormFieldNames } from "../../../../../../constants"
import { getFilePath, isInThePast } from "../../../../../../utils/general.utils"

interface ViewLabourConcurranceProps {}
export default function ViewLabourConcurrance(props: ViewLabourConcurranceProps): ReactElement {
  const [form] = Form.useForm()
  const [users, setUsers] = useState<Array<any>>([])
  let { id } = useParams()
  const [state, setState] = useState<{
    loading: boolean
    fetchLoading: boolean
    fetchError: any
    fetchedData: any
    sendLinkToEntryForm: boolean
  }>({
    loading: false,
    fetchLoading: true,
    fetchError: null,
    fetchedData: null,
    sendLinkToEntryForm: true,
  })

  const fetchByIdAndSetState = () => {
    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          "labourConcurranceDocument",
          "passportDocument",
          "document",
          "finalLetters",
          "finalLetters.pdfLetter",
          "policeVerifications",
          "policeVerifications.pdfVerification",
          "uploadedTippanies",
          "usrEntries",
          "usrEntries.passportImage",
          "pharchyotPerson",
          "applicantBody",
        ],
        filters: {
          recordType: {
            $eq: RecordTypesEnum.LabourConcurrence,
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    setState({ ...state, fetchLoading: true, fetchError: null })
    recordHttpService
      .fetchRecordById(id || "", query)
      .then((res) => {
        form.setFieldsValue({
          dartaNo: res?.data?.attributes?.dartaNo,
          invoiceNo: res?.data?.attributes?.invoiceNo,
          chalaniNo: res?.data?.attributes?.chalaniNo,
          invoiceDate: res?.data?.attributes?.invoiceDate,
          recordType: res?.data?.attributes?.recordType,
          recommendingBody: res?.data?.attributes?.recommendingBody,
          isDocumentVerified: res?.data?.attributes?.isDocumentVerified,
        })
        setUsers(res?.data?.attributes?.usrEntries || [])
        setState({ ...state, fetchLoading: false, fetchedData: res.data })
      })
      .catch((err) => {
        setState({ ...state, fetchLoading: false, fetchError: err })
      })
  }

  useEffect(() => {
    fetchByIdAndSetState()
  }, [])

  const onResetForm = () => {
    form.resetFields()
    setUsers([])
  }

  if (state.fetchLoading) {
    return (
      <Card>
        <div
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </Card>
    )
  }

  if (state.fetchError) {
    return (
      <Card
        style={{
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography>{state.fetchError?.message || "Error occured"}</Typography>
        <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => fetchByIdAndSetState()}>
          Try Again
        </Button>
      </Card>
    )
  }

  return (
    <div>
      <div className="site-card-border-less-wrapper">
        <Card
          title={
            <div>
              <Typography.Title level={3} className="he-title">
                कार्य सहमति हेर्नुहोस्
              </Typography.Title>
              <Typography.Title style={{ marginTop: "-1rem" }}>
                {state?.fetchedData?.attributes?.status === RecordStatus.INPROGRESS ? (
                  <Tag color="warning">{state?.fetchedData?.attributes?.status}</Tag>
                ) : state?.fetchedData?.attributes?.status === RecordStatus.CREATED ? (
                  <Tag color="blue">{state?.fetchedData?.attributes?.status}</Tag>
                ) : (
                  <Tag color="success">{state?.fetchedData?.attributes?.status}</Tag>
                )}
              </Typography.Title>
            </div>
          }
          bordered={false}
        >
          <Row gutter={[32, 32]}>
            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>दर्ता नं.:</b>
                </Typography.Text>
                <Typography.Text>{state.fetchedData?.id}</Typography.Text>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>पत्र संख्या:</b>
                </Typography.Text>
                <Typography.Text>{state.fetchedData?.attributes?.invoiceNo}</Typography.Text>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>चलानी नं.:</b>
                </Typography.Text>
                <Typography.Text>{state.fetchedData?.attributes?.chalaniNo}</Typography.Text>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>पत्र मिती:</b>
                </Typography.Text>
                <Typography.Text>{state.fetchedData?.attributes?.invoiceDate}</Typography.Text>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>निवेदक निकाय:</b>
                </Typography.Text>
                <Typography.Text>
                  {state.fetchedData?.attributes?.applicantBody?.data?.attributes?.name}
                </Typography.Text>
              </Space>
            </Col>

            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>सम्पर्क नं.:</b>
                </Typography.Text>
                <Typography.Text>{state.fetchedData?.attributes?.contactNumber}</Typography.Text>
              </Space>
            </Col>

            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>फर्छ्योट गर्ने व्यक्ति:</b>
                </Typography.Text>
                <Typography.Text>
                  {state.fetchedData?.attributes?.pharchyotPerson?.data?.attributes?.username}
                </Typography.Text>
              </Space>
            </Col>

            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>इमेल:</b>
                </Typography.Text>
                <Typography.Text>{state.fetchedData?.attributes?.contactEmail}</Typography.Text>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>चालचलनको लागि पत्राचार भएको मिती:</b>
                </Typography.Text>
                <Typography.Text>{state.fetchedData?.attributes?.dateOfCorrespondenceForConduct}</Typography.Text>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>जवाफ आएको मिती:</b>
                </Typography.Text>
                <Typography.Text>{state.fetchedData?.attributes?.dateOfReplay}</Typography.Text>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>मन्त्रालयको निर्णय मिती:</b>
                </Typography.Text>
                <Typography.Text>{state.fetchedData?.attributes?.ministryDecisionDate}</Typography.Text>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>पत्राचार हुने मिती:</b>
                </Typography.Text>
                <Typography.Text>{state.fetchedData?.attributes?.dateOfCorrespondence}</Typography.Text>
              </Space>
            </Col>
            <Col xs={24} md={12}>
              <Space align="center">
                <Typography.Text>
                  <b>सम्बन्धित जिल्ला:</b>
                </Typography.Text>
                <Typography.Text>{state.fetchedData?.attributes?.concernedDistrict}</Typography.Text>
              </Space>
            </Col>
          </Row>
          <Divider />
          <div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 20 }}>
                <Typography.Title level={4} className="he-subtitle">
                  विदेशी नागरिकको लागि
                </Typography.Title>
              </div>
            </div>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <UserEntryDataView fields={labourConcurranceUserEntryFormFieldNames} values={users} />
            </Row>
          </div>
          <Divider />
          <div>
            <div style={{ marginBottom: 20 }}>
              <Typography.Title level={4} className="he-subtitle" style={{ marginTop: 5 }}>
                राहदानी कागजात [Passport Copy]
              </Typography.Title>
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {state.fetchedData?.attributes?.passportDocument?.data && (
                <PdfViewer
                  fileUrl={getFilePath(state.fetchedData?.attributes?.passportDocument.data?.attributes?.url)}
                />
              )}
            </div>
          </div>
          <Divider />
          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 20 }}>
              <Typography.Title level={4} className="he-subtitle">
                प्रहरी प्रमाणीकरण
              </Typography.Title>
              <Form.Item name="policeVerification" valuePropName="checked">
                <Switch disabled />
              </Form.Item>
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {state.fetchedData?.attributes?.policeVerifications?.data?.map?.((t: any, i: number) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    key={i}
                  >
                    <PdfViewer fileUrl={getFilePath(t?.attributes?.pdfVerification?.data?.attributes?.url)} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <span style={{ marginRight: 5 }}>चलानी नं.</span>
                      <span>{t?.attributes?.chalaniNo || "--"}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <Divider />
          <div style={{ marginBottom: 20 }}>
            {state.fetchedData?.attributes?.documentUploadStatus === "Completed" && (
              <>
                <Form.Item
                  label={<Typography.Title level={5}>कागजात प्रमाणित छ</Typography.Title>}
                  name="isDocumentVerified"
                  valuePropName="checked"
                >
                  <Switch checked={state.fetchedData?.attributes?.isDocumentVerified} disabled={true} />
                </Form.Item>
                <Divider />
              </>
            )}

            <Space size={40}>
              <div>
                <div style={{ marginBottom: 20 }}>
                  <Typography.Title level={4} className="he-subtitle" style={{ marginTop: 5 }}>
                    Curriculum Vitae [CV] कागजात
                  </Typography.Title>
                  {state.fetchedData?.attributes?.documentTokenExpiry &&
                  isInThePast(new Date(state.fetchedData?.attributes?.documentTokenExpiry)) ? (
                    <Tag color="error">Expired</Tag>
                  ) : (
                    state.fetchedData?.attributes?.documentUploadStatus &&
                    state.fetchedData?.attributes?.documentUploadStatus === "Pending" && (
                      <Tag color="blue">{state.fetchedData?.attributes?.documentUploadStatus}</Tag>
                    )
                  )}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 20,
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {state.fetchedData?.attributes?.document?.data && (
                    <PdfViewer fileUrl={getFilePath(state.fetchedData?.attributes?.document.data?.attributes?.url)} />
                  )}
                </div>
              </div>
              <div>
                <div style={{ marginBottom: 20 }}>
                  <Typography.Title level={4} className="he-subtitle" style={{ marginTop: 5 }}>
                    कार्य सहमति कागजात [LC]
                  </Typography.Title>
                  {state.fetchedData?.attributes?.documentTokenExpiry &&
                  isInThePast(new Date(state.fetchedData?.attributes?.documentTokenExpiry)) ? (
                    <Tag color="error">Expired</Tag>
                  ) : (
                    state.fetchedData?.attributes?.documentUploadStatus &&
                    state.fetchedData?.attributes?.documentUploadStatus === "Pending" && (
                      <Tag color="blue">{state.fetchedData?.attributes?.documentUploadStatus}</Tag>
                    )
                  )}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 20,
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {state.fetchedData?.attributes?.labourConcurranceDocument?.data && (
                    <PdfViewer
                      fileUrl={getFilePath(
                        state.fetchedData?.attributes?.labourConcurranceDocument.data?.attributes?.url
                      )}
                    />
                  )}
                </div>
              </div>
            </Space>
          </div>
          <Divider />
          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 20 }}>
              <Typography.Title level={4} className="he-subtitle">
                टिप्पणीहरु
              </Typography.Title>
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {state.fetchedData?.attributes?.uploadedTippanies?.data?.map?.((t: any, i: number) => {
                return <PdfViewer fileUrl={getFilePath(t?.attributes?.url)} key={i} />
              })}
            </div>
          </div>
          <Divider />
          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 20 }}>
              <Typography.Title level={4} className="he-subtitle">
                अन्तिम पत्र
              </Typography.Title>
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {state.fetchedData?.attributes?.finalLetters?.data?.map?.((t: any, i: number) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    key={i}
                  >
                    <PdfViewer fileUrl={getFilePath(t?.attributes?.pdfLetter?.data?.attributes?.url)} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <span style={{ marginRight: 5 }}>चलानी नं.</span>
                      <span>{t?.attributes?.chalaniNo || "--"}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
