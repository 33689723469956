import { Button, Col, Form, Input, Row, Select, Space, Table } from "antd";
import Upload from "antd/es/upload/Upload";
import { useState } from "react";
import recordHttpService from "../../../../../../services/https/apis/records.http.service";
import { toast } from "react-toastify";
import { SelfControlModalRefsHandler } from "../../../../../../components/common/selfcontrolmodal/index";
import { UploadOutlined } from "@ant-design/icons";
import flightPermissionHttpService from "../../../../../../services/https/apis/flightpermission.http.service";

type UploadDocumentProps = {
  record: any;
  modalRef: React.RefObject<SelfControlModalRefsHandler>;
  ifForFlightPermission?: boolean;
};

export default function UploadDocument({
  record,
  modalRef,
  ifForFlightPermission,
}: UploadDocumentProps) {
  const [state, setState] = useState<{
    loading: boolean;
    document: any;
  }>({
    loading: false,
    document: null,
  });

  const [form] = Form.useForm();

  const onFinishForm = (values: any) => {
    if (!values?.document) {
      return toast.info("Please select letter file to upload ");
    }
    const formData = new FormData();
    formData.append(
      "files.document",
      values?.document?.file,
      values?.document?.file?.name || ""
    );
    formData.append('data',JSON.stringify({isDocumentVerified:false,status:"IN PROGRESS"}))
    setState({ ...state, loading: true });
    if (ifForFlightPermission) {
      flightPermissionHttpService
        .uploadDocument(record.id, formData)
        .then((res) => {
          setState({ ...state, loading: false });
          toast.success("Uploaded");
          modalRef?.current?.hide?.();
        })
        .catch((err) => {
          console.log("Error", err);
          setState({ ...state, loading: false });
          toast.error(err?.message || "Error uploading");
        });
    } else {
      recordHttpService
        .uploadDocument(record.id, formData)
        .then((res) => {
          setState({ ...state, loading: false });
          toast.success("Uploaded");
          modalRef?.current?.hide?.();
        })
        .catch((err) => {
          console.log("Error", err);
          setState({ ...state, loading: false });
          toast.error(err?.message || "Error uploading");
        });
    }
  };

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          chalaniNo: "",
          document: null,
        }}
        onFinish={onFinishForm}
      >
        <Row gutter={10}>
          <Col xs={24}>
            <Form.Item
              label="कागजात"
              name="document"
              rules={[{ required: true, message: "Can not be empty" }]}
            >
              <Upload
                multiple={false}
                beforeUpload={() => false}
                fileList={
                  form.getFieldValue("document")?.file
                    ? [form.getFieldValue("document")?.file]
                    : []
                }
                onChange={({ file }) => {
                  setState({ ...state, document: file });
                }}
                accept="application/pdf"
              >
                <Button icon={<UploadOutlined />}>
                  Click to Select कागजात
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" htmlType="submit" loading={state.loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
