import { Image, Space, Button, Upload, Popconfirm } from "antd";
import React, { ReactElement, useState, useEffect } from "react";
import {
  DeleteOutlined,
  PlusOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import fileUploadHttpService from "../../../services/https/apis/fileupload.http.service";
import { toast } from "react-toastify";
import { getFilePath } from "../../../utils/general.utils";
import type { UploadFile } from "antd/es/upload/interface";
import styles from "./styles.module.css";

interface MultiImageUploadHandlerProps {
  imageProps?: React.ComponentProps<typeof Image>;
  value?: Array<UploadFile & any>;
  containerProps?: React.ComponentProps<typeof Space>;
  height?: string | number;
  width?: string | number;
  onChange?: (value: Array<any>) => void;
  doHttpOperationOnAdd?: boolean;
}

export default function MultiImageUploadHandler({
  value,
  containerProps,
  imageProps,
  height = 100,
  width = 100,
  doHttpOperationOnAdd,
  onChange,
}: MultiImageUploadHandlerProps): ReactElement {
  const [fileList, setFileList] = useState<Array<UploadFile & any>>(
    value || []
  );
  const [addloading, setAddLoading] = useState(false);

  useEffect(() => {
    setFileList(value || []);
  }, [value]);

  const onRemove = (indexId: number) => {
    const newFileList = [...fileList];
    newFileList.splice(indexId, 1);
    setFileList(newFileList);
    onChange?.(newFileList);
  };

  const onReplace = (indexId: number, file: any) => {
    const newFileList = [...fileList];
    newFileList[indexId] = file;
    setFileList(newFileList);
    onChange?.(newFileList);
  };

  const onAdd = (files: UploadFile<any>[]) => {
    if (doHttpOperationOnAdd && files.length > 0) {
      setAddLoading(true);
      fileUploadHttpService
        .multipleFileUploads(files.map((f) => f.originFileObj))
        .then((res) => {
          setAddLoading(false);
          const newFileList = [...fileList, ...res];
          setFileList(newFileList);
          onChange?.(newFileList);
        })
        .catch((err) => {
          toast.error("Error replacing file");
          setAddLoading(false);
        });
    } else {
      const newFileList = [...fileList, ...files];
      setFileList(newFileList);
      onChange?.(newFileList);
    }
  };

  const { style, ...imgProps } = imageProps || {};
  return (
    <Space wrap size={5} {...containerProps}>
      {fileList.map((f, i) => {
        return (
          <UploadedImage
            key={i}
            indexId={i}
            src={
              f?.attributes?.url || f?.url
                ? getFilePath(f?.attributes?.url || f?.url)
                : URL.createObjectURL(f?.originFileObj || {})
            }
            file={f}
            style={{
              width: width,
              height: height,
              objectFit: "contain",
              backgroundColor: "black",
              ...style,
            }}
            {...imgProps}
            onClickRemove={onRemove}
            onClickReplace={onReplace}
          />
        );
      })}
      <Upload
        multiple={true}
        beforeUpload={() => false}
        accept="image/png,image/jpeg"
        onChange={(info) => {
          onAdd(info.fileList || []);
        }}
        fileList={[]}
      >
        <div
          style={{
            height: height,
            width: width,
            border: "1px dashed black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            loading={addloading}
            shape="circle"
            icon={<PlusOutlined />}
            size="small"
          />
        </div>
      </Upload>
    </Space>
  );
}

interface UploadedImageProps extends React.ComponentProps<typeof Image> {
  file: any;
  indexId: number;
  doHttpOperationOnAdd?: boolean;
  onClickRemove?: (indexId: number, file?: any) => void;
  onClickReplace?: (indexId: number, file: any) => void;
}

export function UploadedImage({
  onClickRemove,
  onClickReplace,
  indexId,
  doHttpOperationOnAdd,
  file,
  ...props
}: UploadedImageProps) {
  const [state, setState] = useState({
    deleteLoading: false,
    replaceLoading: false,
  });
  const divRef = React.useRef<HTMLDivElement>(null);
  const fileChooserRef = React.createRef<any>();

  const onClickReplaceFile = (selectedFile: UploadFile<any>) => {
    if (file.id && selectedFile) {
      setState({ ...state, replaceLoading: true });
      fileUploadHttpService
        .replaceFile(file.id, selectedFile.originFileObj)
        .then((res) => {
          setState({ ...state, replaceLoading: false });
          onClickReplace?.(indexId, res);
          setTimeout(()=>{
            refreshImage()
          },2)
        })
        .catch((err) => {
          toast.error("Error replacing file");
          setState({ ...state, replaceLoading: false });
        });
    } else {
      onClickReplace?.(indexId, selectedFile);
    }
  };

  const onClickDelete = () => {
    if (file.id) {
      setState({ ...state, deleteLoading: true });
      fileUploadHttpService
        .deleteFile(file.id)
        .then((res) => {
          setState({ ...state, deleteLoading: false });
          onClickRemove?.(indexId, file);
        })
        .catch((err) => {
          toast.error("Error deleting file");
          setState({ ...state, deleteLoading: false });
        });
    } else {
      onClickRemove?.(indexId);
    }
  };

  const refreshImage = () => {
    // create a new timestamp
    var timestamp = new Date().getTime();

    var el: any = divRef.current?.getElementsByClassName("ant-image-img")?.[0];

    var queryString = "?t=" + timestamp;

    if (el) {
      el.src = `${el.src}${queryString}`;
    }
  };
  return (
    <div ref={divRef} className={styles.uploadedImgContainer}>
      <Image {...props} id="childImageTag"/>
      <Space className={styles.uploadedImgContainerActions}>
        <Popconfirm
          title="Really want to replace"
          onConfirm={() => {
            console.log("fileChooserRef", fileChooserRef);
            fileChooserRef.current?.click?.();
          }}
        >
          <Button
            style={{ color: "green" }}
            shape="circle"
            icon={<RetweetOutlined />}
            size="small"
            loading={state.replaceLoading}
            title="Replace"
          />
        </Popconfirm>
        <Popconfirm title="Really want to delete" onConfirm={onClickDelete}>
          <Button
            style={{ color: "red" }}
            shape="circle"
            icon={<DeleteOutlined />}
            size="small"
            loading={state.deleteLoading}
            title="Delete"
          />
        </Popconfirm>
      </Space>

      {/* File Chooser Input */}
      <Upload
        multiple={false}
        beforeUpload={() => false}
        accept="image/png,image/jpeg"
        onChange={(fileInfo) => {
          const choosenFile = fileInfo.fileList?.[0];
          if (choosenFile) {
            onClickReplaceFile(choosenFile);
          }
        }}
        fileList={[]}
      >
        <button type="button" ref={fileChooserRef} style={{ display: "none" }}>
          Click Me
        </button>
      </Upload>
    </div>
  );
}
