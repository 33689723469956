import { Select } from "antd"
import React from "react"

interface GenderSelectorProps extends React.ComponentProps<typeof Select> {}

export default function GenderSelector({ ...props }: GenderSelectorProps) {
  return (
    <>
      <Select
        {...props}
        options={[
          { value: "Male", label: "Male" },
          { value: "Female", label: "Female" },
          { value: "Other", label: "Other" },
        ]}
      />
      <label style={{ color: "red" }}>
        {props.value ? !["Male", "Female", "Other"].includes(props.value as string) && "Invalid value" : ""}
      </label>
    </>
  )
}
