import { createSlice } from '@reduxjs/toolkit'
import { fetchEmployees,createEmployee,updateEmployee,deleteEmployee } from './employeesThunk'

// Define a type for the slice state
export interface EmployeeState {
    entities: any[]
    loading: boolean
    error:any
}


// Define the initial state using that type
const initialState: EmployeeState = {
    entities: [],
    loading: false,
    error:null
}

export const employeesSlice = createSlice({
  name: 'employees',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
  },
  extraReducers:(builder)=> {
    //Fetch
      builder.addCase(fetchEmployees.pending,(state,action)=>{
        state.loading=true
        state.error=null
      })
      builder.addCase(fetchEmployees.fulfilled,(state,action)=>{
        state.loading=false
        state.entities=action.payload as []
      })
      builder.addCase(fetchEmployees.rejected,(state,action)=>{
        state.loading=false
        state.error=action.error
      })

      // //Create
      // builder.addCase(createEmployee.fulfilled,(state,action)=>{
      //   state.entities.push(action.payload.data)
      // })

      // //Update
      // builder.addCase(updateEmployee.fulfilled,(state,action)=>{
      //   const updatedTemplated = action?.payload?.data as any
      //   const index = state.entities.findIndex(f=>f?.id===updatedTemplated?.id)
      //   if(index!==-1){
      //       state.entities[index]=updatedTemplated
      //   }
      // })

      // //Delete
      // builder.addCase(deleteEmployee.fulfilled,(state,action)=>{
      //   const updatedTemplated = action?.payload?.data as any
      //   const index = state.entities.findIndex(f=>f?.id===updatedTemplated?.id)
      //   if(index!==-1){
      //       state.entities.splice(index,1)
      //   }
      // })
  },
})

export const employeesReducer = employeesSlice.reducer