import { AppWebRequest } from "../NetworkManager";
import { AxiosRequestConfig } from 'axios';

class ApplicantBodyHttpService {
  fetchApplicantBodies(config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {};
    const {sort,populate,...otherParams}=params||{}
    return AppWebRequest({
      method: "get",
      url:"/applicant-bodies",
      ...axiosConfig,
      params:{
        sort:sort||["updatedAt:desc"],
        ...otherParams
      }
    });
  }

  createApplicantBody(name: string) {
    return AppWebRequest({
      method: "post",
      url: "/applicant-bodies",
      data: { data: { name } },
    });
  }

  deleteApplicantBody(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/applicant-bodies/${id}`,
    });
  }
}

const applicantBodyHttpService = new ApplicantBodyHttpService();
export default applicantBodyHttpService;
