import { Space } from "antd";
import React, { ReactElement } from "react";
import FlightPermissionsCountStats from "../components/flightPermissionsCountstats";

interface Props {}

function HomePageForFlightCompany(props: Props): ReactElement {
  return (
    <div>
      <Space wrap size={30}>
        <FlightPermissionsCountStats forFlightCompany={true}/>
      </Space>
    </div>
  );
}

export default HomePageForFlightCompany;
