export default [
  "प्रधानमन्त्री तथा मन्त्रिपरिषद्‌को कार्यालय, सिंहदरबार, काठमाडौं",
  "अर्थ मन्त्रालय, सिंहदरबार, काठमाडौं",
  "उद्योग, वाणिज्य तथा आपूर्ति मन्त्रालय, सिंहदरबार, काठमाडौं",
  "ऊर्जा, जलस्रोत तथा सिंचाइ मन्त्रालय, सिंहदरबार, काठमाडौं",
  "कानुन, न्याय तथा संसदीय मामिला मन्त्रालय, सिंहदरबार, काठमाडौँ",
  "कृषि तथा पशुपंक्षी विकास मन्त्रालय, सिंहदरबार, काठमाडौं",
  "खानेपानी मन्त्रालय, सिंहदरबार, काठमाडौं",
  "परराष्ट्र मन्त्रालय, सिंहदरबार, काठमाडौं",
  "भूमि व्यवस्था, सहकारी तथा गरिबी निवारण मन्त्रालय, सिंहदरबार, काठमाडौं",
  "भौतिक पूर्वाधार तथा यातायात मन्त्रालय, सिंहदरबार, काठमाडौं",
  "महिला, बालबालिका तथा ज्येष्ठ नागरिक मन्त्रालय, सिंहदरबार, काठमाडौँ",
  "युवा तथा खेलकुद मन्त्रालय, सिंहदरबार, काठमाडौं",
  "रक्षा मन्त्रालय, सिंहदरबार, काठमाडौं",
  "वन तथा वातावरण मन्त्रालय, सिंहदरबार, काठमाडौं",
  "शहरी विकास मन्त्रालय, सिंहदरबार, काठमाडौँ",
  "शिक्षा, विज्ञान तथा प्रविधि मन्त्रालय, सिंहदरबार, काठमाडौं",
  "श्रम, रोजगार, तथा सामाजिक सुरक्षा मन्त्रालय, सिंहदरबार, काठमाडौं",
  "सङ्घीय मामिला तथा सामान्य प्रशासन मन्त्रालय, सिंहदरबार, काठमाडौं",
  "सञ्चार तथा सूचना प्रविधि मन्त्रालय, सिंहदरबार, काठमाडौं",
  "संस्कृति, पर्यटन तथा नागरिक उड्डयन मन्त्रालय, सिंहदरबार, काठमाडौं",
  "स्वास्थ्य तथा जनसंख्या मन्त्रालय, रामशाह पथ, काठमाडौं",
  "प्रहरी प्रधान कार्यालय, नक्साल, काठमाडौं",
  "सशस्त्र प्रहरी बल, नेपाल, हलचोक, काठमाडौं",
  "राष्ट्रिय अनुसन्धान विभाग, बरफवाग, काठमाडौं",
  "अध्यागमन विभाग, कालिकास्थान, काठमाडौँ",
  "लगानी बोर्डको कार्यालय, नयाँ बानेश्वर, काठमाडौं",
  "उद्योग विभाग, त्रिपुरेश्वर, काठमाडौं",
  "राष्ट्रिय पुनर्निर्माण प्राधिकरण, सिंहदरबार, काठमाडौं",
  "योजना, अनुगमन तथा मूल्याङ्कन शाखा",
  "नेपाल हज समितिको सचिवालय, बबरमहल, काठमाडौं",
  "कन्सुलर सेवा विभाग, त्रिपुरेश्वर, काठमाडौं",
];
