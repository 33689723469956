import { AppWebRequest } from "../NetworkManager";
import apiUrl from "../apiUrl";

export const fileUploadUrl = `${apiUrl.BASE_URL}/upload`;
class FileUploadHttpService {
  /**
   * Eg:ref:api::restaurant.restaurant,refId:5c126648c7415f0c0ef1bccd,field:cover
   */
  fileUpload(file: any,linkedEntity?:{ref:string,refId:string,field:string}) {
    const formData = new FormData();
    formData.append("files", file, file?.name);
    if(linkedEntity){
      formData.append('ref',linkedEntity.ref)
      formData.append('refId',linkedEntity.refId)
      formData.append('field',linkedEntity.field)
    }
    return AppWebRequest({
      method: "post",
      url: "/upload",
      data: formData,
    }).then(res=>res?.[0])
  }

  multipleFileUploads(files: any,linkedEntity?:{ref:string,refId:string,field:string}) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append("files", file, file?.name);
    }
    if(linkedEntity){
      formData.append('ref',linkedEntity.ref)
      formData.append('refId',linkedEntity.refId)
      formData.append('field',linkedEntity.field)
    }
    return AppWebRequest({
      method: "post",
      url: "/upload",
      data: formData,
    });
  }

  deleteFile(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/upload/files/${id}`,
    });
  }

  replaceFile(id: string,file:any) {
    const formData = new FormData();
    formData.append("files", file, file?.name);
    return AppWebRequest({
      method: "put",
      url: `/upload/files?id=${id}`,
      data: formData,
    });
  }
}

const fileUploadHttpService = new FileUploadHttpService();
export default fileUploadHttpService;
