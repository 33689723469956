import { AppWebRequest } from "../NetworkManager";
class AuthHttpService {
  register(payload: Record<string, any>) {
    return AppWebRequest({
      method: "post",
      url: "/auth/local/register",
      data: payload,
    });
  }

  login(identifier: string, password: string) {
    return AppWebRequest({
      method: "post",
      url: "/auth/local",
      data: {
        identifier: identifier,
        password: password,
      },
    });
  }

  verifyPassword(password: string) {
    return AppWebRequest({
      method: "post",
      url: "/users-permissions/auth/verifyPassword",
      data: {
        password: password,
      },
    });
  }

  fetchProfile(token?:string) {
    return AppWebRequest({
      method: "get",
      url: "/users/me?populate=*",
      headers:{
        Authorization:`Bearer ${token}`
      }
    });
  }
}

const authHttpService = new AuthHttpService();
export default authHttpService
