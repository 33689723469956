import { UserOutlined } from "@ant-design/icons"
import { Avatar, Button, Col, Form, Image, Input, Modal, Row, Space, Switch, Typography, Upload } from "antd"
import { forwardRef, useImperativeHandle, useState } from "react"
import { Bars } from "react-loader-spinner"
import { toast } from "react-toastify"
import DersignationSelector from "../../../../../../components/selectors/designationselector"
import RoleSelector from "../../../../../../components/selectors/rolesselector"
import UIPolicySelector from "../../../../../../components/selectors/uipolicyselector"
import { selectUserId } from "../../../../../../features/auth/authSlice"
import { fetchRoles } from "../../../../../../features/roles/rolesThunk"
import { createUser, updateUser } from "../../../../../../features/users/usersThunk"
import { useAppDispatch, useAppSelector } from "../../../../../../hooks"
import { getFilePath } from "../../../../../../utils/general.utils"

interface CreateORUpdateUserModalProps {}

interface CreateORUpdateUserModalState {
  open?: boolean
  user?: Record<string, any>
  action?: "create" | "update"
  creatingOrUpdating?: boolean
  avatar: any
}

export interface CreateORUpdateUserModalRefsHandler {
  setState: (comState: Partial<CreateORUpdateUserModalState>) => void
}

const initialState: CreateORUpdateUserModalState = {
  open: false,
  user: undefined,
  action: "create",
  creatingOrUpdating: false,
  avatar: null,
}

const CreateORUpdateUserModal = forwardRef<CreateORUpdateUserModalRefsHandler, CreateORUpdateUserModalProps>(
  (props: CreateORUpdateUserModalProps, ref) => {
    const [form] = Form.useForm()
    const [comState, setComState] = useState<CreateORUpdateUserModalState>(initialState)
    const roles = useAppSelector((state) => state.roles)
    const userId = useAppSelector(selectUserId)
    const defaultRole = useAppSelector((state) => state.roles.entities.find((f) => f.type === "authenticated"))
    const dispatch = useAppDispatch()
    useImperativeHandle(ref, () => ({
      setState: (comStateArg: Partial<CreateORUpdateUserModalState>) => {
        setComState({ ...comState, ...comStateArg })
        form.setFieldsValue({
          avatar: null,
          username: comStateArg.user?.username || "",
          fullName: comStateArg.user?.fullName || "",
          email: comStateArg.user?.email || "",
          phoneNumber: comStateArg.user?.phoneNumber || "",
          password: "",
          confirmedPassword: "",
          role: comStateArg.user?.role?.id || defaultRole?.id,
          uiPolicy: comStateArg.user?.uiPolicy?.id || "",
          sendLoginCredentailsToMail: false,
          blocked: comStateArg.user?.blocked || false,
        })
      },
    }))

    const onFinishedCreateOrUpdate = (values: any) => {
      const { avatar, ...userDetails } = values
      const createPayload = {
        ...userDetails,
        enteredBy: userId,
      }
      const updatePayload = {
        ...userDetails,
      }
      const formData = new FormData()
      if (action === "create") {
        formData.append("data", JSON.stringify(createPayload))
        if (userDetails?.password !== userDetails?.confirmedPassword) {
          return toast.error("password did not match")
        }
        if (avatar) {
          formData.append("files.avatar", avatar?.file, avatar?.file?.name)
        }
        setComState({ ...comState, creatingOrUpdating: true })
        dispatch(createUser(formData))
          .unwrap()
          .then((res) => {
            setComState({ ...comState, creatingOrUpdating: false })
            toast.success("Created successfully")
            closeDialog()
          })
          .catch((err) => {
            console.log("Error component***", err)
            setComState({ ...comState, creatingOrUpdating: false })
            toast.error("Error creating")
          })
      } else {
        if (updatePayload?.password || updatePayload?.confirmedPassword) {
          if (updatePayload?.password !== updatePayload?.confirmedPassword) {
            return toast.error("password did not match")
          }
        } else {
          delete updatePayload?.password
          delete updatePayload?.confirmedPassword
        }
        formData.append("data", JSON.stringify(updatePayload))
        if (avatar) {
          formData.append("files.avatar", comState.avatar, comState.avatar?.name)
        }
        setComState({ ...comState, creatingOrUpdating: true })
        dispatch(
          updateUser({
            id: comState.user?.id,
            payload: formData,
          })
        )
          .unwrap()
          .then((res) => {
            setComState({ ...comState, creatingOrUpdating: false })
            toast.success("Updated successfully")
          })
          .catch((err) => {
            setComState({ ...comState, creatingOrUpdating: false })
            toast.error(err?.message || "Error updating department")
          })
      }
    }

    const closeDialog = () => {
      setComState({ ...comState, ...initialState })
      form.resetFields()
    }

    const { open, action, creatingOrUpdating } = comState
    if (!open) {
      return null
    }

    const renderModalContent = () => {
      if (roles.loading) {
        return (
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )
      }

      if (roles.error) {
        return (
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography>{"Error loading User role from system"}</Typography>
            <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => dispatch(fetchRoles())}>
              Try Again
            </Button>
          </div>
        )
      }

      const renderAvatar = () => {
        if (form.getFieldValue("avatar")?.file) {
          return (
            <Avatar
              size={100}
              src={
                <Image
                  src={URL.createObjectURL(form.getFieldValue("avatar")?.file)}
                  style={{ width: 100 }}
                  preview={false}
                />
              }
            />
          )
        } else if (comState.user?.avatar) {
          return (
            <Avatar
              size={100}
              src={<Image src={getFilePath(comState.user?.avatar?.url)} style={{ width: 100 }} preview={false} />}
            />
          )
        } else {
          return <Avatar size={100} icon={<UserOutlined />} />
        }
      }

      return (
        <Form
          initialValues={{
            avatar: null,
            username: comState.user?.username || "",
            fullName: comState.user?.fullName || "",
            email: comState.user?.email || "",
            phoneNumber: comState.user?.phoneNumber || "",
            password: "",
            confirmedPassword: "",
            role: comState.user?.role?.id || defaultRole?.id,
            uiPolicy: comState.user?.uiPolicy?.id || "",
            sendLoginCredentailsToMail: false,
            blocked: comState.user?.blocked || false,
          }}
          onFinish={onFinishedCreateOrUpdate}
          layout="vertical"
          form={form}
          autoComplete="off"
        >
          <Row gutter={10}>
            <Col xs={24}>
              <Form.Item name="avatar" style={{ textAlign: "center" }}>
                <Upload
                  multiple={false}
                  beforeUpload={() => false}
                  accept="image/png,image/jpeg"
                  fileList={[]}
                  onChange={(info) => {
                    setComState({ ...comState, avatar: info.file })
                  }}
                >
                  {renderAvatar()}
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="username"
                label="युजरनेम (Username)"
                style={{ userSelect: "none" }}
                rules={[{ required: true, message: "Can not be empty" }]}
              >
                <Input autoComplete="off" disabled={action === "update"} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="fullName"
                label="नाम/थर"
                style={{ userSelect: "none" }}
                rules={[{ required: true, message: "Can not be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="email"
                label="इमेल"
                style={{ userSelect: "none" }}
                rules={[{ required: true, message: "Can not be empty" }]}
              >
                <Input type="email" disabled={action === "update"} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="phoneNumber"
                label="फोन"
                style={{ userSelect: "none" }}
                rules={[{ required: true, message: "Can not be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="password"
                label="Password"
                style={{ userSelect: "none" }}
                rules={[
                  {
                    required: action === "update" ? false : true,
                    message: "Can not be empty",
                  },
                ]}
              >
                <Input.Password autoComplete="off" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="confirmedPassword"
                label="Re Enter Password"
                style={{ userSelect: "none" }}
                rules={[
                  {
                    required: action === "update" ? false : true,
                    message: "Can not be empty",
                  },
                ]}
              >
                <Input.Password autoComplete="off" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="designation" label="पदनाम">
                <DersignationSelector />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="role" label="भूमिका" rules={[{ required: true, message: "Can not be empty" }]}>
                <RoleSelector />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="uiPolicy"
                label="प्रयोगकर्ता इन्टरफेस नीति"
                rules={[{ required: true, message: "Can not be empty" }]}
              >
                <UIPolicySelector />
              </Form.Item>
            </Col>
          </Row>
          {action === "update" && (
            <Row>
              <Col>
                <Form.Item name="blocked" label="Deactive" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          )}

          {action === "create" && (
            <Row>
              <Col>
                <Form.Item
                  name="sendLoginCredentailsToMail"
                  label="Send Login Credentails To Email"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item>
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="dashed" onClick={closeDialog}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={creatingOrUpdating}>
                {action === "update" ? "Update" : "Create"}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )
    }

    return (
      <Modal
        title={`प्रयोगकर्ताहरु ${action === "create" ? "थप्नुहोस्" : "सम्पादन गर्नुहोस्"}`}
        centered
        open={open}
        footer={null}
        onCancel={closeDialog}
        maskClosable={false}
        keyboard={false}
        width={1000}
      >
        {open && renderModalContent()}
      </Modal>
    )
  }
)
export default CreateORUpdateUserModal
