import { createAsyncThunk } from "@reduxjs/toolkit"
import chalaniHttpService from "../../services/https/apis/chalani.http.service"
import { RootState } from "../../store"
import { getCurrentFiscalYear } from "../../utils/general.utils"

export const fetchLastUsedChalani = createAsyncThunk(
  "global/fetchLastUsedChalani",
  async (payload: {}, { getState }) => {
    const state = getState() as RootState
    const selectedFiscalYearConfig = state?.global?.appSettings?.selectedFiscallYear || getCurrentFiscalYear()
    return chalaniHttpService.fetchLastUsedChalani(selectedFiscalYearConfig.fiscalYear)
  }
)
