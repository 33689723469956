import React from "react";
import { Form, Input, Modal, Button, Card } from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import authHttpService from "../../../services/https/apis/auth.http.service";
import { setPasswordVerifiedStatus } from "../../../features/global/globalSlice";
import { toast } from "react-toastify";

const withPasswordProtected =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) => {
    const [loading, setLoading] = React.useState(false);
    const isPasswordProtectedVerified = useAppSelector(
      (state) => state.global.isPasswordProtectedVerified
    );
    const dispatch = useAppDispatch();

    const onFinish = (values: { password: string }) => {
      setLoading(true);
      authHttpService
        .verifyPassword(values.password)
        .then((res) => {
          setLoading(false);
          dispatch(setPasswordVerifiedStatus(true));
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message || "Error verifying password");
        });
    };

    if (!isPasswordProtectedVerified) {
      return (
        <div
          style={{
            minHeight: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Card
            title="Verify Password Before Acceess"
            style={{ width: "80%" }}
            className="shadow-md"
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
              layout={"vertical"}
            >
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Verify
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      );
    }
    return <Component {...(props as P)} />;
  };
export default withPasswordProtected;
