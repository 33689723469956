import { PlusOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons"
import { Button, Card, Col, Form, Result, Row, Space, Typography, Upload, message } from "antd"
import { ReactElement, memo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import uniqid from "uniqid"
import { Container } from "../../../../../../components/common/container"
import InputPhoneNumber from "../../../../../../components/common/inputphonenumber"
import DistrictSelector from "../../../../../../components/selectors/districtSelector"
import UserEntryFormRow from "../../../../../../components/userentryFormRow"
import { labourConcurranceUserEntryFormFieldNames } from "../../../../../../constants"
import recordEntryHttpService from "../../../../../../services/https/apis/records.http.service"
import { RecordTypesEnum } from "../../../../../../types/typings.d"
import { isInThePast } from "../../../../../../utils/general.utils"
import { convertExcelFileIntoJSON, downloadExcelTemplate } from "./importUtils"

interface AddEntryToLabourConcurranceProps { }

function AddEntryToLabourConcurrance(props: AddEntryToLabourConcurranceProps): ReactElement {
  const userPayload = {
    uid: uniqid(),
    fullName: "",
    country: "",
    gender: "",
    passportNo: "",
    dob: "",
    passportImage: null,
  }
  let [searchParams, setSearchParams] = useSearchParams()
  const totalEntryCount = Number(searchParams?.get?.("entryCount"))
  const [users, setUsers] = useState(
    totalEntryCount
      ? Array(totalEntryCount)
        .fill(userPayload)
        .map((f) => ({ ...f, uid: uniqid() }))
      : [userPayload]
  )
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [passportDocument, setPassportDocument] = useState<any>(null)

  const [generalDetails, setGeneralDetails] = useState<{ contactNumber: string; concernedDistrict: string }>({
    contactNumber: "",
    concernedDistrict: "",
  })

  const onSubmit = () => {
    if (!generalDetails.contactNumber || !generalDetails.concernedDistrict) {
      return toast.info("Please provide सम्पर्क विवरणहरू")
    }
    if (!passportDocument) {
      return toast.info("Please upload राहदानी कागजात [Passport Copy]")
    }
    const token = searchParams?.get?.("token") || ""
    const recordId = searchParams?.get?.("recordId") || ""
    const formData = new FormData()
    formData.append("files.passportDocument", passportDocument, passportDocument?.name)
    formData.append(
      "data",
      JSON.stringify({
        usrEntries: users.map(({ uid, ...usr }) => usr),
        ...generalDetails,
      })
    )
    setLoading(true)
    recordEntryHttpService
      .createUserEntriesWithEntryToken(recordId, formData, token)
      .then((res) => {
        setLoading(false)
        toast.success(`Submited successfully`)
        setSubmitted(true)
        setSearchParams((prev) => {
          prev.set("submitted", "true")
          return prev
        })
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err?.message || "Error submitting")
      })
  }

  if (submitted || searchParams.get("submitted") === "true") {
    return <Result status="success" title="Successfully Submitted you records" subTitle="" />
  }

  if (!searchParams.get("token") || !searchParams.get("expiredAt") || !searchParams.get("recordId")) {
    return <Result status="warning" title="There are some problems with your application. query params are missing" />
  }

  if (searchParams.get("expiredAt") && isInThePast(new Date(searchParams.get("expiredAt") || new Date()))) {
    return (
      <Result status="warning" title="There are some problems with your application. the token has already expired" />
    )
  }

  return (
    <Container>
      <Card className="shadow-sm" style={{ marginTop: 20 }}>
        <div style={{ marginTop: 20 }}>
          <Typography.Title level={3} className="he-title">सम्पर्क विवरणहरू</Typography.Title>
          <Form
            layout="vertical"
            initialValues={generalDetails}
            onValuesChange={(_, values) => setGeneralDetails(values)}
          >
            <Row>
              <Col xs={24} md={8}>
                <Form.Item label="सम्पर्क नं." name="contactNumber">
                  <InputPhoneNumber noCountryCodeSelection />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} md={8}>
                <Form.Item label="सम्बन्धित जिल्ला" name="concernedDistrict">
                  <DistrictSelector />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {/* <Form.Item >
            <Upload
              multiple={false}
              beforeUpload={() => false}
              accept="application/pdf"
              onChange={(info) => {
                setPassportDocument(info.file)
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Upload राहदानी कागजात</Button>
            </Upload>
          </Form.Item> */}
        </div>

        <Space style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }} wrap>
          <div style={{}}>
            <div>
              <Typography.Title level={3} className="he-title">विदेशी नागरिकको लागि</Typography.Title>
            </div>
          </div>
          <Space>
            <Button
              type="dashed"
              onClick={() => {
                downloadExcelTemplate()
              }}
            >
              Download Template Excel (टेम्प्लेट)
            </Button>
            <Upload
              multiple={false}
              fileList={[]}
              beforeUpload={() => false}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={async (info) => {
                try {
                  const file = info.fileList[0].originFileObj as File
                  if (!file) {
                    message.info("Please select file to import")
                    return
                  }
                  const fileToJson = await convertExcelFileIntoJSON(file!)
                  //Only Pick required no of entries
                  const requiredNoOfUserEntriesData = fileToJson.slice(0, totalEntryCount)
                  const newUsers = [...users]
                  for (let index = 0; index < requiredNoOfUserEntriesData.length; index++) {
                    const element = requiredNoOfUserEntriesData[index] as typeof userPayload
                    newUsers[index] = { ...element, uid: uniqid() }
                  }
                  setUsers(newUsers)
                } catch (err: any) {
                  message.error(err?.message || "Error importing data")
                }
              }}
            >
              <Button type="primary">Import From Excel (इम्पोर्ट)</Button>
            </Upload>
          </Space>
        </Space>
        <Row style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          {users?.map?.((user, index) => {
            return (
              <UserEntryFormRow
                fields={labourConcurranceUserEntryFormFieldNames}
                recordType={RecordTypesEnum.LabourConcurrence}
                key={`${user.uid}`}
                index={index}
                values={user}
                onRemove={(index) => {
                  const newUsers = [...users]
                  newUsers.splice(index, 1)
                  setUsers(newUsers)
                }}
                onReset={() => {
                  const newUsers = [...users]
                  newUsers[index] = userPayload
                  setUsers(newUsers)
                }}
                onValuesChange={(values) => {
                  const newUsers = [...users]
                  newUsers[index] = values
                  setUsers(newUsers)
                }}
              />
            )
          })}
        </Row>
        <Space style={{ marginTop: 20 }}>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => {
              if (users.length === totalEntryCount) {
                return toast.info(`Can not add more than ${totalEntryCount} entries`)
              }
              const newUsers = [...users]
              newUsers.push({ ...userPayload, uid: uniqid() })
              setUsers(newUsers)
            }}
            disabled={loading || users.length === totalEntryCount}
          >
            थप्नुहोस्
          </Button>
        </Space>

        <div style={{ marginTop: 20 }}>
          <Typography.Title level={3} className="he-title">राहदानी कागजात [Passport Copy]</Typography.Title>
          <Form.Item help="फाइल नामको नमुना: रजिशष्ट्रेशन नम्बर_संस्थाको नाम">
            <Upload
              multiple={false}
              beforeUpload={() => false}
              accept="application/pdf"
              onChange={(info) => {
                setPassportDocument(info.file)
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Upload राहदानी कागजात [Passport Copy]</Button>
            </Upload>
          </Form.Item>
        </div>

        <div>
          <Space style={{ marginTop: 20 }}>
            <Button type="primary" icon={<SaveOutlined />} loading={loading} onClick={onSubmit}>
              Submit गर्नुहोस्
            </Button>
          </Space>
        </div>
      </Card>
    </Container>
  )
}

export default memo(AddEntryToLabourConcurrance)
