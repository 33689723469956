import { AxiosRequestConfig } from "axios"
import qs from "qs"
import { RecordStatus } from "../../../types/typings.d"
import { AppWebRequest } from "../NetworkManager"
import auditLogsHttpService from "./auditlog.http.service"

class FlightPermissionHttpService {
  fetchFlightPermissions(queryString?: string, config?: AxiosRequestConfig<any>) {
    const parsedQs: Record<string, any> = queryString ? qs.parse(queryString) : {}
    const { params, ...axiosConfig } = config || {}
    const { sort = [], populate = [], filters = {}, ...otherParams } = params || {}
    return AppWebRequest({
      method: "get",
      url: `/flight-permissions`,
      ...axiosConfig,
      params: {
        sort: parsedQs?.sort || ["updatedAt:desc", ...sort],
        populate: parsedQs?.populate || [
          "enteredBy",
          "document",
          "flightCompany",
          "passengers",
          "finalLetters",
          "finalLetters.pdfLetter",
          "pharchyotPerson",
          ...populate,
        ],
        filters: parsedQs?.filters || filters,
        ...otherParams,
      },
    })
  }

  fetchFlightPermissionById(id: string, queryString?: string, config?: AxiosRequestConfig<any>) {
    const parsedQs: Record<string, any> = queryString ? qs.parse(queryString) : {}
    const { params, ...axiosConfig } = config || {}
    const { sort = [], populate = [], filters = {}, ...otherParams } = params || {}
    return AppWebRequest({
      method: "get",
      url: `/flight-permissions/${id}`,
      ...axiosConfig,
      params: {
        sort: parsedQs?.sort || sort,
        populate: parsedQs?.populate || [
          "enteredBy",
          "document",
          "flightCompany",
          "passengers",
          "finalLetters",
          "finalLetters.pdfLetter",
          "pharchyotPerson",
          ...populate,
        ],
        filters: parsedQs?.filters || filters,
        ...otherParams,
      },
    })
  }

  async createFlightPermission(payload: Record<string, any>) {
    const requestBody = payload instanceof FormData ? payload : { data: payload }
    return AppWebRequest({
      method: "post",
      url: `/flight-permissions`,
      data: requestBody,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Flight Permission Created",
        description: `New Flight Permission Created`,
        modalName: "Flight Permission",
        flightPermission: res?.id,
      })
      return res
    })
  }

  async updateFlightPermission(id: string, payload: Record<string, any>) {
    return AppWebRequest({
      method: "put",
      url: `/flight-permissions/${id}`,
      data: { data: payload },
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Flight Permission Updated",
        description: `Existing Flight Permission Updated`,
        modalName: "Flight Permission",
        flightPermission: id,
      })
      return res
    })
  }

  deleteFlightPermission(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/flight-permissions/${id}`,
    })
  }

  countFlightPermissions(querySting?: string) {
    return AppWebRequest({
      method: "get",
      url: querySting ? `/flight-permissions/count?${querySting}` : `/flight-permissions/count`,
    })
  }

  getFlightPermissionStatistics(params?: Record<string, any>) {
    return AppWebRequest({
      method: "get",
      url: `/flight-permissions/count/passengerEntriesCount`,
      params: params,
    })
  }

  async uploadTipaani(id: string, formData: FormData) {
    return AppWebRequest({
      method: "put",
      url: `/flight-permissions/${id}`,
      data: formData,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Flight Permission Tipaani Uploaded",
        description: `Flight Permission Tipaani Uploaded`,
        modalName: "Flight Permission",
        record: id,
      })
      return res
    })
  }

  async uploadDocument(id: string, formData: FormData) {
    return AppWebRequest({
      method: "put",
      url: `/flight-permissions/${id}`,
      data: formData,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Flight Permission Tipaani Generated",
        description: `Flight Permission Tipaani Generated`,
        modalName: "Flight Permission",
        record: id,
      })
      return res
    })
  }

  async uploadFinalLetter(id: string, formData: FormData) {
    formData.append("record", id)
    return AppWebRequest({
      method: "post",
      url: `/final-letters`,
      data: formData,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Flight Permission Status Changed",
        description: `Flight Permission Status Changed to ${RecordStatus.COMPLETED}`,
        modalName: "Flight Permission",
        record: id,
      })
      auditLogsHttpService.createAuditLog({
        action: "Flight Permission Final Letter Uploaded",
        description: `Flight Permission Final Letter Uploaded`,
        modalName: "Flight Permission",
        record: id,
      })
      return res
    })
  }

  async generateFinalLetter(id: string) {
    return AppWebRequest({
      method: "get",
      url: `/flight-permissions/${id}/generate-finalletter`,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Flight Permission Tipaani Generated",
        description: `Flight Permission Tipaani Generated`,
        modalName: "Flight Permission",
        record: id,
      })
      return res
    })
  }
}

const flightPermissionHttpService = new FlightPermissionHttpService()
export default flightPermissionHttpService
