import { Viewer, Worker } from "@react-pdf-viewer/core"
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail"
import * as React from "react"

import { FilePdfOutlined } from "@ant-design/icons"
import "@react-pdf-viewer/core/lib/styles/index.css"
import { Card } from "antd"
import { getFilename, isValidHttpUrl } from "../../../../utils/general.utils"
import { pageThumbnailPlugin } from "./thumbnailPlugin"

var FileSaver = require("file-saver")

export interface PdfThumbnailProps {
  fileUrl: string
  pageIndex: number
  width?: string | number
  height?: string | number
  containerStyle?: React.CSSProperties
  onClick?: () => void
}

const PdfThumbnail: React.FC<PdfThumbnailProps> = ({ fileUrl, pageIndex, width, height, containerStyle, onClick }) => {
  const thumbnailPluginInstance = thumbnailPlugin()
  const { Cover } = thumbnailPluginInstance
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => pageIndex} />,
  })

  return (
    <div style={{ height: height || 80, width: width || 80, ...containerStyle }} onClick={onClick}>
      <Card
        bodyStyle={{
          padding: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: height || 80,
          width: width || 80,
          ...containerStyle,
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
        // onClick={onClick}
      >
        {isValidHttpUrl(fileUrl) ? (
          <Worker workerUrl="/pdf.worker.js">
            <Viewer fileUrl={fileUrl} plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]} />
          </Worker>
        ) : (
          <div style={{ textAlign: "center" }}>PDF</div>
        )}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "green",
            color: "white",
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            fontSize: 10,
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation()
            FileSaver.saveAs(fileUrl, getFilename(fileUrl))
          }}
        >
          Download
        </div>
        <div
          style={{
            position: "absolute",
            top: -5,
            right: -5,
            color: "orange",
          }}
        >
          <FilePdfOutlined style={{ fontSize: 25 }} />
        </div>
      </Card>
    </div>
  )
}

export default PdfThumbnail
