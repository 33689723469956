import { Result, Button, Typography, Spin } from "antd";
import { WarningOutlined } from "@ant-design/icons";
// import styles from "./styles.module.css";
import { CSSProperties } from "react";

interface DataFetchStateComponentProps {
  children: JSX.Element;
  loading: boolean;
  loadingLabel?: string;
  loadingContainerStyle?: CSSProperties;
  loadingBgComponent?: JSX.Element;
  error: boolean;
  tryAgainButtonText?: string;
  errorMessage?: string;
  errorContainerStyle?: CSSProperties;
  onClickTryAgain?: () => void;
}

export default function DataFetchStateComponent({
  children,
  loading,
  loadingLabel,
  loadingContainerStyle,
  loadingBgComponent,
  error,
  errorMessage,
  errorContainerStyle,
  tryAgainButtonText,
  onClickTryAgain,
}: DataFetchStateComponentProps) {
  if (error) {
    return (
      <Result
        status="error"
        className="shadow-sm"
        icon={<WarningOutlined />}
        title={<Typography.Text type="danger">{errorMessage}</Typography.Text>}
        extra={
          <Button size="small" danger onClick={onClickTryAgain}>
            {tryAgainButtonText}
          </Button>
        }
        style={{ ...errorContainerStyle }}
      />
    );
  }

  if (loading) {
    return (
      <Spin tip={loadingLabel}>
        {loadingBgComponent || (
          <div
            style={{ width: "100%", height: 300, ...loadingContainerStyle }}
            className="shadow-sm"
          ></div>
        )}
      </Spin>
    );
  }
  return children;
}

DataFetchStateComponent.defaultProps = {
  errorMessage: "Ops error occured!",
  loadingLabel: "Loading",
  tryAgainButtonText: "Try Again",
};
