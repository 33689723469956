import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  theme,
} from "antd";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CKHtmlEditor from "../../../../../components/ckeditor";
import { selectUserId } from "../../../../../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import templateHttpService from "../../../../../services/https/apis/template.http.service";

interface CreateBibidhTemplateProps { }


export default function CreateBibidhTemplate(
  props: CreateBibidhTemplateProps
): ReactElement {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const userId = useAppSelector(selectUserId);
  const [state, setState] = useState({
    loading: false,
    sendLinkToEntryForm: true,
  });

  const onFinish = (values: any) => {
    templateHttpService.createTemplate({
      ...values,
      templateType: 'Bibidh',
      enteredBy: userId,
    })
      .then((res) => {
        setState({ ...state, loading: false });
        navigate(-1);
        toast.success(`Created`);
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log("Err", err);
        toast.error(err?.message || "Error creating");
      });
  };
  return (
    <div>
      <div className="site-card-border-less-wrapper">
        <Card
          title={<Typography.Title level={3} className="he-title">बिबिध</Typography.Title>}
          bordered={false}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              name: "",
            }}
            onValuesChange={(val) => console.log({ val })}
          >
            <Row gutter={10}>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="नाम" name="name">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item label="टेम्प्लेट" name="html">
                  <CKHtmlEditor />
                </Form.Item>

              </Col>
            </Row>
            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Save
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
}
