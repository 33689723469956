import {
  Avatar,
  Button,
  Card,
  Col,
  ConfigProvider,
  Form,
  Input,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  theme,
  Typography,
} from "antd"
import { createRef, ReactElement, useState } from "react"

import { DeleteOutlined, EditOutlined, FilterOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons"
import { ColumnsType } from "antd/es/table"
import { Bars } from "react-loader-spinner"
import { toast } from "react-toastify"
import withPasswordProtected from "../../../../../../components/hoc/withPasswordProtected"
import DistrictSelector from "../../../../../../components/selectors/districtSelector"
import MunicipalitySelector from "../../../../../../components/selectors/municipalitySelector"
import ProvinceSelector from "../../../../../../components/selectors/provinceselector"
import { deleteBorder, fetchBorders } from "../../../../../../features/internationalBorder/borderThunk"
import { useAppDispatch, useAppSelector } from "../../../../../../hooks"
import internationalBorderHttpService from "../../../../../../services/https/apis/internationalborder.http.service"
import { getFilePath } from "../../../../../../utils/general.utils"
import CreateORUpdateDepartmentModal, {
  CreateORUpdateBorderModalRefsHandler,
} from "../components/createORupdateModal/createORupdateModal"
import styles from "./styles.module.css"
interface BorderProps {}
function Border(props: BorderProps): ReactElement {
  const { token } = theme.useToken()
  const dispatch = useAppDispatch()
  const border = useAppSelector((state) => state.border)
  const createUpdateModalRef = createRef<CreateORUpdateBorderModalRefsHandler>()

  const [state, setState] = useState<{
    loading: boolean
    error: any
    data?: any[]
  }>({
    loading: true,
    error: null,
    data: undefined,
  })

  // TODO: filter content
  const [form] = Form.useForm()
  const qs = require("qs")
  const [open, setOpen] = useState(false)

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  const onFinish = (values: any) => {
    setState({ ...state, loading: true, error: null })
    const query = qs.stringify(
      {
        populate: ["enteredBy", "images"],
        filters: {
          $or: [
            {
              latitude: values.latitude,
            },
            {
              longitude: values.longitude,
            },
            {
              district: values.district,
            },
            {
              street: values.street,
            },
            {
              municipality: values.municipality,
            },
            {
              state: values.status,
            },
          ],
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    internationalBorderHttpService
      .fetchBorders(query)
      .then((res) => {
        setState({ ...state, loading: false, data: res.data })
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err })
      })

    setOpen(false)
  }

  const content = (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Row gutter={10} style={{ width: "20vw" }}>
        <Col span={12}>
          <Form.Item label="अक्षांश" name="latitude">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="देशान्तर" name="longitude">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="प्रदेश" name="province">
            <ProvinceSelector />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="जिल्ला" name="district">
            <DistrictSelector />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="नगरपालिका" name="municipality">
            <MunicipalitySelector />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Filter
          </Button>
          <Button type="primary" htmlType="reset" style={{ backgroundColor: token.colorWarning }}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
  const renderTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
          flexWrap: "wrap",
        }}
      >
        <Button
          type="dashed"
          icon={<ReloadOutlined />}
          onClick={() => {
            dispatch(fetchBorders())
          }}
          disabled={border.error || border.loading}
        >
          रिफ्रेस गर्नुहोस्
        </Button>
        <Space wrap>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => {
              createUpdateModalRef.current?.setState({ open: true })
            }}
          >
            थप्नुहोस्
          </Button>
          <Popover
            content={content}
            title="फिल्टर गर्नुहोस्"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button type="dashed" icon={<FilterOutlined />}>
              फिल्टर गर्नुहोस्
            </Button>
          </Popover>
          {/* <Button
            type="dashed"
            icon={<ExportOutlined />}
            disabled={border.error || border.loading}
          >
            निर्यात गर्नुहोस्
          </Button> */}
        </Space>
      </div>
    )
  }

  const renderTable = () => {
    if (border.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{border.error?.message || "Error occured"}</Typography>
          <Button type="primary" style={{ backgroundColor: "red" }}>
            Try Again
          </Button>
        </Card>
      )
    }

    if (border.loading) {
      return (
        <Card>
          {renderTableHeader()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      )
    }

    const dataSource = (state.data || border.entities).map((f: any) => ({
      id: f?.id,
      imagePaths: f?.attributes?.images?.data?.map((f: any) => getFilePath(f?.attributes?.url)) || [],
      latitude: f?.attributes?.latitude,
      longitude: f?.attributes?.longitude,
      province: f?.attributes?.province,
      district: f?.attributes?.district,
      municipality: f?.attributes?.municipality,
      wardNo: f?.attributes?.wardNo,
      location: f?.attributes?.location,
    }))

    const columns: ColumnsType<{}> = [
      {
        title: "Photos",
        dataIndex: "imagePaths",
        fixed: "left",
        key: "imagePaths",
        render: (imagePaths: string[] = []) => {
          return (
            <Avatar.Group maxCount={2}>
              {imagePaths.map((f) => (
                <Avatar src={f} />
              ))}
            </Avatar.Group>
          )
        },
      },
      {
        title: "अक्षांश",
        dataIndex: "latitude",
        key: "latitude",
      },
      {
        title: "देशान्तर",
        dataIndex: "longitude",
        key: "longitude",
      },
      {
        title: "प्रदेश",
        dataIndex: "province",
        key: "province",
      },
      {
        title: "जिल्ला",
        dataIndex: "district",
        key: "district",
      },
      {
        title: "नगरपालिका",
        dataIndex: "municipality",
        key: "municipality",
      },
      {
        title: "वार्ड नम्बर",
        dataIndex: "wardNo",
        key: "wardNo",
      },
      {
        title: "कार्यहरु",
        fixed: "right",
        key: "action",
        width: "20%",
        render: (_: any, record: any) => (
          <Space size="middle" style={{ backgroundColor: "transparent !important" }}>
            <EditOutlined
              style={{ cursor: "pointer", color: token.colorWarning }}
              onClick={() => {
                const data = (state.data || border.entities)?.find((f) => f.id === record.id)
                createUpdateModalRef.current?.setState({
                  open: true,
                  border: data,
                  action: "update",
                })
              }}
            />
            <DeletePopConfirmed department={record} />
          </Space>
        ),
      },
    ]

    return (
      <Card>
        {renderTableHeader()}
        <ConfigProvider
          theme={{
            components: {
              Table: {
                controlItemBgHover: "#f5425d !important",
              },
            },
          }}
        >
          <Table
            dataSource={dataSource}
            scroll={{ x: "auto" }}
            columns={columns}
            rowClassName={(record: any) => (!record?.wardNo ? styles.warningTableRow : "")}
          />
        </ConfigProvider>
        <CreateORUpdateDepartmentModal ref={createUpdateModalRef} />
      </Card>
    )
  }
  return <div>{renderTable()}</div>
}

function DeletePopConfirmed({ department }: { department: any }) {
  const dispatch = useAppDispatch()
  const { token } = theme.useToken()
  const [deleteState, setDeleteState] = useState({
    openPopConfirmed: false,
    deleting: false,
  })
  return (
    <Popconfirm
      title="Really want to delete?"
      onConfirm={() => {
        setDeleteState({ ...deleteState, deleting: true })
        dispatch(deleteBorder(department?.id))
          .unwrap()
          .then((res) => {
            setDeleteState({
              ...deleteState,
              deleting: false,
              openPopConfirmed: false,
            })
            toast.success("Deleted")
          })
          .catch((err) => {
            setDeleteState({
              ...deleteState,
              deleting: false,
              openPopConfirmed: false,
            })
            toast.error(err?.message || "Error deleting")
          })
      }}
      open={deleteState.openPopConfirmed}
      okButtonProps={{ loading: deleteState.deleting }}
      onCancel={() => {
        setDeleteState({ ...deleteState, openPopConfirmed: false })
      }}
    >
      <DeleteOutlined
        style={{ cursor: "pointer", color: token.colorError }}
        onClick={() => {
          setDeleteState({ ...deleteState, openPopConfirmed: true })
        }}
      />
    </Popconfirm>
  )
}

export default withPasswordProtected(Border)
