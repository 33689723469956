import { EditOutlined, EyeOutlined, FilterOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Card, Col, Form, Input, Popover, Row, Space, Table, Tag, Typography, theme } from "antd"
import { ColumnsType } from "antd/es/table"
import qs from "qs"
import { ReactElement, useState } from "react"
import { Bars } from "react-loader-spinner"
import { Link } from "react-router-dom"
import RecordStatusSelector from "../../../../components/selectors/recordStatusSelector"
import { Records } from "../../../../constants/recordList"
import { fetchRecords } from "../../../../features/records/recordsThunk"
import { useAppDispatch, useAppSelector } from "../../../../hooks"
import recordHttpService from "../../../../services/https/apis/records.http.service"
import { RecordStatus } from "../../../../types/typings.d"

interface ListAllRecordsProps {}

export default function ListAllRecords(props: ListAllRecordsProps): ReactElement {
  const [state, setState] = useState<{
    loading: boolean
    error: any
    data?: any[]
  }>({
    loading: false,
    error: null,
    data: undefined,
  })
  const { token } = theme.useToken()
  const records = useAppSelector((state) => state.records)
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    setState({ ...state, loading: true, error: null })
    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          // "flightCompany",
          // "passengers",
          "finalLetters",
          "finalLetters.pdfLetter",
          "uploadedTippanies",
          "usrEntries",
          "usrEntries.passportImage",
        ],
        filters: {
          // recordType: {
          //   $eq: RecordTypesEnum.AllRecords,
          // },
          $or: [
            {
              dartaNo: values.dartaNo,
            },
            {
              invoiceNo: values.invoiceNo,
            },
            {
              chalaniNo: values.chalaniNo,
            },
            {
              status: values.status,
            },
          ],
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    recordHttpService
      .fetchRecords(query)
      .then((res) => {
        setState({ ...state, loading: false, data: res.data })
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err })
      })

    setOpen(false)
  }

  const content = (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Row gutter={10} style={{ width: "20vw" }}>
        <Col span={24}>
          <Form.Item label="दर्ता नं." name="dartaNo">
            <Input />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="पत्र संख्या" name="invoiceNo">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="चलानी नं." name="chalaniNo">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="अबस्था" name="status">
            <RecordStatusSelector />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={state.loading}>
            Filter
          </Button>
          <Button
            type="primary"
            htmlType="reset"
            style={{ backgroundColor: token.colorWarning }}
            disabled={state.loading}
          >
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )

  const renderTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Button type="dashed" icon={<ReloadOutlined />} onClick={() => dispatch(fetchRecords())}>
          रिफ्रेस गर्नुहोस्
        </Button>
        <Space>
          <Popover
            content={content}
            title="फिल्टर गर्नुहोस्"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
            placement="bottomLeft"
          >
            <Button type="dashed" icon={<FilterOutlined />}>
              फिल्टर गर्नुहोस्
            </Button>
          </Popover>
        </Space>
      </div>
    )
  }

  const renderTable = () => {
    if (records.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{records.error?.message || "Error occured"}</Typography>
          <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => dispatch(fetchRecords())}>
            Try Again
          </Button>
        </Card>
      )
    }

    if (records.loading) {
      return (
        <Card>
          {renderTableHeader()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      )
    }

    const dataSource = (state.data || records.entities).map((f) => ({
      id: f?.id,
      dartaNo: f?.attributes?.dartaNo,
      invoiceNo: f?.attributes?.invoiceNo,
      invoiceDate: f?.attributes?.invoiceDate,
      chalaniNo: f?.attributes?.chalaniNo,
      status: f?.attributes?.status,
      usrEntries: f?.attributes?.usrEntries,
      recordType: f?.attributes?.recordType,
    }))

    const columns: ColumnsType<{}> = [
      {
        title: "दर्ता नं.",
        dataIndex: "dartaNo",
        key: "dartaNo",
        fixed: "left",
      },
      {
        title: "रेकर्ड प्रकार",
        dataIndex: "recordType",
        key: "recordType",
        render: (recordType: string) => {
          return <Tag color="blue">{recordType}</Tag>
        },
      },
      {
        title: "पत्र संख्या",
        dataIndex: "invoiceNo",
        key: "invoiceNo",
      },
      {
        title: "पत्र मिती",
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        // render: (_: any, record: any) =>
        //   record?.invoiceDate ? format(new Date(record?.invoiceDate), "yyyy-MM-dd") : "--",
      },
      {
        title: "चलानी नं.",
        dataIndex: "chalaniNo",
        key: "chalaniNo",
      },

      {
        title: "अबस्था",
        dataIndex: "status",
        key: "status",
        render: (status: string) => {
          if (status === RecordStatus.INPROGRESS) {
            return <Tag color="warning">{status}</Tag>
          } else if (status === RecordStatus.CREATED) {
            return <Tag color="blue">{status}</Tag>
          } else if (status === RecordStatus.COMPLETED) {
            return <Tag color="success">{status}</Tag>
          }
        },
      },
      {
        title: "कार्यहरु",
        key: "action",
        fixed: "right",
        render: (_: any, record: any) => (
          <Space size="middle">
            <Link to={Records?.find?.((f) => f.type === record?.recordType)?.viewRoute(record?.id) || "#"}>
              <EyeOutlined style={{ cursor: "pointer", color: token.colorSuccess }} />
            </Link>
            <Link to={Records?.find?.((f) => f.type === record?.recordType)?.updateRoute(record?.id) || "#"}>
              <EditOutlined style={{ cursor: "pointer", color: token.colorWarning }} />
            </Link>

            {/* <Popconfirm title="Really want to delete?">
                <DeleteOutlined
                  style={{ cursor: "pointer", color: token.colorError }}
                />
              </Popconfirm> */}
          </Space>
        ),
      },
    ]

    return (
      <Card>
        {renderTableHeader()}
        <Table dataSource={dataSource} scroll={{ x: "auto" }} columns={columns} />
      </Card>
    )
  }

  return <div>{renderTable()}</div>
}
