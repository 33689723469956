import { Select } from "antd";
import React from "react";
import districtList from '../../../constants/districtList.json';
import provinceList from '../../../constants/provinceList.json';

interface DistrictSelectorProps extends React.ComponentProps<typeof Select> {
  selectedProvience?:string
}

export default function DistrictSelector({selectedProvience, ...props }: DistrictSelectorProps) {
  const province = provinceList.find(f=>f.name===selectedProvience)
  const options = (province?districtList.filter(f=>f.province_id===province?.id):districtList).map(f=>({label:f.name,value:f.name}))
  return (
    <Select
      {...props}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={options}
    />
  );
}
