import React, { ReactElement, useState, useEffect } from "react";
import CountStats from "../../../../components/stats/count";
import usersHttpService from "../../../../services/https/apis/users.http.service";
import qs from "qs";
interface EmployeesCountStatsProps {}

export default function EmployeesCountStats({}: EmployeesCountStatsProps): ReactElement {
  const [state, setState] = useState({
    loading: true,
    error: null,
    count: 0,
  });

  const fetchCount = () => {
    const qsString = qs.stringify({
      filters: {
        role: {
          type: {
            $eq: "employee",
          },
        },
      },
      populate:"*"
    });
    setState({ ...state, loading: true, error: null });
    usersHttpService
      .countUsers(qsString)
      .then((res) => {
        setState({ ...state, loading: false, count: res });
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err });
      });
  };

  useEffect(() => {
    fetchCount();
  }, []);

  return (
    <CountStats
      title="कर्मचारी"
      count={state.count}
      error={state.error}
      loading={state.loading}
      onClickTryAgain={fetchCount}
      hideNavigate
    />
  );
}
