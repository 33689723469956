import { Col, Layout, Row, Space } from "antd"
import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import FiscallDropdown from "../../../../components/common/fiscallDropdown"
import Logo from "../../../../components/common/logo"
import NotificationsController from "../../../../components/common/notificationsController"
import ProfileMenu from "../../../../components/common/profilemenu"
import SiderMenu from "../../../../components/common/sidermenu"
import { fetchApplicantBodies } from "../../../../features/applicantBodies/applicantBodiesThunk"
import { fetchDepartments } from "../../../../features/departments/departmentsThunk"
import { fetchDesignations } from "../../../../features/designations/designationsThunk"
import { fetchEmployees } from "../../../../features/employees/employeesThunk"
import { fetchFlightCompanies } from "../../../../features/flightCompanies/flightCompaniesThunk"
import { fetchBorders } from "../../../../features/internationalBorder/borderThunk"
import { fetchRecords } from "../../../../features/records/recordsThunk"
import { fetchRoles } from "../../../../features/roles/rolesThunk"
import {
  fetchFlighPermissionStatisticsData,
  fetchRecordsStatisticsData,
  fetchTopRecentAuditLogs,
  fetchUsersRolesStatisticsData,
} from "../../../../features/statistics/statisticsThunk"
import { fetchThirdparties } from "../../../../features/thirdparties/thirdpartiesThunk"
import { fetchUIPolicies } from "../../../../features/uipolicies/uipoliciesThunk"
import { fetchUsers } from "../../../../features/users/usersThunk"
import { useAppDispatch } from "../../../../hooks"

const { Content, Header } = Layout

function DashboardLayout() {
  const dispatch = useAppDispatch()

  const fetchInitialDashboardData = () => {
    //Do initial fetch here
    dispatch(fetchDepartments())
    dispatch(fetchDesignations())
    dispatch(fetchRoles())
    dispatch(fetchEmployees())
    dispatch(fetchUsers())
    dispatch(fetchThirdparties())
    dispatch(fetchUIPolicies())
    dispatch(fetchFlightCompanies())
    dispatch(fetchBorders())
    dispatch(fetchRecords())
    dispatch(fetchApplicantBodies())
    dispatch(fetchRecordsStatisticsData())
    dispatch(fetchUsersRolesStatisticsData())
    dispatch(fetchFlighPermissionStatisticsData())
    dispatch(fetchTopRecentAuditLogs())
  }
  useEffect(() => {
    fetchInitialDashboardData()
    // eslint-disable-next-line
  }, [])

  return (
    <Layout>
      <Header style={{ backgroundColor: "white" }}>
        <Row align="middle" justify="space-between">
          <Col>
            <Logo withName={true} />
          </Col>
          <Col>
            <Space size={25}>
              <FiscallDropdown />
              <NotificationsController />
              <ProfileMenu />
            </Space>
          </Col>
        </Row>
      </Header>
      <Layout className="site-layout">
        <SiderMenu />
        <Layout style={{ padding: "0 24px 24px", margin: "16px 0" }}>
          {/* <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
          </Breadcrumb> */}
          {/* <div className="container"> */}
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: "85vh",
              background: "white",
            }}
          >
            <Outlet />
          </Content>
          {/* </div> */}
        </Layout>
      </Layout>
    </Layout>
  )
}

export default DashboardLayout
