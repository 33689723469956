import { createAsyncThunk } from "@reduxjs/toolkit";
import statisticsHttpService from "../../services/https/apis/statistics.http.service";
import auditLogsHttpService from "../../services/https/apis/auditlog.http.service";

export const fetchRecordsStatisticsData = createAsyncThunk(
  "statistics/fetchRecordsStatisticsData",
  async () => {
    return statisticsHttpService.fetchRecordsStatisticsData()
  }
);

export const fetchUsersRolesStatisticsData = createAsyncThunk(
  "statistics/fetchUsersRolesStatisticsData",
  async () => {
    return statisticsHttpService.fetchUsersRolesStatisticsData()
  }
);


export const fetchFlighPermissionStatisticsData = createAsyncThunk(
  "statistics/fetchFlighPermissionStatisticsData",
  async () => {
    return statisticsHttpService.fetchFlighPermissionStatisticsData()
  }
);

export const fetchTopRecentAuditLogs = createAsyncThunk(
  "statistics/fetchTopRecentAuditLogs",
  async () => {
    return auditLogsHttpService.fetchTopRecentAuditLogs()
  }
);
