import { ReactElement } from "react";
import RecordsReport from "./recordsReport";
import { Tabs } from "antd";
import FlightPermissionsReport from "./flightPermissionsReport";
import { Records } from "../../../constants/recordList";
import { RecordTypesEnum } from "../../../types/typings.d";
import UnderlineTypography from "../../../components/common/underlineTypography";

interface ReportsProps {}

export default function Reports(props: ReportsProps): ReactElement {
  return (
    <div>
      <div>
        <UnderlineTypography>रिपोर्टहरू</UnderlineTypography>
      </div>
      <Tabs
      defaultActiveKey="1"
      items={[
        {
          key:'1',
          label:"रेकर्डहरु",
          children:<RecordsReport />
        },
        {
          key:'2',
          label:'उडान अनुमतिहरु',
          children:<FlightPermissionsReport />
        }
      ]}
      />
    </div>
  )
}
