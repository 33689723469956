import { EditOutlined, EyeOutlined, FilterOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Card, Col, Form, Input, Popover, Row, Space, Table, Tag, theme, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { format } from "date-fns"
import qs from "qs"
import { ReactElement, useEffect, useState } from "react"
import { Bars } from "react-loader-spinner"
import { Link } from "react-router-dom"
import RecordStatusSelector from "../../../../../../components/selectors/recordStatusSelector"
import recordHttpService from "../../../../../../services/https/apis/records.http.service"
import { RecordStatus, RecordTypesEnum } from "../../../../../../types/typings.d"

interface ListFreeTrekkingPermitProps {}

export default function ListFreeTrekkingPermit(props: ListFreeTrekkingPermitProps): ReactElement {
  const [state, setState] = useState<{
    loading: boolean
    error: any
    data: any[]
  }>({
    loading: true,
    error: null,
    data: [],
  })
  const { token } = theme.useToken()

  const fetchData = () => {
    setState({ ...state, loading: true, error: null })
    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          // "flightCompany",
          // "passengers",
          "usrEntries",
          "usrEntries.passportImage",
        ],
        filters: {
          recordType: {
            $eq: RecordTypesEnum.FreeTrekkingPermit,
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    recordHttpService
      .fetchRecords(query)
      .then((res) => {
        setState({ ...state, loading: false, data: res.data })
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err })
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  // TODO: filter content

  const [open, setOpen] = useState(false)
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    setState({ ...state, loading: true, error: null })
    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          // "flightCompany",
          // "passengers",
          "finalLetters",
          "finalLetters.pdfLetter",
          "uploadedTippanies",
          "usrEntries",
          "usrEntries.passportImage",
        ],
        filters: {
          recordType: {
            $eq: RecordTypesEnum.MultipleEntryVisa,
          },
          $or: [
            {
              dartaNo: values.dartaNo,
            },
            {
              invoiceNo: values.invoiceNo,
            },
            {
              chalaniNo: values.chalaniNo,
            },
            {
              status: values.status,
            },
          ],
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    recordHttpService
      .fetchRecords(query)
      .then((res) => {
        setState({ ...state, loading: false, data: res.data })
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err })
      })

    setOpen(false)
  }

  const content = (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Row gutter={10} style={{ width: "20vw" }}>
        <Col span={24}>
          <Form.Item label="दर्ता नं." name="dartaNo">
            <Input />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="पत्र संख्या" name="invoiceNo">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="चलानी नं." name="chalaniNo">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="अबस्था" name="status">
            <RecordStatusSelector />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={state.loading}>
            Filter
          </Button>
          <Button
            type="primary"
            htmlType="reset"
            style={{ backgroundColor: token.colorWarning }}
            disabled={state.loading}
            // onClick={() => setOpen(false)}
          >
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )

  const renderTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Button type="dashed" icon={<ReloadOutlined />} onClick={() => fetchData()}>
          रिफ्रेस गर्नुहोस्
        </Button>
        <Space>
          <Link to="/dashboard/records/registration/create-freetrekkingpermit">
            <Button type="dashed" icon={<PlusOutlined />}>
              थप्नुहोस्
            </Button>
          </Link>
          <Popover
            content={content}
            title="फिल्टर गर्नुहोस्"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button type="dashed" icon={<FilterOutlined />}>
              फिल्टर गर्नुहोस्
            </Button>
          </Popover>
        </Space>
      </div>
    )
  }

  const renderTable = () => {
    if (state.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{state.error?.message || "Error occured"}</Typography>
          <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => fetchData()}>
            Try Again
          </Button>
        </Card>
      )
    }

    if (state.loading) {
      return (
        <Card>
          {renderTableHeader()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      )
    }

    const dataSource = state.data.map((f) => ({
      id: f?.id,
      dartaNo: f?.attributes?.dartaNo,
      invoiceNo: f?.attributes?.invoiceNo,
      invoiceDate: f?.attributes?.invoiceDate,
      chalaniNo: f?.attributes?.chalaniNo,
      isDocumentVerified: f?.attributes?.isDocumentVerified,
      status: f?.attributes?.status,
      usrEntries: f?.attributes?.usrEntries,
    }))

    const columns: ColumnsType<{}> = [
      {
        title: "दर्ता नं.",
        dataIndex: "dartaNo",
        key: "address",
        fixed: "left",
      },
      {
        title: "संख्या",
        dataIndex: "count",
        key: "count",
        render: (_: any, record: any) => {
          return record?.usrEntries?.length
        },
      },
      {
        title: "पत्र संख्या",
        dataIndex: "invoiceNo",
        key: "invoiceNo",
        render: (status: string) => {
          return 1
        },
      },
      {
        title: "चलानी नं.",
        dataIndex: "chalaniNo",
        key: "chalaniNo",
      },
      {
        title: "पत्र मिती",
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        render: (_: any, record: any) =>
          record?.invoiceDate ? format(new Date(record?.invoiceDate), "yyyy-MM-dd") : "--",
      },
      {
        title: "कागजात प्रमाणित",
        dataIndex: "isDocumentVerified",
        key: "isDocumentVerified",
        render: (isDocumentVerified: boolean) => {
          return isDocumentVerified ? <Tag color="success">प्रमाणित छ</Tag> : <Tag color="warning">प्रमाणित छैन</Tag>
        },
      },
      {
        title: "अबस्था",
        dataIndex: "status",
        key: "status",
        render: (status: string) => {
          if (status === RecordStatus.INPROGRESS) {
            return <Tag color="warning">{status}</Tag>
          } else if (status === RecordStatus.CREATED) {
            return <Tag color="blue">{status}</Tag>
          } else if (status === RecordStatus.COMPLETED) {
            return <Tag color="success">{status}</Tag>
          }
        },
      },
      {
        title: "कार्यहरु",
        fixed: "right",
        key: "action",
        render: (_: any, record: any) => (
          <Space size="middle">
            <Link to={`/dashboard/records/registration/view-freetrekkingpermit/${record?.id}`}>
              <EyeOutlined style={{ cursor: "pointer", color: token.colorSuccess }} />
            </Link>
            <Link to={`/dashboard/records/registration/update-freetrekkingpermit/${record?.id}`}>
              <EditOutlined style={{ cursor: "pointer", color: token.colorWarning }} />
            </Link>

            {/* <Popconfirm title="Really want to delete?">
              <DeleteOutlined
                style={{ cursor: "pointer", color: token.colorError }}
              />
            </Popconfirm> */}
          </Space>
        ),
      },
    ]

    return (
      <Card>
        {renderTableHeader()}
        <Table dataSource={dataSource} scroll={{ x: "auto" }} columns={columns} />
      </Card>
    )
  }

  return <div>{renderTable()}</div>
}
