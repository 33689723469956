import { createSlice } from "@reduxjs/toolkit";
import { fetchFlightCompanies } from "./flightCompaniesThunk";

// Define a type for the slice state
export interface EmployeeState {
  entities: any[];
  loading: boolean;
  error: any;
}

// Define the initial state using that type
const initialState: EmployeeState = {
  entities: [],
  loading: false,
  error: null,
};

export const flightCompaniesSlice = createSlice({
  name: "flightCompanies",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Fetch
    builder.addCase(fetchFlightCompanies.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchFlightCompanies.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = action.payload as [];
    });
    builder.addCase(fetchFlightCompanies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const flightCompaniesReducer = flightCompaniesSlice.reducer;
