import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Divider, Select, Typography } from "antd";
import React, { ReactElement } from "react";
import { toast } from "react-toastify";
import { createApplicantBody } from "../../../features/applicantBodies/applicantBodiesThunk";
import { fetchDepartments } from "../../../features/departments/departmentsThunk";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import applicantBodyHttpService from "../../../services/https/apis/applicantbody.http.service";
import { log } from "../../../utils/app.debug";

interface ApplicantBodySelectorProps
  extends React.ComponentProps<typeof Select> { }

export default function ApplicantBodySelector({
  disabled,
  onChange,
  ...props
}: ApplicantBodySelectorProps): ReactElement {
  const [state, setState] = React.useState<{
    searchLoading: boolean;
    addLoading: boolean;
    searchedData: any[] | null;
    searchText?: string;
  }>({
    searchLoading: false,
    addLoading: false,
    searchedData: null,
    searchText: "",
  });
  const { entities, loading, error } = useAppSelector(
    (state) => state.applicantBodies
  );
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();

  const fetchData = () => {
    dispatch(fetchDepartments());
  };

  const fetchFilterData = async (name: string) => {
    try {
      setState((prev) => ({ ...prev, searchLoading: true }));
      const res = await applicantBodyHttpService.fetchApplicantBodies({
        params: {
          filters: {
            name: {
              $containsi: name,
            },
          },
        }
      });
      setState((prev) => ({
        ...prev,
        searchLoading: false,
        searchedData: res.data,
      }));
      log({ res });
    } catch (error) {
      toast.error("Error searching data");
      setState((prev) => ({ ...prev, searchLoading: false }));
    }
  };

  const handleSearch = (newValue: string) => {
    setState({ ...state, searchText: newValue });
    if (newValue && newValue.length >= 3) {
      fetchFilterData(newValue);
    } else {
      setState({ ...state, searchedData: null, searchLoading: false });
    }
  };

  const onClickAddNew = async () => {
    if (!state.searchText) {
      return toast.info("Please add some text");
    }
    try {
      setState((prev) => ({ ...prev, addLoading: true }));
      const res = await dispatch(
        createApplicantBody(state.searchText)
      ).unwrap();
      setState((prev) => ({ ...prev, addLoading: false }));
      onChange?.(res?.data?.id || "", []);
      setOpen(false);
    } catch (error: any) {
      toast.error(error?.message || "Error creating");
      setState((prev) => ({ ...prev, addLoading: false }));
    }
  };

  return (
    <div>
      <Select
        {...props}
        open={open}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        onChange={onChange}
        loading={loading || state.searchLoading}
        disabled={disabled || loading || Boolean(error)}
        showSearch
        optionFilterProp="children"
        filterOption={false}
        options={(state.searchedData || entities)?.map?.((f) => ({
          value: f.id,
          label: `${f?.attributes?.name}`,
        }))}
        onSearch={handleSearch}
        dropdownRender={(menu) => (
          <>
            {menu}
            {(state.searchedData || entities || []).length <= 0 && (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <div style={{ textAlign: "center", marginBottom: 5 }}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={onClickAddNew}
                    style={{ flex: "auto" }}
                    loading={state.addLoading}
                  >
                    निवेदक निकाय थप्नुहोस्
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      />
      {error && (
        <Typography.Paragraph style={{ color: "red" }}>
          Error fetching,{" "}
          <span
            style={{ cursor: "pointer" }}
            title="Reload"
            onClick={fetchData}
          >
            Try again <ReloadOutlined style={{ fontSize: 13 }} />
          </span>
        </Typography.Paragraph>
      )}
    </div>
  );
}
