import React, { ReactElement } from "react";

interface LoadingWrapperProps {}

export default function LoadingWrapper(
  props: LoadingWrapperProps
): ReactElement {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
      }}
    ></div>
  );
}
