import { ReloadOutlined } from "@ant-design/icons"
import { Spin, Tag } from "antd"
import { useAsyncRetry } from "react-use"
import recordHttpService from "../../../../../../services/https/apis/records.http.service"
import { RecordTypesEnum } from "../../../../../../types/typings"

export type RecordStatisticsProps = {
  fromDate: string
  toDate: string
  recordType: RecordTypesEnum
}

export default function RecordStatistics({ recordType, fromDate, toDate }: RecordStatisticsProps) {
  const state = useAsyncRetry<number>(async () => {
    const response = await recordHttpService.getRecordStatistics({
      filters: {
        $and: [
          {
            recordType: recordType,
          },
          // {
          //   createdAt: {
          //     $gte: fromDate,
          //   },
          // },
          // {
          //   createdAt: {
          //     $lte: toDate,
          //   },
          // },
        ],
      },
    })
    return response
  }, [recordType])

  const renderStateData = () => {
    if (state.loading) {
      return <Spin size="small" />
    }
    if (state.error) {
      return <ReloadOutlined title="Click to retry" style={{ cursor: "pointer", color: "red" }} onClick={state.retry} />
    }
    return state.value
  }
  return <Tag color="blue">जम्मा विदेशी संख्या: {renderStateData()}</Tag>
}
