import { createSlice } from "@reduxjs/toolkit";
import {
  fetchRecordsStatisticsData,
  fetchUsersRolesStatisticsData,
  fetchFlighPermissionStatisticsData,
  fetchTopRecentAuditLogs,
} from "./statisticsThunk";

// Define a type for the slice state
export interface StatisticsState {
  recordTypeCountAndStatus: {
    entities: {
      recordsDataByStatus:any[],
      timeSeriesData:any[]
    };
    loading: boolean;
    error: any;
  };
  flightPermissionCountAndStatus: {
    entities: any[];
    loading: boolean;
    error: any;
  };
  usersRole: {
    entities: any[];
    loading: boolean;
    error: any;
  };

  topRecentAuditLogs: {
    entities: any[];
    loading: boolean;
    error: any;
  };
}

// Define the initial state using that type
const initialState: StatisticsState = {
  recordTypeCountAndStatus: {
    entities: {
      recordsDataByStatus:[],
      timeSeriesData:[]
    },
    loading: false,
    error: null,
  },
  flightPermissionCountAndStatus: {
    entities: [],
    loading: false,
    error: null,
  },
  usersRole: {
    entities: [],
    loading: false,
    error: null,
  },
  topRecentAuditLogs: {
    entities: [],
    loading: false,
    error: null,
  },
};

export const statisticsSlice = createSlice({
  name: "statistics",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Fetch FetchRecordsStatisticsData
    builder.addCase(fetchRecordsStatisticsData.pending, (state, action) => {
      state.recordTypeCountAndStatus.loading = true;
      state.recordTypeCountAndStatus.error = null;
    });
    builder.addCase(fetchRecordsStatisticsData.fulfilled, (state, action) => {
      state.recordTypeCountAndStatus.loading = false;
      state.recordTypeCountAndStatus.entities = action.payload;
    });
    builder.addCase(fetchRecordsStatisticsData.rejected, (state, action) => {
      state.recordTypeCountAndStatus.loading = false;
      state.recordTypeCountAndStatus.error = action.error;
    });

    //Fetch fetchUsersRolesStatisticsData
    builder.addCase(fetchUsersRolesStatisticsData.pending, (state, action) => {
      state.usersRole.loading = true;
      state.usersRole.error = null;
    });
    builder.addCase(
      fetchUsersRolesStatisticsData.fulfilled,
      (state, action) => {
        state.usersRole.loading = false;
        state.usersRole.entities = action.payload as [];
      }
    );
    builder.addCase(fetchUsersRolesStatisticsData.rejected, (state, action) => {
      state.usersRole.loading = false;
      state.usersRole.error = action.error;
    });

    //Fetch fetchFlighPermissionStatisticsData
    builder.addCase(
      fetchFlighPermissionStatisticsData.pending,
      (state, action) => {
        state.flightPermissionCountAndStatus.loading = true;
        state.flightPermissionCountAndStatus.error = null;
      }
    );
    builder.addCase(
      fetchFlighPermissionStatisticsData.fulfilled,
      (state, action) => {
        state.flightPermissionCountAndStatus.loading = false;
        state.flightPermissionCountAndStatus.entities = action.payload as [];
      }
    );
    builder.addCase(
      fetchFlighPermissionStatisticsData.rejected,
      (state, action) => {
        state.flightPermissionCountAndStatus.loading = false;
        state.flightPermissionCountAndStatus.error = action.error;
      }
    );

    //Fetch   fetchTopRecentAuditLogs

    builder.addCase(fetchTopRecentAuditLogs.pending, (state, action) => {
      state.topRecentAuditLogs.loading = true;
      state.topRecentAuditLogs.error = null;
    });
    builder.addCase(fetchTopRecentAuditLogs.fulfilled, (state, action) => {
      state.topRecentAuditLogs.loading = false;
      state.topRecentAuditLogs.entities = action.payload.data as [];
    });
    builder.addCase(fetchTopRecentAuditLogs.rejected, (state, action) => {
      state.topRecentAuditLogs.loading = false;
      state.topRecentAuditLogs.error = action.error;
    });
  },
});

export const statisticsReducer = statisticsSlice.reducer;
