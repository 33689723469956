import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../hooks";
import { Typography } from "antd";
import DataFetchStateComponent from "../../../../../../../components/common/datafetchstatecomponent";
import { fetchRecordsStatisticsData } from "../../../../../../../features/statistics/statisticsThunk";
import Chart from "react-apexcharts";

interface RecordsTimeSeriesProps {}
// https://apexcharts.com/javascript-chart-demos/line-charts/zoomable-timeseries/
export default function RecordsTimeSeries({}: RecordsTimeSeriesProps) {
  const { recordTypeCountAndStatus } = useAppSelector(
    (state) => state.statistics
  );
  const dispatch = useAppDispatch();

  return (
    <div>
      <DataFetchStateComponent
        loading={recordTypeCountAndStatus.loading}
        error={Boolean(recordTypeCountAndStatus.error)}
        onClickTryAgain={() => {
          dispatch(fetchRecordsStatisticsData());
        }}
      >
        <Chart
          series={[
            {
              name: "Record Created",
              data: recordTypeCountAndStatus.entities.timeSeriesData.map(f=>([new Date(f?.date).getTime(),Number(f?.count)])),
            },
          ]}
          options={{
            chart: {
              type: "area",
              stacked: false,
              height: 350,
              zoom: {
                type: 'x',
                enabled: false,
                autoScaleYaxis: false
              },
            },

            title: {
                text: 'Record Creation Movement',
                align: 'left'
              },
            yaxis: {
              title: {
                text: "Record",
              },
            },
            xaxis: {
              type: "datetime",
            },
          }}
          type="area"
          //   width="500"
        />
      </DataFetchStateComponent>
    </div>
  );
}
