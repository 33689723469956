import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import authHttpService from "../../services/https/apis/auth.http.service";
import { RootState } from "../../store";
import CurrentUserPolicy from "../uipolicies/currentuserpolicy";
import auditLogsHttpService from "../../services/https/apis/auditlog.http.service";

export const USERAUTHSESSIONKEY = "userAuth";
export const loginIntoSystem = createAsyncThunk(
  "auth/login",
  async (payload: { identifier: string; password: string }) => {
    const loginRes = await authHttpService.login(
      payload.identifier,
      payload.password
    );
    const profile = await authHttpService.fetchProfile(loginRes?.jwt);
    const userAuth = {
      jwt: loginRes?.jwt,
      user: profile,
    };
    sessionStorage.setItem(USERAUTHSESSIONKEY, JSON.stringify(userAuth));
    CurrentUserPolicy.getInstance().setUIPolicy(
      profile?.role,
      profile?.uiPolicy?.policySettings
    );
    return userAuth;
  }
);

export const logOutFromSystem = createAsyncThunk("auth/logout", async () => {
  await auditLogsHttpService.createAuditLog({
    action: "Logout",
    description: "Logout from the system",
    modalName: "User",
  });

  return true;
});

export const fetchProfile = createAsyncThunk(
  "auth/fetchProfile",
  async (token?: string) => {
    return authHttpService.fetchProfile(token);
  }
);

// Define a type for the slice state
export interface AuthState {
  token: string | null;
  profile: {
    data: any;
    loading: boolean;
    error: any;
  };
}

// Define the initial state using that type
const initialState: AuthState = {
  token: null,
  profile: {
    data: null,
    loading: false,
    error: null,
  },
};

const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserAuth: (state, action) => {
      state.profile.data = action?.payload?.user as any;
      state.token = action?.payload?.jwt;
      CurrentUserPolicy.getInstance().setUIPolicy(
        action?.payload?.user?.role,
        action?.payload?.user?.uiPolicy?.policySettings
      );
    },
  },
  extraReducers: (builder) => {
    //Login
    builder.addCase(loginIntoSystem.fulfilled, (state, action) => {
      state.profile.data = action?.payload?.user as any;
      state.token = action?.payload?.jwt;
      auditLogsHttpService.createAuditLog({
        action: "Login",
        description: "Login into the system",
        modalName: "User",
      });
    });

    //Fetch Profile
    builder.addCase(fetchProfile.pending, (state, action) => {
      state.profile.loading = true;
      state.profile.error = null;
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.profile.loading = false;
      state.profile.data = action?.payload as any;
    });
    builder.addCase(fetchProfile.rejected, (state, action) => {
      state.profile.loading = false;
      state.profile.error = action?.error;
    });

    builder.addCase(logOutFromSystem.fulfilled,(state)=>{
      state.profile.data = null;
      state.profile.loading = false;
      state.profile.error = null;
      state.token = null;
      sessionStorage.removeItem(USERAUTHSESSIONKEY);
    })
  },
});

// Action creators
export const {setUserAuth } = authSlice.actions;

// Reducers for this slice
export default authSlice.reducer;

//Selectors
export const selectProfile = (state: RootState) => state.auth?.profile;
export const selectUserId = (state: RootState) =>
  state.auth?.profile?.data?.id as string;
export const selectToken = (state: RootState) => state.auth?.token;
