import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';

export interface PageProps extends React.ComponentProps<'div'>{
  seoTitle?:string
}

const Page = forwardRef(({ children, seoTitle = '', ...props }:PageProps,ref:React.ForwardedRef<HTMLDivElement>) => (
  <div ref={ref} {...props}>
    <Helmet>
      <title>{seoTitle}</title>
    </Helmet>
    {children}
  </div>
));

export default Page;
