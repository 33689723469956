import { Button, Divider, Input, Select, Space, Typography } from "antd";
import React from "react";
import recommendingBodyList from "../../../constants/recommendingBodyList";
import { PlusOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../hooks";
import { selectProfile } from "../../../features/auth/authSlice";

interface RecommendingBodySelectorProps
  extends React.ComponentProps<typeof Select> {
  onClickAddOtherItem?: (text: string) => void;
}

export default function RecommendingBodySelector({
  onClickAddOtherItem,
  ...props
}: RecommendingBodySelectorProps) {
  const inputRef = React.useRef<InputRef>(null);
  const [open, setOpen] = React.useState(false);
  const profile = useAppSelector(selectProfile);
  const onClickAddOtherText = () => {
    if (!inputRef.current?.input?.value) {
      return toast.info("Please add some text");
    }
    onClickAddOtherItem?.(inputRef.current?.input?.value || "");
    setOpen(false);
  };
  return (
    <Select
      {...props}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={recommendingBodyList.map((f) => ({
        value: f,
        label: f,
      }))}
      dropdownRender={(menu) => (
        <>
          {menu}
          {(profile?.data?.role?.type === "super_admin" ||
            profile?.data?.role?.type === "admin") && (
            <>
              <Divider style={{ margin: "8px 0" }} />
              <Typography.Text style={{ padding: "0 8px 4px" }}>
                अन्य
              </Typography.Text>
              <div
                style={{
                  display: "flex",
                  padding: "0 8px 4px",
                  width: "100%",
                  gap: "5px",
                }}
              >
                <Input
                  style={{ flexGrow: 1 }}
                  placeholder="अन्य सिफारिस गर्ने निकाय"
                  ref={inputRef}
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={onClickAddOtherText}
                  style={{ flex: "auto" }}
                ></Button>
              </div>
            </>
          )}
        </>
      )}
    />
  );
}
