import { CKEditor } from "@ckeditor/ckeditor5-react"
import React from "react"
//@ts-ignore
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor"

interface CkHtmlEditorProps extends Omit<React.ComponentProps<typeof CKEditor>, "data" | "onChange" | "editor"> {
  value?: string | null
  onChange?: (value: string) => void
}

export default function CkHtmlEditor({ value, onChange, ...props }: CkHtmlEditorProps) {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      // onReady={(editor) => {
      //   // You can store the "editor" and use when it is needed.
      //   console.log("Editor is ready to use!", editor);
      // }}
      onChange={(event, editor) => {
        // @ts-ignore
        const data: string = editor.getData()
        onChange?.(data)
      }}
      // onBlur={(event, editor) => {
      //   console.log("Blur.", editor);
      // }}
      // onFocus={(event, editor) => {
      //   console.log("Focus.", editor);
      // }}
      {...props}
    />
  )
}
