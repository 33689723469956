import { Button, Card, Col, Divider, Row, Switch, Table, Tag, Typography } from "antd"
import qs from "qs"
import React, { ReactElement, useState } from "react"
import { Bars } from "react-loader-spinner"
import { useParams } from "react-router-dom"
import LoadingWrapper from "../../../../../../components/common/loadingwrapper"
import PdfViewer from "../../../../../../components/common/pdfviewer"
import flightPermissionHttpService from "../../../../../../services/https/apis/flightpermission.http.service"
import { RecordStatus } from "../../../../../../types/typings.d"
import { getFilePath } from "../../../../../../utils/general.utils"

interface ViewFlightPermissionProps {
  entryByFlightCompany?: boolean
}

export default function ViewFlightPermission({ entryByFlightCompany }: ViewFlightPermissionProps): ReactElement {
  const [state, setState] = useState<{
    loading: boolean
    fetchLoading: boolean
    fetchError: any
    fetchedData: any
    sendLinkToEntryForm: boolean
  }>({
    loading: false,
    fetchLoading: true,
    fetchError: null,
    fetchedData: null,
    sendLinkToEntryForm: true,
  })
  const [passengers, setPassengers] = useState([])
  let { id } = useParams()

  const fetchByIdAndSetState = () => {
    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          "document",
          "flightCompany",
          "passengers",
          "finalLetters",
          "finalLetters.pdfLetter",
          "pharchyotPerson",
        ],
        filters: {},
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    setState({ ...state, fetchLoading: true, fetchError: null })
    flightPermissionHttpService
      .fetchFlightPermissionById(id || "", query)
      .then((res) => {
        setPassengers(res?.data?.attributes?.passengers || [])
        setState({ ...state, fetchLoading: false, fetchedData: res.data })
      })
      .catch((err) => {
        setState({ ...state, fetchLoading: false, fetchError: err })
      })
  }

  React.useEffect(() => {
    fetchByIdAndSetState()
  }, [])

  if (state.fetchLoading) {
    return (
      <Card>
        <div
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </Card>
    )
  }

  if (state.fetchError) {
    return (
      <Card
        style={{
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography>{state.fetchError?.message || "Error occured"}</Typography>
        <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => fetchByIdAndSetState()}>
          Try Again
        </Button>
      </Card>
    )
  }
  return (
    <div>
      <div className="site-card-border-less-wrapper" style={{ position: "relative" }}>
        {state.loading && <LoadingWrapper />}
        <Card
          title={
            <div>
              <Typography.Title level={3} className="he-title">उडान दर्ता / Flight Permission</Typography.Title>
              <Typography.Title style={{ marginTop: "-1rem" }}>
                {state?.fetchedData?.attributes?.status === RecordStatus.INPROGRESS ? (
                  <Tag color="warning">{state?.fetchedData?.attributes?.status}</Tag>
                ) : state?.fetchedData?.attributes?.status === RecordStatus.CREATED ? (
                  <Tag color="blue">{state?.fetchedData?.attributes?.status}</Tag>
                ) : (
                  <Tag color="success">{state?.fetchedData?.attributes?.status}</Tag>
                )}
              </Typography.Title>
            </div>
          }
          bordered={false}
        >
          <Row gutter={[32, 32]}>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>दर्ता नं.:</Col>
                <Col span={12}>{state.fetchedData?.id}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>उडान कम्पनी:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.flightCompany?.attributes?.data?.username || "--"}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>उडान मिति:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.flightDate}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>उडान प्रकार:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.flightType}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>चार्टड उडान:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.chartedFlightType}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>जम्मा यात्रु संख्या:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.totalPassengerCount}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>प्रदेश:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.province}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={4}>जिल्ला:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.district}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>स्थानिय तह:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.localLevel}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>स्थान:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.location}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>उडानको प्रयोजन:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.purposeOfFlight}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>सेक्टर:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.sector}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>जम्मा शटल:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.totalShuttle}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>सामाग्री विवरण:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.itemDetails}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>फर्छ्योट गर्ने व्यक्ति:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.pharchyotPerson?.data?.attributes?.username}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>मन्त्रालयको निर्णय मिती:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.ministryDecisionDate}</Col>
              </Row>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Row>
                <Col span={6}>पत्राचार हुने मिती:</Col>
                <Col span={12}>{state.fetchedData?.attributes?.dateOfCorrespondence}</Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 20 }}>
                <Typography.Title level={4} className="he-subtitle">यात्रु</Typography.Title>
              </div>
            </div>
            <Table
              columns={[
                {
                  title: "नाम/थर",
                  dataIndex: "fullName",
                  key: "fullName",
                },
                {
                  title: "देश",
                  dataIndex: "country",
                  key: "country",
                },
                {
                  title: "राहदानी नं",
                  dataIndex: "passportNo",
                  key: "passportNo",
                },
                {
                  title: "अबस्था",
                  key: "status",
                  render: (status: string) => {
                    return status && status === "Rejected" ? (
                      <Tag color="error">{status}</Tag>
                    ) : status === "Pending" ? (
                      <Tag color="info">{status}</Tag>
                    ) : (
                      <Tag color="success">Approved</Tag>
                    )
                  },
                },
              ]}
              dataSource={passengers}
              pagination={false}
            />
          </div>
          <Divider />
          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 20 }}>
              {state.fetchedData?.attributes?.document?.data && (
                <>
                  <Typography.Title level={5}>कागजात प्रमाणित छ</Typography.Title>
                  <Switch checked={state.fetchedData?.attributes?.isDocumentVerified} disabled={true} />
                </>
              )}
              <Divider />
              <Typography.Title level={4} className="he-subtitle" style={{ marginTop: 5 }}>
                कागजात
              </Typography.Title>
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {state.fetchedData?.attributes?.document?.data && (
                <PdfViewer fileUrl={getFilePath(state.fetchedData?.attributes?.document.data?.attributes?.url)} />
              )}
            </div>
          </div>
          <Divider />
          {!entryByFlightCompany && (
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 20 }}>
                <Typography.Title level={4} className="he-subtitle">अन्तिम पत्र</Typography.Title>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {state.fetchedData?.attributes?.finalLetters?.data?.map?.((t: any, i: number) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      key={i}
                    >
                      <PdfViewer fileUrl={getFilePath(t?.attributes?.pdfLetter?.data?.attributes?.url)} />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ marginRight: 5 }}>चलानी नं.</span>
                        <span>{t?.attributes?.chalaniNo || "--"}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}
