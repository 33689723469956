import { AppWebRequest } from "../NetworkManager";
import { AxiosRequestConfig } from 'axios';

class StatisticsHttpService {
  fetchRecordsStatisticsData(config?: AxiosRequestConfig<any>) {
    return AppWebRequest({
      method: "get",
      url:"/records/graph/get-analyticsdata",
      ...config
    });
  }

  fetchFlighPermissionStatisticsData(config?: AxiosRequestConfig<any>) {
    return AppWebRequest({
      method: "get",
      url:"/flight-permissions/graph/get-analyticsdata",
      ...config
    });
  }

  fetchUsersRolesStatisticsData(config?: AxiosRequestConfig<any>) {
    return AppWebRequest({
      method: "get",
      url:"/users-permissions/users/graph/get-analyticsdata",
      ...config
    });
  }

}

const statisticsHttpService = new StatisticsHttpService();
export default statisticsHttpService;
