import type { MenuProps } from "antd"
import { Button, Dropdown } from "antd"
import React from "react"
import { downloadExcel } from "react-export-table-to-excel"
import { useReactToPrint } from "react-to-print"

export interface ExportTableMenuProps extends React.ComponentProps<typeof Dropdown> {
  exportTablePayload: {
    fileName: string
    sheet: string
    tablePayload: {
      header: string[]
      body: any[]
    }
  }
  exportLabel?: string
  pdfComponentRef?: React.MutableRefObject<any>
  buttonProps?: React.ComponentProps<typeof Button>
}

const ExportTableMenu = ({
  exportTablePayload,
  exportLabel,
  pdfComponentRef,
  buttonProps,
  ...props
}: ExportTableMenuProps) => {
  const handleExportPdf = useReactToPrint({
    content: () => pdfComponentRef?.current,
  })

  const handleExportExcel = async () => {
    downloadExcel(exportTablePayload)
  }
  const items: MenuProps["items"] = [
    {
      label: "Excel",
      key: "1",
      onClick: handleExportExcel,
    },
    ...(pdfComponentRef
      ? [
          {
            label: "Pdf",
            key: "2",
            onClick: handleExportPdf,
          },
        ]
      : []),
  ]
  return (
    <Dropdown menu={{ items }} trigger={["click"]} {...props}>
      <Button type="dashed" size="small" {...buttonProps}>
        {exportLabel || "Export"}
      </Button>
    </Dropdown>
  )
}

export default ExportTableMenu
