import {
  Card,
  Checkbox,
  Col,
  Form,
  Row,
  Space,
  Typography,
} from "antd";
import lodash from 'lodash';
import React from "react";
import { Records } from "../../../../../constants/recordList";
import { RecordTypesEnum, UIPolicyI } from "../../../../../types/typings.d";

type PolicyEditorProps = {
  onChange?: (data: UIPolicyI) => void
  value?: UIPolicyI
};

export default function PolicyEditor({ onChange, value }: PolicyEditorProps) {
  const [uiPolicySettings, setUiPolicySettings] = React.useState<UIPolicyI>({});

  React.useEffect(() => {
    setUiPolicySettings(value || {})
  }, [value])

  const isAllowedToAllRecordView = (values: any) => {
    const incommingValues = lodash.cloneDeep(values)
    const recordsHome = incommingValues?.records?.recordsHome || {}
    delete recordsHome["International Border"]
    delete recordsHome["Flight Permission"]
    const allTrue = Object.values(recordsHome).every(
      (value: any) => value?.view === true
    );
    return allTrue
  }

  const onValuesChange = (_: any, values: any) => {
    if (isAllowedToAllRecordView(values)) {
      if (values.records.recordsHome) {
        values.records.recordsHome[RecordTypesEnum.All].view = true
      }
    } else {
      if (values.records.recordsHome) {
        values.records.recordsHome[RecordTypesEnum.All].view = false
      }
    }
    onChange?.(values)
  }

  return (
    <Card bodyStyle={{ backgroundColor: 'whitesmoke' }}>
      <Form
        initialValues={value || uiPolicySettings}
        onValuesChange={onValuesChange}
        layout="vertical"
      >
        <Typography.Title level={4} className="he-subtitle">गृहपृष्ठ</Typography.Title>
        <Form.Item name="dashboard" valuePropName="checked" label="">
          <Checkbox>गृहपृष्ठ</Checkbox>
        </Form.Item>

        <Typography.Title level={4} className="he-subtitle">रेकर्डहरु</Typography.Title>
        <Row gutter={60} wrap>
          <Col xs={24} sm={12}>
            <Typography.Title level={5}>
              {
                Records.find(
                  (f) => f.type === RecordTypesEnum.LabourConcurrence
                )?.title
              }
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.LabourConcurrence,
                  "view",
                ]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.LabourConcurrence,
                  "edit",
                ]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.LabourConcurrence,
                  "add",
                ]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.LabourConcurrence,
                  "delete",
                ]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={5}>
              {
                Records.find((f) => f.type === RecordTypesEnum.FreeArrivalVisa)
                  ?.title
              }
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeArrivalVisa,
                  "view",
                ]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeArrivalVisa,
                  "edit",
                ]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeArrivalVisa,
                  "add",
                ]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeArrivalVisa,
                  "delete",
                ]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={5}>
              {
                Records.find((f) => f.type === RecordTypesEnum.FlightPermission)
                  ?.title
              }
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FlightPermission,
                  "view",
                ]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FlightPermission,
                  "edit",
                ]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FlightPermission,
                  "add",
                ]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FlightPermission,
                  "delete",
                ]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={5}>
              {
                Records.find(
                  (f) => f.type === RecordTypesEnum.FreeVisaExtension
                )?.title
              }
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeVisaExtension,
                  "view",
                ]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeVisaExtension,
                  "edit",
                ]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeVisaExtension,
                  "add",
                ]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeVisaExtension,
                  "delete",
                ]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={5}>
              {
                Records.find((f) => f.type === RecordTypesEnum.Deportation)
                  ?.title
              }
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.Deportation,
                  "view",
                ]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.Deportation,
                  "edit",
                ]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.Deportation,
                  "add",
                ]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.Deportation,
                  "delete",
                ]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={5}>
              {
                Records.find(
                  (f) => f.type === RecordTypesEnum.MultipleEntryVisa
                )?.title
              }
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.MultipleEntryVisa,
                  "view",
                ]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.MultipleEntryVisa,
                  "edit",
                ]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.MultipleEntryVisa,
                  "add",
                ]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.MultipleEntryVisa,
                  "delete",
                ]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={5}>
              {
                Records.find((f) => f.type === RecordTypesEnum.ArrivalVisa)
                  ?.title
              }
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.ArrivalVisa,
                  "view",
                ]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.ArrivalVisa,
                  "edit",
                ]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.ArrivalVisa,
                  "add",
                ]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.ArrivalVisa,
                  "delete",
                ]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={5}>
              {
                Records.find((f) => f.type === RecordTypesEnum.NrnStudyVisa)
                  ?.title
              }
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.NrnStudyVisa,
                  "view",
                ]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.NrnStudyVisa,
                  "edit",
                ]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.NrnStudyVisa,
                  "add",
                ]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.NrnStudyVisa,
                  "delete",
                ]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={5}>
              {
                Records.find(
                  (f) => f.type === RecordTypesEnum.FreeTrekkingPermit
                )?.title
              }
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeTrekkingPermit,
                  "view",
                ]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeTrekkingPermit,
                  "edit",
                ]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeTrekkingPermit,
                  "add",
                ]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.FreeTrekkingPermit,
                  "delete",
                ]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>
          {/* <Col xs={24} sm={12}>
            <Typography.Title level={5}>
              {
                Records.find(
                  (f) => f.type === RecordTypesEnum.InternationalBorder
                )?.title
              }
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.InternationalBorder,
                  "view",
                ]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.InternationalBorder,
                  "edit",
                ]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.InternationalBorder,
                  "add",
                ]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.InternationalBorder,
                  "delete",
                ]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col> */}
          <Col xs={24} sm={12}>
            <Typography.Title level={5}>
              {
                Records.find((f) => f.type === RecordTypesEnum.TravelDocument)
                  ?.title
              }
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.TravelDocument,
                  "view",
                ]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.TravelDocument,
                  "edit",
                ]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.TravelDocument,
                  "add",
                ]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={[
                  "records",
                  "recordsHome",
                  RecordTypesEnum.TravelDocument,
                  "delete",
                ]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>
        </Row>

        <Row gutter={60} wrap>
          <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">दर्ता</Typography.Title>
            <Form.Item
              name={["records", "registrations"]}
              valuePropName="checked"
              label=""
            >
              <Checkbox>दर्ता</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">चलानी</Typography.Title>
            <Form.Item
              name={["records", "chalani"]}
              valuePropName="checked"
              label=""
            >
              <Checkbox>चलानी</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">प्रयोगकर्ताहरु</Typography.Title>
            <Space>
              <Form.Item valuePropName="checked" name={["users", "view"]}>
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item valuePropName="checked" name={["users", "edit"]}>
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item valuePropName="checked" name={["users", "add"]}>
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item valuePropName="checked" name={["users", "delete"]}>
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>

          <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">
              तेस्रो पक्ष प्रयोगकर्ताहरु
            </Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={["thirdparties", "view"]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["thirdparties", "edit"]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item valuePropName="checked" name={["thirdparties", "add"]}>
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["thirdparties", "delete"]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>

          <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">कर्मचारीहरु</Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={["employees", "employeesHome", "view"]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["employees", "employeesHome", "edit"]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["employees", "employeesHome", "add"]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["employees", "employeesHome", "delete"]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>

          <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">विभाग</Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={["employees", "departments", "view"]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["employees", "departments", "edit"]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["employees", "departments", "add"]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["employees", "departments", "delete"]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>

          <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">पदनाम</Typography.Title>
            <Space>
              <Form.Item
                valuePropName="checked"
                name={["employees", "designations", "view"]}
              >
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["employees", "designations", "edit"]}
              >
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["employees", "designations", "add"]}
              >
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["employees", "designations", "delete"]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>

          {/* <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">भूमिका</Typography.Title>
            <Space>
              <Form.Item valuePropName="checked" name={["roles", "view"]}>
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item valuePropName="checked" name={["roles", "edit"]}>
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item valuePropName="checked" name={["roles", "add"]}>
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item valuePropName="checked" name={["roles", "delete"]}>
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col> */}

          <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">
              प्रयोगकर्ता इन्टरफेस नीति
            </Typography.Title>
            <Space>
              <Form.Item valuePropName="checked" name={["uipolicies", "view"]}>
                <Checkbox>View</Checkbox>
              </Form.Item>
              <Form.Item valuePropName="checked" name={["uipolicies", "edit"]}>
                <Checkbox>Edit</Checkbox>
              </Form.Item>
              <Form.Item valuePropName="checked" name={["uipolicies", "add"]}>
                <Checkbox>Add</Checkbox>
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name={["uipolicies", "delete"]}
              >
                <Checkbox disabled>Delete</Checkbox>
              </Form.Item>
            </Space>
          </Col>

          <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">Logs</Typography.Title>
            <Form.Item valuePropName="checked" name={["summarylogs"]}>
              <Checkbox>Logs</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">Reports</Typography.Title>
            <Form.Item valuePropName="checked" name={["reports"]}>
              <Checkbox>Reports</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Title level={4} className="he-subtitle">Bibidhs</Typography.Title>
            <Form.Item valuePropName="checked" name={["bibidhs"]}>
              <Checkbox>Bibidhs</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
