import { Records } from "../../constants/recordList"
import { uiPolicyFullAccess } from "../../constants/uiPolicy"
import { RecordTypesEnum, UIPolicyI } from "../../types/typings.d"
/**
 * The CurrentUserPolicy class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */
export default class CurrentUserPolicy {
  uiPolicy?: UIPolicyI
  private static instance: CurrentUserPolicy

  /**
   * The CurrentUserPolicy's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {}

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the CurrentUserPolicy class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): CurrentUserPolicy {
    if (!CurrentUserPolicy.instance) {
      CurrentUserPolicy.instance = new CurrentUserPolicy()
    }

    return CurrentUserPolicy.instance
  }

  /**
   * Finally, any singleton should define some business logic, which can be
   * executed on its instance.
   */
  public setUIPolicy(role: any, policy: UIPolicyI) {
    switch (role?.type) {
      case "super_admin":
      case "admin":
        this.uiPolicy = uiPolicyFullAccess
        break

      default:
        this.uiPolicy = policy
        break
    }
  }

  getRecords() {
    return Records.filter((f) => this.uiPolicy?.records?.recordsHome?.[f.type]?.view === true)
  }

  isAllowedRecordTableForAction(recordType: RecordTypesEnum, actionType: "edit" | "delete" | "view" | "add") {
    return this.uiPolicy?.records?.recordsHome?.[recordType]?.[actionType]
  }

  isAllowedUsersTableForAction = (actionType: "edit" | "delete" | "view" | "add") => {
    return this.uiPolicy?.users?.[actionType]
  }

  isAllowedThirdPartiesTableForAction = (actionType: "edit" | "delete" | "view" | "add") => {
    return this.uiPolicy?.thirdparties?.[actionType]
  }

  isAllowedEmployeesTableForAction = (actionType: "edit" | "delete" | "view" | "add") => {
    return this.uiPolicy?.employees?.employeesHome?.[actionType]
  }

  isAllowedDepartmentsTableForAction = (actionType: "edit" | "delete" | "view" | "add") => {
    return this.uiPolicy?.employees?.departments?.[actionType]
  }

  isAllowedDesignationsTableForAction = (actionType: "edit" | "delete" | "view" | "add") => {
    return this.uiPolicy?.employees?.designations?.[actionType]
  }

  isAllowedUIPoliciesTableForAction = (actionType: "edit" | "delete" | "view" | "add") => {
    return this.uiPolicy?.uipolicies?.[actionType]
  }

  isAllowedRolesTableForAction = (actionType: "edit" | "delete" | "view" | "add") => {
    return this.uiPolicy?.roles?.[actionType]
  }
}
