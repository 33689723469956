import { RecordStatus } from "../types/typings.d";

export const recordStatus = [
  {
    name: "All",
    nepName: "सबै",
    slug: "All",
  },
  {
    name: "Created",
    nepName: "सिर्जना गरियो",
    slug: RecordStatus.CREATED,
  },
  {
    name: "In Progress",
    nepName: "प्रगति हुदैछ",
    slug: RecordStatus.INPROGRESS,
  },
  {
    name: "Completed",
    nepName: "पूरा भयो",
    slug: RecordStatus.COMPLETED,
  },
];
