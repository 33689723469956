import { Select } from "antd";
import React from "react";
import municipalitiesList from '../../../constants/municipalitiesList.json';
import districtList from '../../../constants/districtList.json';

interface MunicipalitySelectorProps extends React.ComponentProps<typeof Select> {
  selectedDistrict?:string
}

export default function MunicipalitySelector({selectedDistrict, ...props }: MunicipalitySelectorProps) {
  const district = districtList.find(f=>f.name===selectedDistrict)
  const options = (district?municipalitiesList.filter(f=>f.district_id===district?.id):municipalitiesList).map(f=>({label:f.name,value:f.name}))
  return (
    <Select
      {...props}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={options}
    />
  );
}
