import React, { ReactElement, useState, useEffect } from "react";
import CountStats from "../../../../components/stats/count";
import usersHttpService from "../../../../services/https/apis/users.http.service";
import qs from "qs";
interface ThirdPartiesCountStatsProps {}

export default function ThirdPartiesCountStats({}: ThirdPartiesCountStatsProps): ReactElement {
  const [state, setState] = useState({
    loading: true,
    error: null,
    count: 0,
  });

  const fetchCount = () => {
    const qsString = qs.stringify({
      filters: {
        role: {
          type: {
            $eq: "thirdparty",
          },
        },
      },
      populate:"*"
    });
    setState({ ...state, loading: true, error: null });
    usersHttpService
      .countUsers(qsString)
      .then((res) => {
        setState({ ...state, loading: false, count: res });
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err });
      });
  };

  useEffect(() => {
    fetchCount();
  }, []);

  return (
    <CountStats
      title="तेस्रो पक्ष प्रयोगकर्ताहरु"
      count={state.count}
      error={state.error}
      loading={state.loading}
      hideNavigate
      onClickTryAgain={fetchCount}
    />
  );
}
