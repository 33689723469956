import { createAsyncThunk } from "@reduxjs/toolkit";
import internationalBorderHttpService from "../../services/https/apis/internationalborder.http.service";
import qs from "qs";


export const fetchBorders = createAsyncThunk(
  "departments/fetchBorders",
  async () => {
    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          "images",
        ],
        filters: {},
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    return internationalBorderHttpService.fetchBorders(query);
  }
);

export const createBorder = createAsyncThunk(
  "departments/createBorder",
  async (payload: Record<string, any>,{dispatch,rejectWithValue}) => {
    return internationalBorderHttpService.createBorder(payload)
    .then(res=>{
      dispatch(fetchBorders())
      return res
    })
    .catch(err=>{
      return rejectWithValue(err)
    })
  }
);

export const updateBorder = createAsyncThunk(
  "departments/updateBorder",
  async (updatePayload:{id: string, payload: Record<string, any>},{dispatch,rejectWithValue}) => {
    return internationalBorderHttpService.updateBorder(updatePayload.id, updatePayload.payload)
    .then(res=>{
      dispatch(fetchBorders())
      return res
    })
    .catch(err=>{
      return rejectWithValue(err)
    })
  }
);

export const deleteBorder = createAsyncThunk(
  "departments/deleteBorder",
  async (id: string,{dispatch,rejectWithValue}) => {
    return internationalBorderHttpService.deleteBorder(id)
    .then(res=>{
      dispatch(fetchBorders())
      return res
    })
    .catch(err=>{
      return rejectWithValue(err)
    })
  }
);
