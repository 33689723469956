import { Button, Card, Col, theme, Form, Input, Row, Space } from "antd";
import { ReactElement } from "react";
import NPDatePicker from "../common/npdatepicker";
import CountrySelector from "../selectors/countryselector/index";
import GenderSelector from "../selectors/genderselector";

interface PassengerEntryFormRowProps {
  onValuesChange?: (values: any, index: number) => void;
  onRemove?: (index: number) => void;
  index: number;
  onReset?: (index: number) => void;
  values?: Record<string, any>;
  title?: string;
  disabled?: boolean;
  loading?: boolean;
}

export default function PassengerEntryFormRow({
  onValuesChange,
  index,
  onRemove,
  onReset,
  values,
  title,
  disabled,
  loading,
}: PassengerEntryFormRowProps): ReactElement {
  const [form] = Form.useForm();
  const { token } = theme.useToken();

  const onChangeValues = (changedValues: any, valuesData: any) => {
    if (valuesData.passportImage) {
      valuesData.passportImage = valuesData.passportImage.file;
    }
    onValuesChange?.(valuesData, index);
  };
  return (
    <Card title={title} className="shadow-sm">
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          fullName: "",
          country: "",
          gender: "",
          passportNo: "",
          dob: "",
          passportImage: null,
          ...values,
        }}
        onValuesChange={onChangeValues}
      >
        <Row gutter={10}>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="नाम/थर" name="fullName">
              <Input disabled={disabled || loading} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="देश" name="country">
              <CountrySelector disabled={disabled || loading} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="लिङ्ग" name="gender">
              <GenderSelector disabled={disabled || loading} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="राहदानी नं" name="passportNo">
              <Input disabled={disabled || loading} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="अनुमति नं" name="permitNo">
              <Input disabled={disabled || loading} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="अनुमति समाप्ति मिति" name="permitExpiryDate">
              <NPDatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              style={{ backgroundColor: token.colorError }}
              onClick={() => onRemove?.(index)}
              disabled={disabled || loading}
            >
              Remove
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
}
