import { createSlice } from "@reduxjs/toolkit"
import { getCurrentFiscalYear } from "../../utils/general.utils"
import { fetchLastUsedChalani } from "./globalThunk"

// Define a type for the slice state
export interface GlobalState {
  isPasswordProtectedVerified: boolean
  appSettings: {
    selectedFiscallYear: {
      fromDate: Date
      toDate: Date
      fiscalYear: string
    }
  }
  lastUsedChalani: {
    data: any
    error: any
    loading: boolean
  }
}

export const FISCAL_YEAR_LOCALSTORAGE_KEY = "selectedFiscallYear"
// Define the initial state using that type
var currentFiscalYear = getCurrentFiscalYear()
try {
  const currentFiscalYearFromLocalStorage: GlobalState["appSettings"]["selectedFiscallYear"] =
    localStorage.getItem(FISCAL_YEAR_LOCALSTORAGE_KEY) &&
    JSON.parse(localStorage.getItem(FISCAL_YEAR_LOCALSTORAGE_KEY) as string)
  if (currentFiscalYearFromLocalStorage) {
    currentFiscalYear = currentFiscalYearFromLocalStorage
  }
} catch (error) {
  console.warn(error)
}

const initialState: GlobalState = {
  isPasswordProtectedVerified: false,
  appSettings: {
    selectedFiscallYear: {
      fromDate: currentFiscalYear.fromDate,
      toDate: currentFiscalYear.toDate,
      fiscalYear: currentFiscalYear.fiscalYear,
    },
  },
  lastUsedChalani: {
    data: null,
    error: null,
    loading: false,
  },
}

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setPasswordVerifiedStatus: (state, action: { payload: boolean }) => {
      state.isPasswordProtectedVerified = action.payload
    },

    setFiscalYear: (state, action: { payload: { fromDate: Date; toDate: Date; fiscalYear: string } }) => {
      const updatedData = { ...state.appSettings.selectedFiscallYear, ...action.payload }
      state.appSettings.selectedFiscallYear = updatedData
      localStorage.setItem(FISCAL_YEAR_LOCALSTORAGE_KEY, JSON.stringify(updatedData))
      window.location.reload()
    },
  },
  extraReducers: (builder) => {
    //Fetch Last Used Chalani
    builder.addCase(fetchLastUsedChalani.pending, (state, action) => {
      state.lastUsedChalani.loading = true
      state.lastUsedChalani.error = null
    })
    builder.addCase(fetchLastUsedChalani.fulfilled, (state, action) => {
      state.lastUsedChalani.loading = false
      state.lastUsedChalani.data = action.payload?.lastUsedChalaniNo
    })
    builder.addCase(fetchLastUsedChalani.rejected, (state, action) => {
      state.lastUsedChalani.loading = false
      state.lastUsedChalani.error = action.error
    })
  },
})

// Action creators
export const { setPasswordVerifiedStatus, setFiscalYear } = globalSlice.actions

export const globalReducer = globalSlice.reducer
