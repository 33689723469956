import React, { ReactElement, useState, useEffect } from "react";
import CountStats from "../../../../components/stats/count";
import internationalBorderHttpService from '../../../../services/https/apis/internationalborder.http.service';
interface BordersCountStatsProps {}

export default function BordersCountStats({}: BordersCountStatsProps): ReactElement {
  const [state, setState] = useState({
    loading: true,
    error: null,
    count: 0,
  });

  const fetchCount = () => {
    setState({ ...state, loading: true, error: null });
    internationalBorderHttpService
      .countBorders()
      .then((res) => {
        setState({ ...state, loading: false, count: res });
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err });
      });
  };

  useEffect(() => {
    fetchCount();
  }, []);

  return (
    <CountStats
      title="अन्तर्राष्ट्रिय सिमाना"
      count={state.count}
      error={state.error}
      loading={state.loading}
      onClickTryAgain={fetchCount}
      hideNavigate
    />
  );
}
