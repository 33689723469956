const apiUrls = {
  production: {
    BASE_URL: "https://seema.moha.gov.np",
  },
  staging: {
    BASE_URL: "http://localhost:1337",
  },
  local: {
    BASE_URL: "http://localhost:1337",
  },
}

type REACTENV = "production" | "staging" | "local"

const reactEnv = process.env.REACT_APP_ENV as REACTENV
export default apiUrls[reactEnv || "local"]
