import React from "react";

interface LogoProps extends React.ComponentProps<"img"> {
  withName?: boolean;
}

export default function Logo({
  withName = false,
  style,
  className,
  ...props
}: LogoProps) {
  var imgSrc = withName ? "/images/logo_with_name.png" : "/images/logo.png";
  return (
    <img
      src={imgSrc}
      alt="logo"
      {...props}
      style={Object.assign({ width: "300px" }, style)}
      className={[className].join(" ")}
    />
  );
}
