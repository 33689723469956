import * as xlsx from "xlsx"
import { getFilename } from "../../../../../../../utils/general.utils"
var FileSaver = require("file-saver")

export enum ExcelTemplateColumnMap {
  "नाम/थर" = "fullName",
  "देश" = "country",
  "लिङ्ग" = "gender",
  "राहदानी नं" = "passportNo",
  "जन्म मिती" = "dob",
  "कार्य सहमति मिती" = "labourConcurranceDate",
}

export function convertExcelFileIntoJSON(file: File): Promise<Array<Record<string, any>>> {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()

      reader.onload = (e: any) => {
        const data = e.target.result
        const workbook = xlsx.read(data, { type: "array" })
        // organize xlsx data into desired format
        // https://dev.to/bperez3237/import-excel-into-react-12h6
        const worksheet = workbook.Sheets["UserEntries"]
        const jsonData = xlsx.utils.sheet_to_json(worksheet, { raw: false })
        const finalJson = jsonData.map((f) => {
          const objArr = Object.entries(f || {})
          const updatedEntries: Record<string, any> = {}
          for (let index = 0; index < objArr.length; index++) {
            const [key, value] = objArr[index]
            //@ts-ignore
            const mappedColumn = ExcelTemplateColumnMap[key]
            if (!mappedColumn) {
              reject(new Error("Invalid excel template"))
            }
            updatedEntries[mappedColumn] = value
          }

          return updatedEntries
        })
        resolve(finalJson)
      }
      reader.readAsArrayBuffer(new Blob([file!], { type: file!.type }))
    } catch (error) {
      reject(error)
    }
  })
}

export function downloadExcelTemplate() {
  const filePath = "/uploads/LabourConcurranceEntryTemplate.xlsx"
  const fileName = getFilename(filePath)
  FileSaver.saveAs("/uploads/LabourConcurranceEntryTemplate.xlsx", fileName)
}
