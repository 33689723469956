//@ts-ignore
import Calendar from "@sbmdkl/nepali-datepicker-reactjs"
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css"
import React, { ReactElement } from "react"
import { convertNeplaiDateStrIntoEnglishDateStr } from "../../../utils/general.utils"
import styles from "./styles.module.css"

interface NPDatePickerProps extends React.ComponentProps<typeof Calendar> {
  onChange?: (value: string | null) => void
}

export default function NPDatePicker({
  inputClassName,
  className,
  value,
  onChange,
  ...props
}: NPDatePickerProps): ReactElement {
  const divRef = React.useRef<HTMLDivElement>(null)
  const clearDate = () => {
    onChange?.(null)
  }

  return (
    <div ref={divRef} className={styles.parent} style={{ position: "relative" }}>
      <Calendar
        className={[styles.inputClassName, !value ? styles.inputClassNameNoData : "", inputClassName].join(" ")}
        onChange={({ bsDate }: { bsDate: string; adDate: string }) => {
          const bsDateInEnglishStr = convertNeplaiDateStrIntoEnglishDateStr(bsDate)
          onChange?.(bsDateInEnglishStr)
        }}
        placeholder=" "
        defaultDate={value}
        hideDefaultValue={value ? false : true}
        theme="deepdark"
        {...props}
      />
      {!value && <span style={{ position: "absolute", top: 5, left: 10, opacity: 0.3 }}>मिति चयन गर्नुहोस्</span>}
      <span style={{ position: "absolute", top: 5, right: 5, opacity: 0.3 }}>
        <svg
          viewBox="0 0 512 512"
          focusable="false"
          data-icon="calendar"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
          style={{ marginRight: 5, cursor: "pointer" }}
          className={styles.clearIcon}
          onClick={() => {
            clearDate()
          }}
        >
          <path
            d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z"
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: "32px",
            }}
          />
          <line
            x1={336}
            y1={256}
            x2={176}
            y2={256}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "32px",
            }}
          />
        </svg>

        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="calendar"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path>
        </svg>
      </span>
    </div>
  )
}
