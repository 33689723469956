import { Button, Card, Col, Divider, Form, Input, Row, Space, Typography, theme } from "antd"
import { ReactElement, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import NPDatePicker from "../../../../../../components/common/npdatepicker"
import EmployeeSelector from "../../../../../../components/selectors/employeeselector"
import RecommendingBodySelector from "../../../../../../components/selectors/recommendingBodySelector"
import { selectUserId } from "../../../../../../features/auth/authSlice"
import { createRecord } from "../../../../../../features/records/recordsThunk"
import { useAppDispatch, useAppSelector } from "../../../../../../hooks"
import { RecordTypesEnum } from "../../../../../../types/typings.d"

interface CreateDeportionProps {}

export default function CreateDeportion(props: CreateDeportionProps): ReactElement {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { token } = theme.useToken()
  const userId = useAppSelector(selectUserId)
  const [state, setState] = useState({
    loading: false,
    sendLinkToEntryForm: true,
  })

  const [users, setUsers] = useState([])

  const onResetForm = () => {
    form.resetFields()
    setUsers([])
  }

  const onFinish = (values: any) => {
    setState({ ...state, loading: true })
    dispatch(
      createRecord({
        ...values,
        recordType: RecordTypesEnum.Deportation,
        enteredBy: userId,
        usrEntries: users,
        // sendLinkToEntryForm: state.sendLinkToEntryForm,
      })
    )
      .unwrap()
      .then((res) => {
        setState({ ...state, loading: false })
        navigate(-1)
        toast.success(`Created`)
      })
      .catch((err) => {
        setState({ ...state, loading: false })
        console.log("Err", err)
        toast.error(err?.message || "Error creating")
      })
  }
  return (
    <div>
      <div className="site-card-border-less-wrapper">
        <Card
          title={
            <Typography.Title level={3} className="he-title">
              निष्कासन / Deportation
            </Typography.Title>
          }
          bordered={false}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            //   initialValues={{ layout: formLayout }}
            initialValues={{
              invoiceNo: "",
              chalaniNo: "",
              invoiceDate: "",
              recordType: "",
              correspondenceBody: "",
              ministryDecisionDate: "",
              dateOfCorrespondence: "",
              pharchyotPerson: null,
              fromDeportionDuration: "",
              toDeportionDuration: "",
              deportionReason: "भिसा नियमित नगरि ओभरस्टे भएका",
            }}
            //   onValuesChange={onFormLayoutChange}
          >
            <Row gutter={10}>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="दर्ता नं." help="Auto generated by system">
                  <Input readOnly disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्र संख्या" name="invoiceNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="चलानी नं." name="chalaniNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्र मिती" name="invoiceDate">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्राचार गर्ने निकाय" name="correspondenceBody">
                  <RecommendingBodySelector
                    onClickAddOtherItem={(text) => {
                      form.setFieldValue("correspondenceBody", text)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="फर्छ्योट गर्ने व्यक्ति" name={"pharchyotPerson"}>
                  <EmployeeSelector />
                </Form.Item>
              </Col>
              {/* <Col xs={24} md={12} xl={8}>
                <Form.Item label="मन्त्रालयको निर्णय मिती" name="ministryDecisionDate">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्राचार हुने मिती" name="dateOfCorrespondence">
                  <NPDatePicker />
                </Form.Item>
              </Col> */}
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="निष्काशन अबधि (देखि)" name="fromDeportionDuration">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="निष्काशन अबधि (सम्म)" name="toDeportionDuration">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="निष्काशन कारण" name="deportionReason">
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" loading={state.loading}>
                  Submit
                </Button>
                <Button
                  type="primary"
                  htmlType="reset"
                  style={{ backgroundColor: token.colorWarning }}
                  disabled={state.loading}
                  onClick={onResetForm}
                >
                  Reset
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  )
}
