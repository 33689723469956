import { createAsyncThunk } from "@reduxjs/toolkit";
import usersHttpService from "../../services/https/apis/users.http.service";
export const fetchFlightCompanies = createAsyncThunk(
  "flightCompanies/fetchFlightCompanies",
  async () => {
    return usersHttpService.fetchUsers({
      params: {
        filters: {
          role: {
            type: {
              $eq: "flight_company",
            },
          },
        },
        populate:"*"
      },
    });
  }
);
