import { ArrowRightOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Space, theme, Typography } from "antd";
import { ReactElement, ReactNode } from "react";
import { RotatingLines } from "react-loader-spinner";

interface CountStatsProps {
  loading?: boolean;
  count?: ReactNode;
  error?: any;
  onClickTryAgain?: () => void;
  onClickNavigate?: () => void;
  title: ReactNode;
  hideNavigate?: boolean
}

export default function CountStats({
  title,
  count,
  loading,
  error,
  onClickNavigate,
  onClickTryAgain,
  hideNavigate
}: CountStatsProps): ReactElement {
  const { token } = theme.useToken();
  return (
    <Card
      style={{
        position: "relative",
        minHeight: 130,
        width: 300,
        padding: 0,
        ...(Boolean(error) ? { border: `solid 2px ${token.colorError}` } : {}),
      }}
      className="shadow-md"
      bodyStyle={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        width: 200,
        minHeight: 130,
      }}
    >
      <Typography.Title level={4} className="he-subtitle" style={{ width: 200 }}>
        {title}
      </Typography.Title>
      <Card
        style={{
          backgroundColor: Boolean(error)
            ? token.colorError
            : token.colorPrimary,
          height: 80,
          minWidth: 80,
          maxWidth: 120,
          textAlign: "center",
          position: "absolute",
          right: 20,
          top: -20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {Boolean(error) ? (
          <Typography.Title
            onClick={onClickTryAgain}
            level={5}
            style={{ color: "white", fontSize: 10, cursor: "pointer" }}
          >
            Error Retry <ReloadOutlined />
          </Typography.Title>
        ) : loading ? (
          <RotatingLines
            strokeColor="white"
            strokeWidth="2"
            animationDuration="0.75"
            width="20"
            visible={true}
          />
        ) : (
          <Typography.Title level={4} className="he-subtitle" style={{ color: "white", }}>
            {count || '0'}
          </Typography.Title>
        )}
      </Card>
      <Space
        style={{
          position: "absolute",
          right: 20,
          bottom: 10,
        }}
      >
        {!hideNavigate && <Button
          type="link"
          style={{ padding: 0 }}
          title="Navigate"
          size="large"
          loading={loading}
          disabled={loading || Boolean(error)}
          onClick={onClickNavigate}
        >
          <ArrowRightOutlined />
        </Button>}
      </Space>
    </Card>
  );
}
