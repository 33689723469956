import { Modal } from "antd"
import { forwardRef, useImperativeHandle, useState } from "react"
import CreateORUpdateBorderForm from "../createOrUpdateForm/createOrUpdateForm"

interface CreateORUpdateBorderModalProps {}

interface CreateORUpdateBorderModalState {
  open?: boolean
  border?: Record<string, any>
  action?: "create" | "update"
  creatingOrUpdating?: boolean
  imageList?: any[]
}

export interface CreateORUpdateBorderModalRefsHandler {
  setState: (state: Partial<CreateORUpdateBorderModalState>) => void
}

const initialState: CreateORUpdateBorderModalState = {
  open: false,
  border: undefined,
  action: "create",
}

const CreateORUpdateBorderModal = forwardRef<CreateORUpdateBorderModalRefsHandler, CreateORUpdateBorderModalProps>(
  ({}: CreateORUpdateBorderModalProps, ref) => {
    const [comState, setComState] = useState<CreateORUpdateBorderModalState>(initialState)

    useImperativeHandle(ref, () => ({
      setState: (stateArg: Partial<CreateORUpdateBorderModalState>) => {
        setComState({ ...comState, ...stateArg })
      },
    }))

    const closeDialog = () => {
      setComState({ ...comState, ...initialState })
    }

    const { border, open, action } = comState

    if (!open) {
      return null
    }
    return (
      <Modal
        title={`अन्तर्राष्ट्रिय सिमाना / International Border ${
          action === "create" ? "थप्नुहोस्" : "सम्पादन गर्नुहोस्"
        }`}
        centered
        open={open}
        footer={null}
        onCancel={closeDialog}
        maskClosable={false}
        keyboard={false}
        width={800}
      >
        {open && <CreateORUpdateBorderForm action={action} border={border} />}
      </Modal>
    )
  }
)
export default CreateORUpdateBorderModal
