import { AxiosRequestConfig } from "axios";
import { AppWebRequest } from "../NetworkManager";
class DepartmentHttpService {
  fetchDepartments(config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {};
    const {sort,populate,...otherParams}=params||{}
    return AppWebRequest({
      method: "get",
      url:"/departments",
      ...axiosConfig,
      params:{
        sort:sort||["updatedAt:desc"],
        ...otherParams
      }
    });
  }

  createDepartment(payload: Record<string, any>) {
    return AppWebRequest({
      method: "post",
      url: "/departments",
      data: {data:payload},
    });
  }

  updateDepartment(id: string, payload: Record<string, any>) {
    return AppWebRequest({
      method: "put",
      url: `/departments/${id}`,
      data: {data:payload},
    });
  }

  deleteDepartment(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/departments/${id}`,
    });
  }
}

const instance= new DepartmentHttpService();
export default instance
