import { ReloadOutlined } from "@ant-design/icons"
import { Typography } from "antd"
import { useEffect } from "react"
import { RotatingLines } from "react-loader-spinner"
import { fetchLastUsedChalani } from "../../../features/global/globalThunk"
import { useAppDispatch, useAppSelector } from "../../../hooks"

interface LastUsedChalaniViewerProps {}

export default function LastUsedChalaniViewer(props: LastUsedChalaniViewerProps) {
  const dispatch = useAppDispatch()
  const { error, loading, data } = useAppSelector((state) => state.global.lastUsedChalani)

  const loadLastUsedChalani = () => {
    dispatch(fetchLastUsedChalani({}))
  }
  useEffect(() => {
    loadLastUsedChalani()
    const interval = setInterval(() => {
      loadLastUsedChalani()
    }, 15000) //Every 15 sec

    return () => clearInterval(interval)

    // eslint-disable-next-line
  }, [])
  return (
    <>
      Last Used चलानी नं. :{" "}
      {Boolean(error) ? (
        <Typography.Title
          onClick={loadLastUsedChalani}
          level={5}
          style={{ color: "red", fontSize: 10, cursor: "pointer", display: "inline-block" }}
        >
          Retry <ReloadOutlined />
        </Typography.Title>
      ) : !data?.chalaniNo && loading ? (
        <RotatingLines strokeWidth="2" animationDuration="0.75" width="20" visible={true} />
      ) : (
        data?.chalaniNo || "अहिलेसम्म प्रयोग भएको छैन"
      )}
    </>
  )
}
