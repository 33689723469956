import { FilterOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Card, Col, Form, Input, Popover, Row, Space, Table, Tag, Typography, message, theme } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { ReactElement, useEffect, useState } from "react"
import { Bars } from "react-loader-spinner"
import DeletePopConfirmed from "../../../../components/common/deletepopconfirmed"
import PdfViewer from "../../../../components/common/pdfviewer/index"
import SelfControlModal, { SelfControlModalRefsHandler } from "../../../../components/common/selfcontrolmodal"
import bibidhHttpService from "../../../../services/https/apis/bibidh.http.service"
import { getFilePath } from "../../../../utils/general.utils"
import UploadBibidh from "./components/uploadBibidh"

interface LinkedBibidhsProps {}

export default function LinkedBibidhs(props: LinkedBibidhsProps): ReactElement {
  const [state, setState] = useState<{
    loading: boolean
    error: any
    data: any[]
  }>({
    loading: true,
    error: null,
    data: [],
  })

  const refs = {
    uploadBibidhModalRef: React.createRef<SelfControlModalRefsHandler>(),
  }

  const { token } = theme.useToken()

  const fetchData = () => {
    const params = {
      filters: {},
    }
    setState({ ...state, loading: true, error: null })
    bibidhHttpService
      .fetchBibidhs({ params })
      .then((res) => {
        setState({ ...state, loading: false, data: res.data })
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err })
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  // TODO: filter content
  const [open, setOpen] = useState(false)
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    setState({ ...state, loading: true, error: null })
    const params = {
      filters: {
        $or: [
          {
            linkedRegNo: values.linkedRegNo,
          },
        ],
      },
    }
    bibidhHttpService
      .fetchBibidhs({ params: params })
      .then((res) => {
        setState({ ...state, loading: false, data: res.data })
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err })
      })

    setOpen(false)
  }

  const content = (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Row gutter={10} style={{ width: "20vw" }}>
        <Col span={24}>
          <Form.Item label="रेकर्ड दर्ता नं." name="linkedRegNo">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={state.loading}>
            Filter
          </Button>
          <Button
            type="primary"
            htmlType="reset"
            style={{ backgroundColor: token.colorWarning }}
            disabled={state.loading}
          >
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )

  const renderTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Button type="dashed" icon={<ReloadOutlined />} onClick={() => fetchData()}>
          रिफ्रेस गर्नुहोस्
        </Button>
        <Space>
          <SelfControlModal
            anchorElement={
              <Button type="primary" icon={<PlusOutlined />}>
                अपलोड गर्नुहोस्
              </Button>
            }
            title="अपलोड बिबिध"
            width={1000}
            ref={refs.uploadBibidhModalRef}
          >
            <UploadBibidh modalRef={refs.uploadBibidhModalRef} reloadData={fetchData} />
          </SelfControlModal>
          <Popover
            content={content}
            title="फिल्टर गर्नुहोस्"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button type="dashed" icon={<FilterOutlined />}>
              फिल्टर गर्नुहोस्
            </Button>
          </Popover>
        </Space>
      </div>
    )
  }

  const renderTable = () => {
    if (state.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{state.error?.message || "Error occured"}</Typography>
          <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => fetchData()}>
            Try Again
          </Button>
        </Card>
      )
    }

    if (state.loading) {
      return (
        <Card>
          {renderTableHeader()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      )
    }

    const dataSource = state.data.map((f) => ({
      id: f?.id,
      pdfLetter: getFilePath(f?.attributes?.pdfLetter?.data?.attributes?.url),
      linkedRegNo: f?.attributes?.linkedRegNo || "--",
      chalaniNo: f?.attributes?.chalaniNo || "--",
      flightPermission: f?.attributes?.flightPermission,
    }))

    const columns: ColumnsType<{}> = [
      {
        title: "रेकर्ड दर्ता नं.",
        dataIndex: "linkedRegNo",
        key: "linkedRegNo",
      },
      {
        title: "चलानी नं.",
        dataIndex: "chalaniNo",
        key: "chalaniNo",
      },
      {
        title: "फाइल",
        dataIndex: "pdfLetter",
        key: "pdfLetter",
        width: "30%",
        render: (fileUrl: string) => <PdfViewer fileUrl={fileUrl} />,
      },
      {
        title: "उडान हो ?",
        dataIndex: "flightPermission",
        key: "flightPermission",
        width: "10%",
        render: (flightPermission: any) =>
          flightPermission ? <Tag color="green">हो</Tag> : <Tag color="blue">होइन</Tag>,
      },
      {
        title: "कार्यहरु",
        width: "10%",
        key: "action",
        render: (_: any, record: any) => (
          <Space size="middle">
            <DeletePopConfirmed
              title="Really want to delete?"
              actionPromiseFunction={() =>
                bibidhHttpService
                  .deleteBibidh(record?.id)
                  .then((res) => {
                    message.success("Deleted")
                    fetchData()
                  })
                  .catch((err) => {
                    message.error(err?.message || "Error deleting")
                  })
              }
            />
          </Space>
        ),
      },
    ]

    return (
      <Card>
        {renderTableHeader()}
        <Table dataSource={dataSource} scroll={{ x: "auto" }} columns={columns} rowKey="key" />
      </Card>
    )
  }

  return <div>{renderTable()}</div>
}
