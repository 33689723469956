import {
  Avatar,
  Button,
  Card,
  Image,
  Space,
  Table,
  Tag,
  theme,
  Typography,
} from "antd";
import React, { createRef, ReactElement } from "react";
import { fetchEmployees } from "../../../../features/employees/employeesThunk";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  EditOutlined,
  PlusOutlined,
  ExportOutlined,
  ReloadOutlined,
  FilterOutlined,
  UserOutlined,
} from "@ant-design/icons";
import CreateORUpdateEmployeeModal, {
  CreateORUpdateEmployeeModalRefsHandler,
} from "./components/createORupdateModal/createORupdateModal";
import { Bars } from "react-loader-spinner";
import { getFilePath } from "../../../../utils/general.utils";
import { ColumnsType } from "antd/es/table";
import CurrentUserPolicy from '../../../../features/uipolicies/currentuserpolicy';
import UnderlineTypography from "../../../../components/common/underlineTypography";

interface EmployeesHomeProps {}

export default function EmployeesHome({}: EmployeesHomeProps) {
  const { token } = theme.useToken();
  const dispatch = useAppDispatch();
  const employees = useAppSelector((state) => state.employees);
  const createUpdateModalRef =
    createRef<CreateORUpdateEmployeeModalRefsHandler>();

  const renderTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
          flexWrap: "wrap",
        }}
      >
        <Button
          type="dashed"
          icon={<ReloadOutlined />}
          onClick={() => {
            dispatch(fetchEmployees());
          }}
          disabled={employees.error || employees.loading}
        >
          रिफ्रेस गर्नुहोस्
        </Button>
        <Space wrap>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => {
              createUpdateModalRef.current?.setState({ open: true });
            }}
          >
            थप्नुहोस्
          </Button>
          {/* <Button
            type="dashed"
            icon={<FilterOutlined />}
            disabled={employees.error || employees.loading}
          >
            फिल्टर गर्नुहोस्
          </Button> */}
          {/* <Button
            type="dashed"
            icon={<ExportOutlined />}
            disabled={employees.error || employees.loading}
          >
            निर्यात गर्नुहोस्
          </Button> */}
        </Space>
      </div>
    );
  };

  const renderTable = () => {
    if (employees.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{employees.error?.message || "Error occured"}</Typography>
          <Button type="primary" style={{ backgroundColor: "red" }}>
            Try Again
          </Button>
        </Card>
      );
    }

    if (employees.loading) {
      return (
        <Card>
          {renderTableHeader()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      );
    }

    const dataSource = employees.entities.map((f) => ({
      id: f?.id,
      fullName: f?.fullName || "--",
      username: f?.username || "--",
      email: f?.email || "--",
      blocked: f?.blocked,
      phoneNumber: f?.phoneNumber || "--",
      avatarImagePath: getFilePath(f?.avatar?.url),
    }));

    const columns: ColumnsType<{}> = [
      {
        title: "Avatar",
        dataIndex: "avatarImagePath",
        key: "avatarImagePath",
        render: (avatarImagePath: string) =>
          avatarImagePath ? (
            <Avatar
              src={<Image src={avatarImagePath} style={{ width: 32 }} />}
            />
          ) : (
            <Avatar icon={<UserOutlined />} />
          ),
        fixed: "left",
        width: 100,
      },
      {
        title: "नाम/थर",
        dataIndex: "fullName",
        key: "fullName",
        width: 250,
      },
      {
        title: "युजरनेम",
        dataIndex: "username",
        key: "username",
        width: 150,
      },
      {
        title: "इमेल",
        dataIndex: "email",
        key: "email",
        width: 150,
      },
      {
        title: "फोन",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        render: (phoneNumber: string) => phoneNumber || "--",
        width: 200,
      },
      {
        title: "अबस्था",
        dataIndex: "blocked",
        key: "blocked",
        render: (blocked: boolean) =>
          blocked ? (
            <Tag color="error">Blocked</Tag>
          ) : (
            <Tag color="success">Active</Tag>
          ),
        width: 200,
        fixed: "right",
      },
      {
        title: "Actions",
        key: "action",
        width: 200,
        fixed: "right",
        render: (_: any, record: any) => (
          <Space size="middle">
            {CurrentUserPolicy.getInstance().isAllowedEmployeesTableForAction("edit") && (
              <EditOutlined
                style={{ cursor: "pointer", color: token.colorWarning }}
                onClick={() => {
                  const employee = employees?.entities?.find(
                    (f) => f.id === record.id
                  );
                  createUpdateModalRef.current?.setState({
                    open: true,
                    employee: employee,
                    action: "update",
                  });
                }}
              />
            )}
          </Space>
        ),
      },
    ];

    return (
      <Card>
        {renderTableHeader()}
        <Table
          scroll={{ x: "auto" }}
          dataSource={dataSource}
          columns={columns}
        />

        <CreateORUpdateEmployeeModal ref={createUpdateModalRef} />
      </Card>
    );
  };

  return <div>
    <div style={{ marginBottom: 10 }}>
        <UnderlineTypography>कर्मचारीहरू</UnderlineTypography>
      </div>
      {renderTable()}</div>;
}
