import { Select } from "antd";
import React from "react";

interface FlightTypeSelectorProps extends React.ComponentProps<typeof Select> {}

export default function FlightTypeSelector({ ...props }: FlightTypeSelectorProps) {
  return (
    <Select
      {...props}
      options={[
        { value: "Rescue Flight", label: "Rescue Flight" },
        { value: "Chartered Flight", label: "Chartered Flight" }
      ]}
    />
  );
}
