import { AxiosRequestConfig } from "axios";
import { AppWebRequest } from "../NetworkManager";
class UIPolicyHttpService {
  fetchUIPolicies(config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {};
    const {sort,populate,...otherParams}=params||{}
    return AppWebRequest({
      method: "get",
      url:"/ui-policies",
      ...axiosConfig,
      params:{
        sort:sort||["updatedAt:desc"],
        ...otherParams
      }
    })
  }

  createUIPolicy(payload: Record<string, any>) {
    return AppWebRequest({
      method: "post",
      url: "/ui-policies",
      data: {data:payload},
    });
  }

  updateUIPolicy(id: string, payload: Record<string, any>) {
    return AppWebRequest({
      method: "put",
      url: `/ui-policies/${id}`,
      data: {data:payload},
    });
  }

  deleteUIPolicy(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/ui-policies/${id}`,
    });
  }
}

const instance =  new UIPolicyHttpService();
export default instance
