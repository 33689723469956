import { AxiosRequestConfig } from "axios";
import { AppWebRequest } from "../NetworkManager";
class DesignationHttpService {
  fetchDesignations(config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {};
    const {sort,populate,...otherParams}=params||{}
    return AppWebRequest({
      method: "get",
      url:"/designations",
      ...axiosConfig,
      params:{
        sort:sort||["updatedAt:desc"],
        ...otherParams
      }
    });
  }

  createDesignation(payload: Record<string, any>) {
    return AppWebRequest({
      method: "post",
      url: "/designations",
      data: {data:payload},
    });
  }

  updateDesignation(id: string, payload: Record<string, any>) {
    return AppWebRequest({
      method: "put",
      url: `/designations/${id}`,
      data: {data:payload},
    });
  }

  deleteDesignation(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/designations/${id}`,
    });
  }
}

const instance = new DesignationHttpService();
export default instance
