export enum RecordTypesEnum {
  LabourConcurrence = "Labour Concurrence",
  FreeArrivalVisa = "Free Arrival Visa",
  FlightPermission = "Flight Permission",
  FreeVisaExtension = "Free Visa Extension",
  Deportation = "Deportation",
  MultipleEntryVisa = "Multiple Entry Visa",
  ArrivalVisa = "Arrival Visa",
  NrnStudyVisa = "Nrn Study Visa",
  FreeTrekkingPermit = "Free Trekking Permit",
  InternationalBorder = "International Border",
  TravelDocument = "Travel Document",
  None = "None",
  All = "All",
}

export enum RecordStatus {
  CREATED = "CREATED",
  INPROGRESS = "IN PROGRESS",
  COMPLETED = "COMPLETED",
}

export interface UIPolicyI {
  dashboard?: boolean
  records?: {
    recordsHome?: Partial<
      Record<
        RecordTypesEnum,
        {
          edit?: boolean
          delete?: boolean
          view?: boolean
          add?: boolean
        }
      >
    >
    registrations?: boolean
    chalani?: boolean
  }
  users?: {
    edit?: boolean
    delete?: boolean
    view?: boolean
    add?: boolean
  }
  thirdparties?: {
    edit?: boolean
    delete?: boolean
    view?: boolean
    add?: boolean
  }
  employees?: {
    employeesHome?: {
      edit?: boolean
      delete?: boolean
      view?: boolean
      add?: boolean
    }
    departments?: {
      edit?: boolean
      delete?: boolean
      view?: boolean
      add?: boolean
    }
    designations?: {
      edit?: boolean
      delete?: boolean
      view?: boolean
      add?: boolean
    }
  }
  roles?: {
    edit?: boolean
    delete?: boolean
    view?: boolean
    add?: boolean
  }
  uipolicies?: {
    edit?: boolean
    delete?: boolean
    view?: boolean
    add?: boolean
  }
  summarylogs?: boolean
  reports?: boolean
  bibidhs?: boolean
}

export enum FlightType {
  RESCUE_FLIGHT = "Rescue Flight",
  CHARTERED_FLIGHT = "Chartered Flight",
}

export enum CharteredFlightType {
  CHARTERED = "Chartered",
  FREIGHT_CHARTERED = "Freight Chartered",
  SLING_FLIGHT = "Sling Flight",
}

export interface FreeArrivalVisaTemplateDataI {
  date: string
  invoiceNo: string
  chalaniNo: string
  invoiceDate: string
  fromVisaDuration: string
  toVisaDuration: string
  purposeOfVisit: string
  branchOfficer: string
  usrEntries: Array<{
    id: string
    fullName: string
    passportNo: string
    country: string
    remarks: string
    fromVisaDuration: string
    toVisaDuration: string
  }>
}
