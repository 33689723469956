import { AxiosRequestConfig } from "axios";
import { AppWebRequest } from "../NetworkManager";
class TemplateHttpService {
  fetchTemplates(config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {};
    const {sort,populate,...otherParams}=params||{}
    return AppWebRequest({
      method: "get",
      url: "/templates",
      ...axiosConfig,
      params:{
        sort:sort||["updatedAt:desc"],
        ...otherParams
      }
    });
  }

  createTemplate(payload: Record<string, any>) {
    return AppWebRequest({
      method: "post",
      url: "/templates",
      data: {data:payload},
    });
  }

  fetchTemplate(id: string) {
    return AppWebRequest({
      method: "get",
      url: `/templates/${id}`
    });
  }

  updateTemplate(id: string, payload: Record<string, any>) {
    return AppWebRequest({
      method: "put",
      url: `/templates/${id}`,
      data: {data:payload},
    });
  }

  deleteTemplate(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/templates/${id}`,
    });
  }
}

const templateHttpService = new TemplateHttpService();
export default templateHttpService
