import React from "react";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import { useAppDispatch } from "../../../hooks";
import {
  logOutFromSystem,
  selectProfile,
} from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProfileMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);
  const onClickLogout = async () => {
    await dispatch(logOutFromSystem());
    navigate("/");
  };
  const items: MenuProps["items"] = [
    {
      label: "Logout",
      key: "1",
      onClick: onClickLogout,
    },
  ];
  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <a href="/#" onClick={(e) => e.preventDefault()}>
        <Space>
          {profile?.data?.username || "--"}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};

export default ProfileMenu;
