import { CloudDownloadOutlined, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, Form, Input, Row, Space, Switch, Tag, Typography } from "antd"
import qs from "qs"
import React, { ReactElement, useState } from "react"
import { Bars } from "react-loader-spinner"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import LoadingWrapper from "../../../../../../components/common/loadingwrapper"
import NPDatePicker from "../../../../../../components/common/npdatepicker"
import PdfViewer from "../../../../../../components/common/pdfviewer"
import SelfControlModal, { SelfControlModalRefsHandler } from "../../../../../../components/common/selfcontrolmodal"
import Editor from "../../../../../../components/editor"
import PassengerEntryFormRow from "../../../../../../components/passengerentryFormRow"
import CharterFlightTypeSelector from "../../../../../../components/selectors/charterFightTypeSelector/index"
import EmployeeSelector from "../../../../../../components/selectors/employeeselector/index"
import FlightCompanySelector from "../../../../../../components/selectors/flightCompanySelector"
import FlightTypeSelector from "../../../../../../components/selectors/flightTypeSelector"
import { selectProfile } from "../../../../../../features/auth/authSlice"
import { useAppSelector } from "../../../../../../hooks"
import flightPermissionHttpService from "../../../../../../services/https/apis/flightpermission.http.service"
import notificationHttpService from "../../../../../../services/https/apis/notification.http.service"
import { FlightType, RecordStatus } from "../../../../../../types/typings.d"
import { getFilePath } from "../../../../../../utils/general.utils"
import UploadDocument from "../../arrivalvisa/components/uploadDocument"
import UploadFinalLetter from "../components/uploadfinalLetter"

interface UpdateFlightPermissionProps {
  entryByFlightCompany?: boolean
}

const passengerPayload = {
  fullName: "",
  country: "",
  passportNo: "",
}

export default function UpdateFlightPermission({ entryByFlightCompany }: UpdateFlightPermissionProps): ReactElement {
  const [_, setFormValues] = useState({})
  const [form] = Form.useForm()
  const userProfile = useAppSelector(selectProfile)
  const [state, setState] = useState<{
    loading: boolean
    fetchLoading: boolean
    fetchError: any
    fetchedData: any
    sendLinkToEntryForm: boolean
    generateTipaaniLoading: boolean
    generateFinalLetterLoading: boolean
    rawHTML?: string
  }>({
    loading: false,
    fetchLoading: true,
    fetchError: null,
    fetchedData: null,
    sendLinkToEntryForm: true,
    generateTipaaniLoading: false,
    generateFinalLetterLoading: false,
    rawHTML: "",
  })
  const [passengers, setPassengers] = useState([passengerPayload])
  let { id } = useParams()
  const refs = {
    uploadTippaniModalRef: React.createRef<SelfControlModalRefsHandler>(),
    uploadFinalLetterModalRef: React.createRef<SelfControlModalRefsHandler>(),
    uploadDocumentModalRef: React.createRef<SelfControlModalRefsHandler>(),
    editTippaniModalRef: React.createRef<SelfControlModalRefsHandler>(),
    editFinalLetterModalRef: React.createRef<SelfControlModalRefsHandler>(),
  }

  const generateFinalLetter = () => {
    flightPermissionHttpService
      .generateFinalLetter(state?.fetchedData?.id)
      .then((res) => {
        setState({
          ...state,
          rawHTML: res.html,
          generateFinalLetterLoading: false,
        })
        refs.editFinalLetterModalRef.current?.show?.()
      })
      .catch((err) => {
        toast.error(err.message || "Error generation final letter")
        setState({ ...state, generateFinalLetterLoading: false })
      })
  }

  const fetchByIdAndSetState = () => {
    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          "document",
          "flightCompany",
          "passengers",
          "finalLetters",
          "finalLetters.pdfLetter",
          "pharchyotPerson",
        ],
        filters: {},
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    setState({ ...state, fetchLoading: true, fetchError: null })
    flightPermissionHttpService
      .fetchFlightPermissionById(id || "", query)
      .then((res) => {
        form.setFieldsValue({
          dartaNo: res?.data?.attributes?.dartaNo,
          flightCompany: res?.data?.attributes?.flightCompany?.data?.id,
          flightDate: res?.data?.attributes?.flightDate,
          flightType: res?.data?.attributes?.flightType,
          chartedFlightType: res?.data?.attributes?.chartedFlightType,
          totalPassengerCount: res?.data?.attributes?.totalPassengerCount,
          province: res?.data?.attributes?.province,
          district: res?.data?.attributes?.district,
          localLevel: res?.data?.attributes?.localLevel,
          location: res?.data?.attributes?.location,
          sector: res?.data?.attributes?.sector,
          totalShuttle: res?.data?.attributes?.totalShuttle,
          pharchyotPerson: res?.data?.attributes?.pharchyotPerson?.data?.id,
          ministryDecisionDate: res?.data?.attributes?.ministryDecisionDate,
          dateOfCorrespondence: res?.data?.attributes?.dateOfCorrespondence,
          isDocumentVerified: res?.data?.attributes?.isDocumentVerified,
          purposeOfFlight: res?.data?.attributes?.purposeOfFlight,
          itemDetails: res?.data?.attributes?.itemDetails,
        })
        setPassengers(res?.data?.attributes?.passengers || [])
        setState({ ...state, fetchLoading: false, fetchedData: res.data })
      })
      .catch((err) => {
        setState({ ...state, fetchLoading: false, fetchError: err })
      })
  }

  React.useEffect(() => {
    fetchByIdAndSetState()
  }, [])

  const onFinish = (values: any) => {
    delete values.ownerFlightCompany
    delete values.dartaNo
    setState({ ...state, loading: true })
    flightPermissionHttpService
      .updateFlightPermission(state?.fetchedData?.id || "", {
        ...values,
        passengers: passengers,
      })
      .then((res) => {
        setState({ ...state, loading: false })
        if (entryByFlightCompany) {
          //Create notification
          notificationHttpService.createNotification(
            {
              title: `Flight Permission Record has been updated`,
              description: `Flight Permission Record has been updated with registratin id ${state?.fetchedData?.id}, Login to dashboard for more details`,
              notificationFor: "Flight Permission",
              linkedUser: userProfile?.data?.id,
              linkedFlightPermission: state?.fetchedData?.id,
            },
            true
          )
        }
        toast.success(`Update`)
      })
      .catch((err) => {
        setState({ ...state, loading: false })
        console.log("Err", err)
        toast.error(err?.message || "Error creating")
      })
  }

  if (state.fetchLoading) {
    return (
      <Card>
        <div
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </Card>
    )
  }

  if (state.fetchError) {
    return (
      <Card
        style={{
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography>{state.fetchError?.message || "Error occured"}</Typography>
        <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => fetchByIdAndSetState()}>
          Try Again
        </Button>
      </Card>
    )
  }
  return (
    <div>
      <div className="site-card-border-less-wrapper" style={{ position: "relative" }}>
        {state.loading && <LoadingWrapper />}
        <Card bordered={false}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              dartaNo: "",
              flightCompany: "",
              ownerFlightCompany: entryByFlightCompany
                ? userProfile?.data?.fullName || userProfile?.data?.username
                : "",
              flightDate: "",
              flightType: FlightType.RESCUE_FLIGHT,
              chartedFlightType: "",
              totalPassengerCount: "",
              province: "",
              district: "",
              localLevel: "",
              location: "",
              sector: "",
              totalShuttle: "",
              pharchyotPerson: null,
              ministryDecisionDate: "",
              dateOfCorrespondence: "",
              isDocumentVerified: false,
            }}
            onValuesChange={(values) => setFormValues(values)}
          >
            <Form.Item className="sticky-header">
              <Space style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography.Title level={3} className="he-title">
                    उडान दर्ता / Flight Permission
                  </Typography.Title>
                  <Typography.Title style={{ marginTop: "-1rem" }}>
                    {state?.fetchedData?.attributes?.status === RecordStatus.INPROGRESS ? (
                      <Tag color="warning">{state?.fetchedData?.attributes?.status}</Tag>
                    ) : state?.fetchedData?.attributes?.status === RecordStatus.CREATED ? (
                      <Tag color="blue">{state?.fetchedData?.attributes?.status}</Tag>
                    ) : (
                      <Tag color="success">{state?.fetchedData?.attributes?.status}</Tag>
                    )}
                  </Typography.Title>
                </div>
                <Button type="primary" htmlType="submit" loading={state.loading}>
                  Save Changes
                </Button>
              </Space>
            </Form.Item>
            <Divider />
            <Row gutter={10}>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="दर्ता नं." help="Auto generated by system" name="dartaNo">
                  <Input readOnly disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="उडान कम्पनी" name="flightCompany" rules={[{ required: true }]}>
                  <FlightCompanySelector disabled={entryByFlightCompany} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="उडान मिति" name="flightDate">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="उडान प्रकार" name="flightType">
                  <FlightTypeSelector />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="चार्टड उडान" name="chartedFlightType">
                  <CharterFlightTypeSelector disabled={form.getFieldValue("flightType") !== "Chartered Flight"} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="जम्मा यात्रु संख्या" name="totalPassengerCount">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="प्रदेश" name="province">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="जिल्ला" name="district">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="स्थानिय तह" name="localLevel">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="स्थान" name="location">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="उडानको प्रयोजन" name="purposeOfFlight">
                  <Input.TextArea />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="सेक्टर" name="sector">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="जम्मा शटल" name="totalShuttle">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="सामाग्री विवरण" name="itemDetails">
                  <Input.TextArea />
                </Form.Item>
              </Col>
              {!entryByFlightCompany && (
                <>
                  <Col xs={24} md={12} xl={8}>
                    <Form.Item label="फर्छ्योट गर्ने व्यक्ति" name={"pharchyotPerson"}>
                      <EmployeeSelector />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} xl={8}>
                    <Form.Item label="मन्त्रालयको निर्णय मिती" name="ministryDecisionDate">
                      <NPDatePicker />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} xl={8}>
                    <Form.Item label="पत्राचार हुने मिती" name="dateOfCorrespondence">
                      <NPDatePicker />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Divider />
            <div>
              <div style={{ marginBottom: 20 }}>
                <div style={{ marginBottom: 20 }}>
                  <Typography.Title level={4} className="he-subtitle">
                    यात्रु
                  </Typography.Title>
                </div>
              </div>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {passengers?.map?.((psg, index) => {
                  return (
                    <PassengerEntryFormRow
                      key={index}
                      index={index}
                      values={psg}
                      onRemove={(index) => {
                        const newUsers = [...passengers]
                        newUsers.splice(index, 1)
                        setPassengers(newUsers)
                      }}
                      onReset={() => {
                        const newUsers = [...passengers]
                        newUsers[index] = passengerPayload
                        setPassengers(newUsers)
                      }}
                      onValuesChange={(values) => {
                        // console.log("values", values)
                        const newUsers = [...passengers]
                        newUsers[index] = values
                        setPassengers(newUsers)
                      }}
                    />
                  )
                })}
              </Row>
              <Space style={{ marginTop: 10, marginBottom: 20 }}>
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    const newUsers = [...passengers]
                    newUsers.push(passengerPayload)
                    setPassengers(newUsers)
                  }}
                  disabled={state.loading}
                >
                  थप्नुहोस्
                </Button>
              </Space>
            </div>

            <Divider />
            <div style={{ marginBottom: 20 }}>
              {!entryByFlightCompany && (
                <div style={{ marginBottom: 20 }}>
                  {state.fetchedData?.attributes?.document?.data && (
                    <>
                      <Form.Item
                        label={<Typography.Title level={5}>कागजात प्रमाणित छ</Typography.Title>}
                        name="isDocumentVerified"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                      <Divider />
                    </>
                  )}
                </div>
              )}
              <Typography.Title level={4} className="he-subtitle" style={{ marginTop: 5 }}>
                कागजात
              </Typography.Title>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {state.fetchedData?.attributes?.document?.data && (
                  <a
                    href={getFilePath(state.fetchedData?.attributes?.document.data?.attributes?.url)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PdfViewer fileUrl={getFilePath(state.fetchedData?.attributes?.document.data?.attributes?.url)} />
                  </a>
                )}
              </div>
              {entryByFlightCompany && state.fetchedData?.attributes?.document?.data ? null : (
                <Space style={{ marginTop: 10, marginBottom: 20 }}>
                  <SelfControlModal
                    anchorElement={
                      <Button type="primary" icon={<CloudUploadOutlined />} disabled={state.loading}>
                        Upload कागजात
                      </Button>
                    }
                    title="Upload कागजात"
                    width={1000}
                    ref={refs.uploadDocumentModalRef}
                  >
                    <UploadDocument
                      record={state.fetchedData}
                      modalRef={refs.uploadDocumentModalRef}
                      ifForFlightPermission={true}
                    />
                  </SelfControlModal>
                </Space>
              )}
            </div>
            <Divider />
            {!entryByFlightCompany && (
              <div>
                <div style={{ marginBottom: 20 }}>
                  <div style={{ marginBottom: 20 }}>
                    <Typography.Title level={4} className="he-subtitle">
                      अन्तिम पत्र
                    </Typography.Title>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 20,
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {state.fetchedData?.attributes?.finalLetters?.data?.map?.((t: any, i: number) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          key={i}
                        >
                          <a
                            href={getFilePath(t?.attributes?.pdfLetter?.data?.attributes?.url)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <PdfViewer fileUrl={getFilePath(t?.attributes?.pdfLetter?.data?.attributes?.url)} />
                          </a>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <span style={{ marginRight: 5 }}>चलानी नं.</span>
                            <span>{t?.attributes?.chalaniNo || "--"}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <Space style={{ marginTop: 10, marginBottom: 20 }}>
                    <Button
                      type="primary"
                      icon={<CloudDownloadOutlined />}
                      disabled={state.loading}
                      loading={state.generateFinalLetterLoading}
                      onClick={generateFinalLetter}
                    >
                      Generate Final Letter
                    </Button>
                    <SelfControlModal
                      anchorElement={
                        <Button type="primary" icon={<CloudUploadOutlined />} disabled={state.loading}>
                          Upload Final Letter
                        </Button>
                      }
                      title="Upload Final Letter"
                      width={1000}
                      ref={refs.uploadFinalLetterModalRef}
                    >
                      <UploadFinalLetter record={state.fetchedData} modalRef={refs.uploadFinalLetterModalRef} />
                    </SelfControlModal>
                  </Space>
                </div>
              </div>
            )}
          </Form>
        </Card>
      </div>

      <SelfControlModal title="Final Letter EDITOR" width={"80%"} ref={refs.editFinalLetterModalRef}>
        <Editor html={state.rawHTML} />
      </SelfControlModal>
    </div>
  )
}
