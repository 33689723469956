import { AppWebRequest } from "../NetworkManager";
import { AxiosRequestConfig } from "axios";

class NotificationHttpService {
  fetchNotifications(config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {};
    const { sort, populate, ...otherParams } = params || {};
    return AppWebRequest({
      method: "get",
      url: "/notifications",
      ...axiosConfig,
      params: {
        sort: sort || ["updatedAt:desc"],
        populate: populate || [
          "linkedUser",
          "linkedRecord",
          "linkedFlightPermission",
        ],
        ...otherParams,
      },
    });
  }

  fetchTopRecentNotifications(config: AxiosRequestConfig<any> = {}) {
    config.params = {
      ...config.params,
      pagination: {
        limit: 10,
      },
    };
    return this.fetchNotifications(config);
  }

  createNotification(payload: {
    title: string;
    description: string;
    notificationFor: "Record" | "Flight Permission" | "Other";
    linkedUser?: string;
    linkedRecord?: string;
    linkedFlightPermission?: string;

  },sendEmailToAdmin?:boolean) {
    return AppWebRequest({
      method: "post",
      url: `/notifications?sendEmailToAdmin=${sendEmailToAdmin}`,
      data: { data: payload },
    })
  }

  updateNotification(id: string, payload: Record<string, any>) {
    return AppWebRequest({
      method: "put",
      url: `/notifications/${id}`,
      data: { data: payload },
    });
  }

  deleteNotification(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/notifications/${id}`,
    });
  }

  markAsRead(id: string) {
    return AppWebRequest({
      method: "put",
      url: `/notifications/${id}`,
      data: {
        data: {
          isRead: true,
        },
      },
    });
  }
}

const notificationHttpService = new NotificationHttpService();
export default notificationHttpService;
