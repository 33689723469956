import { createSlice } from '@reduxjs/toolkit'
import { fetchDepartments,createDepartment,updateDepartment,deleteDepartment } from './departmentsThunk'

// Define a type for the slice state
export interface DepartmentState {
    entities: any[]
    loading: boolean
    error:any
}


// Define the initial state using that type
const initialState: DepartmentState = {
    entities: [],
    loading: false,
    error:null
}

export const departmentsSlice = createSlice({
  name: 'departments',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
  },
  extraReducers:(builder)=> {
    //Fetch
      builder.addCase(fetchDepartments.pending,(state,action)=>{
        state.loading=true
        state.error=null
      })
      builder.addCase(fetchDepartments.fulfilled,(state,action)=>{
        state.loading=false
        state.entities=action.payload.data as []
      })
      builder.addCase(fetchDepartments.rejected,(state,action)=>{
        state.loading=false
        state.error=action.error
      })

      //Create
      builder.addCase(createDepartment.fulfilled,(state,action)=>{
        state.entities.push(action.payload.data)
      })

      //Update
      builder.addCase(updateDepartment.fulfilled,(state,action)=>{
        const updatedTemplated = action?.payload?.data as any
        const index = state.entities.findIndex(f=>f?.id===updatedTemplated?.id)
        if(index!==-1){
            state.entities[index]=updatedTemplated
        }
      })

      //Delete
      builder.addCase(deleteDepartment.fulfilled,(state,action)=>{
        const updatedTemplated = action?.payload?.data as any
        const index = state.entities.findIndex(f=>f?.id===updatedTemplated?.id)
        if(index!==-1){
            state.entities.splice(index,1)
        }
      })
  },
})

export const departmentsReducer = departmentsSlice.reducer