import {
  Button,
  Card,
  Popconfirm,
  Space,
  Table,
  theme,
  Typography,
} from "antd";
import React, { createRef, ReactElement, useState } from "react";

import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExportOutlined,
  ReloadOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import CreateORUpdateDepartmentModal, {
  CreateORUpdateDepartmentModalRefsHandler,
} from "./components/createORupdateModal/createORupdateModal";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  deleteDepartment,
  fetchDepartments,
} from "../../../../features/departments/departmentsThunk";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import UnderlineTypography from "../../../../components/common/underlineTypography";

interface DepartmentProps {}

export default function Department({}: DepartmentProps): ReactElement {
  const { token } = theme.useToken();
  const dispatch = useAppDispatch();
  const departments = useAppSelector((state) => state.departments);
  const createUpdateModalRef =
    createRef<CreateORUpdateDepartmentModalRefsHandler>();

  const renderTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
          flexWrap: "wrap",
        }}
      >
        <Button
          type="dashed"
          icon={<ReloadOutlined />}
          onClick={() => {
            dispatch(fetchDepartments());
          }}
          disabled={departments.error || departments.loading}
        >
          रिफ्रेस गर्नुहोस्
        </Button>
        <Space wrap>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => {
              createUpdateModalRef.current?.setState({ open: true });
            }}
          >
            थप्नुहोस्
          </Button>
          {/* <Button
            type="dashed"
            icon={<FilterOutlined />}
            disabled={departments.error || departments.loading}
          >
            फिल्टर गर्नुहोस्
          </Button> */}
          {/* <Button
            type="dashed"
            icon={<ExportOutlined />}
            disabled={departments.error || departments.loading}
          >
            निर्यात गर्नुहोस्
          </Button> */}
        </Space>
      </div>
    );
  };

  const renderTable = () => {
    if (departments.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>
            {departments.error?.message || "Error occured"}
          </Typography>
          <Button type="primary" style={{ backgroundColor: "red" }}>
            Try Again
          </Button>
        </Card>
      );
    }

    if (departments.loading) {
      return (
        <Card>
          {renderTableHeader()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      );
    }

    const dataSource = departments.entities.map((f) => ({
      id: f?.id,
      name: f?.attributes?.name,
    }));

    const columns = [
      {
        title: "नाम",
        dataIndex: "name",
        key: "name",
        width: "80%",
      },
      {
        title: "Actions",
        key: "action",
        width: "20%",
        render: (_: any, record: any) => (
          <Space size="middle">
            <EditOutlined
              style={{ cursor: "pointer", color: token.colorWarning }}
              onClick={() => {
                createUpdateModalRef.current?.setState({
                  open: true,
                  department: record,
                  action: "update",
                });
              }}
            />
            <DeletePopConfirmed department={record} />
          </Space>
        ),
      },
    ];

    return (
      <Card>
        {renderTableHeader()}
        <Table
          dataSource={dataSource}
          scroll={{ x: "auto" }}
          columns={columns}
        />

        <CreateORUpdateDepartmentModal ref={createUpdateModalRef} />
      </Card>
    );
  };
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <UnderlineTypography>विभागहरू</UnderlineTypography>
      </div>
      {renderTable()}
    </div>
  );
}

function DeletePopConfirmed({ department }: { department: any }) {
  const dispatch = useAppDispatch();
  const { token } = theme.useToken();
  const [deleteState, setDeleteState] = useState({
    openPopConfirmed: false,
    deleting: false,
  });
  return (
    <Popconfirm
      title="Really want to delete?"
      onConfirm={() => {
        setDeleteState({ ...deleteState, deleting: true });
        dispatch(deleteDepartment(department?.id))
          .unwrap()
          .then((res) => {
            setDeleteState({
              ...deleteState,
              deleting: false,
              openPopConfirmed: false,
            });
            toast.success("Deleted");
          })
          .catch((err) => {
            setDeleteState({
              ...deleteState,
              deleting: false,
              openPopConfirmed: false,
            });
            toast.error(err?.message || "Error deleting");
          });
      }}
      open={deleteState.openPopConfirmed}
      okButtonProps={{ loading: deleteState.deleting }}
      onCancel={() => {
        setDeleteState({ ...deleteState, openPopConfirmed: false });
      }}
    >
      <DeleteOutlined
        style={{ cursor: "pointer", color: token.colorError }}
        onClick={() => {
          setDeleteState({ ...deleteState, openPopConfirmed: true });
        }}
      />
    </Popconfirm>
  );
}
