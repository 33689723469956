export function EngToNep(englishWord: string): string {
  const dictionary: { [key: string]: string } = {
    fullName: "नाम/थर",
    country: "देश",
    gender: "लिङ्ग",
    passportNo: "राहदानी नं",
    dob: "जन्म मिती",
    fromVisaDuration: "भिसा अबधि (देखि)",
    designation: "पदनाम",
    fromTrekkingDuration: "पदयात्रा अबधि (देखि)",
    toTrekkingDuration: "पदयात्रा अबधि (सम्म)",
    trekkingDistrict: "जिल्ला",
    trekkingLocation: "पदयात्रा क्षेत्र",
    toVisaDuration: "भिसा अबधि (सम्म)",
    purposeOfVisit: "भ्रमण प्रयोजन",
    nepalEntryPoint: "नेपाल प्रवेश बिन्दु",
    diplomaticBody: "कुटनीतिक निकाय",
    dateOfCorrespondenceForConduct: "चालचलनको लागि पत्राचार भएको मिती",
    dateOfReplay: "जवाफ आएको मिती",
    deportionPeriod: "निष्काशन अबधि",
    deportionReason: "निष्काशन कारण",
    ministryDecisionDate: "मन्त्रालयको निर्णय मिती",
    dateOfCorrespondence: "पत्राचार हुने मिती",
    labourConcurranceDate: "कार्य सहमति मिती",
    fromLabourConcurranceDate: "कार्य सहमति मिती (देखि)",
    toLabourConcurranceDate: "कार्य सहमति मिती (सम्म)",
    childFullNameNepali: "नाम / थर (नेपालीमा)",
    childFullNameEnglish: "नाम / थर (English)",
    childDobEnglish: "जन्म मिति (English)",
    childDobNepali: "जन्म मिति (नेपालीमा)",
    childMotherFullNameNepali: "आमाको नाम / थर (नेपालीमा)",
    childMotherFullNameEnglish: "आमाको नाम / थर (English)",
    childMotherPassportNo: "आमाको राहधानी नं.",
    childMotherCitizenshipNo: "आमाको नागरिकता नं.",
    childMotherFatherFullName: "आमाको बुबाको नाम / थर",
    childMotherGFatherFullName: "आमाको बाजेको नाम / थर",
    childMotherPermaAddress: "आमाको स्थायी ठेगाना",
    childFatherFullNameNepali: "बुबाको नाम / थर (नेपालीमा)",
    childFatherFullNameEnglish: "बुबाको नाम / थर (English)",
    childFatherPassportNo: "बुबाको राहधानी नं.",
    childFatherCitizenshipNo: "बुबाको नागरिकता नं.",
    childFatherFatherFullName: "बुबाको बुबाको नाम / थर",
    childFatherGFatherFullName: "बुबाको बाजेको नाम / थर",
    childFatherPermaAddress: "बुबाको स्थायी ठेगाना",
    childFatherCountry: "बुबा रहेको देश",
  }
  return dictionary[englishWord]
}
