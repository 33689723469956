import React, { useEffect, useState } from "react";
import { Dna } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import { setUserAuth, USERAUTHSESSIONKEY } from "./features/auth/authSlice";
import { useAppDispatch } from "./hooks";
import AppRoutes from "./routes";

function App() {
  const dispatch = useAppDispatch();
  const [sessionLoading, setSessionLoading] = useState(true);
  const getAndSetInitialSession = () => {
    try {
      var userSessionJson:any = {};
      const userSession = sessionStorage.getItem(USERAUTHSESSIONKEY);
      if (userSession) {
        userSessionJson = JSON.parse(userSession);
        dispatch(setUserAuth(userSessionJson));
      }
      setSessionLoading(false);
    } catch (error) {
      setSessionLoading(false);
    }
  };
  useEffect(() => {
    getAndSetInitialSession();
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      {sessionLoading ? (
        <div
          style={{
            height: "85vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection:'column'
          }}
        >
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
          <div>Validating Session...</div>
        </div>
      ) : (
        <AppRoutes />
      )}
      <ToastContainer
        position="top-center"
        newestOnTop
        hideProgressBar
        autoClose={3000}
        pauseOnFocusLoss={false}
      />
    </React.Fragment>
  );
}

export default App;
