import { ReloadOutlined } from "@ant-design/icons"
import { Space, Spin, Tag } from "antd"
import { useAsyncRetry } from "react-use"
import flightPermissionHttpService from "../../../../../../services/https/apis/flightpermission.http.service"
export type FlightPermissionStatisticsProps = {
  fromDate: string
  toDate: string
}

export default function FlightPermissionStatistics({ fromDate, toDate }: FlightPermissionStatisticsProps) {
  const state = useAsyncRetry<{ totalPassengersEntries: number; totalShuttleCount: number }>(async () => {
    const response = await flightPermissionHttpService.getFlightPermissionStatistics({
      filters: {
        $and: [
          {
            createdAt: {
              $gte: fromDate,
            },
          },
          {
            createdAt: {
              $lte: toDate,
            },
          },
        ],
      },
    })
    return response
  }, [])

  if (state.loading) {
    return (
      <Space>
        <Tag color="blue">
          जम्मा यात्रु संख्या: <Spin size="small" />
        </Tag>
        <Tag color="blue">
          जम्मा शटल संख्या: <Spin size="small" />
        </Tag>
      </Space>
    )
  }
  if (state.error) {
    return (
      <Space>
        <Tag color="blue">
          जम्मा यात्रु संख्या:{" "}
          <ReloadOutlined title="Click to retry" style={{ cursor: "pointer", color: "red" }} onClick={state.retry} />
        </Tag>
        <Tag color="blue">
          जम्मा शटल संख्या:{" "}
          <ReloadOutlined title="Click to retry" style={{ cursor: "pointer", color: "red" }} onClick={state.retry} />
        </Tag>
      </Space>
    )
  }
  return (
    <Space>
      <Tag color="blue">जम्मा यात्रु संख्या: {state?.value?.totalPassengersEntries || 0}</Tag>
      <Tag color="blue">जम्मा शटल संख्या: {state?.value?.totalShuttleCount || 0}</Tag>
    </Space>
  )
}
