import { Button, Form, Input, Modal, Space } from "antd";
import React, {
  ReactElement,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useAppDispatch } from "../../../../../../hooks";
import {
  createDepartment,
  updateDepartment,
} from "../../../../../../features/departments/departmentsThunk";
import { toast } from "react-toastify";
interface CreateORUpdateDepartmentModalProps {}

interface CreateORUpdateDepartmentModalState {
  open?: boolean;
  department?: Record<string, any>;
  action?: "create" | "update";
  creatingOrUpdating?: boolean;
}

export interface CreateORUpdateDepartmentModalRefsHandler {
  setState: (comState: Partial<CreateORUpdateDepartmentModalState>) => void;
}

const initialState: CreateORUpdateDepartmentModalState = {
  open: false,
  department: undefined,
  action: "create",
  creatingOrUpdating: false,
};

const CreateORUpdateDepartmentModal = forwardRef<
  CreateORUpdateDepartmentModalRefsHandler,
  CreateORUpdateDepartmentModalProps
>(({}: CreateORUpdateDepartmentModalProps, ref) => {
  const [comState, setComState] =
    useState<CreateORUpdateDepartmentModalState>(initialState);

  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => ({
    setState: (comStateArg: Partial<CreateORUpdateDepartmentModalState>) => {
      setComState({ ...comState, ...comStateArg });
    },
  }));

  const onFinishedCreateOrUpdate = (values: any) => {
    if (action === "create") {
      dispatch(createDepartment({ name: values?.name }))
        .unwrap()
        .then((res) => {
          toast.success("Created successfully");
          closeDialog()
        })
        .catch((err) => {
          toast.error(err?.message || "Error creating department");
        });
    } else {
      dispatch(
        updateDepartment({
          id: comState.department?.id,
          payload: { name: values?.name },
        })
      )
        .unwrap()
        .then((res) => {
          toast.success("Updated successfully");
        })
        .catch((err) => {
          toast.error(err?.message || "Error updating department");
        });
    }
  };

  const closeDialog = () => {
    setComState({ ...comState, ...initialState });
  };

  const { department, open, action, creatingOrUpdating } = comState;
  if (!open) {
    return null;
  }
  return (
    <Modal
      title={`विभाग ${action === "create" ? "थप्नुहोस्" : "सम्पादन गर्नुहोस्"}`}
      centered
      open={open}
      footer={null}
      onCancel={closeDialog}
      maskClosable={false}
      keyboard={false}
    >
      {open && (
        <Form
          initialValues={{ name: department?.name || "" }}
          onFinish={onFinishedCreateOrUpdate}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="नाम"
            style={{ userSelect: "none" }}
            rules={[{ required: true, message: "Can not be empty" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="dashed" onClick={closeDialog}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={creatingOrUpdating}
              >
                {action == "update" ? "Update" : "Create"}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
});
export default CreateORUpdateDepartmentModal;
