import { createAsyncThunk } from "@reduxjs/toolkit";
import usersHttpService from "../../services/https/apis/users.http.service";
export const fetchUsers = createAsyncThunk(
  "employees/fetchUsers",
  async () => {
    return usersHttpService.fetchUsers({
      params: {
        filters: {
          role: {
            type: {
              $notIn: ["employee","thirdparty"],
            },
          },
        },
        populate:"*"
      },
    });
  }
);

export const createUser = createAsyncThunk(
  "employees/createUser",
  async (payload: Record<string, any>,{dispatch,rejectWithValue}) => {
    return usersHttpService.createUser(payload)
    .then(res=>{
      dispatch(fetchUsers())
      return res
    })
    .catch(err=>{
      console.log("Error *****",err)
      return rejectWithValue(err)
    })
  }
);

export const updateUser = createAsyncThunk(
  "employees/updateUser",
  async (updatePayload:{id: string, payload: Record<string, any>},ThunkAPI) => {
    return usersHttpService.updateUser(updatePayload.id, updatePayload.payload)
    .then(res=>{
      ThunkAPI.dispatch(fetchUsers())
      return res
    })
  }
);

export const deleteUser = createAsyncThunk(
  "employees/deleteUser",
  async (id: string,ThunkAPI) => {
    return usersHttpService.deleteUser(id)
    .then(res=>{
      ThunkAPI.dispatch(fetchUsers())
      return res
    })
  }
);
