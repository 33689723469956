import { createSlice } from '@reduxjs/toolkit'
import { fetchRoles,createRole,updateRole,deleteRole } from './rolesThunk'

// Define a type for the slice state
export interface RoleState {
    entities: any[]
    loading: boolean
    error:any
}


// Define the initial state using that type
const initialState: RoleState = {
    entities: [],
    loading: false,
    error:null
}

export const rolesSlice = createSlice({
  name: 'roles',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
  },
  extraReducers:(builder)=> {
    //Fetch
      builder.addCase(fetchRoles.pending,(state,action)=>{
        state.loading=true
        state.error=null
      })
      builder.addCase(fetchRoles.fulfilled,(state,action)=>{
        state.loading=false
        state.entities=action.payload.roles as []
      })
      builder.addCase(fetchRoles.rejected,(state,action)=>{
        state.loading=false
        state.error=action.error
      })

      //Create
      builder.addCase(createRole.fulfilled,(state,action)=>{
        state.entities.push(action.payload)
      })

      //Update
      builder.addCase(updateRole.fulfilled,(state,action)=>{
        const updatedTemplated = action?.payload as any
        const index = state.entities.findIndex(f=>f?.id===updatedTemplated?.id)
        if(index!==-1){
            state.entities[index]=updatedTemplated
        }
      })

      //Delete
      builder.addCase(deleteRole.fulfilled,(state,action)=>{
        const updatedTemplated = action?.payload as any
        const index = state.entities.findIndex(f=>f?.id===updatedTemplated?.id)
        if(index!==-1){
            state.entities.splice(index,1)
        }
      })
  },
})

export const rolesReducer = rolesSlice.reducer