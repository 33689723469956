import { Typography } from 'antd';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DataFetchStateComponent from '../../../../../../../components/common/datafetchstatecomponent';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';
import { fetchUsersRolesStatisticsData } from '../../../../../../../features/statistics/statisticsThunk';
import { hexToRgb } from '../../../../../../../utils/general.utils';

ChartJS.register(ArcElement, Tooltip, Legend);

interface UsersRoleChartProps {}
const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'User Role',
      },
    },
  };

export default function UsersRoleChart(props: UsersRoleChartProps) {
    const { usersRole } = useAppSelector(
        (state) => state.statistics
      );
      const dispatch = useAppDispatch();

    const data = {
        labels: usersRole?.entities?.map?.(f=>f.roleName),
        datasets: [
          {
            label: '# of Users',
            data: usersRole?.entities?.map?.(f=>f.userCount),
            backgroundColor: [
                hexToRgb('#1677ff',0.2),
                hexToRgb('#faad14',0.2),
                hexToRgb('#52c41a',0.2),
                hexToRgb('#ff0000',0.2),
                hexToRgb('#9900ff',0.2),
                hexToRgb('#ff00ff',0.2),
                hexToRgb('#ff0080',0.2),
            ],
            borderColor: [
                hexToRgb('#1677ff',1),
                hexToRgb('#faad14',1),
                hexToRgb('#52c41a',1),
                hexToRgb('#ff0000',0.2),
                hexToRgb('#9900ff',0.2),
                hexToRgb('#ff00ff',0.2),
                hexToRgb('#ff0080',0.2),
            ],
            borderWidth: 1,
          },
        ],
      };
  return (
    <div>
        <Typography.Title style={{textAlign:'center'}} level={5}>Users & Roles</Typography.Title>
        <DataFetchStateComponent
        loading={usersRole.loading}
        error={Boolean(usersRole.error)}
        errorMessage={usersRole.error?.message}
        onClickTryAgain={() => {
          dispatch(fetchUsersRolesStatisticsData());
        }}
      >
        <Doughnut options={options} data={data} />
      </DataFetchStateComponent>

    </div>
  )
}