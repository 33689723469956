import { UploadOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Row, Space } from "antd"
import Upload from "antd/es/upload/Upload"
import { useState } from "react"
import { toast } from "react-toastify"
import LastUsedChalaniViewer from "../../../../../../components/common/lastUsedChalaniViewer"
import { SelfControlModalRefsHandler } from "../../../../../../components/common/selfcontrolmodal/index"
import recordHttpService from "../../../../../../services/https/apis/records.http.service"

type UploadPoliceVerificationProps = {
  record: any
  modalRef: React.RefObject<SelfControlModalRefsHandler>
  fetchByIdAndSetState?: () => void
}

export default function UploadPoliceVerification({
  record,
  modalRef,
  fetchByIdAndSetState,
}: UploadPoliceVerificationProps) {
  const [state, setState] = useState<{
    loading: boolean
    verificationFile: any
  }>({
    loading: false,
    verificationFile: null,
  })

  const [form] = Form.useForm()

  const onFinishForm = (values: any) => {
    if (!values?.verificationFile) {
      return toast.info("Please select verification file to upload ")
    }
    const formData = new FormData()
    formData.append("files.pdfVerification", values?.verificationFile?.file, values?.verificationFile?.name?.file || "")
    formData.append("data", JSON.stringify({ chalaniNo: values?.chalaniNo, record: record.id, fiscalYear: '2080-2081' }))
    setState({ ...state, loading: true })
    recordHttpService
      .uploadPoliceVerification(record.id, formData)
      .then((res) => {
        setState({ ...state, loading: false })
        toast.success("Uploaded")
        fetchByIdAndSetState?.()
        modalRef?.current?.hide?.()
      })
      .catch((err) => {
        // console.log("Error", err)
        setState({ ...state, loading: false })
        toast.error(err?.message || "Error uploading")
      })
  }

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          chalaniNo: "",
          verificationFile: null,
        }}
        onFinish={onFinishForm}
      >
        <Row gutter={10}>
          <Col xs={24}>
            <Form.Item
              label="चलानी नं."
              name="chalaniNo"
              rules={[{ required: true, message: "Can not be empty" }]}
              help={<LastUsedChalaniViewer />}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="Verification File"
              name="verificationFile"
              rules={[{ required: true, message: "Can not be empty" }]}
            >
              <Upload
                multiple={false}
                beforeUpload={() => false}
                fileList={
                  form.getFieldValue("verificationFile")?.file ? [form.getFieldValue("verificationFile")?.file] : []
                }
                onChange={({ file }) => {
                  setState({ ...state, verificationFile: file })
                }}
                accept="application/pdf"
              >
                <Button icon={<UploadOutlined />}>Click to Select File</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" htmlType="submit" loading={state.loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}
