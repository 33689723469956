import { ReactElement, useEffect, useState } from "react"
import CountStats from "../../../../components/stats/count"
import recordHttpService from "../../../../services/https/apis/records.http.service"
interface RecordsCountStatsProps {}

export default function RecordsCountStats({}: RecordsCountStatsProps): ReactElement {
  const [state, setState] = useState({
    loading: true,
    error: null,
    count: 0,
  })

  const fetchCount = () => {
    setState({ ...state, loading: true, error: null })
    recordHttpService
      .countRecords()
      .then((res) => {
        setState({ ...state, loading: false, count: res })
      })
      .catch((err) => {
        console.log("err", err)
        setState({ ...state, loading: false, error: err })
      })
  }

  useEffect(() => {
    fetchCount()
  }, [])

  return (
    <CountStats
      title="रेकर्डहरु"
      count={state.count}
      error={state.error}
      loading={state.loading}
      hideNavigate
      onClickTryAgain={fetchCount}
    />
  )
}
