import UnderlineTypography from "../../../../components/common/underlineTypography";
import ListBibidhTemplates from "../bibidhTemplates/list";
import { Tabs } from "antd";
import LinkedBibidhs from "../linkedBibidh";
import PdfViewer from "../../../../components/common/pdfviewer";

interface BibidhHomeProps {}

export default function BibidhHome(props: BibidhHomeProps) {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <UnderlineTypography>बिबिधहरु</UnderlineTypography>
      </div>
      <Tabs
      defaultActiveKey="1"
      items={[
        {
          key:'1',
          label:"टेम्प्लेटहरु",
          children:<ListBibidhTemplates />
        },
        {
          key:'2',
          label:"अपलोड बिबिधहरु",
          children:<LinkedBibidhs />
        },
      ]}
      />
    </div>
  );
}
