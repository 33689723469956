import {
  Button,
  Card,
  Space,
  Table,
  theme,
  Typography,
} from "antd";
import React, { createRef, ReactElement, useEffect } from "react";

import {
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import CreateORUpdateUIPolicyModal, {
  CreateORUpdateUIPolicyModalRefsHandler,
} from "./components/createORupdateModal/createORupdateModal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteUIPolicy,
  fetchUIPolicies,
} from "../../../features/uipolicies/uipoliciesThunk";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import DeletePopConfirmed from "../../../components/common/deletepopconfirmed";
import UnderlineTypography from "../../../components/common/underlineTypography";

interface UIPolicyProps {}

export default function UIPolicy({}: UIPolicyProps): ReactElement {
  const { token } = theme.useToken();
  const dispatch = useAppDispatch();
  const uipolicies = useAppSelector((state) => state.uipolicies);
  const createUpdateModalRef =
    createRef<CreateORUpdateUIPolicyModalRefsHandler>();

  useEffect(() => {}, []);

  const renderTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
          flexWrap: "wrap",
        }}
      >
        <Button
          type="dashed"
          icon={<ReloadOutlined />}
          onClick={() => {
            dispatch(fetchUIPolicies());
          }}
          disabled={uipolicies.error || uipolicies.loading}
        >
          रिफ्रेस गर्नुहोस्
        </Button>
        <Space wrap>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => {
              createUpdateModalRef.current?.setState({ open: true });
            }}
          >
            थप्नुहोस्
          </Button>
          {/* <Button
            type="dashed"
            icon={<FilterOutlined />}
            disabled={uipolicies.error || uipolicies.loading}
          >
            फिल्टर गर्नुहोस्
          </Button> */}
          {/* <Button
            type="dashed"
            icon={<ExportOutlined />}
            disabled={uipolicies.error || uipolicies.loading}
          >
            निर्यात गर्नुहोस्
          </Button> */}
        </Space>
      </div>
    );
  };

  const renderTable = () => {
    if (uipolicies.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>
            {uipolicies.error?.message || "Error occured"}
          </Typography>
          <Button type="primary" style={{ backgroundColor: "red" }}>
            Try Again
          </Button>
        </Card>
      );
    }

    if (uipolicies.loading) {
      return (
        <Card>
          {renderTableHeader()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      );
    }

    const dataSource = uipolicies.entities.map((f) => ({
      id: f?.id,
      name: f?.attributes?.name,
    }));

    const columns = [
      {
        title: "नाम",
        dataIndex: "name",
        key: "name",
        width: "80%",
      },
      {
        title: "कार्यहरु",
        key: "action",
        width: "20%",
        render: (_: any, record: any) => (
          <Space size="middle">
            <EditOutlined
              style={{ cursor: "pointer", color: token.colorWarning }}
              onClick={() => {
                const policy = uipolicies?.entities?.find(
                  (f) => f?.id === record?.id
                );
                createUpdateModalRef.current?.setState({
                  open: true,
                  uipolicy: policy,
                  action: "update",
                });
              }}
            />
            <DeletePopConfirmed
              title="Really want to delete?"
              actionPromiseFunction={async () => {
                return dispatch(deleteUIPolicy(record?.id))
                  .unwrap()
                  .then((res) => {
                    toast.success("Deleted successfully");
                    return res;
                  })
                  .catch((err) => {
                    toast.error(err?.message || "Error deleting");
                    return Promise.reject(err);
                  });
              }}
            />
          </Space>
        ),
      },
    ];

    return (
      <Card>
        {renderTableHeader()}
        <Table
          dataSource={dataSource}
          scroll={{ x: "auto" }}
          columns={columns}
        />

        <CreateORUpdateUIPolicyModal ref={createUpdateModalRef} />
      </Card>
    );
  };
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <UnderlineTypography>प्रयोगकर्ता इन्टरफेस नीतिहरू</UnderlineTypography>
      </div>
      {renderTable()}
    </div>
  );
}
