import { AxiosRequestConfig } from "axios"
import qs from "qs"
import { RecordStatus } from "../../../types/typings.d"
import { AppWebRequest } from "../NetworkManager"
import auditLogsHttpService from "./auditlog.http.service"

class RecordHttpService {
  fetchRecords(queryString?: string, config?: AxiosRequestConfig<any>) {
    const parsedQs: Record<string, any> = queryString ? qs.parse(queryString) : {}
    const { params, ...axiosConfig } = config || {}
    const { sort = [], populate = [], filters = {}, ...otherParams } = params || {}

    return AppWebRequest({
      method: "get",
      url: `/records`,
      ...axiosConfig,
      params: {
        sort: parsedQs?.sort || ["updatedAt:desc", ...sort],
        populate: parsedQs?.populate || [
          "enteredBy",
          "document",
          "passportDocument",
          "labourConcurranceDocument",
          "finalLetters",
          "finalLetters.pdfLetter",
          "uploadedTippanies",
          "usrEntries",
          "usrEntries.passportImage",
          "usrEntries.childImage",
          "pharchyotPerson",
          "applicantBody",
          ...populate,
        ],
        pagination: parsedQs?.pagination,
        filters: parsedQs?.filters || filters,
        ...otherParams,
      },
    })
  }

  // TODO: fetch records for export in csv
  fetchRecordsForExport(queryString?: string, config?: AxiosRequestConfig<any>) {
    const parsedQs: Record<string, any> = queryString ? qs.parse(queryString) : {}
    const { params, ...axiosConfig } = config || {}
    const { sort = [], populate = [], filters = {}, ...otherParams } = params || {}

    return AppWebRequest({
      method: "get",
      url: `/records`,
      ...axiosConfig,
      params: {
        sort: parsedQs?.sort || ["updatedAt:desc", ...sort],
        populate: parsedQs?.populate || [
          "enteredBy",
          "document",
          "passportDocument",
          "labourConcurranceDocument",
          "finalLetters",
          "finalLetters.pdfLetter",
          "uploadedTippanies",
          "usrEntries",
          "usrEntries.passportImage",
          "usrEntries.childImage",
          "pharchyotPerson",
          "applicantBody",
          ...populate,
        ],
        pagination: parsedQs?.pagination,
        filters: parsedQs?.filters || filters,
        ...otherParams,
      },
    })
  }

  fetchRecordById(id: string, queryString?: string, config?: AxiosRequestConfig<any>) {
    const parsedQs: Record<string, any> = queryString ? qs.parse(queryString) : {}
    const { params, ...axiosConfig } = config || {}
    const { sort = [], populate = [], filters = {}, ...otherParams } = params || {}
    return AppWebRequest({
      method: "get",
      url: `/records/${id}`,
      ...axiosConfig,
      params: {
        sort: parsedQs?.sort || sort,
        populate: parsedQs?.populate || [
          "enteredBy",
          "document",
          "passportDocument",
          "labourConcurranceDocument",
          "finalLetters",
          "finalLetters.pdfLetter",
          "uploadedTippanies",
          "usrEntries",
          "usrEntries.passportImage",
          "usrEntries.childImage",
          "pharchyotPerson",
          "applicantBody",
          ...populate,
        ],
        filters: parsedQs?.filters || filters,
        ...otherParams,
      },
    })
  }

  async createRecord(payload: Record<string, any>) {
    const { sendLinkToEntryForm, ...payloadData } = payload
    return AppWebRequest({
      method: "post",
      url: `/records?sendLinkToEntryForm=${sendLinkToEntryForm ? true : false}`,
      data: { data: payloadData },
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Record Created",
        description: `New Record Created`,
        modalName: "Record",
        record: res?.id,
      })
      return res
    })
  }

  async updateRecord(id: string, payload: Record<string, any>) {
    return AppWebRequest({
      method: "put",
      url: `/records/${id}`,
      data: { data: payload },
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Record Updated",
        description: `Existing Record Updated`,
        modalName: "Record",
        record: id,
      })
      return res
    })
  }

  createUserEntriesWithEntryToken(id: string, payload: Record<string, any>, entryToken: string) {
    return AppWebRequest({
      method: "post",
      url: `/records/${id}/create-entries-with-token?token=${entryToken}`,
      data: payload,
    })
  }

  addDocumentWithDocumentToken(id: string, payload: FormData, entryToken: string) {
    return AppWebRequest({
      method: "post",
      url: `/records/${id}/add-document-with-token?token=${entryToken}`,
      data: payload,
    })
  }

  sendDocumentRequestForm(id: string) {
    return AppWebRequest({
      method: "get",
      url: `/records/${id}/send-document-request-form`,
    })
  }

  sendUserEntryRequestForm(id: string) {
    return AppWebRequest({
      method: "get",
      url: `/records/${id}/send-userentry-request-form`,
    })
  }

  deleteRecord(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/records/${id}`,
    })
  }

  countRecords(querySting?: string) {
    return AppWebRequest({
      method: "get",
      url: querySting ? `/records/count?${querySting}` : `/records/count`,
    })
  }

  getRecordStatistics(params?: Record<string, any>) {
    return AppWebRequest({
      method: "get",
      url: `/records/count/userEntriesCount`,
      params: params,
    })
  }

  async uploadTipaani(id: string, formData: FormData) {
    return AppWebRequest({
      method: "put",
      url: `/records/${id}`,
      data: formData,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Record Tipaani Uploaded",
        description: `Record Tipaani Uploaded`,
        modalName: "Record",
        record: id,
      })
      return res
    })
  }

  async uploadDocument(id: string, formData: FormData) {
    return AppWebRequest({
      method: "put",
      url: `/records/${id}`,
      data: formData,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Record Document Uploaded",
        description: `Record Document Uploaded`,
        modalName: "Record",
        record: id,
      })
      return res
    })
  }

  async uploadFinalLetter(id: string, formData: FormData) {
    formData.append("record", id)
    return AppWebRequest({
      method: "post",
      url: `/final-letters`,
      data: formData,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Record Status Changed",
        description: `Record Status Changed to ${RecordStatus.COMPLETED}`,
        modalName: "Record",
        record: id,
      })
      auditLogsHttpService.createAuditLog({
        action: "Record Final Letter Uploaded",
        description: `Record Final Letter Uploaded`,
        modalName: "Record",
        record: id,
      })
      return res
    })
  }

  async uploadPoliceVerification(id: string, formData: FormData) {
    formData.append("record", id)
    return AppWebRequest({
      method: "post",
      url: `/police-verifications`,
      data: formData,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Record Police Verification Uploaded",
        description: `Record Police Verification Uploaded`,
        modalName: "Record",
        record: id,
      })
      return res
    })
  }

  async generateTipaani(id: string) {
    return AppWebRequest({
      method: "get",
      url: `/records/${id}/generate-tippani`,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Record Tipaani Generated",
        description: `Record Tipaani Generated`,
        modalName: "Record",
        record: id,
      })
      return res
    })
  }

  async generateFinalLetter(id: string) {
    return AppWebRequest({
      method: "get",
      url: `/records/${id}/generate-finalletter`,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Final Letter Generated",
        description: `Final Letter Generated`,
        modalName: "Record",
        record: id,
      })
      return res
    })
  }

  async generatePoliceVerification(id: string) {
    return AppWebRequest({
      method: "get",
      url: `/records/${id}/generate-policeverification`,
    }).then((res) => {
      auditLogsHttpService.createAuditLog({
        action: "Police Verification Generated",
        description: `Police Verification Generated`,
        modalName: "Record",
        record: id,
      })
      return res
    })
  }
}

const recordHttpService = new RecordHttpService()
export default recordHttpService
