import { Button, Card, Col, Form, Input, Popconfirm, Row, Select, Space, Typography, theme } from "antd"
import dayjs from "dayjs"
import React, { ReactElement } from "react"
import { RecordTypesEnum } from "../../types/typings.d"
import EnDatePicker from "../common/endatepicker"
import NPDatePicker from "../common/npdatepicker"
import SingleImageUploadHandler from "../common/singleImageUploadHandler"
import CountrySelector from "../selectors/countryselector"
import DistrictSelector from "../selectors/districtSelector"
import GenderSelector from "../selectors/genderselector"

interface UserEntryFormRowPropsProps {
  onValuesChange?: (values: any, index: number) => void
  onRemove?: (index: number) => void
  index: number
  onReset?: (index: number) => void
  values?: Record<string, any>
  title?: string
  disabled?: boolean
  loading?: boolean
  fields?: Array<string>
  recordType?: RecordTypesEnum
  hideRemoveButton?: boolean
  hideResetButton?: boolean
}

export default function UserEntryFormRow({
  onValuesChange,
  index,
  onRemove,
  values,
  title,
  disabled,
  loading,
  fields,
  recordType,
  hideRemoveButton,
}: UserEntryFormRowPropsProps): ReactElement {
  const [form] = Form.useForm()
  const { token } = theme.useToken()
  const onChangeValues = (_: any, valuesData: any) => {
    onValuesChange?.(valuesData, index)
  }

  const formFields = [
    {
      name: "fullName",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="नाम/थर: [Name]" name="fullName">
            <Input disabled={disabled || loading} />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "country",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="देश [Country]" name="country">
            <CountrySelector disabled={disabled || loading} />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "gender",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item
            label="लिङ्ग [Gender]"
            name="gender"
            rules={[{ enum: ["Male", "Female", "Other"], message: "Only this" }]}
          >
            <GenderSelector disabled={disabled || loading} />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "passportNo",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="राहदानी नं [Passport No.]" name="passportNo">
            <Input disabled={disabled || loading} />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "dob",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label={"जन्म मिती [Date of Birth - YYYY-MM-DD]"} name="dob">
            <EnDatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            {/* <EnDatePicker style={{ width: "100%" }} format="YYYY-MM-DD" /> */}
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "designation",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="पदनाम [Designation]" name="designation">
            <Input />
          </Form.Item>
        </Col>
      ),
    },

    {
      name: "fromTrekkingDuration",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="पदयात्रा अबधि (देखि)" name="fromTrekkingDuration">
            <NPDatePicker />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "toTrekkingDuration",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="पदयात्रा अबधि (सम्म)" name="toTrekkingDuration">
            <NPDatePicker />
          </Form.Item>
        </Col>
      ),
    },

    {
      name: "trekkingDistrict",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="जिल्ला" name="trekkingDistrict">
            <DistrictSelector disabled={disabled || loading} />
          </Form.Item>
        </Col>
      ),
    },

    {
      name: "trekkingLocation",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="पदयात्रा क्षेत्र" name="trekkingLocation">
            <Input />
          </Form.Item>
        </Col>
      ),
    },

    {
      name: "fromVisaDuration",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="भिसा अबधि (देखि)" name="fromVisaDuration">
            <NPDatePicker />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "toVisaDuration",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="भिसा अबधि (सम्म)" name="toVisaDuration">
            <NPDatePicker />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "purposeOfVisit",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="भ्रमण प्रयोजन" name="purposeOfVisit">
            <Input />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "nepalEntryPoint",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="नेपाल प्रवेश बिन्दु" name="nepalEntryPoint">
            <Input />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "diplomaticBody",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="कुटनीतिक निकाय" name="diplomaticBody">
            <Input />
          </Form.Item>
        </Col>
      ),
    },

    {
      name: "dateOfCorrespondenceForConduct",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="चालचलनको लागि पत्राचार भएको मिती" name="dateOfCorrespondenceForConduct">
            <NPDatePicker />
          </Form.Item>
        </Col>
      ),
    },

    {
      name: "dateOfReplay",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="जवाफ आएको मिती" name="dateOfReplay">
            <NPDatePicker />
          </Form.Item>
        </Col>
      ),
    },

    {
      name: "deportionPeriod",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="निष्काशन अबधि" name="deportionPeriod">
            <NPDatePicker />
          </Form.Item>
        </Col>
      ),
    },

    {
      name: "deportionReason",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="निष्काशन कारण" name="deportionReason">
            <Input />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "ministryDecisionDate",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="मन्त्रालयको निर्णय मिती" name="ministryDecisionDate">
            <NPDatePicker />
          </Form.Item>
        </Col>
      ),
    },

    {
      name: "dateOfCorrespondence",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="पत्राचार हुने मिती" name="dateOfCorrespondence">
            <NPDatePicker />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "labourConcurranceDate",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="कार्य सहमति मिती" name="labourConcurranceDate">
            <EnDatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "fromLabourConcurranceDate",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="कार्य सहमति मिती (देखि)" name="fromLabourConcurranceDate">
            <EnDatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
      ),
    },
    {
      name: "toLabourConcurranceDate",
      component: ({ key }: { key?: string | number }) => (
        <Col xs={24} md={12} xl={8} key={key}>
          <Form.Item label="कार्य सहमति मिती (सम्म)" name="toLabourConcurranceDate">
            <EnDatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
      ),
    },
  ]

  return (
    <Card title={title} className="shadow-sm">
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          fullName: "",
          country: "",
          gender: "",
          passportNo: "",
          labourConcurranceDate: "",
          ...values,
          childDobEnglish: values?.childDobEnglish ? dayjs(values?.childDobEnglish || null) : null,
          dob:
            recordType !== RecordTypesEnum.LabourConcurrence
              ? values?.dob
              : values?.dob
              ? dayjs(values?.dob || null)
              : null,
          fromLabourConcurranceDate: values?.fromLabourConcurranceDate
            ? dayjs(values?.fromLabourConcurranceDate || null, "YYYY-MM-DD")
            : "",
          toLabourConcurranceDate: values?.toLabourConcurranceDate
            ? dayjs(values?.toLabourConcurranceDate || null)
            : "",
          childImage: values?.childImage?.data,
        }}
        onValuesChange={onChangeValues}
      >
        <Row gutter={10}>
          {formFields.map((f, i) => {
            return fields?.includes(f.name) ? f.component({ key: i }) : null
          })}
        </Row>
        {fields?.includes("childFullNameNepali") && (
          <React.Fragment>
            <Typography.Title level={5}>बच्चा सम्बधि बिबरण</Typography.Title>
            <Row gutter={10}>
              <Col xs={24}>
                <Form.Item label="Photo" name="childImage">
                  <SingleImageUploadHandler doHttpOperationOnAdd={true} imageProps={{ preview: false }} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="नाम / थर (नेपालीमा)" name="childFullNameNepali">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} xl={8}>
                <Form.Item label="नाम / थर (English)" name="childFullNameEnglish">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} xl={8}>
                <Form.Item label="लिङ्ग" name="childGender">
                  <Select
                    options={[
                      { value: "Male", label: "Male" },
                      { value: "Female", label: "Female" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} xl={8}>
                <Form.Item label="जन्म मिति (नेपालीमा)" name="childDobNepali">
                  <NPDatePicker />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} xl={8}>
                <Form.Item label="जन्म मिति (English)" name="childDobEnglish">
                  <EnDatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
                  {/* <EnDatePicker style={{ width: "100%" }} format="YYYY-MM-DD" /> */}
                </Form.Item>
              </Col>
            </Row>

            <Typography.Title level={5}>बच्चाको आमा सम्बधि बिबरण</Typography.Title>
            <Row gutter={10}>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="आमाको नाम / थर (नेपालीमा)" name="childMotherFullNameNepali">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="आमाको नाम / थर (English)" name="childMotherFullNameEnglish">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="आमाको राहधानी नं." name="childMotherPassportNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="आमाको नागरिकता नं." name="childMotherCitizenshipNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="आमाको बुबाको नाम / थर" name="childMotherFatherFullName">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="आमाको बाजेको नाम / थर" name="childMotherGFatherFullName">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="आमाको स्थायी ठेगाना" name="childMotherPermaAddress">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Typography.Title level={5}>बच्चाको बुबा सम्बधि बिबरण</Typography.Title>
            <Row gutter={10}>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="बुबाको नाम / थर (नेपालीमा)" name="childFatherFullNameNepali">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="बुबाको नाम / थर (English)" name="childFatherFullNameEnglish">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="बुबाको राहधानी नं." name="childFatherPassportNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="बुबाको नागरिकता नं." name="childFatherCitizenshipNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="बुबाको बुबाको नाम / थर" name="childFatherFatherFullName">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="बुबाको बाजेको नाम / थर" name="childFatherGFatherFullName">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="बुबाको स्थायी ठेगाना" name="childFatherPermaAddress">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="बुबा रहेको देश" name="childFatherCountry">
                  <CountrySelector />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        )}

        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          {!hideRemoveButton && (
            <Popconfirm
              title="Really want to remove ?"
              onConfirm={() => onRemove?.(index)}
              disabled={disabled || loading}
            >
              <Button type="primary" style={{ backgroundColor: token.colorError }} disabled={disabled || loading}>
                Remove
              </Button>
            </Popconfirm>
          )}
          {/* {!hideResetButton && (
            <Button type="dashed" onClick={() => onReset?.(index)} disabled={disabled || loading}>
              Reset
            </Button>
          )} */}
        </Space>
      </Form>
    </Card>
  )
}
