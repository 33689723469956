import { CloudDownloadOutlined, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, Form, Input, InputNumber, Row, Space, Switch, Tag, Typography } from "antd"
import qs from "qs"
import React, { ReactElement, useEffect, useState } from "react"
import { Bars } from "react-loader-spinner"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import NPDatePicker from "../../../../../../components/common/npdatepicker"
import UserEntryFormRow from "../../../../../../components/userentryFormRow"
import recordHttpService from "../../../../../../services/https/apis/records.http.service"
import { RecordStatus, RecordTypesEnum } from "../../../../../../types/typings.d"

import InputPhoneNumber from "../../../../../../components/common/inputphonenumber"
import PdfViewer from "../../../../../../components/common/pdfviewer"
import SelfControlModal, { SelfControlModalRefsHandler } from "../../../../../../components/common/selfcontrolmodal"
import Editor from "../../../../../../components/editor"
import ApplicantBodySelector from "../../../../../../components/selectors/applicantBodySelector/index"
import DistrictSelector from "../../../../../../components/selectors/districtSelector"
import EmployeeSelector from "../../../../../../components/selectors/employeeselector"
import RecommendingBodySelector from "../../../../../../components/selectors/recommendingBodySelector"
import { labourConcurranceUserEntryFormFieldNames } from "../../../../../../constants"
import { getFilePath, isInThePast } from "../../../../../../utils/general.utils"
import UploadPoliceVerification from "../components/uploadPoliceVerification"
import UploadFinalLetter from "../components/uploadfinalLetter"
import UploadTippani from "../components/uploadtippani"

interface UpdateLabourConcurranceProps {}
const userPayload = {
  fullName: "",
  country: "",
  gender: "",
  passportNo: "",
  dob: "",
  passportImage: null,
}
export default function UpdateLabourConcurrance(props: UpdateLabourConcurranceProps): ReactElement {
  const [form] = Form.useForm()
  const [users, setUsers] = useState<Array<any>>([])
  let { id } = useParams()
  const refs = {
    uploadTippaniModalRef: React.createRef<SelfControlModalRefsHandler>(),
    uploadFinalLetterModalRef: React.createRef<SelfControlModalRefsHandler>(),
    uploadPoliceVerificationModalRef: React.createRef<SelfControlModalRefsHandler>(),
    editTippaniModalRef: React.createRef<SelfControlModalRefsHandler>(),
    editFinalLetterModalRef: React.createRef<SelfControlModalRefsHandler>(),
    editPoliceVerificationModalRef: React.createRef<SelfControlModalRefsHandler>(),
  }
  const [_, setFormValues] = useState({})
  const [state, setState] = useState<{
    loading: boolean
    fetchLoading: boolean
    fetchError: any
    fetchedData: any
    sendLinkToEntryForm: boolean
    sendDocumentUploadLoading: boolean
    sendUserEntryFormLoading: boolean
    generateTipaaniLoading: boolean
    generateFinalLetterLoading: boolean
    generatePoliceVerificationLoading: boolean
    rawHTML?: string
  }>({
    loading: false,
    fetchLoading: true,
    fetchError: null,
    fetchedData: null,
    sendLinkToEntryForm: true,
    sendDocumentUploadLoading: false,
    sendUserEntryFormLoading: false,
    generateTipaaniLoading: false,
    generateFinalLetterLoading: false,
    generatePoliceVerificationLoading: false,
    rawHTML: "",
  })

  const generateTippani = () => {
    recordHttpService
      .generateTipaani(state?.fetchedData?.id)
      .then((res) => {
        setState({
          ...state,
          rawHTML: res.html,
          generateTipaaniLoading: false,
        })
        refs.editTippaniModalRef.current?.show?.()
      })
      .catch((err) => {
        toast.error(err.message || "Error generation tipaani")
        setState({ ...state, generateTipaaniLoading: false })
      })
  }

  const generateFinalLetter = () => {
    recordHttpService
      .generateFinalLetter(state?.fetchedData?.id)
      .then((res) => {
        setState({
          ...state,
          rawHTML: res.html,
          generateFinalLetterLoading: false,
        })
        refs.editFinalLetterModalRef.current?.show?.()
      })
      .catch((err) => {
        toast.error(err.message || "Error generation final letter")
        setState({ ...state, generateFinalLetterLoading: false })
      })
  }

  const generatePoliceVerification = () => {
    recordHttpService
      .generatePoliceVerification(state?.fetchedData?.id)
      .then((res) => {
        setState({
          ...state,
          rawHTML: res.html,
          generatePoliceVerificationLoading: false,
        })
        refs.editPoliceVerificationModalRef.current?.show?.()
      })
      .catch((err) => {
        toast.error(err.message || "Error generation police verification")
        setState({ ...state, generatePoliceVerificationLoading: false })
      })
  }

  const fetchByIdAndSetState = () => {
    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          "document",
          "passportDocument",
          "labourConcurranceDocument",
          "finalLetters",
          "finalLetters.pdfLetter",
          "policeVerifications",
          "policeVerifications.pdfVerification",
          "uploadedTippanies",
          "usrEntries",
          "usrEntries.passportImage",
          "pharchyotPerson",
          "applicantBody",
        ],
        filters: {
          recordType: {
            $eq: RecordTypesEnum.LabourConcurrence,
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    setState({ ...state, fetchLoading: true, fetchError: null })
    recordHttpService
      .fetchRecordById(id || "", query)
      .then((res) => {
        form.setFieldsValue({
          dartaNo: res?.data?.attributes?.dartaNo,
          invoiceNo: res?.data?.attributes?.invoiceNo,
          chalaniNo: res?.data?.attributes?.chalaniNo,
          invoiceDate: res?.data?.attributes?.invoiceDate,
          recordType: res?.data?.attributes?.recordType,
          recommendingBody: res?.data?.attributes?.recommendingBody,
          applicantBody: res?.data?.attributes?.applicantBody?.data?.id,
          contactPerson: res?.data?.attributes?.contactPerson,
          contactNumber: res?.data?.attributes?.contactNumber,
          totalEntryCount: res?.data?.attributes?.totalEntryCount,
          pharchyotPerson: res?.data?.attributes?.pharchyotPerson?.data?.id,
          contactEmail: res?.data?.attributes?.contactEmail,
          isDocumentVerified: res?.data?.attributes?.isDocumentVerified,
          policeVerification: res?.data?.attributes?.policeVerification,
          dateOfCorrespondenceForConduct: res?.data?.attributes?.dateOfCorrespondenceForConduct,
          dateOfReplay: res?.data?.attributes?.dateOfReplay,
          ministryDecisionDate: res?.data?.attributes?.ministryDecisionDate,
          dateOfCorrespondence: res?.data?.attributes?.dateOfCorrespondence,
          concernedDistrict: res?.data?.attributes?.concernedDistrict,
        })
        setUsers(res?.data?.attributes?.usrEntries || [])
        setState({ ...state, fetchLoading: false, fetchedData: res.data })
      })
      .catch((err) => {
        setState({ ...state, fetchLoading: false, fetchError: err })
      })
  }
  useEffect(() => {
    fetchByIdAndSetState()
  }, [])

  const sendDocumentUploadLink = () => {
    setState({ ...state, sendDocumentUploadLoading: true })
    recordHttpService
      .sendDocumentRequestForm(state.fetchedData?.id)
      .then((res) => {
        toast.success("Link Sent")
        setState({ ...state, sendDocumentUploadLoading: false })
      })
      .catch((err) => {
        toast.error("Error Sending Link")
        setState({ ...state, sendDocumentUploadLoading: false })
      })
  }

  const sendUserEntryFormLink = () => {
    setState({ ...state, sendUserEntryFormLoading: true })
    recordHttpService
      .sendUserEntryRequestForm(state.fetchedData?.id)
      .then((res) => {
        toast.success("Link Sent")
        setState({ ...state, sendUserEntryFormLoading: false })
      })
      .catch((err) => {
        toast.error("Error Sending Link")
        setState({ ...state, sendUserEntryFormLoading: false })
      })
  }

  const onFinish = (values: any) => {
    setState({ ...state, loading: true })
    delete values.dartaNo
    recordHttpService
      .updateRecord(state?.fetchedData?.id || "", {
        ...values,
        usrEntries: users,
      })
      .then((res) => {
        setState({ ...state, loading: false })
        toast.success(`Updated`)
      })
      .catch((err) => {
        setState({ ...state, loading: false })
        console.log("Err", err)
        toast.error(err?.message || "Error updating")
      })
  }

  if (state.fetchLoading) {
    return (
      <Card>
        <div
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </Card>
    )
  }

  if (state.fetchError) {
    return (
      <Card
        style={{
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography>{state.fetchError?.message || "Error occured"}</Typography>
        <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => fetchByIdAndSetState()}>
          Try Again
        </Button>
      </Card>
    )
  }

  return (
    <div>
      <div className="site-card-border-less-wrapper">
        <Card bordered={false}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              dartaNo: "",
              invoiceNo: "",
              chalaniNo: "",
              invoiceDate: "",
              recordType: "Labour Concurrence",
              recommendingBody: "",
              applicantBody: "",
              contactPerson: "",
              contactNumber: "",
              pharchyotPerson: null,
              totalEntryCount: "",
              contactEmail: "",
              isDocumentVerified: false,
              policeVerification: false,
              dateOfCorrespondenceForConduct: "",
              dateOfRelay: "",
              ministryDecisionDate: "",
              dateOfCorrespondence: "",
              concernedDistrict: "",
            }}
            onValuesChange={(_: any, values: any) => {
              setFormValues(values)
            }}
          >
            <Form.Item className="sticky-header">
              <Space style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography.Title level={3} className="he-title">
                    कार्य सहमति दर्ता / Labour Concurrence
                  </Typography.Title>
                  <Typography.Title style={{ marginTop: "-1rem" }}>
                    {state?.fetchedData?.attributes?.status === RecordStatus.INPROGRESS ? (
                      <Tag color="warning">{state?.fetchedData?.attributes?.status}</Tag>
                    ) : state?.fetchedData?.attributes?.status === RecordStatus.CREATED ? (
                      <Tag color="blue">{state?.fetchedData?.attributes?.status}</Tag>
                    ) : (
                      <Tag color="success">{state?.fetchedData?.attributes?.status}</Tag>
                    )}
                  </Typography.Title>
                </div>
                <Button type="primary" htmlType="submit" loading={state.loading}>
                  Save Changes
                </Button>
              </Space>
            </Form.Item>
            <Divider />
            <Row gutter={10}>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="दर्ता नं." help="Auto generated by system" name="dartaNo">
                  <Input readOnly disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्र संख्या" name="invoiceNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="चलानी नं." name="chalaniNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्र मिती" name="invoiceDate">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item
                  label="सिफारिस गर्ने निकाय"
                  name="recommendingBody"
                  rules={[{ required: true, message: "Can not be empty" }]}
                >
                  <RecommendingBodySelector
                    onClickAddOtherItem={(text) => {
                      form.setFieldValue("recommendingBody", text)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item
                  label="निवेदक निकाय"
                  name="applicantBody"
                  rules={[{ required: true, message: "Can not be empty" }]}
                >
                  <ApplicantBodySelector />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="सम्पर्क व्यक्ति" name="contactPerson">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="सम्पर्क नं." name="contactNumber">
                  <InputPhoneNumber noCountryCodeSelection />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="फर्छ्योट गर्ने व्यक्ति" name={"pharchyotPerson"}>
                  <EmployeeSelector />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item
                  label="जम्मा विदेशी संख्या"
                  name={"totalEntryCount"}
                  rules={[{ required: true, message: "Can not be empty" }]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="इमेल" name={"contactEmail"} rules={[{ required: true, message: "Can not be empty" }]}>
                  <Input type="email" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="चालचलनको लागि पत्राचार भएको मिती" name="dateOfCorrespondenceForConduct">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="जवाफ आएको मिती" name="dateOfReplay">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="मन्त्रालयको निर्णय मिती" name="ministryDecisionDate">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्राचार हुने मिती" name="dateOfCorrespondence">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="सम्बन्धित जिल्ला" name="concernedDistrict">
                  <DistrictSelector />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <div>
              <div style={{ marginBottom: 20 }}>
                <div style={{ marginBottom: 20 }}>
                  <Typography.Title level={4} className="he-subtitle">
                    विदेशी नागरिकको लागि{" "}
                    {state.fetchedData?.attributes?.labourConEntryTokenExpiry &&
                    isInThePast(new Date(state.fetchedData?.attributes?.labourConEntryTokenExpiry)) ? (
                      <Tag color="error">Expired</Tag>
                    ) : (
                      state.fetchedData?.attributes?.labourConEntryToken &&
                      state.fetchedData?.attributes?.usrEntries?.length <= 0 && <Tag color="blue">Sent & Pending</Tag>
                    )}
                  </Typography.Title>
                </div>
                {/*Always show Send Entry Form Link /*}
                {/* {users.length <= 0 && ( */}
                <>
                  <Button onClick={sendUserEntryFormLink} loading={state.sendUserEntryFormLoading} type="primary">
                    Send Entry Form Link
                  </Button>
                  <div>[Email: {state.fetchedData?.attributes?.contactEmail}]</div>
                </>
                {/* )} */}
              </div>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {users?.map?.((user, index) => {
                  return (
                    <UserEntryFormRow
                      fields={labourConcurranceUserEntryFormFieldNames}
                      recordType={RecordTypesEnum.LabourConcurrence}
                      key={index}
                      index={index}
                      values={user}
                      onRemove={(ind) => {
                        const newUsers = [...users]
                        newUsers.splice(ind, 1)
                        setUsers(newUsers)
                      }}
                      onReset={() => {
                        const newUsers = [...users]
                        newUsers[index] = userPayload
                        setUsers(newUsers)
                      }}
                      onValuesChange={(values) => {
                        const newUsers = [...users]
                        newUsers[index] = values
                        setUsers(newUsers)
                      }}
                    />
                  )
                })}
              </Row>
              <Space style={{ marginTop: 10, marginBottom: 20 }}>
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    const newUsers = [...users]
                    newUsers.push(userPayload)
                    setUsers(newUsers)
                  }}
                  disabled={state.loading || Number(form.getFieldValue("totalEntryCount")) <= users.length}
                >
                  थप्नुहोस्
                </Button>
              </Space>
            </div>
            <Divider />
            <div>
              <div style={{ marginBottom: 20 }}>
                <Typography.Title level={4} className="he-subtitle" style={{ marginTop: 5 }}>
                  राहदानी कागजात [Passport Copy]
                </Typography.Title>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {state.fetchedData?.attributes?.passportDocument?.data && (
                  <a
                    href={getFilePath(state.fetchedData?.attributes?.passportDocument.data?.attributes?.url)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PdfViewer
                      fileUrl={getFilePath(state.fetchedData?.attributes?.passportDocument.data?.attributes?.url)}
                    />
                  </a>
                )}
              </div>
            </div>
            <Divider />
            <div style={{ marginBottom: 20 }}>
              <div style={{ marginBottom: 20 }}>
                <Typography.Title level={4} className="he-subtitle">
                  प्रहरी प्रमाणीकरण
                </Typography.Title>
                <Form.Item name="policeVerification" valuePropName="checked">
                  <Switch />
                </Form.Item>
                Police Clearance
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {state.fetchedData?.attributes?.policeVerifications?.data?.map?.((t: any, i: number) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      key={i}
                    >
                      <a
                        href={getFilePath(t?.attributes?.pdfVerification.data?.attributes?.url)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PdfViewer fileUrl={getFilePath(t?.attributes?.pdfVerification?.data?.attributes?.url)} />
                      </a>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ marginRight: 5 }}>चलानी नं.</span>
                        <span>{t?.attributes?.chalaniNo || "--"}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <Space style={{ marginTop: 10, marginBottom: 20 }}>
                <Button
                  type="primary"
                  icon={<CloudDownloadOutlined />}
                  disabled={state.loading}
                  loading={state.generatePoliceVerificationLoading}
                  onClick={generatePoliceVerification}
                >
                  Generate प्रहरी प्रमाणीकरण
                </Button>
                <SelfControlModal
                  anchorElement={
                    <Button type="primary" icon={<CloudUploadOutlined />} disabled={state.loading}>
                      Upload प्रहरी प्रमाणीकरण
                    </Button>
                  }
                  title="Upload प्रहरी प्रमाणीकरण"
                  width={1000}
                  ref={refs.uploadPoliceVerificationModalRef}
                >
                  <UploadPoliceVerification
                    record={state.fetchedData}
                    modalRef={refs.uploadPoliceVerificationModalRef}
                    fetchByIdAndSetState={fetchByIdAndSetState}
                  />
                </SelfControlModal>
              </Space>
            </div>
            <Divider />
            <div style={{ marginBottom: 20 }}>
              {state.fetchedData?.attributes?.documentUploadStatus === "Completed" && (
                <>
                  <Form.Item
                    label={<Typography.Title level={5}>कागजात प्रमाणित छ</Typography.Title>}
                    name="isDocumentVerified"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Divider />
                </>
              )}

              <Space size={40}>
                <div>
                  <div style={{ marginBottom: 20 }}>
                    <Typography.Title level={4} className="he-subtitle" style={{ marginTop: 5 }}>
                      Curriculum Vitae [CV] कागजात
                    </Typography.Title>
                    {state.fetchedData?.attributes?.documentTokenExpiry &&
                    isInThePast(new Date(state.fetchedData?.attributes?.documentTokenExpiry)) ? (
                      <Tag color="error">Expired</Tag>
                    ) : (
                      state.fetchedData?.attributes?.documentUploadStatus &&
                      state.fetchedData?.attributes?.documentUploadStatus === "Pending" && (
                        <Tag color="blue">{state.fetchedData?.attributes?.documentUploadStatus}</Tag>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 20,
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {state.fetchedData?.attributes?.document?.data && (
                      <a
                        href={getFilePath(state.fetchedData?.attributes?.document.data?.attributes?.url)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PdfViewer
                          fileUrl={getFilePath(state.fetchedData?.attributes?.document.data?.attributes?.url)}
                        />
                      </a>
                    )}
                  </div>
                </div>
                <div>
                  <div style={{ marginBottom: 20 }}>
                    <Typography.Title level={4} className="he-subtitle" style={{ marginTop: 5 }}>
                      कार्य सहमति कागजात [LC]
                    </Typography.Title>
                    {state.fetchedData?.attributes?.documentTokenExpiry &&
                    isInThePast(new Date(state.fetchedData?.attributes?.documentTokenExpiry)) ? (
                      <Tag color="error">Expired</Tag>
                    ) : (
                      state.fetchedData?.attributes?.documentUploadStatus &&
                      state.fetchedData?.attributes?.documentUploadStatus === "Pending" && (
                        <Tag color="blue">{state.fetchedData?.attributes?.documentUploadStatus}</Tag>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 20,
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {state.fetchedData?.attributes?.labourConcurranceDocument?.data && (
                      <a
                        href={getFilePath(
                          state.fetchedData?.attributes?.labourConcurranceDocument.data?.attributes?.url
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PdfViewer
                          fileUrl={getFilePath(
                            state.fetchedData?.attributes?.labourConcurranceDocument.data?.attributes?.url
                          )}
                        />
                      </a>
                    )}
                  </div>
                </div>
              </Space>
              <div>
                <Space style={{ marginTop: 10, marginBottom: 20 }}>
                  <Button
                    type="primary"
                    icon={<CloudUploadOutlined />}
                    disabled={state.loading}
                    onClick={sendDocumentUploadLink}
                    loading={state.sendDocumentUploadLoading}
                  >
                    Send Document Upload Link
                  </Button>
                  [ Email: {state.fetchedData?.attributes?.contactEmail} ]
                </Space>
              </div>
            </div>
            <Divider />
            {form.getFieldValue("policeVerification") && (
              <>
                <div style={{ marginBottom: 20 }}>
                  <div style={{ marginBottom: 20 }}>
                    <Typography.Title level={4} className="he-subtitle">
                      टिप्पणीहरु
                    </Typography.Title>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 20,
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {state.fetchedData?.attributes?.uploadedTippanies?.data?.map?.((t: any, i: number) => {
                      return (
                        <a href={getFilePath(t?.attributes?.url)} target="_blank" rel="noreferrer">
                          <PdfViewer fileUrl={getFilePath(t?.attributes?.url)} key={i} />
                        </a>
                      )
                    })}
                  </div>
                  <Space style={{ marginTop: 10, marginBottom: 20 }}>
                    <Button
                      type="primary"
                      icon={<CloudDownloadOutlined />}
                      disabled={state.loading}
                      loading={state.generateTipaaniLoading}
                      onClick={generateTippani}
                    >
                      Generate टिप्पणी
                    </Button>
                    <SelfControlModal
                      anchorElement={
                        <Button type="primary" icon={<CloudUploadOutlined />} disabled={state.loading}>
                          Upload टिप्पणी
                        </Button>
                      }
                      title="Upload टिप्पणी"
                      width={1000}
                      ref={refs.uploadTippaniModalRef}
                    >
                      <UploadTippani
                        record={state.fetchedData}
                        modalRef={refs.uploadTippaniModalRef}
                        fetchByIdAndSetState={fetchByIdAndSetState}
                      />
                    </SelfControlModal>
                  </Space>
                </div>
                <Divider />
                <div style={{ marginBottom: 20 }}>
                  <div style={{ marginBottom: 20 }}>
                    <Typography.Title level={4} className="he-subtitle">
                      अन्तिम पत्र
                    </Typography.Title>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 20,
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {state.fetchedData?.attributes?.finalLetters?.data?.map?.((t: any, i: number) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          key={i}
                        >
                          <a
                            href={getFilePath(t?.attributes?.pdfLetter?.data?.attributes?.url)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <PdfViewer fileUrl={getFilePath(t?.attributes?.pdfLetter?.data?.attributes?.url)} />
                          </a>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <span style={{ marginRight: 5 }}>चलानी नं.</span>
                            <span>{t?.attributes?.chalaniNo || "--"}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <Space style={{ marginTop: 10, marginBottom: 20 }}>
                    <Button
                      type="primary"
                      icon={<CloudDownloadOutlined />}
                      disabled={state.loading}
                      loading={state.generateFinalLetterLoading}
                      onClick={generateFinalLetter}
                    >
                      Generate Final Letter
                    </Button>
                    <SelfControlModal
                      anchorElement={
                        <Button type="primary" icon={<CloudUploadOutlined />} disabled={state.loading}>
                          Upload Final Letter
                        </Button>
                      }
                      title="Upload Final Letter"
                      width={1000}
                      ref={refs.uploadFinalLetterModalRef}
                    >
                      <UploadFinalLetter
                        record={state.fetchedData}
                        modalRef={refs.uploadFinalLetterModalRef}
                        fetchByIdAndSetState={fetchByIdAndSetState}
                      />
                    </SelfControlModal>
                  </Space>
                </div>
              </>
            )}
          </Form>
        </Card>
      </div>

      <SelfControlModal title="टिप्पणी EDITOR" width={"80%"} ref={refs.editTippaniModalRef}>
        <Editor html={state.rawHTML} />
      </SelfControlModal>

      <SelfControlModal title="प्रहरी प्रमाणीकरण EDITOR" width={"80%"} ref={refs.editPoliceVerificationModalRef}>
        <Editor html={state.rawHTML} />
      </SelfControlModal>

      <SelfControlModal title="Final Letter EDITOR" width={"80%"} ref={refs.editFinalLetterModalRef}>
        <Editor html={state.rawHTML} />
      </SelfControlModal>
    </div>
  )
}
