import { createAsyncThunk } from "@reduxjs/toolkit";
import roleHttpService from "../../services/https/apis/role.http.service";
export const fetchRoles = createAsyncThunk(
  "roles/fetchRoles",
  async () => {
    return roleHttpService.fetchRoles()
  }
);

export const createRole = createAsyncThunk(
  "roles/createRole",
  async (payload: Record<string,any>) => {
    return roleHttpService.createRole(payload)
  }
);

export const updateRole = createAsyncThunk(
  "roles/updateRole",
  async (id: string, payload: Record<string,any>) => {
    return roleHttpService.updateRole(id,payload)
  }
);

export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async (id: string) => {
    return roleHttpService.deleteRole(id)
  }
);
