import { RecordTypesEnum,UIPolicyI } from "../types/typings.d";

export const uiPolicyFullAccess: Partial<UIPolicyI> = {
  dashboard: true,
  records: {
    recordsHome: {
      [RecordTypesEnum.All]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      [RecordTypesEnum.LabourConcurrence]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      [RecordTypesEnum.FreeArrivalVisa]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      [RecordTypesEnum.FlightPermission]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      [RecordTypesEnum.FreeVisaExtension]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      [RecordTypesEnum.Deportation]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      [RecordTypesEnum.MultipleEntryVisa]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      [RecordTypesEnum.ArrivalVisa]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      [RecordTypesEnum.NrnStudyVisa]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      [RecordTypesEnum.FreeTrekkingPermit]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      [RecordTypesEnum.InternationalBorder]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      [RecordTypesEnum.TravelDocument]: {
        edit: true,
        delete: true,
        view: true,
        add: true,
      }
    },
    registrations: true,
    chalani:true,
  },
  users:{
    edit: true,
    delete: true,
    view: true,
    add: true,
  },
  thirdparties:{
    edit: true,
    delete: true,
    view: true,
    add: true,
  },
  employees:{
    employeesHome:{
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      departments:{
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
      designations:{
        edit: true,
        delete: true,
        view: true,
        add: true,
      },
  },
  // roles:{
  //   edit: true,
  //   delete: true,
  //   view: true,
  //   add: true,
  // },
  uipolicies:{
    edit: true,
    delete: true,
    view: true,
    add: true,
  },
  summarylogs:true,
  reports:true,
  bibidhs:true
};

export const uiPolicyDefault: Partial<UIPolicyI> = {
  dashboard: false,
  records: {
    recordsHome: {
      [RecordTypesEnum.LabourConcurrence]: {
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      [RecordTypesEnum.FreeArrivalVisa]: {
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      [RecordTypesEnum.FlightPermission]: {
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      [RecordTypesEnum.FreeVisaExtension]: {
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      [RecordTypesEnum.Deportation]: {
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      [RecordTypesEnum.MultipleEntryVisa]: {
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      [RecordTypesEnum.ArrivalVisa]: {
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      [RecordTypesEnum.NrnStudyVisa]: {
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      [RecordTypesEnum.FreeTrekkingPermit]: {
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      [RecordTypesEnum.InternationalBorder]: {
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      [RecordTypesEnum.TravelDocument]: {
        edit: false,
        delete: false,
        view: false,
        add: false,
      }
    },
    registrations: false,
    chalani:false,
  },
  users:{
    edit: false,
    delete: false,
    view: false,
    add: false,
  },
  thirdparties:{
    edit: false,
    delete: false,
    view: false,
    add: false,
  },
  employees:{
    employeesHome:{
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      departments:{
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
      designations:{
        edit: false,
        delete: false,
        view: false,
        add: false,
      },
  },
  // roles:{
  //   edit: false,
  //   delete: false,
  //   view: false,
  //   add: false,
  // },
  uipolicies:{
    edit: false,
    delete: false,
    view: false,
    add: false,
  },
  summarylogs:false,
  reports:false,
  bibidhs:false
};

