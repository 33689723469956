import { UploadOutlined } from "@ant-design/icons"
import { Button, Select, Space, Table } from "antd"
import Upload from "antd/es/upload/Upload"
import { format } from "date-fns"
import { useState } from "react"
import { toast } from "react-toastify"
import { SelfControlModalRefsHandler } from "../../../../../../components/common/selfcontrolmodal/index"
import recordHttpService from "../../../../../../services/https/apis/records.http.service"

type UploadTippaniProps = {
  record: any
  modalRef: React.RefObject<SelfControlModalRefsHandler>
  fetchByIdAndSetState?: () => void
}

export default function UploadTippani({ record, modalRef, fetchByIdAndSetState }: UploadTippaniProps) {
  const [state, setState] = useState<{
    usrEntries: Array<any>
    tippaniFile: any
    loading: boolean
  }>({
    usrEntries: record?.attributes?.usrEntries || [],
    tippaniFile: null,
    loading: false,
  })

  const uploadTippani = () => {
    const { usrEntries, tippaniFile } = state
    if (!tippaniFile) {
      return toast.info("Please select टिप्पणी to upload ")
    }
    const formData = new FormData()
    formData.append("files.uploadedTippanies", tippaniFile, tippaniFile?.name || "")
    formData.append("data", JSON.stringify({ usrEntries: usrEntries }))
    setState({ ...state, loading: true })
    recordHttpService
      .uploadTipaani(record.id, formData)
      .then((res) => {
        setState({ ...state, loading: false })
        toast.success("Uploaded")
        fetchByIdAndSetState?.()
        modalRef?.current?.hide?.()
      })
      .catch((err) => {
        console.log("Error", err)
        setState({ ...state, loading: false })
        toast.error(err?.message || "Error uploading")
      })
  }

  return (
    <div>
      <Table
        columns={[
          {
            title: "नाम/थर",
            dataIndex: "fullName",
            key: "fullName",
          },
          {
            title: "कार्य सहमति अवधि",
            dataIndex: "fromLabourConcurranceDate",
            key: "fromLabourConcurranceDate",
            render: (_: any, record: any) =>
              `${
                record?.fromLabourConcurranceDate
                  ? format(new Date(record?.fromLabourConcurranceDate), "yyyy-MM-dd")
                  : "--"
              } - ${
                record?.toLabourConcurranceDate ? format(new Date(record?.toLabourConcurranceDate), "yyyy-MM-dd") : "--"
              }`,
          },
          {
            title: "राहदानी नं",
            dataIndex: "passportNo",
            key: "passportNo",
          },
          {
            title: "Actions",
            key: "action",
            render: (_: any, re: any, index: number) => {
              return (
                <Select
                  defaultValue={re?.status || "Approved"}
                  options={[
                    { value: "Approved", label: "Approved" },
                    { value: "Pending", label: "Pending" },
                    { value: "Rejected", label: "Rejected" },
                  ]}
                  onChange={(value) => {
                    const newUsrEntries = [...state.usrEntries]
                    newUsrEntries[index].status = value
                  }}
                />
              )
            },
          },
        ]}
        dataSource={state.usrEntries}
        pagination={false}
      />
      <Upload
        multiple={false}
        beforeUpload={() => false}
        onChange={(info) => {
          setState({ ...state, tippaniFile: info.file })
        }}
        accept="application/pdf"
      >
        <Button icon={<UploadOutlined />} style={{ marginTop: 20 }}>
          Click to Select File
        </Button>
      </Upload>

      <Space style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}>
        <Button type="primary" onClick={uploadTippani}>
          Upload टिप्पणी
        </Button>
      </Space>
    </div>
  )
}
