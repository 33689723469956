import { createAsyncThunk } from "@reduxjs/toolkit";
import uipolicyHttpService from "../../services/https/apis/uipolicy.http.service";
export const fetchUIPolicies = createAsyncThunk(
  "uipolicies/fetchUIPolicys",
  async () => {
    return uipolicyHttpService.fetchUIPolicies();
  }
);

export const createUIPolicy = createAsyncThunk(
  "uipolicies/createUIPolicy",
  async (payload: Record<string, any>,{dispatch,rejectWithValue}) => {
    return uipolicyHttpService.createUIPolicy(payload)
    .then(res=>{
      dispatch(fetchUIPolicies())
      return res
    })
    .catch(err=>{
      console.log("Error *****",err)
      return rejectWithValue(err)
    })
  }
);

export const updateUIPolicy = createAsyncThunk(
  "uipolicies/updateUIPolicy",
  async (updatePayload:{id: string, payload: Record<string, any>},{dispatch,rejectWithValue}) => {
    return uipolicyHttpService.updateUIPolicy(updatePayload.id, updatePayload.payload)
    .then(res=>{
      dispatch(fetchUIPolicies())
      return res
    })
    .catch(err=>{
      console.log("Error *****",err)
      return rejectWithValue(err)
    })
  }
);

export const deleteUIPolicy = createAsyncThunk(
  "uipolicies/deleteUIPolicy",
  async (id: string,{dispatch,rejectWithValue}) => {
    return uipolicyHttpService.deleteUIPolicy(id)
    .then(res=>{
      dispatch(fetchUIPolicies())
      return res
    })
    .catch(err=>{
      console.log("Error *****",err)
      return rejectWithValue(err)
    })
  }
);
