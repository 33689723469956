import { createAsyncThunk } from "@reduxjs/toolkit";
import recordsHttpService from "../../services/https/apis/records.http.service";
export const fetchRecords = createAsyncThunk(
  "records/fetchRecords",
  async () => {
    return recordsHttpService.fetchRecords();
  }
);

export const createRecord = createAsyncThunk(
  "records/createRoles",
  async (payload: Record<string, any>,thunkAPI) => {
    return recordsHttpService.createRecord(payload)
    .then(res=>{
      thunkAPI.dispatch(fetchRecords())
      return res
    })
    .catch(err=>{
      return thunkAPI.rejectWithValue(err)
    })
  }
);

export const updateRecord = createAsyncThunk(
  "records/updateRole",
  async (updatePayload:{id: string, payload: Record<string, any>},thunkAPI) => {
    return recordsHttpService.updateRecord(updatePayload.id, updatePayload.payload)
    .then(res=>{
      thunkAPI.dispatch(fetchRecords())
      return res
    })
    .catch(err=>{
      thunkAPI.rejectWithValue(err)
    })
  }
);

export const deleteRecord = createAsyncThunk(
  "records/deleteRole",
  async (id: string,thunkAPI) => {
    return recordsHttpService.deleteRecord(id)
    .then(res=>{
      thunkAPI.dispatch(fetchRecords())
      return res
    })
    .catch(err=>{
      thunkAPI.rejectWithValue(err)
    })
  }
);
