import { AxiosRequestConfig } from "axios"
import { AppWebRequest } from "../NetworkManager"
class UsersHttpService {
  fetchUsers(config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {}
    const { sort = [], populate = [], ...otherParams } = params || {}
    return AppWebRequest({
      method: "get",
      url: "/users",
      ...axiosConfig,
      params: {
        sort: ["updatedAt:desc", ...sort],
        populate: ["designation", "department", "role", "avatar", "uiPolicy", ...populate],
        ...otherParams,
      },
    })
  }

  createUser(payload: Record<string, any>) {
    return AppWebRequest({
      method: "post",
      url: "/users-permissions/users/createWithCustomFields",
      data: payload,
    })
  }

  countUsers(querySting?: string) {
    return AppWebRequest({
      method: "get",
      url: querySting ? `/users/count?${querySting}` : `/users/count`,
    })
  }

  updateUser(id: string, payload: Record<string, any>) {
    return AppWebRequest({
      method: "put",
      url: `/users-permissions/users/${id}/updateWithCustomFields`,
      data: payload,
    })
  }

  deleteUser(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/users/${id}`,
    })
  }
}

const usersHttpService = new UsersHttpService()
export default usersHttpService
