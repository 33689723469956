import { Button, Card, Col, Form, message, Row, Space, Table, Tag, theme, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { endOfDay, startOfDay } from "date-fns"
import NepaliDate from "nepali-date-converter"
import qs from "qs"
import { ReactElement, useRef, useState } from "react"
import { Bars } from "react-loader-spinner"
import ExportTableMenu from "../../../../components/common/exportTableMenu"
import NPDatePicker from "../../../../components/common/npdatepicker"
import RecordStatusSelector from "../../../../components/selectors/recordStatusSelector"
import flightPermissionHttpService from "../../../../services/https/apis/flightpermission.http.service"
import { RecordStatus, RecordTypesEnum } from "../../../../types/typings.d"
import FlightPermissionStatistics, { FlightPermissionStatisticsProps } from "./components/flightPermissionStatistics"

interface FlightPermissionsReportProps {}

export default function FlightPermissionsReport(props: FlightPermissionsReportProps): ReactElement {
  const [state, setState] = useState<{
    loading: boolean
    error: any
    data?: any[]
    meta?: {
      pagination: {
        page: number
        pageCount: number
        pageSize: number
        total: number
      }
    }
    fetchCorrespondingStatisticsData?: boolean
    statisticsPropsData?: FlightPermissionStatisticsProps
  }>({
    loading: false,
    error: null,
    data: [],
    meta: undefined,
  })
  const { token } = theme.useToken()
  const [form] = Form.useForm()
  const pdfComponentRef = useRef<any>()

  const onFinish = (values: any) => {
    if (!values.fromDate || !values.toDate) {
      return message.error("Please select अबधि")
    }

    if (values.status === "All") {
      delete values.status
    }
    if (values.fromDate) {
      //Convert Nepali date into english date
      const npDate = new NepaliDate(values.fromDate)
      const asJson = npDate.getAD()
      const adDate = new Date(asJson.year, asJson.month, asJson.date)
      values.fromDate = startOfDay(adDate)
    }
    if (values.toDate) {
      //Convert Nepali date into english date
      const npDate = new NepaliDate(values.toDate)
      const asJson = npDate.getAD()
      const adDate = new Date(asJson.year, asJson.month, asJson.date)
      values.toDate = endOfDay(adDate)
    }
    setState({ ...state, loading: true, error: null })
    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          "flightCompany",
          "passengers",
          "finalLetters",
          "finalLetters.pdfLetter",
          "uploadedTippanies",
          "pharchyotPerson",
        ],
        filters: {
          $and: [
            {
              recordType: values.recordType,
            },
            {
              status: values.status,
            },
            {
              createdAt: {
                $gte: values.fromDate,
              },
            },
            {
              createdAt: {
                $lte: values.toDate,
              },
            },
          ],
        },
      },
      {
        encodeValuesOnly: true,
      }
    )
    flightPermissionHttpService
      .fetchFlightPermissions(query)
      .then((res) => {
        setState({
          ...state,
          loading: false,
          data: res.data,
          meta: res.meta,
          fetchCorrespondingStatisticsData: true,
          statisticsPropsData: {
            fromDate: values.fromDate,
            toDate: values.toDate,
          },
        })
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err, fetchCorrespondingStatisticsData: false })
      })
  }

  const renderFilter = () => {
    return (
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          fromDate: "",
          toDate: "",
          recordType: RecordTypesEnum.All,
          status: "All",
        }}
      >
        <Row gutter={10}>
          <Col xs={24} sm={12}>
            <Form.Item label="अबधि (देखि)" name="fromDate" required rules={[{ message: "Required" }]}>
              <NPDatePicker />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item label="अबधि (सम्म)" name="toDate" required rules={[{ message: "Required" }]}>
              <NPDatePicker />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item label="अबस्था" name="status">
              <RecordStatusSelector />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={state.loading}>
              Search
            </Button>
            <Button
              type="primary"
              htmlType="reset"
              style={{ backgroundColor: token.colorWarning }}
              disabled={state.loading}
            >
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    )
  }

  const renderTableHeader = (exportTablePayload: { header: string[]; body: any[] }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <ExportTableMenu
          exportTablePayload={{
            fileName: "FlightPermissions_Report",
            sheet: "FlightPermissions",
            tablePayload: exportTablePayload,
          }}
          // pdfComponentRef={pdfComponentRef}
          disabled={(state?.data || [])?.length <= 0 ? true : false}
        />
      </div>
    )
  }

  const renderTable = () => {
    if (state.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{state.error?.message || "Error occured"}</Typography>
          <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => form.submit()}>
            Try Again
          </Button>
        </Card>
      )
    }

    if (state.loading) {
      return (
        <Card>
          {renderFilter()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      )
    }

    const dataSource = (state?.data || [])?.map?.((f) => ({
      id: f?.id,
      dartaNo: f?.attributes?.dartaNo,
      flightCompany: f?.attributes?.flightCompany?.data?.attributes?.username || "--",
      flightType: f?.attributes?.flightType || "--",
      passengersCount: f?.attributes?.passengers?.length || 0,
      totalShuttle: f?.attributes?.totalShuttle || 0,
      status: f?.attributes?.status,
    }))

    const columns: ColumnsType<{}> = [
      {
        title: "दर्ता न",
        dataIndex: "dartaNo",
        key: "dartaNo",
        fixed: "left",
      },
      {
        title: "उडान कम्पनी",
        dataIndex: "flightCompany",
        key: "flightCompany",
      },
      {
        title: "उडान प्रकार",
        dataIndex: "flightType",
        key: "flightType",
        render: (flightType: string) => <Tag color="blue">{flightType}</Tag>,
      },
      {
        title: "यात्रु संख्या",
        dataIndex: "passengersCount",
        key: "passengersCount",
      },
      {
        title: "जम्मा शटल",
        dataIndex: "totalShuttle",
        key: "totalShuttle",
      },
      {
        title: "अबस्था",
        dataIndex: "status",
        key: "status",
        render: (status: string) => {
          if (status === RecordStatus.INPROGRESS) {
            return <Tag color="warning">{status}</Tag>
          } else if (status === RecordStatus.CREATED) {
            return <Tag color="blue">{status}</Tag>
          } else if (status === RecordStatus.COMPLETED) {
            return <Tag color="success">{status}</Tag>
          }
        },
      },
    ]

    return (
      <Card>
        {renderFilter()}
        {renderTableHeader({
          header: columns.map((f) => f.title as string),
          body: dataSource.map(({ id, ...data }) => data),
        })}
        <div ref={pdfComponentRef}>
          <Table
            dataSource={dataSource}
            scroll={{ x: "auto" }}
            columns={columns}
            summary={() => {
              return (
                <Space style={{ marginTop: 10 }}>
                  {state?.meta && <Tag color="blue">जम्मा: {state?.meta?.pagination?.total}</Tag>}
                  {state.fetchCorrespondingStatisticsData && (
                    <FlightPermissionStatistics {...state.statisticsPropsData!} />
                  )}
                </Space>
              )
            }}
          />
        </div>
      </Card>
    )
  }

  return <div>{renderTable()}</div>
}
