import { EditOutlined, PlusOutlined, ReloadOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Button, Card, Image, Space, Table, Tag, Typography, theme } from "antd"
import { ColumnsType } from "antd/es/table"
import { createRef } from "react"
import { Bars } from "react-loader-spinner"
import UnderlineTypography from "../../../../components/common/underlineTypography"
import { fetchUsers } from "../../../../features/users/usersThunk"
import { useAppDispatch, useAppSelector } from "../../../../hooks"
import { getFilePath } from "../../../../utils/general.utils"
import CreateORUpdateUserModal, {
  CreateORUpdateUserModalRefsHandler,
} from "./components/createORupdateModal/createORupdateModal"

interface UsersHomeProps {}

export default function UsersHome(props: UsersHomeProps) {
  const { token } = theme.useToken()
  const dispatch = useAppDispatch()
  const users = useAppSelector((state) => state.users)
  const createUpdateModalRef = createRef<CreateORUpdateUserModalRefsHandler>()

  const renderTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
          flexWrap: "wrap",
        }}
      >
        <Button
          type="dashed"
          icon={<ReloadOutlined />}
          onClick={() => {
            dispatch(fetchUsers())
          }}
          disabled={users.error || users.loading}
        >
          रिफ्रेस गर्नुहोस्
        </Button>
        <Space wrap>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => {
              createUpdateModalRef.current?.setState({ open: true })
            }}
          >
            थप्नुहोस्
          </Button>
          {/* <Button
            type="dashed"
            icon={<FilterOutlined />}
            disabled={users.error || users.loading}
          >
            फिल्टर गर्नुहोस्
          </Button> */}
          {/* <Button
            type="dashed"
            icon={<ExportOutlined />}
            disabled={users.error || users.loading}
          >
            निर्यात गर्नुहोस्
          </Button> */}
        </Space>
      </div>
    )
  }

  const renderTable = () => {
    if (users.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{users.error?.message || "Error occured"}</Typography>
          <Button type="primary" style={{ backgroundColor: "red" }}>
            Try Again
          </Button>
        </Card>
      )
    }

    if (users.loading) {
      return (
        <Card>
          {renderTableHeader()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      )
    }

    const dataSource = users.entities.map((f) => ({
      id: f?.id,
      fullName: f?.fullName || "--",
      username: f?.username || "--",
      email: f?.email || "--",
      blocked: f?.blocked,
      phoneNumber: f?.phoneNumber || "--",
      avatarImagePath: getFilePath(f?.avatar?.url),
      roleName: f?.role?.type === "authenticated" ? "User" : f?.role?.name,
    }))

    const columns: ColumnsType<{}> = [
      {
        title: "Photo",
        dataIndex: "avatarImagePath",
        key: "avatarImagePath",
        render: (avatarImagePath: string) =>
          avatarImagePath ? (
            <Avatar src={<Image src={avatarImagePath} style={{ width: 32 }} />} />
          ) : (
            <Avatar icon={<UserOutlined />} />
          ),
        fixed: "left",
        width: 100,
      },
      {
        title: "नाम/थर",
        dataIndex: "fullName",
        key: "fullName",
        width: 250,
      },
      {
        title: "युजरनेम",
        dataIndex: "username",
        key: "username",
        width: 150,
      },
      {
        title: "इमेल",
        dataIndex: "email",
        key: "email",
        width: 150,
      },
      {
        title: "फोन",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        render: (phoneNumber: string) => phoneNumber || "--",
        width: 200,
      },
      {
        title: "भूमिका",
        dataIndex: "roleName",
        key: "roleName",
        render: (roleName: string) => <Tag color="success">{roleName}</Tag>,
        width: 200,
      },
      {
        title: "अबस्था",
        dataIndex: "blocked",
        key: "blocked",
        render: (blocked: string) => (blocked ? <Tag color="error">Blocked</Tag> : <Tag color="success">Active</Tag>),
        width: 200,
        fixed: "right",
      },
      {
        title: "कार्यहरु",
        key: "action",
        width: 200,
        fixed: "right",
        render: (_: any, record: any) => (
          <Space size="middle">
            <EditOutlined
              style={{ cursor: "pointer", color: token.colorWarning }}
              onClick={() => {
                const user = users?.entities?.find((f) => f.id === record.id)
                createUpdateModalRef.current?.setState({
                  open: true,
                  user: user,
                  action: "update",
                })
              }}
            />
          </Space>
        ),
      },
    ]

    return (
      <Card>
        {renderTableHeader()}
        <Table scroll={{ x: "auto" }} dataSource={dataSource} columns={columns} />

        <CreateORUpdateUserModal ref={createUpdateModalRef} />
      </Card>
    )
  }

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <UnderlineTypography>प्रयोगकर्ताहरु</UnderlineTypography>
      </div>
      {renderTable()}
    </div>
  )
}
