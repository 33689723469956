import { AxiosRequestConfig } from "axios"
import { AppWebRequest } from "../NetworkManager"
class ChalaniHttpService {
  fetchLastUsedChalani(fiscalYear: string, config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {}
    return AppWebRequest({
      method: "get",
      url: "/chalanis/info/getLastUsedChalaniNo",
      ...axiosConfig,
      params: {
        ...params,
        fiscalYear: { $eq: fiscalYear },
      },
    })
  }

  fetchChalanis(fiscalYear: string, config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {}
    const { sort, populate, ...otherParams } = params || {}
    return AppWebRequest({
      method: "get",
      url: "/chalanis",
      ...axiosConfig,
      params: {
        sort: sort || ["updatedAt:desc"],
        populate: populate || ["record", "flightPermission", "bibidh", "policeVerification", "finalLetter"],
        ...otherParams,
        fiscalYear: { $eq: fiscalYear },
      },
    })
  }
}

const chalaniHttpService = new ChalaniHttpService()
export default chalaniHttpService
