import { Select } from "antd";
import React from "react";
import provinceList from '../../../constants/provinceList.json';


interface ProvinceSelectorProps extends React.ComponentProps<typeof Select> {}

export default function ProvinceSelector({ ...props }: ProvinceSelectorProps) {
  return (
    <Select
      {...props}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={provinceList.map(f=>({label:f.name,value:f.name}))}
    />
  );
}
