import { CopyOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, InputNumber, Row, Space, Typography, theme } from "antd"
import { ReactElement, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import InputPhoneNumber from "../../../../../../components/common/inputphonenumber"
import NPDatePicker from "../../../../../../components/common/npdatepicker"
import ApplicantBodySelector from "../../../../../../components/selectors/applicantBodySelector"
import EmployeeSelector from "../../../../../../components/selectors/employeeselector"
import RecommendingBodySelector from "../../../../../../components/selectors/recommendingBodySelector"
import { selectUserId } from "../../../../../../features/auth/authSlice"
import { createRecord } from "../../../../../../features/records/recordsThunk"
import { useAppDispatch, useAppSelector } from "../../../../../../hooks"
import { RecordTypesEnum } from "../../../../../../types/typings.d"

interface CreateLabourConcurranceProps { }

export default function CreateLabourConcurrance(props: CreateLabourConcurranceProps): ReactElement {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { token } = theme.useToken()
  const userId = useAppSelector(selectUserId)
  const [state, setState] = useState({
    loading: false,
    sendLinkToEntryForm: true,
  })

  const { entities, loading, error } = useAppSelector(
    (state) => state.applicantBodies
  );

  const onFinish = (values: any) => {
    setState({ ...state, loading: true })
    dispatch(
      createRecord({
        ...values,
        recordType: RecordTypesEnum.LabourConcurrence,
        enteredBy: userId,
        sendLinkToEntryForm: state.sendLinkToEntryForm,
      })
    )
      .unwrap()
      .then((res) => {
        setState({ ...state, loading: false })
        navigate(-1)
        toast.success(`Create ${state.sendLinkToEntryForm ? "& sent link" : ""}`)
      })
      .catch((err) => {
        setState({ ...state, loading: false })
        console.log("Err", err)
        toast.error(err?.message || "Error creating")
      })
  }

  const copytoClipboardy = () => {
    const seleted = form.getFieldValue('applicantBody')
    const value = entities.filter(item => item.id == seleted)[0].attributes.name
    navigator.clipboard.writeText(value)
  }
  return (
    <div>
      <div className="site-card-border-less-wrapper">
        <Card title={<Typography.Title level={3} className="he-title">कार्य सहमति दर्ता / Labour Concurrence</Typography.Title>} bordered={false}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              invoiceNo: "",
              chalaniNo: "",
              invoiceDate: "",
              recordType: "Labour Concurrence",
              recommendingBody: "",
              applicantBody: "",
              contactPerson: "",
              contactNumber: "",
              pharchyotPerson: null,
              totalEntryCount: "",
              contactEmail: "",
            }}
          >
            <Row gutter={10}>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="दर्ता नं." help="Auto generated by system">
                  <Input readOnly disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्र संख्या" name="invoiceNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="चलानी नं." name="chalaniNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्र मिती" name="invoiceDate">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item
                  label="सिफारिस गर्ने निकाय"
                  name="recommendingBody"
                  rules={[{ required: true, message: "Can not be empty" }]}
                >
                  <RecommendingBodySelector
                    onClickAddOtherItem={(text) => {
                      form.setFieldValue("recommendingBody", text)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Button shape="circle" size="small" icon={<CopyOutlined />} onClick={copytoClipboardy} />
                <Form.Item
                  label="निवेदक निकाय"
                  name="applicantBody"
                  id="applicantBody"
                  rules={[{ required: true, message: "Can not be empty" }]}
                >
                  <ApplicantBodySelector />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="सम्पर्क व्यक्ति" name="contactPerson">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="सम्पर्क नं." name="contactNumber">
                  <InputPhoneNumber noCountryCodeSelection />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="फर्छ्योट गर्ने व्यक्ति" name={"pharchyotPerson"}>
                  <EmployeeSelector />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item
                  label="जम्मा विदेशी संख्या"
                  name={"totalEntryCount"}
                  rules={[{ required: true, message: "Can not be empty" }]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="इमेल" name={"contactEmail"} rules={[{ required: true, message: "Can not be empty" }]}>
                  <Input type="email" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" loading={state.loading}>
                  Save and Send Link
                </Button>
                <Button
                  type="primary"
                  htmlType="reset"
                  style={{ backgroundColor: token.colorWarning }}
                  onClick={() => {
                    form.resetFields()
                  }}
                >
                  Reset
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  )
}
