import { toast } from "react-toastify"
import { logOutFromSystem } from "../../features/auth/authSlice"
import { GlobalState } from "../../features/global/globalSlice"
import { log } from "../../utils/app.debug"
import { appAxiosInstance } from "./NetworkManager"

const setup = (store: any) => {
  appAxiosInstance.interceptors.request.use(
    (config) => {
      const token = store?.getState?.()?.auth?.token
      if (token) {
        // config.headers = { ...config.headers } as Record<string, any>;
        config.headers.set("Authorization", `Bearer ${token}`)
        config.headers["Authorization"] = `Bearer ${token}`
        config.params = config?.params || {}
        config.params.filters = config.params?.filters || {}
        //Fiscall insertation
        // console.log("config", config)
        const selectedFiscalYear = store?.getState?.()?.global?.appSettings
          ?.selectedFiscallYear as GlobalState["appSettings"]["selectedFiscallYear"]
        if (
          selectedFiscalYear &&
          config?.method === "get" &&
          (config?.url?.startsWith?.("/records") ||
            config?.url?.startsWith?.("/flight-permissions") ||
            config?.url?.startsWith("/international-borders"))
          // config?.url?.startsWith("/chalanis")
        ) {
          const oldFilters = config?.params?.filters || {}
          const oldAndFilters = config?.params?.filters?.$and || []
          config["params"]["filters"] = {
            $and: [
              ...oldAndFilters,
              {
                fiscalYear: selectedFiscalYear.fiscalYear,
              },

              // {
              //   createdAt: { $gte: selectedFiscalYear.fromDate },
              // },
              // {
              //   createdAt: { $lte: selectedFiscalYear.toDate },
              // },
              ...Object.entries(oldFilters).map(([key, value]) => ({ [key]: value })),
            ],
          }
        }

        if (
          selectedFiscalYear &&
          config?.method === "post" &&
          (config?.url?.startsWith?.("/records") ||
            config?.url?.startsWith?.("/flight-permissions") ||
            config?.url?.startsWith("/international-borders") ||
            config?.url?.startsWith("/final-letters") ||
            config?.url?.startsWith("/bibidhs"))
        ) {
          // Add fiscall Year
          console.log("selectedFiscalYear", selectedFiscalYear)
          config.data = config?.data || {}
          var payload = config.data
          if (payload instanceof FormData) {
            let data = payload?.get("data") ? JSON.parse(payload?.get("data") as string) : null
            data = { ...data, fiscalYear: selectedFiscalYear.fiscalYear }
            payload.delete("data")
            payload.append("data", JSON.stringify(data))
          } else {
            let data = payload?.data ? payload?.data : payload
            data = { ...data, fiscalYear: selectedFiscalYear.fiscalYear }
            payload = payload?.data ? { data: data } : data
          }

          config["data"] = payload
        }
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  const { dispatch } = store
  appAxiosInstance.interceptors.response.use(
    (res) => {
      return res
    },
    async (error) => {
      var responseError = {}
      if (error.response) {
        if (error.response.status === 401) {
          // case for refresh token
          toast.error("Your session is invalid. Please log in again")
          dispatch(logOutFromSystem)
        }
        log("error response", error.response)
        responseError = {
          ...error.response?.data,
          ...getProperErrorMessageFromError(error.response?.data?.error),
          status: error.response.status,
          // headers: error.response.headers,
        }
      } else if (error.request) {
        responseError = {
          ...error,
          message: "Can not made connection to the server",
        }
      } else {
        responseError = {
          ...error,
          message: "Unexpected error occured!",
        }
      }
      log("exactual error", error)
      log("responseError", responseError)
      return Promise.reject(responseError)
    }
  )
}

function getProperErrorMessageFromError(error: { message: string; details: object & { errors: any[] }; name: string }) {
  const errorObj: {
    message: string
    originalErrorDetails: object
    errorData: any[]
  } = {
    message: "",
    originalErrorDetails: error.details,
    errorData: Array.isArray(error?.details?.errors) ? error?.details?.errors : [],
  }
  try {
    if (typeof error == "string") {
      errorObj.message = error
    } else if (errorObj.errorData.length <= 1) {
      errorObj.message = error.message
    } else if (errorObj.errorData.length > 1) {
      errorObj.message = `${error.message}, ${errorObj?.errorData.map((f) => f.message).join(", ")}`
    } else {
      errorObj.message = error.message || error.name || "Unexpected error occured"
    }
  } catch (er: any) {
    errorObj.message = er.message || "Unexpected error occured"
  }

  return errorObj
}

export default setup
