export default [
  {
    iso: "AFG",
    country: "Afghanistan",
    eu: false,
  },
  {
    iso: "ALA",
    country: "Åland Islands",
    eu: false,
  },
  {
    iso: "ALB",
    country: "Albania",
    eu: false,
  },
  {
    iso: "DZA",
    country: "Algeria",
    eu: false,
  },
  {
    iso: "ASM",
    country: "American Samoa",
    eu: false,
  },
  {
    iso: "AND",
    country: "Andorra",
    eu: false,
  },
  {
    iso: "AGO",
    country: "Angola",
    eu: false,
  },
  {
    iso: "AIA",
    country: "Anguilla",
    eu: false,
  },
  {
    iso: "ATA",
    country: "Antarctica",
    eu: false,
  },
  {
    iso: "ATG",
    country: "Antigua and Barbuda",
    eu: false,
  },
  {
    iso: "ARG",
    country: "Argentina",
    eu: false,
  },
  {
    iso: "ARM",
    country: "Armenia",
    eu: false,
  },
  {
    iso: "ABW",
    country: "Aruba",
    eu: false,
  },
  {
    iso: "AUS",
    country: "Australia",
    eu: false,
  },
  {
    iso: "AUT",
    country: "Austria",
    eu: true,
  },
  {
    iso: "AZE",
    country: "Azerbaijan",
    eu: false,
  },
  {
    iso: "BHS",
    country: "Bahamas",
    eu: false,
  },
  {
    iso: "BHR",
    country: "Bahrain",
    eu: false,
  },
  {
    iso: "BGD",
    country: "Bangladesh",
    eu: false,
  },
  {
    iso: "BRB",
    country: "Barbados",
    eu: false,
  },
  {
    iso: "BLR",
    country: "Belarus",
    eu: false,
  },
  {
    iso: "BEL",
    country: "Belgium",
    eu: true,
  },
  {
    iso: "BLZ",
    country: "Belize",
    eu: false,
  },
  {
    iso: "BEN",
    country: "Benin",
    eu: false,
  },
  {
    iso: "BMU",
    country: "Bermuda",
    eu: false,
  },
  {
    iso: "BTN",
    country: "Bhutan",
    eu: false,
  },
  {
    iso: "BOL",
    country: "Bolivia, Plurinational State of",
    eu: false,
  },
  {
    iso: "BES",
    country: "Bonaire, Sint Eustatius and Saba",
    eu: false,
  },
  {
    iso: "BIH",
    country: "Bosnia and Herzegovina",
    eu: false,
  },
  {
    iso: "BWA",
    country: "Botswana",
    eu: false,
  },
  {
    iso: "BVT",
    country: "Bouvet Island",
    eu: false,
  },
  {
    iso: "BRA",
    country: "Brazil",
    eu: false,
  },
  {
    iso: "IOT",
    country: "British Indian Ocean Territory",
    eu: false,
  },
  {
    iso: "BRN",
    country: "Brunei Darussalam",
    eu: false,
  },
  {
    iso: "BGR",
    country: "Bulgaria",
    eu: true,
  },
  {
    iso: "BFA",
    country: "Burkina Faso",
    eu: false,
  },
  {
    iso: "BDI",
    country: "Burundi",
    eu: false,
  },
  {
    iso: "KHM",
    country: "Cambodia",
    eu: false,
  },
  {
    iso: "CMR",
    country: "Cameroon",
    eu: false,
  },
  {
    iso: "CAN",
    country: "Canada",
    eu: false,
  },
  {
    iso: "CPV",
    country: "Cape Verde",
    eu: false,
  },
  {
    iso: "CYM",
    country: "Cayman Islands",
    eu: false,
  },
  {
    iso: "CAF",
    country: "Central African Republic",
    eu: false,
  },
  {
    iso: "TCD",
    country: "Chad",
    eu: false,
  },
  {
    iso: "CHL",
    country: "Chile",
    eu: false,
  },
  {
    iso: "CHN",
    country: "China",
    eu: false,
  },
  {
    iso: "CXR",
    country: "Christmas Island",
    eu: false,
  },
  {
    iso: "CCK",
    country: "Cocos (Keeling) Islands",
    eu: false,
  },
  {
    iso: "COL",
    country: "Colombia",
    eu: false,
  },
  {
    iso: "COM",
    country: "Comoros",
    eu: false,
  },
  {
    iso: "COG",
    country: "Congo",
    eu: false,
  },
  {
    iso: "COD",
    country: "Congo, the Democratic Republic of the",
    eu: false,
  },
  {
    iso: "COK",
    country: "Cook Islands",
    eu: false,
  },
  {
    iso: "CRI",
    country: "Costa Rica",
    eu: false,
  },
  {
    iso: "CIV",
    country: "Côte d'Ivoire",
    eu: false,
  },
  {
    iso: "HRV",
    country: "Croatia",
    eu: true,
  },
  {
    iso: "CUB",
    country: "Cuba",
    eu: false,
  },
  {
    iso: "CUW",
    country: "Curaçao",
    eu: false,
  },
  {
    iso: "CYP",
    country: "Cyprus",
    eu: true,
  },
  {
    iso: "CZE",
    country: "Czech Republic",
    eu: true,
  },
  {
    iso: "DNK",
    country: "Denmark",
    eu: true,
  },
  {
    iso: "DJI",
    country: "Djibouti",
    eu: false,
  },
  {
    iso: "DMA",
    country: "Dominica",
    eu: false,
  },
  {
    iso: "DOM",
    country: "Dominican Republic",
    eu: false,
  },
  {
    iso: "ECU",
    country: "Ecuador",
    eu: false,
  },
  {
    iso: "EGY",
    country: "Egypt",
    eu: false,
  },
  {
    iso: "SLV",
    country: "El Salvador",
    eu: false,
  },
  {
    iso: "GNQ",
    country: "Equatorial Guinea",
    eu: false,
  },
  {
    iso: "ERI",
    country: "Eritrea",
    eu: false,
  },
  {
    iso: "EST",
    country: "Estonia",
    eu: true,
  },
  {
    iso: "ETH",
    country: "Ethiopia",
    eu: false,
  },
  {
    iso: "FLK",
    country: "Falkland Islands (Malvinas)",
    eu: false,
  },
  {
    iso: "FRO",
    country: "Faroe Islands",
    eu: false,
  },
  {
    iso: "FJI",
    country: "Fiji",
    eu: false,
  },
  {
    iso: "FIN",
    country: "Finland",
    eu: true,
  },
  {
    iso: "FRA",
    country: "France",
    eu: true,
  },
  {
    iso: "GUF",
    country: "French Guiana",
    eu: false,
  },
  {
    iso: "PYF",
    country: "French Polynesia",
    eu: false,
  },
  {
    iso: "ATF",
    country: "French Southern Territories",
    eu: false,
  },
  {
    iso: "GAB",
    country: "Gabon",
    eu: false,
  },
  {
    iso: "GMB",
    country: "Gambia",
    eu: false,
  },
  {
    iso: "GEO",
    country: "Georgia",
    eu: false,
  },
  {
    iso: "DEU",
    country: "Germany",
    eu: true,
  },
  {
    iso: "GHA",
    country: "Ghana",
    eu: false,
  },
  {
    iso: "GIB",
    country: "Gibraltar",
    eu: false,
  },
  {
    iso: "GRC",
    country: "Greece",
    eu: true,
  },
  {
    iso: "GRL",
    country: "Greenland",
    eu: false,
  },
  {
    iso: "GRD",
    country: "Grenada",
    eu: false,
  },
  {
    iso: "GLP",
    country: "Guadeloupe",
    eu: false,
  },
  {
    iso: "GUM",
    country: "Guam",
    eu: false,
  },
  {
    iso: "GTM",
    country: "Guatemala",
    eu: false,
  },
  {
    iso: "GGY",
    country: "Guernsey",
    eu: false,
  },
  {
    iso: "GIN",
    country: "Guinea",
    eu: false,
  },
  {
    iso: "GNB",
    country: "Guinea-Bissau",
    eu: false,
  },
  {
    iso: "GUY",
    country: "Guyana",
    eu: false,
  },
  {
    iso: "HTI",
    country: "Haiti",
    eu: false,
  },
  {
    iso: "HMD",
    country: "Heard Island and McDonald Islands",
    eu: false,
  },
  {
    iso: "VAT",
    country: "Holy See (Vatican City State)",
    eu: false,
  },
  {
    iso: "HND",
    country: "Honduras",
    eu: false,
  },
  {
    iso: "HKG",
    country: "Hong Kong",
    eu: false,
  },
  {
    iso: "HUN",
    country: "Hungary",
    eu: true,
  },
  {
    iso: "ISL",
    country: "Iceland",
    eu: false,
  },
  {
    iso: "IND",
    country: "India",
    eu: false,
  },
  {
    iso: "IDN",
    country: "Indonesia",
    eu: false,
  },
  {
    iso: "IRN",
    country: "Iran, Islamic Republic of",
    eu: false,
  },
  {
    iso: "IRQ",
    country: "Iraq",
    eu: false,
  },
  {
    iso: "IRL",
    country: "Ireland",
    eu: true,
  },
  {
    iso: "IMN",
    country: "Isle of Man",
    eu: false,
  },
  {
    iso: "ISR",
    country: "Israel",
    eu: false,
  },
  {
    iso: "ITA",
    country: "Italy",
    eu: true,
  },
  {
    iso: "JAM",
    country: "Jamaica",
    eu: false,
  },
  {
    iso: "JPN",
    country: "Japan",
    eu: false,
  },
  {
    iso: "JEY",
    country: "Jersey",
    eu: false,
  },
  {
    iso: "JOR",
    country: "Jordan",
    eu: false,
  },
  {
    iso: "KAZ",
    country: "Kazakhstan",
    eu: false,
  },
  {
    iso: "KEN",
    country: "Kenya",
    eu: false,
  },
  {
    iso: "KIR",
    country: "Kiribati",
    eu: false,
  },
  {
    iso: "PRK",
    country: "Korea, Democratic People's Republic of",
    eu: false,
  },
  {
    iso: "KOR",
    country: "Korea, Republic of",
    eu: false,
  },
  {
    iso: "KWT",
    country: "Kuwait",
    eu: false,
  },
  {
    iso: "KGZ",
    country: "Kyrgyzstan",
    eu: false,
  },
  {
    iso: "LAO",
    country: "Lao People's Democratic Republic",
    eu: false,
  },
  {
    iso: "LVA",
    country: "Latvia",
    eu: true,
  },
  {
    iso: "LBN",
    country: "Lebanon",
    eu: false,
  },
  {
    iso: "LSO",
    country: "Lesotho",
    eu: false,
  },
  {
    iso: "LBR",
    country: "Liberia",
    eu: false,
  },
  {
    iso: "LBY",
    country: "Libya",
    eu: false,
  },
  {
    iso: "LIE",
    country: "Liechtenstein",
    eu: false,
  },
  {
    iso: "LTU",
    country: "Lithuania",
    eu: true,
  },
  {
    iso: "LUX",
    country: "Luxembourg",
    eu: true,
  },
  {
    iso: "MAC",
    country: "Macao",
    eu: false,
  },
  {
    iso: "MKD",
    country: "Macedonia, the former Yugoslav Republic of",
    eu: false,
  },
  {
    iso: "MDG",
    country: "Madagascar",
    eu: false,
  },
  {
    iso: "MWI",
    country: "Malawi",
    eu: false,
  },
  {
    iso: "MYS",
    country: "Malaysia",
    eu: false,
  },
  {
    iso: "MDV",
    country: "Maldives",
    eu: false,
  },
  {
    iso: "MLI",
    country: "Mali",
    eu: false,
  },
  {
    iso: "MLT",
    country: "Malta",
    eu: true,
  },
  {
    iso: "MHL",
    country: "Marshall Islands",
    eu: false,
  },
  {
    iso: "MTQ",
    country: "Martinique",
    eu: false,
  },
  {
    iso: "MRT",
    country: "Mauritania",
    eu: false,
  },
  {
    iso: "MUS",
    country: "Mauritius",
    eu: false,
  },
  {
    iso: "MYT",
    country: "Mayotte",
    eu: false,
  },
  {
    iso: "MEX",
    country: "Mexico",
    eu: false,
  },
  {
    iso: "FSM",
    country: "Micronesia, Federated States of",
    eu: false,
  },
  {
    iso: "MDA",
    country: "Moldova, Republic of",
    eu: false,
  },
  {
    iso: "MCO",
    country: "Monaco",
    eu: false,
  },
  {
    iso: "MNG",
    country: "Mongolia",
    eu: false,
  },
  {
    iso: "MNE",
    country: "Montenegro",
    eu: false,
  },
  {
    iso: "MSR",
    country: "Montserrat",
    eu: false,
  },
  {
    iso: "MAR",
    country: "Morocco",
    eu: false,
  },
  {
    iso: "MOZ",
    country: "Mozambique",
    eu: false,
  },
  {
    iso: "MMR",
    country: "Myanmar",
    eu: false,
  },
  {
    iso: "NAM",
    country: "Namibia",
    eu: false,
  },
  {
    iso: "NRU",
    country: "Nauru",
    eu: false,
  },
  {
    iso: "NPL",
    country: "Nepal",
    eu: false,
  },
  {
    iso: "NLD",
    country: "Netherlands",
    eu: true,
  },
  {
    iso: "NCL",
    country: "New Caledonia",
    eu: false,
  },
  {
    iso: "NZL",
    country: "New Zealand",
    eu: false,
  },
  {
    iso: "NIC",
    country: "Nicaragua",
    eu: false,
  },
  {
    iso: "NER",
    country: "Niger",
    eu: false,
  },
  {
    iso: "NGA",
    country: "Nigeria",
    eu: false,
  },
  {
    iso: "NIU",
    country: "Niue",
    eu: false,
  },
  {
    iso: "NFK",
    country: "Norfolk Island",
    eu: false,
  },
  {
    iso: "MNP",
    country: "Northern Mariana Islands",
    eu: false,
  },
  {
    iso: "NOR",
    country: "Norway",
    eu: false,
  },
  {
    iso: "OMN",
    country: "Oman",
    eu: false,
  },
  {
    iso: "PAK",
    country: "Pakistan",
    eu: false,
  },
  {
    iso: "PLW",
    country: "Palau",
    eu: false,
  },
  {
    iso: "PSE",
    country: "Palestinian Territory, Occupied",
    eu: false,
  },
  {
    iso: "PAN",
    country: "Panama",
    eu: false,
  },
  {
    iso: "PNG",
    country: "Papua New Guinea",
    eu: false,
  },
  {
    iso: "PRY",
    country: "Paraguay",
    eu: false,
  },
  {
    iso: "PER",
    country: "Peru",
    eu: false,
  },
  {
    iso: "PHL",
    country: "Philippines",
    eu: false,
  },
  {
    iso: "PCN",
    country: "Pitcairn",
    eu: false,
  },
  {
    iso: "POL",
    country: "Poland",
    eu: true,
  },
  {
    iso: "PRT",
    country: "Portugal",
    eu: true,
  },
  {
    iso: "PRI",
    country: "Puerto Rico",
    eu: false,
  },
  {
    iso: "QAT",
    country: "Qatar",
    eu: false,
  },
  {
    iso: "REU",
    country: "Réunion",
    eu: false,
  },
  {
    iso: "ROU",
    country: "Romania",
    eu: true,
  },
  {
    iso: "RUS",
    country: "Russian Federation",
    eu: false,
  },
  {
    iso: "RWA",
    country: "Rwanda",
    eu: false,
  },
  {
    iso: "BLM",
    country: "Saint Barthélemy",
    eu: false,
  },
  {
    iso: "SHN",
    country: "Saint Helena, Ascension and Tristan da Cunha",
    eu: false,
  },
  {
    iso: "KNA",
    country: "Saint Kitts and Nevis",
    eu: false,
  },
  {
    iso: "LCA",
    country: "Saint Lucia",
    eu: false,
  },
  {
    iso: "MAF",
    country: "Saint Martin (French part)",
    eu: false,
  },
  {
    iso: "SPM",
    country: "Saint Pierre and Miquelon",
    eu: false,
  },
  {
    iso: "VCT",
    country: "Saint Vincent and the Grenadines",
    eu: false,
  },
  {
    iso: "WSM",
    country: "Samoa",
    eu: false,
  },
  {
    iso: "SMR",
    country: "San Marino",
    eu: false,
  },
  {
    iso: "STP",
    country: "Sao Tome and Principe",
    eu: false,
  },
  {
    iso: "SAU",
    country: "Saudi Arabia",
    eu: false,
  },
  {
    iso: "SEN",
    country: "Senegal",
    eu: false,
  },
  {
    iso: "SRB",
    country: "Serbia",
    eu: false,
  },
  {
    iso: "SYC",
    country: "Seychelles",
    eu: false,
  },
  {
    iso: "SLE",
    country: "Sierra Leone",
    eu: false,
  },
  {
    iso: "SGP",
    country: "Singapore",
    eu: false,
  },
  {
    iso: "SXM",
    country: "Sint Maarten (Dutch part)",
    eu: false,
  },
  {
    iso: "SVK",
    country: "Slovakia",
    eu: true,
  },
  {
    iso: "SVN",
    country: "Slovenia",
    eu: true,
  },
  {
    iso: "SLB",
    country: "Solomon Islands",
    eu: false,
  },
  {
    iso: "SOM",
    country: "Somalia",
    eu: false,
  },
  {
    iso: "ZAF",
    country: "South Africa",
    eu: false,
  },
  {
    iso: "SGS",
    country: "South Georgia and the South Sandwich Islands",
    eu: false,
  },
  {
    iso: "SSD",
    country: "South Sudan",
    eu: false,
  },
  {
    iso: "ESP",
    country: "Spain",
    eu: true,
  },
  {
    iso: "LKA",
    country: "Sri Lanka",
    eu: false,
  },
  {
    iso: "SDN",
    country: "Sudan",
    eu: false,
  },
  {
    iso: "SUR",
    country: "Suricountry",
    eu: false,
  },
  {
    iso: "SJM",
    country: "Svalbard and Jan Mayen",
    eu: false,
  },
  {
    iso: "SWZ",
    country: "Swaziland",
    eu: false,
  },
  {
    iso: "SWE",
    country: "Sweden",
    eu: true,
  },
  {
    iso: "CHE",
    country: "Switzerland",
    eu: false,
  },
  {
    iso: "SYR",
    country: "Syrian Arab Republic",
    eu: false,
  },
  {
    iso: "TWN",
    country: "Taiwan, Province of China",
    eu: false,
  },
  {
    iso: "TJK",
    country: "Tajikistan",
    eu: false,
  },
  {
    iso: "TZA",
    country: "Tanzania, United Republic of",
    eu: false,
  },
  {
    iso: "THA",
    country: "Thailand",
    eu: false,
  },
  {
    iso: "TLS",
    country: "Timor-Leste",
    eu: false,
  },
  {
    iso: "TGO",
    country: "Togo",
    eu: false,
  },
  {
    iso: "TKL",
    country: "Tokelau",
    eu: false,
  },
  {
    iso: "TON",
    country: "Tonga",
    eu: false,
  },
  {
    iso: "TTO",
    country: "Trinidad and Tobago",
    eu: false,
  },
  {
    iso: "TUN",
    country: "Tunisia",
    eu: false,
  },
  {
    iso: "TUR",
    country: "Turkey",
    eu: false,
  },
  {
    iso: "TKM",
    country: "Turkmenistan",
    eu: false,
  },
  {
    iso: "TCA",
    country: "Turks and Caicos Islands",
    eu: false,
  },
  {
    iso: "TUV",
    country: "Tuvalu",
    eu: false,
  },
  {
    iso: "UGA",
    country: "Uganda",
    eu: false,
  },
  {
    iso: "UKR",
    country: "Ukraine",
    eu: false,
  },
  {
    iso: "ARE",
    country: "United Arab Emirates",
    eu: false,
  },
  {
    iso: "GBR",
    country: "United Kingdom",
    eu: true,
  },
  {
    iso: "USA",
    country: "United States",
    eu: false,
  },
  {
    iso: "UMI",
    country: "United States Minor Outlying Islands",
    eu: false,
  },
  {
    iso: "URY",
    country: "Uruguay",
    eu: false,
  },
  {
    iso: "UZB",
    country: "Uzbekistan",
    eu: false,
  },
  {
    iso: "VUT",
    country: "Vanuatu",
    eu: false,
  },
  {
    iso: "VEN",
    country: "Venezuela, Bolivarian Republic of",
    eu: false,
  },
  {
    iso: "VNM",
    country: "Viet Nam",
    eu: false,
  },
  {
    iso: "VGB",
    country: "Virgin Islands, British",
    eu: false,
  },
  {
    iso: "VIR",
    country: "Virgin Islands, U.S.",
    eu: false,
  },
  {
    iso: "WLF",
    country: "Wallis and Futuna",
    eu: false,
  },
  {
    iso: "ESH",
    country: "Western Sahara",
    eu: false,
  },
  {
    iso: "YEM",
    country: "Yemen",
    eu: false,
  },
  {
    iso: "ZMB",
    country: "Zambia",
    eu: false,
  },
  {
    iso: "ZWE",
    country: "Zimbabwe",
    eu: false,
  },
]
