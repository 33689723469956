import { Typography, theme } from 'antd';
import React, { CSSProperties } from 'react';

interface UnderlineTypographyProps
  extends React.ComponentProps<typeof Typography.Title> {
  underlineColor?: string;
  underlineWidthPercentage?: number;
  containerStyle?: CSSProperties;
}

export default function UnderlineTypography({
  underlineColor,
  underlineWidthPercentage,
  containerStyle,
  className,
  children,
  ...props
}: UnderlineTypographyProps) {
  const { token } = theme.useToken();
  return (
    <div className="underline-container" style={{ ...containerStyle }}>
      <Typography.Title
        level={3}
        className={[className, 'content'].join(' ')}
        {...props}
      >
        {children}
      </Typography.Title>
      <style>
        {`
          .underline-container {
            position: relative;
            display: inline-block;
            padding-bottom: 2px;
            margin-bottom:3px;
          }
          .content {
            display: inline;
            margin-bottom: 0px;
            padding-bottom: 0px;
          }
          .content::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: ${underlineWidthPercentage || 90}%;
            height: 2.5px;
            background-color: ${underlineColor || token.colorPrimary};
          }
        `}
      </style>
    </div>
  );
}
