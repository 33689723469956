import React, { ReactElement, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Select, Typography } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { fetchDesignations } from "../../../features/designations/designationsThunk";

interface DersignationSelectorProps extends React.ComponentProps<typeof Select> {}

export default function DersignationSelector({
  disabled,
  ...props
}: DersignationSelectorProps): ReactElement {
  const { entities, loading, error } = useAppSelector(
    (state) => state.designations
  );
  const dispatch = useAppDispatch();

  const fetchData = () => {
    dispatch(fetchDesignations());
  };

  return (
    <div>
      <Select
        {...props}
        loading={loading}
        disabled={disabled||loading || Boolean(error)}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={entities?.map?.((f) => ({
          value: f.id,
          label: `${f?.attributes?.name}`,
        }))}
      />
      {error && (
        <Typography.Paragraph style={{ color: "red" }}>
          Error fetching,{" "}
          <span
            style={{ cursor: "pointer" }}
            title="Reload"
            onClick={fetchData}
          >
            Try again <ReloadOutlined style={{ fontSize: 13 }} />
          </span>
        </Typography.Paragraph>
      )}
    </div>
  );
}
