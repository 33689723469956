import { Col, Row, Space } from "antd";
import React, { ReactElement } from "react";
import RecordsCountStats from "./components/recordscountstats";
import UsersCountStats from "./components/userscountstats";
import ThirdPartiesCountStats from "./components/thirdpartiescountstats";
import EmployeesCountStats from "./components/employeescountstats";
import BordersCountStats from "./components/borderscountstats";
import FlightPermissionsCountStats from "./components/flightPermissionsCountstats";
import RecordTypeChart from "./components/charts/barcharts/recordtypechart";
import UsersRoleChart from "./components/charts/doughnutcharts/usersrolechart";
import RecordsTimeSeries from "./components/charts/timeseries/recordstimeseries";
import LatestRecords from "./components/latestRecords";

interface Props {}

function HomePage(props: Props): ReactElement {
  return (
    <div>
      <div id="analytics-count">
        <Space wrap size={30}>
          <RecordsCountStats />
          <UsersCountStats />
          <ThirdPartiesCountStats />
          <EmployeesCountStats />
          <BordersCountStats />
          <FlightPermissionsCountStats />
        </Space>
      </div>

      <div id="analytics-charts" style={{ marginTop: 40 }}>
        <Row
          gutter={[20, 20]}
          style={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Col xs={24} md={16}>
            <RecordTypeChart />
          </Col>
          <Col xs={24} md={8}>
            <UsersRoleChart />
          </Col>
        </Row>

        {/* <Row
          gutter={[20, 20]}
          style={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop:50
          }}
        >
          <Col xs={24} md={10}>
            <FlightPermissionStatusChart />
          </Col>
          <Col xs={24} md={14}>
            <TopRecentAuditLogs/>
          </Col>
        </Row> */}

        <Row
          gutter={[20, 20]}
          style={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop:50
          }}
        >
          <Col xs={24} md={15}>
            <RecordsTimeSeries/>
          </Col>
          <Col xs={24} md={9}>
            <LatestRecords/>
          </Col>
        </Row>

      </div>
    </div>
  );
}

export default HomePage;
