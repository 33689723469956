import { ReactElement } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { selectProfile, selectToken } from "../features/auth/authSlice"
import { useAppSelector } from "../hooks"
import Page404 from "../pages/404/404.page"
import SignInPage from "../pages/auth/signin.page"
import Department from "../pages/dashboard/employees/department"
import Designation from "../pages/dashboard/employees/designation"
import EmployeesHome from "../pages/dashboard/employees/employeeshome"
import HomePage from "../pages/dashboard/home/home.page"
import RecordHome from "../pages/dashboard/records/recordhome"
import CreateArrivalVisa from "../pages/dashboard/records/registration/arrivalvisa/create"
import UpdateArrivalVisa from "../pages/dashboard/records/registration/arrivalvisa/update"
import ViewArrivalVisa from "../pages/dashboard/records/registration/arrivalvisa/view"
import CreateDeportion from "../pages/dashboard/records/registration/deportation/create"
import UpdateDeportion from "../pages/dashboard/records/registration/deportation/update"
import ViewDeportion from "../pages/dashboard/records/registration/deportation/view"
import CreateFlightPermission from "../pages/dashboard/records/registration/flightpermission/create"
import UpdateFlightPermission from "../pages/dashboard/records/registration/flightpermission/update"
import ViewFlightPermission from "../pages/dashboard/records/registration/flightpermission/view"
import CreateFreeArrivalVisa from "../pages/dashboard/records/registration/freearrivalvisa/create"
import UpdateFreeArrivalVisa from "../pages/dashboard/records/registration/freearrivalvisa/update"
import ViewFreeArrivalVisa from "../pages/dashboard/records/registration/freearrivalvisa/view"
import CreateFreeTrekkingPermit from "../pages/dashboard/records/registration/freetrekkingpermit/create"
import UpdateFreeTrekkingPermit from "../pages/dashboard/records/registration/freetrekkingpermit/update"
import ViewFreeTrekkingPermit from "../pages/dashboard/records/registration/freetrekkingpermit/view"
import CreateFreeVisaExtension from "../pages/dashboard/records/registration/freevisaextension/create"
import UpdateFreeVisaExtension from "../pages/dashboard/records/registration/freevisaextension/update"
import ViewFreeVisaExtension from "../pages/dashboard/records/registration/freevisaextension/view"
// import CreateInternationalBoarder from "../pages/dashboard/records/registration/internationalborder/create";
// import UpdateInternationalBoarder from "../pages/dashboard/records/registration/internationalborder/update";
// import ViewInternationalBoarder from "../pages/dashboard/records/registration/internationalborder/view";
import BibidhHome from "../pages/dashboard/bibidhs/bibidhHome"
import CreateBibidh from "../pages/dashboard/bibidhs/bibidhTemplates/create"
import UpdateBibidh from "../pages/dashboard/bibidhs/bibidhTemplates/update"
import HomePageForFlightCompany from "../pages/dashboard/home/forflightcompany/home.page"
import Chalani from "../pages/dashboard/records/chalani"
import ListFlightPermissionForFlightCompany from "../pages/dashboard/records/registration/flightpermission/forflightcompany/list/index"
import AddDocumentToLabourConcurrance from "../pages/dashboard/records/registration/labourconcurrance/addDocument"
import AddEntryToLabourConcurrance from "../pages/dashboard/records/registration/labourconcurrance/addEntry"
import CreateLabourConcurrance from "../pages/dashboard/records/registration/labourconcurrance/create/index"
import ListLabourConcurranceForPolice from "../pages/dashboard/records/registration/labourconcurrance/forPolice/list"
import UpdateLabourConcurrance from "../pages/dashboard/records/registration/labourconcurrance/update/index"
import ViewLabourConcurrance from "../pages/dashboard/records/registration/labourconcurrance/view/index"
import CreateMultipleEntryVisa from "../pages/dashboard/records/registration/multipleentryvisa/create"
import UpdateMultipleEntryVisa from "../pages/dashboard/records/registration/multipleentryvisa/update"
import ViewMultipleEntryVisa from "../pages/dashboard/records/registration/multipleentryvisa/view"
import CreateNrnStudyVisa from "../pages/dashboard/records/registration/nrnstudyvisa/create"
import UpdateNrnStudyVisa from "../pages/dashboard/records/registration/nrnstudyvisa/update"
import ViewNrnStudyVisa from "../pages/dashboard/records/registration/nrnstudyvisa/view"
import RegistrationHome from "../pages/dashboard/records/registration/registrationhome"
import CreateTravelDocument from "../pages/dashboard/records/registration/traveldocument/create"
import UpdateTravelDocument from "../pages/dashboard/records/registration/traveldocument/update"
import ViewTravelDocument from "../pages/dashboard/records/registration/traveldocument/view"
import Reports from "../pages/dashboard/reports"
import RolesHome from "../pages/dashboard/roles/roleshome"
import SummaryLog from "../pages/dashboard/summarylog"
import TemplateHome from "../pages/dashboard/templates/templatehome"
import ThirdPartiesHome from "../pages/dashboard/thirdparty/thirdpartyhome/index"
import UIPolicy from "../pages/dashboard/uipolicies"
import UsersHome from "../pages/dashboard/users/userhome"
import { PrivateRoute, PublicRoute } from "./components/routes.component"
import AuthLayout from "./layouts/auth.layout"
import FlightCompanyDashboardLayout from "./layouts/dashboard/flightcompany/dashboard.layout"
import PoliceDashboardLayout from "./layouts/dashboard/police/dashboard.layout"
import SuperAdminDashboardLayout from "./layouts/dashboard/superadmin/dashboard.layout"

interface Props {}

function AppRoutes(props: Props): ReactElement {
  const token = useAppSelector(selectToken)
  const profile = useAppSelector(selectProfile)

  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/auth" replace />} />
        <Route
          path="/auth"
          element={
            <PublicRoute isLoggedIn={Boolean(token)}>
              <AuthLayout />
            </PublicRoute>
          }
        >
          <Route index path="/auth" element={<Navigate to="/auth/signin" replace />} />
          <Route path="/auth/signin" element={<SignInPage />} />
        </Route>

        {/* Super Admin Layout */}
        {(profile?.data?.role?.type === "super_admin" ||
          profile?.data?.role?.type === "admin" ||
          profile?.data?.role?.type === "thirdparty" ||
          profile?.data?.role?.type === "employee" ||
          profile?.data?.role?.type === "authenticated") && (
          <Route
            path="/dashboard"
            element={
              <PrivateRoute isLoggedIn={Boolean(token)}>
                <SuperAdminDashboardLayout />
              </PrivateRoute>
            }
          >
            <Route index path="/dashboard" element={<Navigate to="/dashboard/home" replace />} />
            <Route path="/dashboard/home" element={<HomePage />} />
            <Route path="/dashboard/records" element={<RecordHome />} />
            <Route path="/dashboard/records/registration" element={<RegistrationHome />} />

            <Route path="/dashboard/records/registration/record-by-chalani" element={<Chalani />} />

            <Route path="/dashboard/records/registration/create-arrivalvisa" element={<CreateArrivalVisa />} />
            <Route path="/dashboard/records/registration/update-arrivalvisa/:id" element={<UpdateArrivalVisa />} />
            <Route path="/dashboard/records/registration/view-arrivalvisa/:id" element={<ViewArrivalVisa />} />

            <Route path="/dashboard/records/registration/create-deportation" element={<CreateDeportion />} />
            <Route path="/dashboard/records/registration/update-deportation/:id" element={<UpdateDeportion />} />
            <Route path="/dashboard/records/registration/view-deportation/:id" element={<ViewDeportion />} />

            <Route
              path="/dashboard/records/registration/create-flightpermission"
              element={<CreateFlightPermission />}
            />
            <Route
              path="/dashboard/records/registration/update-flightpermission/:id"
              element={<UpdateFlightPermission />}
            />
            <Route
              path="/dashboard/records/registration/view-flightpermission/:id"
              element={<ViewFlightPermission />}
            />

            <Route path="/dashboard/records/registration/create-freearrivalvisa" element={<CreateFreeArrivalVisa />} />
            <Route
              path="/dashboard/records/registration/update-freearrivalvisa/:id"
              element={<UpdateFreeArrivalVisa />}
            />
            <Route path="/dashboard/records/registration/view-freearrivalvisa/:id" element={<ViewFreeArrivalVisa />} />

            <Route
              path="/dashboard/records/registration/create-freetrekkingpermit"
              element={<CreateFreeTrekkingPermit />}
            />
            <Route
              path="/dashboard/records/registration/update-freetrekkingpermit/:id"
              element={<UpdateFreeTrekkingPermit />}
            />
            <Route
              path="/dashboard/records/registration/view-freetrekkingpermit/:id"
              element={<ViewFreeTrekkingPermit />}
            />

            <Route
              path="/dashboard/records/registration/create-freevisaextension"
              element={<CreateFreeVisaExtension />}
            />
            <Route
              path="/dashboard/records/registration/update-freevisaextension/:id"
              element={<UpdateFreeVisaExtension />}
            />
            <Route
              path="/dashboard/records/registration/view-freevisaextension/:id"
              element={<ViewFreeVisaExtension />}
            />

            {/* <Route
              path="/dashboard/records/registration/create-internationalboarder"
              element={<CreateInternationalBoarder />}
            /> */}
            {/* <Route
              path="/dashboard/records/registration/update-internationalboarder/:id"
              element={<UpdateInternationalBoarder />}
            /> */}
            {/* <Route
              path="/dashboard/records/registration/view-internationalboarder/:id"
              element={<ViewInternationalBoarder />}
            /> */}

            <Route
              path="/dashboard/records/registration/create-labourconcurrance"
              element={<CreateLabourConcurrance />}
            />
            <Route
              path="/dashboard/records/registration/update-labourconcurrance/:id"
              element={<UpdateLabourConcurrance />}
            />
            <Route
              path="/dashboard/records/registration/view-labourconcurrance/:id"
              element={<ViewLabourConcurrance />}
            />

            <Route
              path="/dashboard/records/registration/create-multipleentryvisa"
              element={<CreateMultipleEntryVisa />}
            />
            <Route
              path="/dashboard/records/registration/update-multipleentryvisa/:id"
              element={<UpdateMultipleEntryVisa />}
            />
            <Route
              path="/dashboard/records/registration/view-multipleentryvisa/:id"
              element={<ViewMultipleEntryVisa />}
            />

            <Route path="/dashboard/records/registration/create-nrnstudyvisa" element={<CreateNrnStudyVisa />} />
            <Route path="/dashboard/records/registration/update-nrnstudyvisa/:id" element={<UpdateNrnStudyVisa />} />
            <Route path="/dashboard/records/registration/view-nrnstudyvisa/:id" element={<ViewNrnStudyVisa />} />

            <Route path="/dashboard/records/registration/create-traveldocument" element={<CreateTravelDocument />} />
            <Route
              path="/dashboard/records/registration/update-traveldocument/:id"
              element={<UpdateTravelDocument />}
            />
            <Route path="/dashboard/records/registration/view-traveldocument/:id" element={<ViewTravelDocument />} />

            <Route path="/dashboard/users" element={<UsersHome />} />
            <Route path="/dashboard/thirdparties" element={<ThirdPartiesHome />} />
            <Route path="/dashboard/employees" element={<EmployeesHome />} />
            <Route path="/dashboard/employees/departments" element={<Department />} />
            <Route path="/dashboard/employees/designations" element={<Designation />} />

            <Route path="/dashboard/employees/designation" element={<Designation />} />

            <Route path="/dashboard/roles" element={<RolesHome />} />
            <Route path="/dashboard/uipolicies" element={<UIPolicy />} />

            <Route path="/dashboard/summarylogs" element={<SummaryLog />} />
            <Route path="/dashboard/reports" element={<Reports />} />
            <Route path="/dashboard/templates" element={<TemplateHome />} />

            <Route path="/dashboard/bibidhs" element={<BibidhHome />} />
            <Route path="/dashboard/bibidhs/create" element={<CreateBibidh />} />
            <Route path="/dashboard/bibidhs/update/:id" element={<UpdateBibidh />} />
          </Route>
        )}

        {/* Flight Company Layout */}
        {profile?.data?.role?.type === "flight_company" && (
          <Route
            path="/dashboard"
            element={
              <PrivateRoute isLoggedIn={Boolean(token)}>
                <FlightCompanyDashboardLayout />
              </PrivateRoute>
            }
          >
            <Route index path="/dashboard" element={<Navigate to="/dashboard/home" replace />} />
            <Route path="/dashboard/home" element={<HomePageForFlightCompany />} />
            <Route path="/dashboard/flights" element={<ListFlightPermissionForFlightCompany />} />
            <Route
              path="/dashboard/flights/create-flightpermission"
              element={<CreateFlightPermission entryByFlightCompany={true} />}
            />
            <Route
              path="/dashboard/flights/update-flightpermission/:id"
              element={<UpdateFlightPermission entryByFlightCompany={true} />}
            />
            <Route
              path="/dashboard/flights/view-flightpermission/:id"
              element={<ViewFlightPermission entryByFlightCompany={true} />}
            />
          </Route>
        )}

        {/* Police Layout */}
        {profile?.data?.role?.type === "police" && (
          <Route
            path="/dashboard"
            element={
              <PrivateRoute isLoggedIn={Boolean(token)}>
                <PoliceDashboardLayout />
              </PrivateRoute>
            }
          >
            <Route index path="/dashboard" element={<Navigate to="/dashboard/list-labourconcurrance" replace />} />
            <Route path="/dashboard/list-labourconcurrance" element={<ListLabourConcurranceForPolice />} />
          </Route>
        )}

        <Route path="/add-entries-to-labourconcurrance" element={<AddEntryToLabourConcurrance />} />
        <Route path="/add-document-to-labourconcurrance" element={<AddDocumentToLabourConcurrance />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </div>
  )
}

export default AppRoutes
