import { CloudDownloadOutlined, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, Form, Input, Row, Space, Switch, Tag, Typography } from "antd"
import qs from "qs"
import React, { ReactElement, useEffect, useState } from "react"
import { Bars } from "react-loader-spinner"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import NPDatePicker from "../../../../../../components/common/npdatepicker"
import UserEntryFormRow from "../../../../../../components/userentryFormRow"
import recordHttpService from "../../../../../../services/https/apis/records.http.service"
import { RecordStatus, RecordTypesEnum } from "../../../../../../types/typings.d"

import PdfViewer from "../../../../../../components/common/pdfviewer"
import SelfControlModal, { SelfControlModalRefsHandler } from "../../../../../../components/common/selfcontrolmodal"
import Editor from "../../../../../../components/editor"
import DistrictSelector from "../../../../../../components/selectors/districtSelector"
import EmployeeSelector from "../../../../../../components/selectors/employeeselector"
import RecommendingBodySelector from "../../../../../../components/selectors/recommendingBodySelector"
import { freetrekkingUserEntryFormFieldNames } from "../../../../../../constants"
import { getFilePath } from "../../../../../../utils/general.utils"
import UploadDocument from "../../arrivalvisa/components/uploadDocument"
import UploadFinalLetter from "../components/uploadfinalLetter"

interface UpdateFreeTrekkingPermitProps {}

const userPayload = {
  fullName: "",
  country: "",
  gender: "",
  passportNo: "",
  dob: "",
  passportImage: null,
  ministryDecisionDate: "",
  dateOfCorrespondence: "",
  trekkingDuration: "",
  trekkingLocation: "",
  diplomaticBody: "",
  designation: "",
}

export default function UpdateFreeTrekkingPermit(props: UpdateFreeTrekkingPermitProps): ReactElement {
  const [form] = Form.useForm()
  const [users, setUsers] = useState<Array<any>>([])
  let { id } = useParams()
  const refs = {
    uploadTippaniModalRef: React.createRef<SelfControlModalRefsHandler>(),
    uploadFinalLetterModalRef: React.createRef<SelfControlModalRefsHandler>(),
    uploadDocumentModalRef: React.createRef<SelfControlModalRefsHandler>(),
    editFinalLetterModalRef: React.createRef<SelfControlModalRefsHandler>(),
  }
  const [state, setState] = useState<{
    loading: boolean
    fetchLoading: boolean
    fetchError: any
    fetchedData: any
    sendLinkToEntryForm: boolean
    generateFinalLetterLoading: boolean
    rawHTML?: string
  }>({
    loading: false,
    fetchLoading: true,
    fetchError: null,
    fetchedData: null,
    sendLinkToEntryForm: true,
    generateFinalLetterLoading: false,
    rawHTML: "",
  })

  const generateFinalLetter = () => {
    recordHttpService
      .generateFinalLetter(state?.fetchedData?.id)
      .then((res) => {
        setState({
          ...state,
          rawHTML: res.html,
          generateFinalLetterLoading: false,
        })
        refs.editFinalLetterModalRef.current?.show?.()
      })
      .catch((err) => {
        toast.error(err.message || "Error generation final letter")
        setState({ ...state, generateFinalLetterLoading: false })
      })
  }

  const fetchByIdAndSetState = () => {
    const query = qs.stringify(
      {
        populate: [
          "enteredBy",
          "pharchyotPerson",
          "document",
          "finalLetters",
          "finalLetters.pdfLetter",
          "uploadedTippanies",
          "usrEntries",
          "usrEntries.passportImage",
        ],
        filters: {
          recordType: {
            $eq: RecordTypesEnum.FreeTrekkingPermit,
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    setState({ ...state, fetchLoading: true, fetchError: null })
    recordHttpService
      .fetchRecordById(id || "", query)
      .then((res) => {
        form.setFieldsValue({
          dartaNo: res?.data?.attributes?.dartaNo,
          invoiceNo: res?.data?.attributes?.invoiceNo,
          chalaniNo: res?.data?.attributes?.chalaniNo,
          invoiceDate: res?.data?.attributes?.invoiceDate,
          recordType: res?.data?.attributes?.recordType,
          correspondenceBody: res?.data?.attributes?.correspondenceBody,
          pharchyotPerson: res?.data?.attributes?.pharchyotPerson?.data?.id,
          recommendingDiplomaticAgency: res?.data?.attributes?.recommendingDiplomaticAgency,
          fromTrekkingDuration: res?.data?.attributes?.fromTrekkingDuration,
          toTrekkingDuration: res?.data?.attributes?.toTrekkingDuration,
          trekkingDistrict: res?.data?.attributes?.trekkingDistrict,
          trekkingLocation: res?.data?.attributes?.trekkingLocation,
          ministryDecisionDate: res?.data?.attributes?.ministryDecisionDate,
          dateOfCorrespondence: res?.data?.attributes?.dateOfCorrespondence,
          isDocumentVerified: res?.data?.attributes?.isDocumentVerified,
        })
        setUsers(res?.data?.attributes?.usrEntries || [])
        setState({ ...state, fetchLoading: false, fetchedData: res.data })
      })
      .catch((err) => {
        setState({ ...state, fetchLoading: false, fetchError: err })
      })
  }

  useEffect(() => {
    fetchByIdAndSetState()
  }, [])

  const onFinish = (values: any) => {
    setState({ ...state, loading: true })
    delete values.dartaNo
    recordHttpService
      .updateRecord(state?.fetchedData?.id || "", {
        ...values,
        usrEntries: users,
      })
      .then((res) => {
        setState({ ...state, loading: false })
        toast.success(`Updated`)
      })
      .catch((err) => {
        setState({ ...state, loading: false })
        console.log("Err", err)
        toast.error(err?.message || "Error updating")
      })
  }

  if (state.fetchLoading) {
    return (
      <Card>
        <div
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </Card>
    )
  }

  if (state.fetchError) {
    return (
      <Card
        style={{
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography>{state.fetchError?.message || "Error occured"}</Typography>
        <Button type="primary" style={{ backgroundColor: "red" }} onClick={() => fetchByIdAndSetState()}>
          Try Again
        </Button>
      </Card>
    )
  }

  return (
    <div>
      <div className="site-card-border-less-wrapper">
        <Card bordered={false}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              dartaNo: "0",
              invoiceNo: "",
              chalaniNo: "",
              invoiceDate: "",
              recordType: "",
              correspondenceBody: "",
              pharchyotPerson: null,
              recommendingDiplomaticAgency: "",
              fromTrekkingDuration: "",
              toTrekkingDuration: "",
              trekkingDistrict: "",
              trekkingLocation: "",
              ministryDecisionDate: "",
              dateOfCorrespondence: "",
            }}
          >
            <Form.Item className="sticky-header">
              <Space style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography.Title level={3} className="he-title">
                    नि: शुल्क ट्रेकिङ अनुमति
                  </Typography.Title>
                  <Typography.Title style={{ marginTop: "-1rem" }}>
                    {state?.fetchedData?.attributes?.status === RecordStatus.INPROGRESS ? (
                      <Tag color="warning">{state?.fetchedData?.attributes?.status}</Tag>
                    ) : state?.fetchedData?.attributes?.status === RecordStatus.CREATED ? (
                      <Tag color="blue">{state?.fetchedData?.attributes?.status}</Tag>
                    ) : (
                      <Tag color="success">{state?.fetchedData?.attributes?.status}</Tag>
                    )}
                  </Typography.Title>
                </div>
                <Button type="primary" htmlType="submit" loading={state.loading}>
                  Save Changes
                </Button>
              </Space>
            </Form.Item>
            <Divider />
            <Row gutter={10}>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="दर्ता नं." help="Auto generated by system" name="dartaNo">
                  <Input readOnly disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्र संख्या" name="invoiceNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="चलानी नं." name="chalaniNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्र मिती" name="invoiceDate">
                  <NPDatePicker />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्राचार गर्ने निकाय" name="correspondenceBody">
                  <RecommendingBodySelector
                    onClickAddOtherItem={(text) => {
                      form.setFieldValue("correspondenceBody", text)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="फर्छ्योट गर्ने व्यक्ति" name={"pharchyotPerson"}>
                  <EmployeeSelector />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="सिफारिस गर्ने निकाय" name="recommendingDiplomaticAgency">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पदयात्रा अबधि (देखि)" name="fromTrekkingDuration">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पदयात्रा अबधि (सम्म)" name="toTrekkingDuration">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="जिल्ला" name="trekkingDistrict">
                  <DistrictSelector />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पदयात्रा क्षेत्र" name="trekkingLocation">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="मन्त्रालयको निर्णय मिती" name="ministryDecisionDate">
                  <NPDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Form.Item label="पत्राचार हुने मिती" name="dateOfCorrespondence">
                  <NPDatePicker />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <div>
              <div style={{ marginBottom: 20 }}>
                <div style={{ marginBottom: 20 }}>
                  <Typography.Title level={4} className="he-subtitle">
                    विदेशी नागरिकको लागि
                  </Typography.Title>
                </div>
              </div>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {users?.map?.((user, index) => {
                  return (
                    <UserEntryFormRow
                      fields={freetrekkingUserEntryFormFieldNames}
                      key={index}
                      index={index}
                      values={user}
                      onRemove={(ind) => {
                        const newUsers = [...users]
                        newUsers.splice(ind, 1)
                        setUsers(newUsers)
                      }}
                      onReset={() => {
                        const newUsers = [...users]
                        newUsers[index] = userPayload
                        setUsers(newUsers)
                      }}
                      onValuesChange={(values) => {
                        // console.log("values", values)
                        const newUsers = [...users]
                        newUsers[index] = values
                        setUsers(newUsers)
                      }}
                    />
                  )
                })}
              </Row>
              <Space style={{ marginTop: 10, marginBottom: 20 }}>
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    const newUsers = [...users]
                    newUsers.push(userPayload)
                    setUsers(newUsers)
                  }}
                  disabled={state.loading}
                >
                  थप्नुहोस्
                </Button>
              </Space>
            </div>
          </Form>
          <Divider />
          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 20 }}>
              {state.fetchedData?.attributes?.document?.data && (
                <Form.Item
                  label={<Typography.Title level={5}>कागजात प्रमाणित छ</Typography.Title>}
                  name="isDocumentVerified"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              )}
              <Divider />
              <Typography.Title level={4} className="he-subtitle" style={{ marginTop: 5 }}>
                कागजात
              </Typography.Title>
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {state.fetchedData?.attributes?.document?.data && (
                <a
                  href={getFilePath(state.fetchedData?.attributes?.document.data?.attributes?.url)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PdfViewer fileUrl={getFilePath(state.fetchedData?.attributes?.document.data?.attributes?.url)} />
                </a>
              )}
            </div>
            <Space style={{ marginTop: 10, marginBottom: 20 }}>
              <SelfControlModal
                anchorElement={
                  <Button type="primary" icon={<CloudUploadOutlined />} disabled={state.loading}>
                    Upload कागजात
                  </Button>
                }
                title="Upload कागजात"
                width={1000}
                ref={refs.uploadDocumentModalRef}
              >
                <UploadDocument record={state.fetchedData} modalRef={refs.uploadDocumentModalRef} />
              </SelfControlModal>
            </Space>
          </div>
          <Divider />
          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 20 }}>
              <Typography.Title level={4} className="he-subtitle">
                अन्तिम पत्र
              </Typography.Title>
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {state.fetchedData?.attributes?.finalLetters?.data?.map?.((t: any, i: number) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    key={i}
                  >
                    <a
                      href={getFilePath(t?.attributes?.pdfLetter?.data?.attributes?.url)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PdfViewer fileUrl={getFilePath(t?.attributes?.pdfLetter?.data?.attributes?.url)} />
                    </a>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <span style={{ marginRight: 5 }}>चलानी नं.</span>
                      <span>{t?.attributes?.chalaniNo || "--"}</span>
                    </div>
                  </div>
                )
              })}
            </div>
            <Space style={{ marginTop: 10, marginBottom: 20 }}>
              <Button
                type="primary"
                icon={<CloudDownloadOutlined />}
                disabled={state.loading}
                loading={state.generateFinalLetterLoading}
                onClick={generateFinalLetter}
              >
                Generate Final Letter
              </Button>
              <SelfControlModal
                anchorElement={
                  <Button type="primary" icon={<CloudUploadOutlined />} disabled={state.loading}>
                    Upload Final Letter
                  </Button>
                }
                title="Upload Final Letter"
                width={1000}
                ref={refs.uploadFinalLetterModalRef}
              >
                <UploadFinalLetter record={state.fetchedData} modalRef={refs.uploadFinalLetterModalRef} />
              </SelfControlModal>
            </Space>
          </div>
        </Card>
      </div>

      <SelfControlModal title="Final Letter EDITOR" width={"80%"} ref={refs.editFinalLetterModalRef}>
        <Editor html={state.rawHTML} />
      </SelfControlModal>
    </div>
  )
}
