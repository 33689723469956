import { Select } from "antd"
import { setFiscalYear } from "../../../features/global/globalSlice"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { generateFiscalYears } from "../../../utils/general.utils"
interface FiscallDropdownProps {}

export default function FiscallDropdown(props: FiscallDropdownProps) {
  const generatedFiscalYears = generateFiscalYears(5, 5)
  const selectedFiscalYear = useAppSelector((state) => state.global.appSettings.selectedFiscallYear)
  const dispatch = useAppDispatch()
  return (
    <Select
      //   defaultValue={generatedFiscalYears.currentFiscalYear.fiscalYear}
      value={selectedFiscalYear.fiscalYear}
      style={{ width: 120 }}
      onChange={(_, opt: any) => {
        dispatch(setFiscalYear(opt?.data))
      }}
      options={generatedFiscalYears.list.map((f) => ({
        label: f.fiscalYear,
        value: f.fiscalYear,
        data: f,
      }))}
    />
  )
}
