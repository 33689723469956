import { createAsyncThunk } from "@reduxjs/toolkit";
import templateHttpService from "../../services/https/apis/template.http.service";
export const fetchTemplates = createAsyncThunk(
  "templates/fetchTemplates",
  async () => {
    return templateHttpService.fetchTemplates()
  }
);

export const createTemplate = createAsyncThunk(
  "templates/createTemplate",
  async (payload: Record<string,any>) => {
    return templateHttpService.createTemplate(payload)
  }
);

export const updateTemplate = createAsyncThunk(
  "templates/updateTemplate",
  async (id: string, payload: Record<string,any>) => {
    return templateHttpService.updateTemplate(id,payload)
  }
);

export const deleteTemplate = createAsyncThunk(
  "templates/deleteTemplate",
  async (id: string) => {
    return templateHttpService.deleteTemplate(id)
  }
);
