import { createSlice } from '@reduxjs/toolkit'
import { fetchRecords} from './recordsThunk'

// Define a type for the slice state
export interface RecordState {
    entities: any[]
    loading: boolean
    error:any
}


// Define the initial state using that type
const initialState: RecordState = {
    entities: [],
    loading: false,
    error:null
}

export const recordsSlice = createSlice({
  name: 'records',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
  },
  extraReducers:(builder)=> {
    //Fetch
      builder.addCase(fetchRecords.pending,(state,action)=>{
        state.loading=true
        state.error=null
      })
      builder.addCase(fetchRecords.fulfilled,(state,action)=>{
        state.loading=false
        state.entities=action.payload.data as []
      })
      builder.addCase(fetchRecords.rejected,(state,action)=>{
        state.loading=false
        state.error=action.error
      })
  },
})

export const recordsReducer = recordsSlice.reducer