import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popover,
  Row,
  Space,
  Table,
  theme,
  Typography,
} from "antd";
import React, { ReactElement, useState, useEffect } from "react";
import {
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { ColumnsType } from "antd/es/table";
import templateHttpService from "../../../../../services/https/apis/template.http.service";
import DeletePopConfirmed from "../../../../../components/common/deletepopconfirmed";

interface ListBibidhTemplatesProps {}

export default function ListBibidhTemplates(props: ListBibidhTemplatesProps): ReactElement {
  const [state, setState] = useState<{
    loading: boolean;
    error: any;
    data: any[];
  }>({
    loading: true,
    error: null,
    data: [],
  });
  const { token } = theme.useToken();

  const fetchData = () => {
    const params = {
      filters: {
        templateType: {
          $eq: "Bibidh",
        },
      },
    };
    setState({ ...state, loading: true, error: null });
    templateHttpService
      .fetchTemplates({ params })
      .then((res) => {
        setState({ ...state, loading: false, data: res.data });
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // TODO: filter content
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    setState({ ...state, loading: true, error: null });
    const params = {
      filters: {
        templateType: {
          $eq: "Bibidh",
        },
        $or: [
          {
            name: values.title,
          },
        ],
      },
    };
    templateHttpService
      .fetchTemplates({ params: params })
      .then((res) => {
        setState({ ...state, loading: false, data: res.data });
      })
      .catch((err) => {
        setState({ ...state, loading: false, error: err });
      });

    setOpen(false);
  };

  const content = (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Row gutter={10} style={{ width: "20vw" }}>
        <Col span={24}>
          <Form.Item label="नाम" name="name">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={state.loading}>
            Filter
          </Button>
          <Button
            type="primary"
            htmlType="reset"
            style={{ backgroundColor: token.colorWarning }}
            disabled={state.loading}
            // onClick={() => setOpen(false)}
          >
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );

  const renderTableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Button
          type="dashed"
          icon={<ReloadOutlined />}
          onClick={() => fetchData()}
        >
          रिफ्रेस गर्नुहोस्
        </Button>
        <Space>
          <Link to="/dashboard/bibidhs/create">
            <Button type="dashed" icon={<PlusOutlined />}>
              थप्नुहोस्
            </Button>
          </Link>
          <Popover
            content={content}
            title="फिल्टर गर्नुहोस्"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button type="dashed" icon={<FilterOutlined />}>
              फिल्टर गर्नुहोस्
            </Button>
          </Popover>
        </Space>
      </div>
    );
  };

  const renderTable = () => {
    if (state.error) {
      return (
        <Card
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{state.error?.message || "Error occured"}</Typography>
          <Button
            type="primary"
            style={{ backgroundColor: "red" }}
            onClick={() => fetchData()}
          >
            Try Again
          </Button>
        </Card>
      );
    }

    if (state.loading) {
      return (
        <Card>
          {renderTableHeader()}
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </Card>
      );
    }

    const dataSource = state.data.map((f) => ({
      id: f?.id,
      name: f?.attributes?.name,
    }));

    const columns: ColumnsType<{}> = [
      {
        title: "नाम",
        dataIndex: "name",
        key: "name",
        width: "80%",
      },
      {
        title: "कार्यहरु",
        key: "action",
        render: (_: any, record: any) => (
          <Space size="middle">
            <Link to={`/dashboard/bibidhs/update/${record?.id}`}>
              <EditOutlined
                style={{ cursor: "pointer", color: token.colorWarning }}
              />
            </Link>

            <DeletePopConfirmed
              title="Really want to delete?"
              actionPromiseFunction={() =>
                templateHttpService.deleteTemplate(record?.id).then((res) => {
                  message.success("deleted");
                  fetchData();
                })
                .catch(err=>{
                  message.error(err?.message||"Error deleting");
                })
              }
            />
          </Space>
        ),
      },
    ];

    return (
      <Card>
        {renderTableHeader()}
        <Table
          dataSource={dataSource}
          scroll={{ x: "auto" }}
          columns={columns}
        />
      </Card>
    );
  };

  return <div>{renderTable()}</div>;
}
