import { Button, Typography } from "antd"
import { ReactElement, useRef, useState } from "react"
import { RecordI } from "../../../../../constants/recordList"
import CurrentUserPolicy from "../../../../../features/uipolicies/currentuserpolicy"
import { RecordTypesEnum } from "../../../../../types/typings.d"
interface RegistrationHomeProps {}

export default function RegistrationHome(props: RegistrationHomeProps): ReactElement {
  const [selectedRecord, setSelectedRecord] = useState<RecordI | undefined>(undefined)

  const records = CurrentUserPolicy.getInstance().getRecords()

  const filteredRecords = records.filter((record) => record.type !== RecordTypesEnum.All)

  const ref = useRef<HTMLDivElement | null>(null)

  const selectRecord = (record: RecordI) => {
    setSelectedRecord(record)
    ref.current?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div>
      <Typography.Title level={4} className="he-subtitle">
        दर्ता प्रकार
      </Typography.Title>
      {/* <Select
        style={{ minWidth: 300 }}
        onChange={(recordType) => {
          const record = Records.find((f) => f.type === recordType);
          setSelectedRecord(record);
        }}
        placeholder="दर्ता प्रकार छान्नुहोस्"
        options={CurrentUserPolicy.getInstance()
          .getRecords()
          ?.filter((f) => f.type !== RecordTypesEnum.All)
          .map((record) => {
            return {
              label: (
                <span style={{ verticalAlign: "middle" }}>
                  {record.icon({
                    style: { verticalAlign: "middle", marginRight: "10px" },
                  })}
                  {record.title}
                </span>
              ),
              value: record.type,
            };
          })}
      /> */}
      <ul>
        {filteredRecords.map((record, index) => (
          <li
            key={record.type}
            style={
              index % 2 === 0
                ? { backgroundColor: "#efefef", listStyle: "none", marginBottom: "7px" }
                : { listStyle: "none", marginBottom: "7px" }
            }
          >
            <span style={{ backgroundColor: "green", padding: "5px 8px", borderRadius: "5px", color: "#fff" }}>
              {record.shortName}
            </span>
            <Button type="text" onClick={() => selectRecord(record)} style={{ fontSize: "16px" }}>
              {/* {record.icon({
                style: { verticalAlign: "middle", marginRight: "10px" },
              })} */}
              <strong>{record.title}</strong>
            </Button>
          </li>
        ))}
      </ul>
      <div style={{ marginTop: 20 }} ref={ref}>
        {selectedRecord?.recordCreateComponent}
      </div>
    </div>
  )
}
