import { Button, Select, Space, Table } from "antd";
import Upload from "antd/es/upload/Upload";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import recordHttpService from "../../../../../../services/https/apis/records.http.service";
import { toast } from "react-toastify";
import { SelfControlModalRefsHandler } from '../../../../../../components/common/selfcontrolmodal/index';

type UploadTippaniProps = {
  record: any;
  modalRef:React.RefObject<SelfControlModalRefsHandler>
};

export default function UploadTippani({ record,modalRef }: UploadTippaniProps) {
  const [state, setState] = useState<{
    usrEntries: Array<any>;
    tippaniFile: any;
    loading: boolean;
  }>({
    usrEntries: record?.attributes?.usrEntries || [],
    tippaniFile: null,
    loading: false,
  });

  const uploadTippani = () => {
    const { usrEntries, tippaniFile } = state;
    if(!tippaniFile){
        return toast.info("Please select टिप्पणी to upload ")
    }
    const formData = new FormData();
    formData.append(
      "files.uploadedTippanies",
      tippaniFile,
      tippaniFile?.name || ""
    );
    formData.append("data", JSON.stringify({ usrEntries: usrEntries }));
    setState({ ...state, loading: true });
    recordHttpService
      .uploadTipaani(record.id, formData)
      .then((res) => {
        setState({ ...state, loading: false });
        toast.success("Uploaded");
        modalRef?.current?.hide?.()
      })
      .catch((err) => {
        console.log("Error", err);
        setState({ ...state, loading: false });
        toast.error(err?.message || "Error uploading");
      });
  };

  return (
    <div>
      <Table
        columns={[
          {
            title: "नाम/थर",
            dataIndex: "fullName",
            key: "fullName",
          },
          {
            title: "देश",
            dataIndex: "country",
            key: "country",
          },
          {
            title: "लिङ्ग",
            dataIndex: "gender",
            key: "gender",
          },
          {
            title: "राहदानी नं",
            dataIndex: "passportNo",
            key: "passportNo",
          },
          {
            title: "Actions",
            key: "action",
            render: (_: any, re: any, index: number) => {
              return (
                <Select
                  defaultValue={re?.status || "Approved"}
                  options={[
                    { value: "Approved", label: "Approved" },
                    { value: "Rejected", label: "Rejected" },
                  ]}
                  onChange={(value) => {
                    const newUsrEntries = [...state.usrEntries];
                    newUsrEntries[index].status = value;
                  }}
                />
              );
            },
          },
        ]}
        dataSource={state.usrEntries}
        pagination={false}
      />
      <Upload
        multiple={false}
        beforeUpload={() => false}
        onChange={(info) => {
          setState({ ...state, tippaniFile: info.file });
        }}
        accept="application/pdf"
      >
        <Button icon={<UploadOutlined />} style={{ marginTop: 20 }}>
          Click to Select टिप्पणी
        </Button>
      </Upload>

      <Space
        style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}
      >
        <Button type="primary" onClick={uploadTippani}>
          Upload टिप्पणी
        </Button>
      </Space>
    </div>
  );
}
