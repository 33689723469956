import { createAsyncThunk } from "@reduxjs/toolkit";
import designationHttpService from "../../services/https/apis/designation.http.service";
export const fetchDesignations = createAsyncThunk(
  "designations/fetchDesignations",
  async () => {
    return designationHttpService.fetchDesignations();
  }
);

export const createDesignation = createAsyncThunk(
  "designations/createDesignation",
  async (payload: Record<string, any>) => {
    return designationHttpService.createDesignation(payload);
  }
);

export const updateDesignation = createAsyncThunk(
  "designations/updateDesignation",
  async (updatePayload:{id: string, payload: Record<string, any>}) => {
    return designationHttpService.updateDesignation(updatePayload.id, updatePayload.payload);
  }
);

export const deleteDesignation = createAsyncThunk(
  "designations/deleteDesignation",
  async (id: string) => {
    return designationHttpService.deleteDesignation(id);
  }
);
