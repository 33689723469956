import React from "react"
import ListAllRecords from "../pages/dashboard/records/list"
import CreateArrivalVisa from "../pages/dashboard/records/registration/arrivalvisa/create"
import ListArrivalVisa from "../pages/dashboard/records/registration/arrivalvisa/list"
import CreateDeportion from "../pages/dashboard/records/registration/deportation/create"
import ListDeportion from "../pages/dashboard/records/registration/deportation/list"
import CreateFlightPermission from "../pages/dashboard/records/registration/flightpermission/create"
import ListFlightPermission from "../pages/dashboard/records/registration/flightpermission/list"
import CreateFreeArrivalVisa from "../pages/dashboard/records/registration/freearrivalvisa/create/index"
import ListFreeArrivalVisa from "../pages/dashboard/records/registration/freearrivalvisa/list"
import CreateFreeTrekkingPermit from "../pages/dashboard/records/registration/freetrekkingpermit/create"
import ListFreeTrekkingPermit from "../pages/dashboard/records/registration/freetrekkingpermit/list"
import CreateFreeVisaExtension from "../pages/dashboard/records/registration/freevisaextension/create"
import ListFreeVisaExtension from "../pages/dashboard/records/registration/freevisaextension/list"
import CreateORUpdateBorderForm from "../pages/dashboard/records/registration/internationalborder/components/createOrUpdateForm/createOrUpdateForm"
import ListInternationalBoarder from "../pages/dashboard/records/registration/internationalborder/list"
import CreateLabourConcurrance from "../pages/dashboard/records/registration/labourconcurrance/create"
import ListLabourConcurrance from "../pages/dashboard/records/registration/labourconcurrance/list"
import CreateMultipleEntryVisa from "../pages/dashboard/records/registration/multipleentryvisa/create"
import ListMultipleEntryVisa from "../pages/dashboard/records/registration/multipleentryvisa/list"
import CreateNrnStudyVisa from "../pages/dashboard/records/registration/nrnstudyvisa/create"
import ListNrnStudyVisa from "../pages/dashboard/records/registration/nrnstudyvisa/list"
import CreateTravelDocument from "../pages/dashboard/records/registration/traveldocument/create"
import ListTravelDocument from "../pages/dashboard/records/registration/traveldocument/list"
import { RecordTypesEnum } from "../types/typings.d"
const ICON_SIZE = 20

interface IIconProps extends React.ComponentProps<"span"> {}

export interface RecordI {
  id: number
  type: RecordTypesEnum
  title: string
  shortName: string
  subtitle: string
  icon: ({ style, className, ...props }: IIconProps) => JSX.Element
  createRoute: string
  updateRoute: (id: string) => string
  viewRoute: (id: string) => string
  recordListComponent: JSX.Element
  recordCreateComponent: JSX.Element
}

export const Records: RecordI[] = [
  {
    id: 1,
    type: RecordTypesEnum.All,
    title: "सबै / ALL",
    shortName: "ALL",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        apps
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-labourconcurrance",
    updateRoute: (id: string) => `/dashboard/records/registration/update-labourconcurrance/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-labourconcurrance/${id}`,
    recordListComponent: <ListAllRecords />,
    recordCreateComponent: <CreateLabourConcurrance />,
  },
  {
    id: 2,
    type: RecordTypesEnum.LabourConcurrence,
    title: "कार्य सहमति / Labour Concurrence ",
    shortName: "LC",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        local_mall
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-labourconcurrance",
    updateRoute: (id: string) => `/dashboard/records/registration/update-labourconcurrance/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-labourconcurrance/${id}`,
    recordListComponent: <ListLabourConcurrance />,
    recordCreateComponent: <CreateLabourConcurrance />,
  },
  {
    id: 3,
    title: "आगमन नि: शुल्क भिसा / Free Visa on Arrival ",
    type: RecordTypesEnum.FreeArrivalVisa,
    shortName: "FVA",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        south
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-freearrivalvisa",
    updateRoute: (id: string) => `/dashboard/records/registration/update-freearrivalvisa/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-freearrivalvisa/${id}`,
    recordListComponent: <ListFreeArrivalVisa />,
    recordCreateComponent: <CreateFreeArrivalVisa />,
  },
  {
    id: 4,
    type: RecordTypesEnum.FlightPermission,
    title: "उडान अनुमति / Flight Permission ",
    shortName: "FP",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        flight
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-flightpermission",
    updateRoute: (id: string) => `/dashboard/records/registration/update-flightpermission/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-flightpermission/${id}`,
    recordListComponent: <ListFlightPermission />,
    recordCreateComponent: <CreateFlightPermission />,
  },
  {
    id: 5,
    type: RecordTypesEnum.FreeVisaExtension,
    title: "नि: शुल्क भिसा / Free Visa ",
    shortName: "FV",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        extension
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-freevisaextension",
    updateRoute: (id: string) => `/dashboard/records/registration/update-freevisaextension/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-freevisaextension/${id}`,
    recordListComponent: <ListFreeVisaExtension />,
    recordCreateComponent: <CreateFreeVisaExtension />,
  },
  {
    id: 6,
    title: "निष्कासन / Deportation ",
    type: RecordTypesEnum.Deportation,
    shortName: "DP",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        arrow_outward
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-deportation",
    updateRoute: (id: string) => `/dashboard/records/registration/update-deportation/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-deportation/${id}`,
    recordListComponent: <ListDeportion />,
    recordCreateComponent: <CreateDeportion />,
  },
  {
    id: 7,
    type: RecordTypesEnum.MultipleEntryVisa,
    title: "बहु प्रवेश भिसा / Multiple Entry Visa ",
    shortName: "MV",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        keyboard_double_arrow_down
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-multipleentryvisa",
    updateRoute: (id: string) => `/dashboard/records/registration/update-multipleentryvisa/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-multipleentryvisa/${id}`,
    recordListComponent: <ListMultipleEntryVisa />,
    recordCreateComponent: <CreateMultipleEntryVisa />,
  },
  {
    id: 8,
    type: RecordTypesEnum.ArrivalVisa,
    title: "आगमन भिसा / Visa on Arrival ",
    shortName: "VA",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        south
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-arrivalvisa",
    updateRoute: (id: string) => `/dashboard/records/registration/update-arrivalvisa/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-arrivalvisa/${id}`,
    recordListComponent: <ListArrivalVisa />,
    recordCreateComponent: <CreateArrivalVisa />,
  },
  {
    id: 9,
    type: RecordTypesEnum.NrnStudyVisa,
    title: "एनआरएन अध्ययन भिसा / NRN Study Visa ",
    shortName: "SV",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        school
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-nrnstudyvisa",
    updateRoute: (id: string) => `/dashboard/records/registration/update-nrnstudyvisa/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-nrnstudyvisa/${id}`,
    recordListComponent: <ListNrnStudyVisa />,
    recordCreateComponent: <CreateNrnStudyVisa />,
  },
  {
    id: 10,
    type: RecordTypesEnum.FreeTrekkingPermit,
    title: "नि: शुल्क ट्रेकिङ अनुमति / Free Trekking Permit ",
    shortName: "TP",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        directions_run
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-freetrekkingpermit",
    updateRoute: (id: string) => `/dashboard/records/registration/update-freetrekkingpermit/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-freetrekkingpermit/${id}`,
    recordListComponent: <ListFreeTrekkingPermit />,
    recordCreateComponent: <CreateFreeTrekkingPermit />,
  },
  {
    id: 11,
    type: RecordTypesEnum.InternationalBorder,
    title: "अन्तर्राष्ट्रिय सिमाना / International Border ",
    shortName: "IB",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        my_location
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-internationalboarder",
    updateRoute: (id: string) => `/dashboard/records/registration/update-internationalboarder/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-internationalboarder/${id}`,
    recordListComponent: <ListInternationalBoarder />,
    recordCreateComponent: <CreateORUpdateBorderForm showTitle={true} />,
  },
  {
    id: 12,
    type: RecordTypesEnum.TravelDocument,
    title: "यात्रा कागजात/ Travel Document ",
    shortName: "TD",
    subtitle: "सरल डमी हो",
    icon: ({ style, className, ...props }: IIconProps) => (
      <span className={`${className} material-symbols-outlined`} style={{ fontSize: ICON_SIZE, ...style }} {...props}>
        departure_board
      </span>
    ),
    createRoute: "/dashboard/records/registration/create-traveldocument",
    updateRoute: (id: string) => `/dashboard/records/registration/update-traveldocument/${id}`,
    viewRoute: (id: string) => `/dashboard/records/registration/view-traveldocument/${id}`,
    recordListComponent: <ListTravelDocument />,
    recordCreateComponent: <CreateTravelDocument />,
  },
]

export const getRecordsAsObject = () => {
  return Records.reduce((a, b) => ({ ...a, [b.type]: { ...b } }), {})
}
