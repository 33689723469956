import { DatePicker, DatePickerProps } from "antd"
import dayjs from "dayjs"

type EnDatePickerProps = Omit<DatePickerProps, "onChange" | "value"> & {
  onChange?: (value: string) => void
  value?: string | null
}

export default function EnDatePicker({ onChange, value, ...props }: EnDatePickerProps) {
  return <DatePicker {...props} onChange={(_, dateString) => onChange?.(dateString)} value={value ? dayjs(value) : undefined} />
}
