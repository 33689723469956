import { AppWebRequest } from "../NetworkManager";
import { AxiosRequestConfig } from 'axios';

class AuditLogsHttpService {
  fetchAuditLogs(config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {};
    const {sort,populate,...otherParams}=params||{}
    return AppWebRequest({
      method: "get",
      url:"/audit-logs",
      ...axiosConfig,
      params:{
        sort:sort||["updatedAt:desc"],
        populate:populate||["actionBy", "record", "flightPermission"],
        ...otherParams
      }
    });
  }

  fetchTopRecentAuditLogs(config: AxiosRequestConfig<any>={}){
    config.params={...config.params,pagination:{
      limit:5
    }}
    return this.fetchAuditLogs(config)
  }

  createAuditLog(payload: {
    action: string;
    description?: string;
    modalName?: string;
    actionBy?: string;
    record?:string;
    flightPermission?:string
  }) {
    return AppWebRequest({
      method: "post",
      url: "/audit-logs",
      data: {data:payload},
    }).catch(err=>{
      console.log("Error creating audit logs",err)
    })
  }

  updateAuditLog(id: string, payload: Record<string, any>) {
    return AppWebRequest({
      method: "put",
      url: `/audit-logs/${id}`,
      data: {data:payload},
    });
  }

  deleteAuditLog(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/audit-logs/${id}`,
    });
  }
}

const auditLogsHttpService = new AuditLogsHttpService();
export default auditLogsHttpService;
