import { AxiosRequestConfig } from "axios";
import { AppWebRequest } from "../NetworkManager";
class RoleHttpService {
  fetchRoles(config?: AxiosRequestConfig<any>) {
    const { params, ...axiosConfig } = config || {};
    const {sort,populate,...otherParams}=params||{}
    return AppWebRequest({
      method: "get",
      url:"/users-permissions/roles",
      ...axiosConfig,
      params:{
        sort:sort||["updatedAt:desc"],
        ...otherParams
      }
    });
  }

  createRole(payload: Record<string, any>) {
    return AppWebRequest({
      method: "post",
      url: "/users-permissions/roles",
      data: payload,
    });
  }

  updateRole(id: string, payload: Record<string, any>) {
    return AppWebRequest({
      method: "put",
      url: `/users-permissions/roles/${id}`,
      data: payload,
    });
  }

  deleteRole(id: string) {
    return AppWebRequest({
      method: "delete",
      url: `/users-permissions/roles/${id}`,
    });
  }
}

const instance =  new RoleHttpService();
export default instance
