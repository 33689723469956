import { PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import { ReactElement, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAsyncRetry } from "react-use";
import CKHtmlEditor from "../../../../../components/ckeditor";
import DataFetchStateComponent from "../../../../../components/common/datafetchstatecomponent";
import templateHttpService from "../../../../../services/https/apis/template.http.service";

interface UpdateBibidhTemplateProps { }

export default function UpdateBibidhTemplate(
  props: UpdateBibidhTemplateProps
): ReactElement {
  const [form] = Form.useForm();
  const { id = "" } = useParams();
  const [state, setState] = useState({
    loading: false,
    sendLinkToEntryForm: true,
  });

  const dataState = useAsyncRetry(async () => {
    const res = await templateHttpService.fetchTemplate(id || "");
    form.setFieldsValue({
      name: res?.data?.attributes?.name,
      html: res?.data?.attributes?.html,
    });
  }, [id]);

  const onFinish = (values: any) => {
    templateHttpService
      .updateTemplate(id, {
        ...values,
      })
      .then((res) => {
        setState({ ...state, loading: false });
        toast.success(`Updated`);
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        toast.error(err?.message || "Error updating");
      });
  };

  const onPrint = () => {
    const content = form.getFieldValue("html");
    if (!content) {
      return message.error("No content to download");
    }
    var iFrame: any = document?.getElementById?.("ifmcontentstoprint");
    var pri = iFrame?.contentWindow;
    pri.document.open();
    pri.document.write(content);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <div>
      <Helmet>
        {/* inline style elements */}
        <style type="text/css">{`
        @print {
            @page :footer {
                display: none
            }

            @page :header {
                display: true
            }
        }

@media print {
    @page {
        margin-top: 0;
        margin-bottom: 0;
    }
    body {
        padding-top: 72px;
        padding-bottom: 72px ;
    }
}
    `}</style>
      </Helmet>
      <div className="site-card-border-less-wrapper">
        <Card
          title={<Typography.Title level={3} className="he-title">बिबिध</Typography.Title>}
          bordered={false}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              name: "",
            }}
            onValuesChange={(val) => console.log({ val })}
          >
            <DataFetchStateComponent
              loading={dataState.loading}
              error={Boolean(dataState.error)}
              onClickTryAgain={dataState.retry}
            >
              <>
                <Row gutter={10}>
                  <Col xs={24} md={12} xl={8}>
                    <Form.Item label="नाम" name="name">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <Form.Item label="टेम्प्लेट" name="html">
                      <CKHtmlEditor />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Space>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={state.loading}
                    >
                      Save
                    </Button>
                    <Button
                      icon={<PrinterOutlined />}
                      type="primary"
                      onClick={onPrint}
                    >
                      Print
                    </Button>
                  </Space>
                </Form.Item>
              </>
            </DataFetchStateComponent>
          </Form>
        </Card>
      </div>
      <iframe
        id="ifmcontentstoprint"
        style={{ height: "0px", width: "0px", position: "absolute" }}
      />
    </div>
  );
}
